import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'

export const NoMarginPadding = css`
  padding: 0;
  margin: 0;
`

export const Flex = css`
  display: flex;
`

export const FlexJustifyCenter = css(Flex, {
  justifyContent: 'center',
})

export const FlexAlignCenter = css(Flex, {
  alignItems: 'center',
})

export const FlexCenter = css(Flex, FlexJustifyCenter, FlexAlignCenter)

export const Divider = styled.hr`
  background-color: ${vars.$semantic.color.divider1};
  margin: 0;
  border: none;
  height: 0.0625rem;
`

export const VerticalDivider = styled.div<{ height: number; margin?: number }>`
  width: 1px;
  height: ${({ height }) => `${height}px`};
  background: ${vars.$scale.color.gray400};
  margin: 0 ${({ margin }) => (margin ? `${margin}px` : '0')};
`

const DividerWrapper = styled.div<{ padding: number }>`
  padding: ${({ padding }) => `0 ${padding}px`};
`

export const ListItemDivider = css`
  box-shadow: 0 1px 0 0 ${vars.$semantic.color.divider1};

  &:last-of-type {
    box-shadow: none;
  }
`

export const DividerWithPadding: React.FC<{ padding?: number }> = ({ padding = 0 }) => {
  return (
    <DividerWrapper padding={padding}>
      <Divider />
    </DividerWrapper>
  )
}

export const clickAfterDimm = css`
  position: relative;
  overflow: hidden;
  :before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.05);
    opacity: 0;
    transition: opacity 200ms;
    pointer-events: none;
  }
  :active:not(:focus-within):before {
    opacity: 1;
  }
  :disabled:before {
    content: none;
  }
`

export const clickAnchor = css`
  transition: opacity 200ms;
  opacity: 1;

  :active {
    opacity: 0.7;
  }
`

export const opacityOnActive = css`
  opacity: 1;

  &:active {
    opacity: 0.4;
  }
`

export const S_FullHeightProgressWrapper = styled.div<{ background?: string }>`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ background }) => (background ? background : 'transparent')};
`

const defaultButtonStyles = css`
  border-radius: 0.3125rem;
  font-weight: bold;
  font-size: 1rem;
  line-height: 140%;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: -0.02em;
  color: ${vars.$semantic.color.paperDefault};
  height: 2.5rem;
  width: 100%;
  transition: all 200ms;
  ${clickAfterDimm}
`

export const KarrotButton = styled.button`
  ${defaultButtonStyles}
  background: ${vars.$semantic.color.primary};
  color: ${vars.$semantic.color.onPrimary};

  :disabled,
  [disabled] {
    background: ${vars.$scale.color.gray300};
    color: ${vars.$scale.color.gray500};
  }
`

export const OutlineWhiteButton = styled.button`
  ${defaultButtonStyles}
  background: ${vars.$semantic.color.paperDefault};
  color: ${vars.$scale.color.gray900};
  border: 1px solid ${vars.$semantic.color.divider3};

  :disabled,
  [disabled] {
    border-color: ${vars.$scale.color.gray200};
    color: ${vars.$scale.color.gray400};
  }
`

export const Svg = styled.svg`
  display: block;
`

export const getFadeInStyle = (ms: number) => css`
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  animation: ${ms}ms fadeIn;
`

export const textDefaultStyle = css`
  line-height: 150%;
  letter-spacing: -0.02em;
`

export const EmptySpace = styled.div`
  flex: 1;
`

export const getEllipsisStyle = (line: number) =>
  line === 1
    ? css`
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      `
    : css`
        display: -webkit-box;
        word-break: break-word;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: normal;
        -webkit-line-clamp: ${line};
        -webkit-box-orient: vertical;
      `

export const removeScrollBar = css`
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`

export const Captions = styled.div`
  display: flex;
  align-items: center;
  ${vars.$semantic.typography.caption1Regular};
  color: ${vars.$scale.color.gray600};
`

export const Caption = styled.div`
  white-space: nowrap;

  &::before {
    content: '·';
    color: ${vars.$scale.color.gray600};
    margin: 0 0.25rem;
  }

  &:first-of-type {
    &::before {
      display: none;
    }
  }
`

export const activeTransition = css`
  transition: background-color 200ms;

  &:active {
    transition: background-color 0s;
  }
`

export const activeEffect = (type: 'hilight' | 'scale' = 'hilight') =>
  type === 'hilight'
    ? css`
        transition: background-color 200ms;
        cursor: pointer;
        -webkit-tap-highlight-color: transparent;

        &:active {
          background-color: ${vars.$scale.color.gray100};
          transition: background-color 0s;
        }
      `
    : css`
        transition: transform 300ms;
        cursor: pointer;
        -webkit-tap-highlight-color: transparent;

        &:active {
          transform: translateY(-0.125rem) scale(0.95);
          transition: 300ms;
        }
      `

export const noneActiveEffect = css`
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
`

export const noScrollbar = css`
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`

export const NonWebkitTabHighlight = css`
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
`

export const DotSeparator = css`
  &:not(:last-of-type)::after {
    font-weight: normal;
    content: '·';
    margin: 0 0.25rem;
  }
`

export const webviewFontFamily = css`
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
`
