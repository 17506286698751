import { type FC, useLayoutEffect } from 'react'
import { graphql, useLazyLoadQuery } from 'react-relay'

import HomeSection from './HomeSection'
import { type HomeLazySectionQuery, type SectionTypeEnum } from '../../__relay__/HomeLazySectionQuery.graphql'
import { type Position } from '../../currentPositionResource'
import { useViewer } from '../../hooks/useViewer'
import { type PropsOf } from '../../lib/T/utilTypes'
import withErrorBoundary from '../../lib/with-fallback/withErrorBoundary'

interface HomeLazySectionProps {
  fetchKey: number
  currentPosition: Position | null
  originalSectionType: SectionTypeEnum
  homeSectionProps: Omit<PropsOf<typeof HomeSection>, 'section'>
  onLazySectionFailed?: () => void
}

const HomeLazySection: FC<HomeLazySectionProps> = (props) => {
  const viewer = useViewer()

  const lazyQuery = useLazyLoadQuery<HomeLazySectionQuery>(
    graphql`
      query HomeLazySectionQuery($sectionType: SectionTypeEnum!, $regionId: Int!, $currentCoords: CoordsInput) {
        section(input: { sectionType: $sectionType, regionId: $regionId, currentCoords: $currentCoords }) {
          ...HomeSection_section
        }
      }
    `,
    {
      sectionType: props.originalSectionType,
      regionId: viewer.region.id || 0,
      currentCoords: props.currentPosition
        ? {
            lat: props.currentPosition.lat,
            lng: props.currentPosition.lng,
          }
        : null,
    },
    {
      fetchKey: props.fetchKey,
      fetchPolicy: 'store-and-network',
    }
  )

  useLayoutEffect(() => {
    if (!lazyQuery || !lazyQuery.section) {
      props.onLazySectionFailed?.()
    }
  }, [lazyQuery, props])

  if (!lazyQuery || !lazyQuery.section) {
    return null
  }

  return <HomeSection {...props.homeSectionProps} section={lazyQuery.section} />
}

export default withErrorBoundary(HomeLazySection, () => null)
