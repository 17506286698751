import styled from '@emotion/styled'
import { type FC } from 'react'
import { graphql, useFragment } from 'react-relay'

import { type BADGE_SIZE, type BADGE_TYPE } from '@src/components/_lib/BadgeSquare/token'

import { type Badges_badges$key, type NearbyBadgeEnum } from '../../../__relay__/Badges_badges.graphql'
import { type PropsOf } from '../../../lib/T/utilTypes'
import BadgeSquare from '../../_lib/BadgeSquare/BadgeSquare'
import { Flex } from '../../_lib/styles'

const BADGE_MAP: Record<NearbyBadgeEnum, { type?: BADGE_TYPE; label: string }> = {
  FAST_RESPONSE: {
    label: '연락을 잘해줘요',
  },
  WALK_10_MIN: {
    type: 'accent',
    label: '걸어서 10분',
  },
  COUPON_EXISTS: {
    label: '쿠폰',
  },
  DELIVERY: {
    label: '배송',
  },
  FREE_DELIVERY: {
    label: '무료배송',
  },
  SAME_DAY_DELIVERY: {
    label: '당일배송',
    type: 'accent',
  },
  MOST_REVIEW: {
    label: '후기 많은 업체',
    type: 'success',
  },
  MOST_VIEW: {
    label: '방문 많은 업체',
    type: 'success',
  },
  HAS_TRIAL: {
    label: '체험가능',
    type: 'accent',
  },
}

interface BadgeProps {
  className?: string
  badgeType: NearbyBadgeEnum
  size?: BADGE_SIZE
}

export const Badge: FC<BadgeProps> = ({ badgeType, className, size = 'medium' }) => {
  const { label, type } = BADGE_MAP[badgeType]

  return <BadgeSquare className={className} type={type} size={size} label={label} />
}

interface BadgesProps {
  className?: string
  badgesRef: Badges_badges$key
  badgeProps?: Omit<PropsOf<typeof Badge>, 'badgeType'>
}

export const Badges: FC<BadgesProps> = ({ className, badgesRef, badgeProps }) => {
  const badges = useFragment(
    graphql`
      fragment Badges_badges on Badge {
        badges
      }
    `,
    badgesRef
  )
  return (
    <Container className={className}>
      {badges.badges.map((badge) => (
        <Badge key={badge} badgeType={badge} {...badgeProps} />
      ))}
    </Container>
  )
}

const Container = styled.div`
  ${Flex};

  & > *:not(:last-child) {
    margin-right: 0.25rem;
  }
`
