import React, { FC, useEffect } from 'react'

import { sendPageView } from '../../lib/analytics/gtag'

interface GaPageViewWrapperProps {
  children: React.ReactNode
  name: string
  params: {
    [key: string]: string | undefined
  }
}

const GaPageViewWrapper: FC<GaPageViewWrapperProps> = ({ children, name, params }) => {
  useEffect(() => {
    sendPageView({
      title: name,
      params,
    })
  }, [name, params])

  return <React.Fragment>{children}</React.Fragment>
}

export default GaPageViewWrapper
