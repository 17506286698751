import { Pin } from '@daangn/karrotmaps-react'
import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { useEffect, useRef, type FC } from 'react'

import { Flex } from '@src/components/_lib/styles'

import { COUPON_MAP_Z_INDEX } from '../../../styles'
import { type ImagePinMarker } from '../../types'

type ImagePinProps = Omit<ImagePinMarker, 'position' | 'type'>

const ImagePin: FC<ImagePinProps> = ({ imageUrl, label, backgroundColor, isSelected }) => {
  const ref = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (ref.current?.parentElement) {
      ref.current.parentElement.style.zIndex = isSelected ? COUPON_MAP_Z_INDEX.SELECTED_PIN : '0'
    }
  }, [isSelected, label])

  return (
    <S_ImagePin ref={ref} backgroundColor={backgroundColor} data-type="marker-icon">
      <S_Pin>
        <Pin variation="pin-selected" />
        <Img src={imageUrl} alt={label} width={35} height={35} />
      </S_Pin>
    </S_ImagePin>
  )
}

export default ImagePin

const S_ImagePin = styled.div<{ backgroundColor?: string | null }>`
  ${Flex};
  flex-direction: column;
  svg {
    path {
      fill: ${({ backgroundColor }) => backgroundColor ?? vars.$semantic.color.primary};
    }
  }
`

const S_Pin = styled.div`
  ${Flex};
`

const Img = styled.img`
  border-radius: 50%;
  position: absolute;
  top: 0.1563rem;
  left: 0.8438rem;
  z-index: 9;
`
