import styled from '@emotion/styled'
import { useMemo } from 'react'
import { useFragment } from 'react-relay'
import { graphql } from 'relay-runtime'

import { type BizAccountListItem_BizAccount$key } from '@src/__relay__/BizAccountListItem_BizAccount.graphql'
import { activeEffect } from '@src/components/_lib/styles'
import { type PropsOf } from '@src/lib/T/utilTypes'
import { formatDistance } from '@src/lib/unit'

import NewCardItem from './NewCardItem'
import type { ListItemClickProp } from '../CategoryList'
import { useCategoryFilterOption } from '../hooks/useCategoryFilter'

interface BizAccountListItemProps {
  bizAccountRef: BizAccountListItem_BizAccount$key
  onClick: (props: ListItemClickProp) => void
  onClickWatch?: (props: { id: string; badges?: string[] }) => void
  isFeaturedImage: boolean
  itemType: string
}

const BizAccountListItem: React.FC<BizAccountListItemProps> = ({
  bizAccountRef,
  isFeaturedImage,
  onClick,
  onClickWatch,
  itemType,
}) => {
  const bizAccount = useFragment(
    graphql`
      fragment BizAccountListItem_BizAccount on BizAccount {
        id
        externalId
        name
        description
        categoryName
        badges
        hasCoupon
        onSale
        reservable
        isNew
        starRating
        distance {
          distance
          name
        }
        followerCount
        reviewCount
        image {
          url
          thumbnail
        }
        images {
          url
        }
        targetUri
        viewerIsWatched
      }
    `,
    bizAccountRef
  )

  const { subCategoryFilterOption } = useCategoryFilterOption()

  const badges = useMemo(() => {
    const arr: PropsOf<typeof NewCardItem>['badges'] = []

    if (bizAccount.hasCoupon) {
      arr.push({
        title: '쿠폰',
      })
    }
    if (bizAccount?.reservable) {
      arr.push({
        title: '바로예약',
      })
    }
    if (bizAccount?.onSale) {
      arr.push({
        title: '바로결제',
      })
    }
    if (bizAccount.badges.includes('HAS_TRIAL')) {
      arr.push({
        title: '체험가능',
        type: 'accent',
      })
    }

    return arr
  }, [bizAccount.badges, bizAccount.hasCoupon, bizAccount?.onSale, bizAccount?.reservable])

  const indicators = useMemo(() => {
    const arr: PropsOf<typeof NewCardItem>['indicators'] = []

    if (bizAccount?.reviewCount)
      arr.push({
        title: `후기 ${bizAccount.reviewCount}`,
      })
    if (bizAccount?.followerCount)
      arr.push({
        title: `단골 ${bizAccount.followerCount}`,
      })
    if (bizAccount?.distance)
      arr.push({
        title: formatDistance(bizAccount.distance) || '0',
      })

    return arr
  }, [bizAccount.distance, bizAccount.followerCount, bizAccount.reviewCount])

  const handleClick = () => {
    onClick({
      itemType: 'bizprofile',
      externalId: bizAccount.externalId,
      targetUri: bizAccount.targetUri,
      tags: badges.map(({ title }) => title),
    })
  }

  const isAllTab = !subCategoryFilterOption || subCategoryFilterOption === 'all'

  return (
    <S_CategoryNewListItemCard
      itemId={bizAccount.id}
      externalItemId={bizAccount.externalId}
      descriptionInfo={{
        text: bizAccount.description ?? '',
        ellipsisLine: 1,
      }}
      useNewTag={bizAccount?.isNew}
      title={bizAccount?.name}
      type={isFeaturedImage ? 'featured-image' : 'default'}
      imageUrls={bizAccount?.images.map((a) => a.url)}
      badges={badges}
      starRating={bizAccount.starRating}
      indicators={indicators}
      category={isAllTab ? bizAccount?.categoryName : undefined}
      isWatched={bizAccount.viewerIsWatched}
      onClick={handleClick}
      onClickWatch={onClickWatch}
      itemType={itemType}
    />
  )
}

export default BizAccountListItem

const S_CategoryNewListItemCard = styled(NewCardItem)`
  width: calc(100% - 0.5rem);

  ${activeEffect()};
`
