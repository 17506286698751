import { atom } from 'recoil'

import { type Experiments } from '@src/lib/experiment/ExperimentType'
import recoilKeyGenerator from '@src/lib/recoil-util'

const generateExperimentsKey = recoilKeyGenerator('nearby')

/**
 * 현재 진행중인 실험
 */
export const experimentsAtom = atom<Experiments>({
  key: generateExperimentsKey('experiments'),
  default: {},
})
