import { type FC } from 'react'
import { graphql, useLazyLoadQuery } from 'react-relay'

import { type HomeDirectoryQuery } from '@src/__relay__/HomeDirectoryQuery.graphql'
import { useViewer } from '@src/hooks/useViewer'

import HomePurposeLaunchpad from './HomePurposeLaunchpad/HomePurposeLaunchpad'

const HomeDirectory: FC = () => {
  const viewer = useViewer()

  const { directory } = useLazyLoadQuery<HomeDirectoryQuery>(
    graphql`
      query HomeDirectoryQuery($regionId: Int!) {
        directory(regionId: $regionId) {
          __typename
          ... on SectionPurposeLaunchpad {
            ...HomePurposeLaunchpad_SectionPurposeLaunchpad
          }
        }
      }
    `,
    {
      regionId: viewer.region.id || 0,
    }
  )

  const LaunchpadComponent = () => {
    switch (directory.__typename) {
      case 'SectionPurposeLaunchpad':
        return <HomePurposeLaunchpad sectionLaunchpad={directory} />
      default:
        return null
    }
  }

  return (
    <>
      <LaunchpadComponent />
    </>
  )
}

export default HomeDirectory
