/**
 * @generated SignedSource<<65f16f364c31027defe73ee5b0a89b4e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type BusinessAccountComponentEnum = "BUSINESS_ACCOUNT_COMPONENT_A";
export type BusinessPostComponentEnum = "BUSINESS_POST_COMPONENT_A";
export type ContentTypeEnum = "BUSINESS_ACCOUNT" | "BUSINESS_POST";
import { FragmentRefs } from "relay-runtime";
export type CustomSection_componentType$data = {
  readonly businessAccountFilter: {
    readonly componentType: BusinessAccountComponentEnum;
  } | null;
  readonly businessPostFilter: {
    readonly componentType: BusinessPostComponentEnum;
  } | null;
  readonly contentType: ContentTypeEnum;
  readonly " $fragmentType": "CustomSection_componentType";
};
export type CustomSection_componentType$key = {
  readonly " $data"?: CustomSection_componentType$data;
  readonly " $fragmentSpreads": FragmentRefs<"CustomSection_componentType">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "componentType",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CustomSection_componentType",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contentType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BusinessPostFilter",
      "kind": "LinkedField",
      "name": "businessPostFilter",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BusinessAccountFilter",
      "kind": "LinkedField",
      "name": "businessAccountFilter",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    }
  ],
  "type": "RequestInfo",
  "abstractKey": null
};
})();

(node as any).hash = "cee94defde24fbd2fa28feb5d90698da";

export default node;
