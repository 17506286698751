import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { useFragment } from 'react-relay'
import { graphql } from 'relay-runtime'

import { type HomeNativeReviewItemUser_userProfileNativeReview$key } from '@src/__relay__/HomeNativeReviewItemUser_userProfileNativeReview.graphql'
import { useLogger } from '@src/hooks/useLogger'

import { Badge } from './Badge'

interface HomeNativeReviewItemUserProps {
  userProfileNativeReview: HomeNativeReviewItemUser_userProfileNativeReview$key
  reviewId: string
}
const HomeNativeReviewItemUser: React.FC<HomeNativeReviewItemUserProps> = (props) => {
  const profile = useFragment<HomeNativeReviewItemUser_userProfileNativeReview$key>(
    graphql`
      fragment HomeNativeReviewItemUser_userProfileNativeReview on NativeReview {
        hoianUser {
          id
          image {
            thumbnail
          }
          nickname
        }
        startYearOfResidence
        visitCount
      }
    `,
    props.userProfileNativeReview
  )

  const { contentsSectionLog, localBizLog } = useLogger()
  const currentYear = new Date().getFullYear()
  const yearsOfResidency = profile.startYearOfResidence ? currentYear - profile.startYearOfResidence + 1 : undefined

  const handleReviewUserClick = () => {
    contentsSectionLog('click', {
      contentsType: 'native_reviews',
      buttonType: 'list',
      params: {
        click_target: 'user',
        user_id: profile.hoianUser.id,
      },
    })

    localBizLog({
      name: 'click_nearby_tobaki_section_profile',
      params: {
        review_id: props.reviewId,
        user_id: profile.hoianUser.id,
      },
    })
  }

  return (
    <Card onClick={handleReviewUserClick}>
      <Thumbnail>
        <img src={profile.hoianUser.image.thumbnail} />
      </Thumbnail>
      <Nickname>{profile.hoianUser?.nickname}</Nickname>
      <Badge yearsOfResidency={yearsOfResidency} />
    </Card>
  )
}

const Card = styled.div`
  margin: 0 0 0.5rem 0;
  display: flex;
  flex-direction: row;
  align-items: center;
`

const Thumbnail = styled.div`
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 0.375rem 0 0;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`

const Nickname = styled.div`
  ${vars.$semantic.typography.label3Bold}
  color: ${vars.$scale.color.gray900};
  margin: 0 0.25rem 0 0;
`

export default HomeNativeReviewItemUser
