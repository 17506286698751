import { atom } from 'recoil'

import { type Position } from '@src/currentPositionResource'

import { generateUserStateKey } from './key'

/**
 * 현재 유저의 위치
 */
export const currentPositionAtom = atom<Position | null>({
  key: generateUserStateKey('currentPosition'),
  default: null,
})
