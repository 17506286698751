import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { forwardRef, type PropsWithChildren } from 'react'

interface SectionContainerProps {
  [key: string]: any
}

const SectionContainer = forwardRef<HTMLElement, PropsWithChildren<SectionContainerProps>>(
  ({ children, ...props }, ref) => {
    return (
      <S_SectionContainer ref={ref} {...props}>
        {children}
      </S_SectionContainer>
    )
  }
)

export default SectionContainer

export const S_SectionContainer = styled.section`
  background-color: ${vars.$semantic.color.paperDefault};
`
