import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { noneActiveEffect } from '../styles'

export const SwipeItemCSSStyle = css`
  border-radius: 0.5rem;
  ${noneActiveEffect};
`

export const SwipeItem = styled.article`
  ${SwipeItemCSSStyle}
`
