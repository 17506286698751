import { Pin as _Pin } from '@daangn/karrotmaps-react'
import styled from '@emotion/styled'
import { type FC } from 'react'

import { FlexAlignCenter } from '@src/components/_lib/styles'

import { type PinMarker } from '../../types'

type PinProps = Omit<PinMarker, 'position' | 'type'>

const Pin: FC<PinProps> = ({ variation = 'pin-default-multi' }) => {
  return (
    <S_Pin>
      <_Pin variation={variation} />
    </S_Pin>
  )
}

export default Pin

const S_Pin = styled.div`
  ${FlexAlignCenter};
  flex-direction: column;
  width: 80px;
`
