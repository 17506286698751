/**
 * @generated SignedSource<<6e890e8fa715c422aa654a42b191f343>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FeedBusinessAccountSortOption = "CREATED_AT_DESC" | "DISTANCE_ASC" | "FOLLOWERS_COUNT_DESC" | "RANDOM" | "RECOMMENDATION" | "REVIEWS_COUNT_DESC";
export type FeedBusinessPostSortOption = "CREATED_AT_DESC" | "DISTANCE_ASC" | "SCORE_DESC";
export type FeedRange = "ADJACENT" | "MY" | "NONE" | "RANGE2" | "RANGE3";
export type FeedCoordsInput = {
  lat: number;
  lng: number;
};
export type FeedBusinessPostFilterInput = {
  coolingTime?: number | null;
  createdAfter?: any | null;
  createdInDays?: number | null;
  firstPostOfDatePerBusinessAccount?: boolean | null;
  hasCoupon?: boolean | null;
  isPublishable?: boolean | null;
  keywordInContent?: ReadonlyArray<string> | null;
  keywordInPost?: ReadonlyArray<string> | null;
  keywordInTitle?: ReadonlyArray<string> | null;
  minimumImageCount?: number | null;
  sizePerBusinessAccount?: number | null;
  sortOption?: FeedBusinessPostSortOption | null;
};
export type FeedBusinessAccountFilterInput = {
  coolingTime?: number | null;
  minPositiveReviewCount?: number | null;
  minScore?: number | null;
  sortOption?: FeedBusinessAccountSortOption | null;
};
export type LazyCustomListSectionQuery$variables = {
  bizAccountFilter?: FeedBusinessAccountFilterInput | null;
  bizPostFilter?: FeedBusinessPostFilterInput | null;
  coords?: FeedCoordsInput | null;
  count: number;
  createdAfter?: any | null;
  createdInDays?: number | null;
  excludeCategoryIds?: ReadonlyArray<number> | null;
  includeCategoryIds?: ReadonlyArray<number> | null;
  includeThemeIds?: ReadonlyArray<number> | null;
  isBizAccount: boolean;
  isBizPost: boolean;
  range: FeedRange;
  regionIds?: ReadonlyArray<number> | null;
};
export type LazyCustomListSectionQuery$data = {
  readonly contentCurator: {
    readonly businessAccounts?: {
      readonly __typename: "FeedBusinessAccountConnection";
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
        };
      }>;
    };
    readonly businessPosts?: {
      readonly __typename: "FeedBusinessPostConnection";
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly " $fragmentSpreads": FragmentRefs<"BusinessPostComponentA_bizPost">;
        };
      }>;
    };
  };
};
export type LazyCustomListSectionQuery = {
  response: LazyCustomListSectionQuery$data;
  variables: LazyCustomListSectionQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "bizAccountFilter"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "bizPostFilter"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "coords"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "count"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "createdAfter"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "createdInDays"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "excludeCategoryIds"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "includeCategoryIds"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "includeThemeIds"
},
v9 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isBizAccount"
},
v10 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isBizPost"
},
v11 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "range"
},
v12 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "regionIds"
},
v13 = [
  {
    "kind": "Variable",
    "name": "businessAccountCreatedAfter",
    "variableName": "createdAfter"
  },
  {
    "kind": "Variable",
    "name": "businessAccountCreatedInDays",
    "variableName": "createdInDays"
  },
  {
    "kind": "Variable",
    "name": "businessAccountExcludeCategoryIds",
    "variableName": "excludeCategoryIds"
  },
  {
    "kind": "Variable",
    "name": "businessAccountIncludeCategoryIds",
    "variableName": "includeCategoryIds"
  },
  {
    "kind": "Variable",
    "name": "businessAccountIncludeThemeIds",
    "variableName": "includeThemeIds"
  },
  {
    "kind": "Variable",
    "name": "coords",
    "variableName": "coords"
  },
  {
    "kind": "Variable",
    "name": "range",
    "variableName": "range"
  },
  {
    "kind": "Variable",
    "name": "regionIds",
    "variableName": "regionIds"
  }
],
v14 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "count"
},
v15 = [
  {
    "kind": "Variable",
    "name": "filter",
    "variableName": "bizPostFilter"
  },
  (v14/*: any*/)
],
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v18 = {
  "condition": "isBizAccount",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "filter",
          "variableName": "bizAccountFilter"
        },
        (v14/*: any*/)
      ],
      "concreteType": "FeedBusinessAccountConnection",
      "kind": "LinkedField",
      "name": "businessAccounts",
      "plural": false,
      "selections": [
        (v16/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "FeedBusinessAccountEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "FeedBusinessAccount",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v17/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ]
},
v19 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "url",
    "storageKey": null
  },
  (v17/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/),
      (v10/*: any*/),
      (v11/*: any*/),
      (v12/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "LazyCustomListSectionQuery",
    "selections": [
      {
        "alias": null,
        "args": (v13/*: any*/),
        "concreteType": "FeedContentCurator",
        "kind": "LinkedField",
        "name": "contentCurator",
        "plural": false,
        "selections": [
          {
            "condition": "isBizPost",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": null,
                "args": (v15/*: any*/),
                "concreteType": "FeedBusinessPostConnection",
                "kind": "LinkedField",
                "name": "businessPosts",
                "plural": false,
                "selections": [
                  (v16/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "FeedBusinessPostEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "FeedBusinessPost",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v17/*: any*/),
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "BusinessPostComponentA_bizPost"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ]
          },
          (v18/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v12/*: any*/),
      (v2/*: any*/),
      (v11/*: any*/),
      (v7/*: any*/),
      (v6/*: any*/),
      (v8/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v10/*: any*/),
      (v9/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "LazyCustomListSectionQuery",
    "selections": [
      {
        "alias": null,
        "args": (v13/*: any*/),
        "concreteType": "FeedContentCurator",
        "kind": "LinkedField",
        "name": "contentCurator",
        "plural": false,
        "selections": [
          {
            "condition": "isBizPost",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": null,
                "args": (v15/*: any*/),
                "concreteType": "FeedBusinessPostConnection",
                "kind": "LinkedField",
                "name": "businessPosts",
                "plural": false,
                "selections": [
                  (v16/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "FeedBusinessPostEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "FeedBusinessPost",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v17/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "externalId",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "title",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "createdAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": [
                              {
                                "kind": "Literal",
                                "name": "referrer",
                                "value": ""
                              }
                            ],
                            "kind": "ScalarField",
                            "name": "targetUri",
                            "storageKey": "targetUri(referrer:\"\")"
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "FeedImage",
                            "kind": "LinkedField",
                            "name": "images",
                            "plural": true,
                            "selections": (v19/*: any*/),
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "FeedBusinessAccount",
                            "kind": "LinkedField",
                            "name": "businessAccount",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "name",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "categoryName",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "FeedBusinessAccountViewerAttribute",
                                "kind": "LinkedField",
                                "name": "viewerAttribute",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "distance",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "hasViewerFollowed",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "FeedImage",
                                "kind": "LinkedField",
                                "name": "image",
                                "plural": false,
                                "selections": (v19/*: any*/),
                                "storageKey": null
                              },
                              (v17/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ]
          },
          (v18/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ff616c0e381507c34bb0ef572ea21833",
    "id": null,
    "metadata": {},
    "name": "LazyCustomListSectionQuery",
    "operationKind": "query",
    "text": "query LazyCustomListSectionQuery(\n  $count: Int!\n  $regionIds: [Int!]\n  $coords: FeedCoordsInput\n  $range: FeedRange!\n  $includeCategoryIds: [Int!]\n  $excludeCategoryIds: [Int!]\n  $includeThemeIds: [Int!]\n  $createdAfter: FeedLocalDateTime\n  $createdInDays: Int\n  $isBizPost: Boolean!\n  $isBizAccount: Boolean!\n  $bizPostFilter: FeedBusinessPostFilterInput\n  $bizAccountFilter: FeedBusinessAccountFilterInput\n) {\n  contentCurator(regionIds: $regionIds, coords: $coords, range: $range, businessAccountCreatedAfter: $createdAfter, businessAccountCreatedInDays: $createdInDays, businessAccountIncludeCategoryIds: $includeCategoryIds, businessAccountExcludeCategoryIds: $excludeCategoryIds, businessAccountIncludeThemeIds: $includeThemeIds) {\n    businessPosts(filter: $bizPostFilter, first: $count) @include(if: $isBizPost) {\n      __typename\n      edges {\n        node {\n          id\n          ...BusinessPostComponentA_bizPost\n        }\n      }\n    }\n    businessAccounts(filter: $bizAccountFilter, first: $count) @include(if: $isBizAccount) {\n      __typename\n      edges {\n        node {\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment BusinessPostComponentA_bizPost on FeedBusinessPost {\n  externalId\n  title\n  createdAt\n  targetUri(referrer: \"\")\n  images {\n    url\n    id\n  }\n  businessAccount {\n    name\n    categoryName\n    viewerAttribute {\n      distance\n      hasViewerFollowed\n    }\n    image {\n      url\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "78c86e3d3839c71f270101799b92b72f";

export default node;
