import { ThemeProvider } from '@emotion/react'
import React from 'react'
import { __, match } from 'ts-pattern'

import { karrotBridge } from '../sdk'

export const appTheme = match(karrotBridge.driver.getCurrentEnvironment())
  .with('Cupertino', () => 'cupertino' as const)
  .with(__, () => 'android' as const)
  .exhaustive()

export const MyThemeProvider: React.FCWithChildren = (props) => {
  const theme = {
    environment: appTheme,
  }

  return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
}
