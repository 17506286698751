import * as z from 'zod'

import { nearbyStorage } from './storage'

/**
 * Common Storage
 * ❗️ ui 변경사항을 다루는 storage값일 경우 recoil state와 syncWithStorage를 사용하자.
 */

const persisStateStorage = nearbyStorage
const KEY = 'common' // TODO. 각각 아이템에 대해 키 분리 해야됨. (아직 공용 키로 관리중)

type Setter<S> = (fn: (prevState: S) => S) => void

const SchemaNearbyStorage = z.object({
  isJobReviewBannerClose: z.boolean(),
  isViewJobThaksgivingBannerTooltip: z.boolean(),
  recentlyUseCategoryServiceIds: z.array(z.number().or(z.object({ id: z.number(), name: z.string().nullable() }))),
})

type ISchemaNearbyStorage = z.infer<typeof SchemaNearbyStorage>

export function initialPersistedState(): ISchemaNearbyStorage {
  const initialState: ISchemaNearbyStorage = {
    isJobReviewBannerClose: false,
    isViewJobThaksgivingBannerTooltip: false,
    recentlyUseCategoryServiceIds: [],
  }

  persisStateStorage.setItem(KEY, JSON.stringify(initialState))

  return initialState
}

export const getNearbyStorage = () => {
  const serialized = persisStateStorage.getItem(KEY)

  if (!serialized) {
    return initialPersistedState()
  }
  try {
    const parsed = JSON.parse(serialized)

    const ignoreExtraKeyState = SchemaNearbyStorage.strip().parse(parsed)

    persisStateStorage.setItem(KEY, JSON.stringify(ignoreExtraKeyState))

    return ignoreExtraKeyState
  } catch {
    return initialPersistedState()
  }
}

export const setNearbyStorage: Setter<ISchemaNearbyStorage> = (fn) => {
  const prevStorageData = getNearbyStorage()
  const nextStorageData = fn(prevStorageData)
  persisStateStorage.setItem(KEY, JSON.stringify(nextStorageData))

  return nextStorageData
}
