import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { type FC, useRef } from 'react'
import { graphql, useFragment } from 'react-relay'

import {
  type HotCarArticle_carArticle$data,
  type HotCarArticle_carArticle$key,
} from '../../../../__relay__/HotCarArticle_carArticle.graphql'
import { numberWithComma } from '../../../../lib/unit'
import CoveredLazyLoadImage from '../../../_lib/CoveredLazyLoadImage'
import { useImpression } from '../../../_lib/Impression'
import { getEllipsisStyle } from '../../../_lib/styles'

interface HotCarArticleProps {
  carArticle: HotCarArticle_carArticle$key
  itemOrder: number
  itemScrollContainerRef: React.RefObject<HTMLElement>
  onClick: (article: HotCarArticle_carArticle$data, itemOrder: number) => void
  onImpression: (article: HotCarArticle_carArticle$data, itemOrder: number) => void
}

const HotCarArticle: FC<HotCarArticleProps> = (props) => {
  const carArticle = useFragment(
    graphql`
      fragment HotCarArticle_carArticle on CarArticle {
        id
        externalId
        carName
        title
        price
        targetUri
        image {
          thumbnail
        }
        leaseMonthlyFee
        rentalMonthlyFee
        manufacturer
        articleManufacturerString
        viewCountInLast3Hours
      }
    `,
    props.carArticle
  )

  const { price, rentalMonthlyFee, leaseMonthlyFee } = carArticle
  const hasPrice = price ?? leaseMonthlyFee ?? rentalMonthlyFee

  const itemRef = useRef<HTMLDivElement>(null)

  // swipe log
  useImpression(
    {
      ref: itemRef,
      scrollContainerRef: props.itemScrollContainerRef,
      disableImpressionOnload: true,
    },
    () => {
      props.onImpression(carArticle, props.itemOrder)
    },
    [props.itemOrder, props.onImpression]
  )

  const handleClick = () => {
    props.onClick(carArticle, props.itemOrder)
  }

  return (
    <S_CarArticle ref={itemRef} onClick={handleClick}>
      <ImageWrapper>
        <CoveredLazyLoadImage src={carArticle.image.thumbnail} alt={carArticle.title} visibleByDefault />
      </ImageWrapper>
      <InfoWrapper>
        <BasicInfo>
          <Manufacturer>{carArticle.articleManufacturerString}</Manufacturer>
          <Title>{carArticle.title}</Title>
          {hasPrice && (
            <Price>
              {price
                ? `${numberWithComma(price)}만원`
                : leaseMonthlyFee
                ? `리스 ${numberWithComma(leaseMonthlyFee)}만원/월`
                : rentalMonthlyFee
                ? `렌트 ${numberWithComma(rentalMonthlyFee)}만원/월`
                : null}
            </Price>
          )}
        </BasicInfo>
        <ViewCount>{`${numberWithComma(carArticle.viewCountInLast3Hours)}명이 보는 중`}</ViewCount>
      </InfoWrapper>
    </S_CarArticle>
  )
}

export default HotCarArticle

const S_CarArticle = styled.article`
  display: inline-block;
  width: 8.75rem;
  color: ${vars.$scale.color.gray900};
  vertical-align: top;

  & > *:not(:last-child) {
    margin-bottom: 0.5rem;
  }
`

const ImageWrapper = styled.div`
  width: 8.75rem;
  height: 8.75rem;
`

const InfoWrapper = styled.div`
  & > *:not(:last-child) {
    margin-bottom: 0.25rem;
  }
`

const BasicInfo = styled.div`
  & > *:nth-of-type(1) {
    margin-bottom: 0.125rem;
  }

  & > *:nth-of-type(2) {
    margin-bottom: 0.063rem;
  }
`

const Manufacturer = styled.div`
  ${vars.$semantic.typography.caption2Regular};
  color: ${vars.$scale.color.gray600};
  ${getEllipsisStyle(1)};
`

const Title = styled.h3`
  margin: 0;
  ${vars.$semantic.typography.subtitle2Regular};
  ${getEllipsisStyle(1)};
`

const Price = styled.div`
  ${vars.$semantic.typography.label3Bold};
  ${getEllipsisStyle(1)};
`

const ViewCount = styled.div`
  ${vars.$semantic.typography.caption1Regular};
  color: ${vars.$semantic.color.accent};
  ${getEllipsisStyle(1)};
`
