import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { useActivity } from '@stackflow/react'
import React, { useCallback, useRef } from 'react'
import { graphql, useFragment } from 'react-relay'

import { type ExpertRecommendItem_BizAccount$key } from '@src/__relay__/ExpertRecommendItem_BizAccount.graphql'
import { useImpression } from '@src/components/_lib/Impression'
import { StarRatingDisplay } from '@src/components/_lib/StarRating/StarRatingDisplay'
import { getEllipsisStyle } from '@src/components/_lib/styles'
import { getBizProfileHomeScheme } from '@src/lib/scheme-util'
import { numberWithComma, toFloatingPoint } from '@src/lib/unit'

interface Props {
  bizAccountRef: ExpertRecommendItem_BizAccount$key
  itemOrder: number
  onClick: (baId: string) => void
  onImpression: (itemOrder: number) => void
  itemScrollContainerRef: React.RefObject<HTMLDivElement>
}

const ExpertRecommendItem = ({ bizAccountRef, itemOrder, onClick, onImpression, itemScrollContainerRef }: Props) => {
  const bizAccount = useFragment(
    graphql`
      fragment ExpertRecommendItem_BizAccount on BizAccount {
        id
        images {
          thumbnail
        }
        starRating
        reviewCount
        distance {
          distance
        }
        name
      }
    `,
    bizAccountRef
  )

  const { name } = useActivity()
  const id = bizAccount.id.split('#')[1]
  const handleClickBizProfile = useCallback(() => {
    onClick(id)
  }, [id, onClick])

  const itemRef = useRef<HTMLDivElement>(null)
  useImpression(
    {
      ref: itemRef,
      scrollContainerRef: itemScrollContainerRef,
      disableImpressionOnload: true,
      threshold: 0.7,
    },
    () => {
      onImpression(itemOrder)
    },
    [itemOrder, onImpression]
  )

  return (
    <BusinessAccountItem key={bizAccount.id} onClick={handleClickBizProfile} href={getBizProfileHomeScheme(id, name)}>
      <AccountImageWrapper>
        <AccountImage src={bizAccount.images[0].thumbnail} alt={`${bizAccount.name} 사진`} />
      </AccountImageWrapper>
      {bizAccount.distance && <Distance>{toFloatingPoint(bizAccount.distance.distance, 0)}m</Distance>}
      <AccountName>{bizAccount.name}</AccountName>
      <TagGroup>
        {bizAccount.starRating && (
          <Tag>
            <StarRatingDisplay type="with-text" size="small" rating={bizAccount.starRating} />
          </Tag>
        )}
        {bizAccount.reviewCount > 0 && (
          <Tag>
            <Review>후기 {numberWithComma(bizAccount.reviewCount)}</Review>
          </Tag>
        )}
      </TagGroup>
    </BusinessAccountItem>
  )
}
const BusinessAccountItem = styled.a`
  display: 'block';
  min-width: 34%;
  color: ${vars.$scale.color.gray900};
  text-decoration: none;
`
const AccountImageWrapper = styled.div`
  width: 100%;
  height: 66%;
  border-radius: 0.375rem;
  margin-bottom: 0.375rem;
`

const AccountImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 0.375rem;
  object-fit: cover;
  border: 0.0625rem inset ${vars.$semantic.color.divider1};
`

const AccountName = styled.p`
  ${vars.$semantic.typography.bodyM2Regular};
  margin: 0 0 0.0625rem;
  ${getEllipsisStyle(1)};
`

const TagGroup = styled.div`
  display: flex;
`
const Tag = styled.span`
  display: flex;
  ${vars.$semantic.typography.caption1Regular};
  color: ${vars.$scale.color.gray900};

  &:not(:last-of-type)::after {
    content: '·';
    color: ${vars.$scale.color.gray600};
    margin: 0 0.25rem;
  }
`
const Review = styled.span`
  color: ${vars.$scale.color.gray600};
`
const Distance = styled.p`
  margin: 0;
  ${vars.$semantic.typography.caption1Bold};
  color: ${vars.$scale.color.gray600};
`

export default ExpertRecommendItem
