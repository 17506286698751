import styled from '@emotion/styled'
import { type FC, useCallback, useEffect, useReducer, useRef } from 'react'

import withErrorBoundary from '@src/lib/with-fallback/withErrorBoundary'

import HomeLazySection from './HomeLazySection'
import { useImpressedSectionOrder } from '../../hooks/useImpressedSectionOrder'
import { type PropsOf } from '../../lib/T/utilTypes'
import { useImpression } from '../_lib/Impression'
import { useScrollContainerRef } from '../_lib/ScrollContainer'

// 현재 보이는 섹션에서 몇번째 뒤의 섹션까지 로드할지 결정하는 상수
const SECTION_LOAD_STEP = 3

interface HomeOptimizedLazySectionProps {
  sectionOrder: number
  loadingComponent?: JSX.Element
  homeLazySectionProps: PropsOf<typeof HomeLazySection>
}

const HomeOptimizedLazySection: FC<HomeOptimizedLazySectionProps> = ({
  sectionOrder,
  loadingComponent,
  homeLazySectionProps,
}) => {
  const scrollContainerRef = useScrollContainerRef()
  const lazySectionRef = useRef<HTMLDivElement>(null)

  const { currentImpressedSectionOrder, setCurrentImpressedSectionOrder } = useImpressedSectionOrder()

  const [isLoaded, loadSection] = useReducer(() => true, sectionOrder < 3)
  const [isFailed, failSection] = useReducer(() => true, false)

  const handleLazySectionImpress = useCallback(() => {
    setCurrentImpressedSectionOrder(sectionOrder)
  }, [sectionOrder, setCurrentImpressedSectionOrder])

  useImpression(
    {
      scrollContainerRef,
      ref: lazySectionRef,
      threshold: 0.1,
    },
    handleLazySectionImpress,
    []
  )

  useEffect(() => {
    if (currentImpressedSectionOrder + SECTION_LOAD_STEP >= sectionOrder) {
      loadSection()
    }
  }, [currentImpressedSectionOrder, sectionOrder])

  if (isFailed) {
    return null
  }

  return (
    <S_HomeOptimizedLazySection ref={lazySectionRef}>
      {isLoaded ? <HomeLazySection {...homeLazySectionProps} onLazySectionFailed={failSection} /> : loadingComponent}
    </S_HomeOptimizedLazySection>
  )
}

export default withErrorBoundary(HomeOptimizedLazySection, () => null)

const S_HomeOptimizedLazySection = styled.div`
  width: 100%;
  height: 100%;
`
