import styled from '@emotion/styled'
import { AnimatePresence, motion } from 'framer-motion'
import { type FC } from 'react'

interface AccordionProps {
  isOpen: boolean
  content: React.ReactNode
  expandedContent: React.ReactNode
  initial?: boolean
  duration?: number
  disabled?: boolean
}

const Accordion: FC<AccordionProps> = ({
  isOpen,
  content,
  expandedContent,
  disabled,
  duration = 250,
  initial = false,
}) => {
  return (
    <S_Accordion>
      {content}
      <AnimatePresence initial={initial}>
        {!disabled && isOpen && (
          <motion.section
            layout
            key="expandedContent"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { height: 'auto' },
              collapsed: { height: 0, overflow: 'hidden' },
            }}
            transition={{ duration: duration / 1000 }}>
            {expandedContent}
          </motion.section>
        )}
      </AnimatePresence>
    </S_Accordion>
  )
}

export default Accordion

const S_Accordion = styled.div``
