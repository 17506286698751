import { Modal, type ModalProps } from '@stackflow/plugin-basic-ui'
import { type ActivityComponentType } from '@stackflow/react'
import { useCallback, useState } from 'react'

import { useModalRender, useModalRenderDispatch } from './useModal'

export type StackflowModalParams = Partial<Omit<ModalProps, 'onOutsideClick' | 'children'>>

const StackflowModal: ActivityComponentType<StackflowModalParams> = ({
  params = {
    borderRadius: '0px',
  },
}) => {
  const [{ render }] = useState<ReturnType<typeof useModalRender>>(useModalRender())
  const setRenderContext = useModalRenderDispatch()

  const handleOutsideClick = useCallback(() => {
    setRenderContext({
      render: null,
    })
  }, [setRenderContext])

  if (!render) {
    return null
  }

  return (
    <Modal {...params} onOutsideClick={handleOutsideClick}>
      {render()}
    </Modal>
  )
}

export default StackflowModal
