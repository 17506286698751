import React from 'react'

const PlaceholderBizPost: React.FC = () => {
  return (
    <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="120" height="120" fill="#AEB6BF" fillOpacity="0.44" />
      <g clipPath="url(#clip0_193_113)">
        <path
          d="M35.4102 95.19H92.3102C93.2788 95.19 94.238 94.999 95.1328 94.628C96.0277 94.257 96.8406 93.7133 97.5251 93.0278C98.2096 92.3424 98.7522 91.5288 99.122 90.6334C99.4918 89.7381 99.6815 88.7787 99.6802 87.81V49.87C99.6841 48.8379 99.4842 47.8152 99.092 46.8605C98.6998 45.9059 98.1229 45.0381 97.3945 44.3069C96.6661 43.5757 95.8005 42.9955 94.8473 42.5996C93.8942 42.2038 92.8722 42 91.8402 42H50.4602C48.3799 42 46.3848 42.8257 44.9129 44.2957C43.441 45.7657 42.6128 47.7598 42.6102 49.84V87.24C42.6102 95.19 35.4102 95.19 35.4102 95.19Z"
          fill="#C0C3C9"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32.09 95.19H93C93 95.19 85.78 95.19 85.78 87.24V33.6501C85.78 31.5698 84.9543 29.5747 83.4843 28.1028C82.0143 26.6309 80.0202 25.8027 77.94 25.8H32.55C30.4698 25.8027 28.4756 26.6309 27.0056 28.1028C25.5356 29.5747 24.71 31.5698 24.71 33.6501V87.81C24.7087 88.7796 24.8986 89.7398 25.2691 90.6358C25.6395 91.5318 26.183 92.3459 26.8686 93.0314C27.5541 93.717 28.3682 94.2606 29.2642 94.631C30.1602 95.0014 31.1204 95.1914 32.09 95.19ZM43.91 39.8101C43.91 42.693 41.5729 45.0301 38.69 45.0301C35.807 45.0301 33.47 42.693 33.47 39.8101C33.47 36.9272 35.807 34.5901 38.69 34.5901C41.5729 34.5901 43.91 36.9272 43.91 39.8101Z"
          fill="#F4F5F6"
        />
        <path
          d="M73.99 61.29H35.79C34.5142 61.29 33.48 62.3243 33.48 63.6V63.61C33.48 64.8858 34.5142 65.92 35.79 65.92H73.99C75.2658 65.92 76.3 64.8858 76.3 63.61V63.6C76.3 62.3243 75.2658 61.29 73.99 61.29Z"
          fill="#C0C3C9"
        />
        <path
          d="M59.55 71.0901H35.79C34.5142 71.0901 33.48 72.1243 33.48 73.4001V73.4101C33.48 74.6859 34.5142 75.7201 35.79 75.7201H59.55C60.8258 75.7201 61.86 74.6859 61.86 73.4101V73.4001C61.86 72.1243 60.8258 71.0901 59.55 71.0901Z"
          fill="#C0C3C9"
        />
        <path
          d="M63.16 51.49H35.79C34.5142 51.49 33.48 52.5242 33.48 53.8V53.81C33.48 55.0858 34.5142 56.12 35.79 56.12H63.16C64.4358 56.12 65.47 55.0858 65.47 53.81V53.8C65.47 52.5242 64.4358 51.49 63.16 51.49Z"
          fill="#C0C3C9"
        />
      </g>
      <defs>
        <clipPath id="clip0_193_113">
          <rect width="74.9701" height="69.39" fill="white" transform="translate(24.71 25.8)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default PlaceholderBizPost
