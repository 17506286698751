import { css, keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'

const PlaceholderAnimation = keyframes`
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
`

interface IPlaceholderProps {
  width: string
  height: string
  margin?: string
  marginLeft?: string
  marginRight?: string
  marginTop?: string
  marginBottom?: string
  borderRadius?: string
  display?: string
  flex?: string
}
const Placeholder = styled.div<IPlaceholderProps>`
  background-color: ${vars.$scale.color.grayAlpha50};
  border-radius: ${(props) => props.borderRadius || '.25rem'};
  display: ${(props) => props.display || 'block'};
  margin-bottom: ${(props) => props.marginBottom || 0};
  margin-top: ${(props) => props.marginTop || 0};
  margin-left: ${(props) => props.marginLeft || 0};
  margin-right: ${(props) => props.marginRight || 0};
  margin: ${(props) => props.margin};
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  position: relative;
  z-index: 0;
  overflow: hidden;

  transform: translate3d(0, 0, 0);
  mask-image: -webkit-radial-gradient(white, black);

  &::after {
    content: '';
    display: block;
    background: linear-gradient(
      to left,
      ${vars.$scale.color.gray400},
      ${vars.$scale.color.grayAlpha50} 40%,
      ${vars.$scale.color.grayAlpha50} 60%,
      ${vars.$scale.color.gray400}
    );
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translateX(0);
    animation: 2s ${PlaceholderAnimation} ease-in-out infinite;
    min-width: 10rem;
  }

  ${(props) =>
    props.display === 'inline-block' &&
    css`
      vertical-align: middle;
    `}

  ${(props) =>
    props.flex &&
    css`
      flex: ${props.flex};
    `}
`

export default Placeholder
