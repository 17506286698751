import { useRecoilValue } from 'recoil'

import { type UpdateReferrer } from '@src/lib/target'

import { spotlightTargetInfoAtom } from '../state/spotlightTargetInfo'

const SPOTLIGHT_REFERRER_PREFIX = 'spotlight'

interface useSpotlightReferrerProps {
  checkId?: string
}

export function useSpotlightReferrer({ checkId }: useSpotlightReferrerProps): {
  spotlightReferrer?: string
  updateReferrer?: UpdateReferrer
} {
  const spotlightTargetInfo = useRecoilValue(spotlightTargetInfoAtom)

  if (!checkId)
    return {
      spotlightReferrer: undefined,
    }

  const { targetId, referrer } = spotlightTargetInfo
  const isSpotlighted = targetId?.includes(checkId)

  const spotlightReferrer = isSpotlighted ? `${SPOTLIGHT_REFERRER_PREFIX}${referrer ? `.${referrer}` : ''}` : undefined

  return {
    spotlightReferrer,
    updateReferrer: isSpotlighted ? (referrer) => `${referrer}.${spotlightReferrer}` : undefined,
  }
}
