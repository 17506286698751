import { atom } from 'recoil'

import { type Position } from '@src/currentPositionResource'

import { generateMapStateKey } from './key'

/**
 * 현재 보고있는 지도의 중심좌표
 */
export const centerCoordsAtom = atom<Position | null>({
  key: generateMapStateKey('currentViewPoints'),
  default: null,
})
/**
 * 첫 렌더링 시 지도의 중심좌표
 */
export const initialCenterCoordsAtom = atom<Position | null>({
  key: generateMapStateKey('initialViewPoints'),
  default: null,
})
