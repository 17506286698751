import { type KarrotBridgeSchema } from '@daangn/karrotbridge/lib/__generated__/makeKarrotBridge'
import { Error } from 'globalthis/implementation'
import pRetry from 'p-retry'
import pTimeout from 'p-timeout'

import {
  DEFAULT_APP,
  DEFAULT_REGION,
  DEFAULT_USER,
  LOCAL_STORAGE_FALLBACK_APP_KEY,
  LOCAL_STORAGE_FALLBACK_REGION_KEY,
  LOCAL_STORAGE_FALLBACK_USER_KEY,
} from './constants'
import { karrotBridge } from './sdk'

const BRIDGE_TIMEOUT_MS = 5000

export type BridgeAppInfo = KarrotBridgeSchema['ReqInfoAppResponse']['info']['app']
export type BridgeRegionInfo = KarrotBridgeSchema['ReqInfoRegionResponse']['info']['region']
export type BridgeUserInfo = KarrotBridgeSchema['ReqInfoUserResponse']['info']['user']

export async function getApp(): Promise<BridgeAppInfo> {
  if (DEFAULT_APP) {
    return DEFAULT_APP
  }

  try {
    const appResp = await pRetry(() => pTimeout(karrotBridge.getAppInfo({}), BRIDGE_TIMEOUT_MS), {
      retries: 3,
      minTimeout: 16,
    })

    const app = appResp.info.app

    if (!app) {
      throw new Error()
    }

    localStorage.setItem(LOCAL_STORAGE_FALLBACK_APP_KEY, JSON.stringify(app))

    return app
  } catch (error) {
    const serialized = localStorage.getItem(LOCAL_STORAGE_FALLBACK_APP_KEY)

    if (!serialized) {
      throw new Error('app not found')
    }

    return JSON.parse(serialized)
  }
}

export async function getRegion(): Promise<BridgeRegionInfo> {
  if (DEFAULT_REGION) {
    return DEFAULT_REGION as BridgeRegionInfo
  }

  try {
    const regionResp = await pRetry(() => pTimeout(karrotBridge.getRegionInfo({}), BRIDGE_TIMEOUT_MS), {
      retries: 3,
      minTimeout: 16,
    })

    const region = regionResp.info.region

    if (!region) {
      throw new Error()
    }

    localStorage.setItem(LOCAL_STORAGE_FALLBACK_REGION_KEY, JSON.stringify(region))

    return region
  } catch {
    const serialized = localStorage.getItem(LOCAL_STORAGE_FALLBACK_REGION_KEY)

    if (!serialized) {
      throw new Error('region not found')
    }

    return JSON.parse(serialized)
  }
}

export async function getUser(): Promise<BridgeUserInfo | null> {
  if (DEFAULT_USER) {
    return DEFAULT_USER as BridgeUserInfo
  }

  try {
    const userResp = await pRetry(() => pTimeout(karrotBridge.getUserInfo({}), BRIDGE_TIMEOUT_MS), {
      retries: 3,
      minTimeout: 16,
    })

    const user = userResp.info.user

    if (!user) {
      throw new Error()
    }

    localStorage.setItem(LOCAL_STORAGE_FALLBACK_USER_KEY, JSON.stringify(user))

    return user
  } catch {
    const serialized = localStorage.getItem(LOCAL_STORAGE_FALLBACK_USER_KEY)

    if (!serialized) {
      return null
    }

    return JSON.parse(serialized)
  }
}

export async function getInfo() {
  const [user, region, app] = await Promise.all([getUser(), getRegion(), getApp()])

  return {
    user,
    region,
    app,
  }
}
