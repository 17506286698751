import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { type FC } from 'react'

import { type BADGE_OPTION, type BADGE_SIZE, type BADGE_TYPE, getSizeOptionToken, getTypeOptionToken } from './token'
import { FlexCenter } from '../styles'

interface BadgeSquareProps {
  type?: BADGE_TYPE
  size?: BADGE_SIZE
  option?: BADGE_OPTION
  label: string
  className?: string
}

const BadgeSquare: FC<BadgeSquareProps> = ({
  type = 'basic',
  size = 'medium',
  option = 'default',
  label,
  className,
}) => {
  return (
    <S_BadgeSquare type={type} size={size} option={option} className={className}>
      {label}
    </S_BadgeSquare>
  )
}

export default BadgeSquare

const S_BadgeSquare = styled.span<{
  type?: BADGE_TYPE
  size?: BADGE_SIZE
  option?: BADGE_OPTION
}>`
  ${FlexCenter};
  padding: 0.25rem 0.5rem;
  ${vars.$semantic.typography.label4Regular};
  border-radius: 0.25rem;
  background-color: ${vars.$scale.color.gray100};
  color: ${vars.$scale.color.gray700};

  ${({ type, size, option }) => css`
    ${getTypeOptionToken(type, option)};
    ${getSizeOptionToken(size, option)};
  `}
`
