import { useMap } from '@daangn/karrotmaps-react'
import { type MarkerProps } from '@daangn/karrotmaps-react/dist/components/Marker'
import styled from '@emotion/styled'
import { type FC, useEffect, type ComponentType } from 'react'

import { noneActiveEffect } from '@src/components/_lib/styles'

import Marker from './Marker'
import { type TMarker } from './types'

export type MarkerHOC = (MarkerComponent: ComponentType<MarkerProps>) => ComponentType<MarkerProps>

type MarkersProps = {
  markers: TMarker[]
  isFitBounds?: boolean
  useSymbolLabel?: boolean
}

const Markers: FC<MarkersProps> = ({ markers, isFitBounds, useSymbolLabel }) => {
  const { current, maplibregl } = useMap()

  useEffect(() => {
    // marker 영역에 딱 맞게 지도 영역을 조정
    if (isFitBounds) {
      const boundCoordinates = markers.map((marker) => [marker.position.lng, marker.position.lat])

      const bounds = boundCoordinates.reduce(function (bounds, coord) {
        return bounds.extend(coord)
      }, new maplibregl.LngLatBounds(boundCoordinates[0], boundCoordinates[0]))

      current.fitBounds(bounds, {
        padding: { top: 55, bottom: 30, left: 30, right: 30 },
      })
    }
  }, [current, isFitBounds, maplibregl.LngLatBounds, markers])

  // useEffect(() => {
  //   const possibleSymbolLabel = useSymbolLabel && !current.getLayer('marker-labels')
  //   if (possibleSymbolLabel) {
  //     current.addLayer({
  //       id: 'marker-labels',
  //       type: 'symbol',
  //       source: {
  //         type: 'geojson',
  //         data: {
  //           type: 'FeatureCollection',
  //           features: markers
  //             .slice()
  //             .reverse()
  //             .map((marker) => {
  //               let title = marker.label ?? ''
  //               if (title.length > 12) {
  //                 // 6글자 지점과 12글자 지점에서 줄바꿈 문자를 삽입합니다.
  //                 title = title.slice(0, 6) + '\n' + title.slice(6, 12) + '...'
  //               } else if (title.length > 6) {
  //                 // 6글자 지점에서 줄바꿈 문자를 삽입합니다.
  //                 title = title.slice(0, 6) + '\n' + title.slice(6)
  //               }

  //               return {
  //                 type: 'Feature',
  //                 geometry: {
  //                   type: 'Point',
  //                   coordinates: [marker.position.lng, marker.position.lat],
  //                 },
  //                 properties: {
  //                   title,
  //                 },
  //               }
  //             }),
  //         },
  //       },
  //       layout: {
  //         'text-field': ['get', 'title'],
  //         'text-font': ['Pretendard Bold'],
  //         'text-size': 12,
  //         'text-anchor': 'top',
  //         'text-offset': [0, 1.5],
  //         'symbol-placement': 'point',
  //         'symbol-avoid-edges': true,
  //       },
  //       paint: {
  //         'text-color': '#000',
  //         'text-halo-color': '#fff',
  //         'text-halo-width': 2,
  //       },
  //     })
  //   }

  //   return () => {
  //     if (possibleSymbolLabel) {
  //       current.removeLayer('marker-labels')
  //       current.removeSource('marker-labels')
  //     }
  //   }
  // }, [current, markers, useSymbolLabel])

  return (
    <>
      {markers.map((marker, index) => {
        return (
          <MarkerWrapper id={marker.id} key={marker.id ?? index}>
            <Marker marker={marker} useSymbolLabel={useSymbolLabel} />
          </MarkerWrapper>
        )
      })}
    </>
  )
}

export default Markers

const MarkerWrapper = styled.div`
  ${noneActiveEffect};
`
