import { usePullToRefreshScrollElement } from '@daangn/karrot-clothes/lib/pullToRefresh'
import { chunk } from 'lodash'
import { type FC, useCallback, useMemo } from 'react'
import { graphql, useFragment } from 'react-relay'

import HotBizAccountItem from './HotBizAccountItem'
import { type HomeHotBizAccounts_sectionNearbyBizPost$key } from '../../../../__relay__/HomeHotBizAccounts_sectionNearbyBizPost.graphql'
import { useLogger } from '../../../../hooks/useLogger'
import { type PropOf } from '../../../../lib/T/utilTypes'
import { useOpenTarget } from '../../../../lib/target'
import type { SectionInfo } from '../../../../types'
import { ListEntry } from '../../../_lib/ListSection/ListEntry'
import CarouselContent from '../Common/CarouselContent'
import HomeSectionBase from '../Common/HomeSectionBase'
interface HomeHotBizAccountsProps {
  sectionHotBizAccounts: HomeHotBizAccounts_sectionNearbyBizPost$key
  sectionInfo: SectionInfo
}

const VIEW_ITEM_COUNT = 3

const HomeHotBizAccounts: FC<HomeHotBizAccountsProps> = ({ sectionInfo, ...props }) => {
  const sectionHotBizAccounts = useFragment(
    graphql`
      fragment HomeHotBizAccounts_sectionNearbyBizPost on SectionHotBizAccount {
        __typename
        loggingMeta {
          click
          show
          swipe
        }
        bizAccounts {
          id
          ...HotBizAccountItem_bizAccount
        }
        ...HomeSectionBase_sectionBase
      }
    `,
    props.sectionHotBizAccounts
  )

  const scrollContainerRef = usePullToRefreshScrollElement()
  const { nearbyLog, contentsSectionLog, loggingMetaLog } = useLogger()
  const { openTarget } = useOpenTarget()

  const handleImpression = () => {
    contentsSectionLog('show', {
      contentsType: 'hot_stores',
      sectionOrder: sectionInfo.order,
    })

    if (sectionInfo.order === 1) {
      loggingMetaLog(sectionHotBizAccounts.loggingMeta.show)
    }
  }

  const handleCarouselListSwipe: PropOf<typeof CarouselContent, 'onListSwipe'> = useCallback(
    (offset) => {
      nearbyLog({
        params: {
          name: 'swipe_contents_section',
          contentsType: 'hot_stores',
          page: offset,
          sectionOrder: sectionInfo.order,
        },
      })

      loggingMetaLog(sectionHotBizAccounts.loggingMeta.swipe)
    },
    [loggingMetaLog, nearbyLog, sectionInfo.order, sectionHotBizAccounts.loggingMeta.swipe]
  )

  const handleBizAccountClick: PropOf<typeof HotBizAccountItem, 'onClick'> = useCallback(
    ({ badges, targetUri }) => {
      contentsSectionLog('click', {
        contentsType: 'hot_stores',
        buttonType: 'list',
        params: {
          sectionOrder: sectionInfo.order,
          ...(badges.length > 0 ? { badges: badges.join(',') } : null),
        },
      })

      loggingMetaLog(sectionHotBizAccounts.loggingMeta.click)

      openTarget({
        targetUri,
      })
    },
    [contentsSectionLog, loggingMetaLog, openTarget, sectionInfo.order, sectionHotBizAccounts.loggingMeta.click]
  )

  const bizPostItems = useMemo(
    () =>
      chunk(sectionHotBizAccounts.bizAccounts, VIEW_ITEM_COUNT).map((bizAccounts, index) => (
        <ListEntry key={index}>
          {bizAccounts.map((bizAccount) => (
            <HotBizAccountItem key={bizAccount.id} bizAccount={bizAccount} onClick={handleBizAccountClick} />
          ))}
        </ListEntry>
      )),
    [handleBizAccountClick, sectionHotBizAccounts.bizAccounts]
  )

  return (
    <HomeSectionBase
      sectionBase={sectionHotBizAccounts}
      ImpressionProps={{
        scrollContainerRef,
        onImpression: handleImpression,
        threshold: 0.9,
      }}>
      <CarouselContent
        listItems={bizPostItems}
        onListSwipe={handleCarouselListSwipe}
        sectionOrder={sectionInfo.order}
      />
    </HomeSectionBase>
  )
}

export default HomeHotBizAccounts
