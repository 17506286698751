import { BoxButton } from '@daangn/sprout-components-button'
import { Slider } from '@daangn/sprout-components-slider'
import { useState, type FC } from 'react'
import { useRecoilState } from 'recoil'

import { centerCoordsAtom } from '@src/features/couponMap/state/centerCoords'
import { toleranceAtom } from '@src/features/couponMap/state/tolerance'
import { maxZoomAtom, minZoomAtom, zoomLevelAtom } from '@src/features/couponMap/state/zoomLevel'
import { useCurrentPosition } from '@src/hooks/useCurrentPosition'

import BasicModalView, {
  DevModalInput,
  DevModalInputWrapper,
  DevModalLabel,
  DevModalSubInputWrapper,
  DevModalSubTitle,
} from '../lib/BasicModalView'

interface SettingMapOptionsModalViewProps {
  closeAll: () => void
}

const SettingMapOptionsModalView: FC<SettingMapOptionsModalViewProps> = ({ closeAll }) => {
  const { currentPosition } = useCurrentPosition()
  const [tolerance, setTolerance] = useRecoilState(toleranceAtom)
  const [centerCoords, setCoords] = useRecoilState(centerCoordsAtom)
  const [zoomLevel, setZoomLevel] = useRecoilState(zoomLevelAtom)
  const [maxZoom, setMaxZoom] = useRecoilState(maxZoomAtom)
  const [minZoom, setMinZoom] = useRecoilState(minZoomAtom)

  const [localZoomLevel, setLocalZoomLevel] = useState(zoomLevel.toString())
  const [localTolerance, setLocalTolerance] = useState(tolerance.toString())

  const handleZoomLevelSlideChange = (value: number) => {
    setLocalZoomLevel((Math.round(value * 10) / 10).toString())
  }

  const handleZoomLevelApplyButtonClick = () => {
    setZoomLevel(Number(localZoomLevel))
  }

  //반올림
  const midZoom = Math.round((maxZoom + minZoom) / 2)

  return (
    <BasicModalView title="지도 세팅 디버그">
      <DevModalInputWrapper>
        <DevModalSubTitle>zoomLevel: </DevModalSubTitle>
        <DevModalLabel>{localZoomLevel}</DevModalLabel>
        <Slider
          markers={[
            {
              align: 'start',
              label: minZoom.toString(),
              value: minZoom,
            },
            {
              align: 'center',
              label: midZoom.toString(),
              value: midZoom,
            },
            {
              align: 'end',
              label: maxZoom.toString(),
              value: maxZoom,
            },
          ]}
          maxValue={maxZoom}
          minValue={minZoom}
          onChange={handleZoomLevelSlideChange}
          step={0.1}
          value={Number(localZoomLevel)}
        />
        <DevModalInput
          type="text"
          value={localZoomLevel}
          onChange={(e) => {
            const value = Number(e.currentTarget.value)
            if (isNaN(value)) {
              alert('숫자만 입력해주세요')
              return
            }

            if (value < minZoom || value > maxZoom) {
              alert(`minZoom: ${minZoom} ~ maxZoom: ${maxZoom} 사이의 값을 입력해주세요`)
              return
            }
            setLocalZoomLevel(e.currentTarget.value)
          }}
        />
        <BoxButton size="xsmall" onClick={handleZoomLevelApplyButtonClick}>
          적용
        </BoxButton>
      </DevModalInputWrapper>
      <DevModalInputWrapper>
        <DevModalSubTitle>tolerance: (동일위경도 조정)</DevModalSubTitle>
        <DevModalSubInputWrapper style={{ margin: 0 }}>
          <DevModalLabel>max tolerance: </DevModalLabel>
          <DevModalInput
            value={localTolerance}
            onChange={(e) => {
              setLocalTolerance(e.currentTarget.value)
            }}
          />
        </DevModalSubInputWrapper>
        <BoxButton
          size="xsmall"
          onClick={() => {
            // 유효한지 검증
            const tolerance = Number(localTolerance)
            if (isNaN(tolerance)) {
              alert('숫자만 입력해주세요')
              return
            }

            setTolerance(tolerance)
          }}>
          적용
        </BoxButton>
      </DevModalInputWrapper>
      <DevModalInputWrapper>
        <DevModalSubTitle>currentPosition: </DevModalSubTitle>
        <DevModalLabel style={{ fontSize: '0.75rem' }}>{JSON.stringify(currentPosition)}</DevModalLabel>
      </DevModalInputWrapper>
    </BasicModalView>
  )
}

export default SettingMapOptionsModalView
