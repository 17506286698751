import { LOCAL_CONTENT_CURATOR_GATEWAY_API } from './constants'

interface InfraProxyHealthCheckResponse {
  status: {
    code: string
    message: string
    type: string
  }
}

// type LocalGatewayHealthCheckResponse = object

type APIHealthCheckResponse = InfraProxyHealthCheckResponse // | LocalGatewayHealthCheckResponse

const SERVER_PROXY_MOCK_DATA: InfraProxyHealthCheckResponse = {
  status: {
    code: 'status_maintenance',
    message: '더 빠르고 안정적인\n당근을 만들기 위해\n서버를 점검하고 있어요!\n 점검시간: AM 2:30～3:30',
    type: 'alert',
  },
}

// https://daangn.slack.com/archives/C06JS0471KN/p1719566366857869?thread_ts=1719564140.969309&cid=C06JS0471KN
const HEALTH_CHECK_ENDPOINT = `${LOCAL_CONTENT_CURATOR_GATEWAY_API}/healthz`

async function getApiHealthCheck(
  { defaultErrorMessage }: { defaultErrorMessage: string } = { defaultErrorMessage: '' }
) {
  const res = await fetch(`${HEALTH_CHECK_ENDPOINT}`, {
    method: 'GET',
  })

  // test를 위한 Mock
  // const res = {
  //   status: 599,
  //   ok: false,
  //   text: async () => 'ok',
  //   json: async () => SERVER_PROXY_MOCK_DATA,
  //   url: HEALTH_CHECK_ENDPOINT,
  //   headers: new Headers(),
  // }

  if (!res.ok) {
    const contentType = res.headers.get('content-type')

    if (!contentType || (!contentType.includes('application/json') && res.text)) {
      const text = await res.text()

      throw new Error(defaultErrorMessage, {
        cause: {
          status: res.status,
          url: res.url,
          errorData: {
            status: {
              code: 'ERROR_TYPE_UNSPECIFIED',
              text,
              contentType,
            },
          },
        },
      })
    }

    // 빈 스트링으로 보낼시 Global Error Fallback에서 처리해주어야함
    const errorData = (await res.json()) as APIHealthCheckResponse

    if (errorData && errorData.status) {
      throw new Error(errorData?.status.message, {
        cause: {
          status: res.status,
          url: res.url,
          errorData: errorData,
          errorViewMessage: errorData?.status.message,
        },
      })
    }

    if (!errorData) {
      throw new Error(defaultErrorMessage, {
        cause: {
          status: res.status,
          url: res.url,
          errorData: {
            status: {
              code: 'ERROR_TYPE_UNSPECIFIED', // TODO: define error types eg)ERROR_TYPE_SERVER_UNHEALTHY
            },
          },
        },
      })
    }

    return true
  }
}

export const makeApiHealthCheckResource = () => {
  type State =
    | {
        _t: 'pending'
      }
    | {
        _t: 'rejected'
        result: Error
      }
    | {
        _t: 'resolved'
      }

  let state: State = {
    _t: 'pending',
  }

  const promise = (async () => {
    try {
      await getApiHealthCheck()

      state = {
        _t: 'resolved',
      }
    } catch (error) {
      state = {
        _t: 'rejected',
        result: error as Error,
      }
    }
  })()

  return {
    read() {
      switch (state._t) {
        case 'pending':
          throw promise
        case 'rejected':
          throw state.result
        case 'resolved':
          return
      }
    },
  }
}

export const apiHealthCheckResource = makeApiHealthCheckResource()
