import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { Array, type HTMLLIElement, type HTMLUListElement } from 'globalthis/implementation'
import { type ReactNode, useMemo, useReducer, useRef } from 'react'

import { useIntersectionObserver, type useIntersectionObserverProp } from '../../hooks/useIntersectionObserver'

interface ScrollSnapCarouselProps {
  elementRef?: React.RefObject<HTMLUListElement>
  items: ReactNode[]
  onOffsetChange: (offset: number) => void
  onImpressionItem?: (offset: number) => void
}

export const ScrollSnapCarousel: React.FC<ScrollSnapCarouselProps> = ({
  elementRef,
  items,
  onOffsetChange,
  onImpressionItem,
}) => {
  const handleVisibleItem = (index: number) => {
    onOffsetChange(index)
  }

  return (
    <ScrollSnapCarouselList ref={elementRef}>
      {items.map((item, index) => (
        <CarouselItem key={index} offset={index} onVisibleItem={handleVisibleItem} onImpressionItem={onImpressionItem}>
          {item}
        </CarouselItem>
      ))}
    </ScrollSnapCarouselList>
  )
}

const ScrollSnapCarouselList = styled.ul`
  display: flex;
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none;
  overflow-x: scroll;
  overflow-y: hidden;
  scroll-snap-align: center;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    display: none;
  }
`

interface CarouselItemProps {
  offset: number
  onVisibleItem: (index: number) => void
  onImpressionItem?: (index: number) => void
}

const CarouselItem: React.FCWithChildren<CarouselItemProps> = ({
  offset,
  onVisibleItem,
  onImpressionItem,
  children,
}) => {
  const itemElementRef = useRef<HTMLLIElement>(null)
  const [impressed, impress] = useReducer(() => true, false)

  const searchSectionIntersectionControl: useIntersectionObserverProp = useMemo(
    () => ({
      onChangeVisibility: (entry) => {
        if (entry.isIntersecting) {
          if (!impressed) {
            onImpressionItem?.(offset)
          }
          impress()
          onVisibleItem(offset)
        }
      },
      targetElementRef: itemElementRef,
      options: {
        threshold: 0.7,
      },
    }),
    [offset, onVisibleItem, impressed, onImpressionItem]
  )

  useIntersectionObserver(searchSectionIntersectionControl)

  return <ScrollSnapCarouselItem ref={itemElementRef}>{children}</ScrollSnapCarouselItem>
}

const ScrollSnapCarouselItem = styled.li`
  margin: 0;
  padding: 0;
  scroll-snap-stop: always;
  scroll-snap-align: center;
  min-width: 100%;
`

interface CarouselIndicatorProps {
  activeOffset: number
  maxOffset: number
  className?: string
}

export const CarouselIndicator: React.FC<CarouselIndicatorProps> = ({ activeOffset, maxOffset, className }) => {
  return (
    <Container className={className}>
      <Dots>
        {maxOffset > 1 &&
          [...new Array(maxOffset)].map((_, i) =>
            i === activeOffset ? <ActiveDot key={i} data-active /> : <Dot key={i} />
          )}
      </Dots>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0 0 0.875rem;
`

const Dots = styled.div`
  display: flex;
`

const Dot = styled.span`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: ${vars.$scale.color.grayAlpha500};
  opacity: 0.2;

  &:not(:last-of-type) {
    margin-right: 8px;
  }
`

const ActiveDot = styled(Dot)`
  opacity: 1;
  background-color: ${vars.$semantic.color.primary};
`
