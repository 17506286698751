import type React from 'react'
import useDeepCompareEffect from 'use-deep-compare-effect'

import { useLogger } from '../../hooks/useLogger'
import { compactMap } from '../../lib/F'

type FirebaseLogProps = {
  name: 'nearby_event' | 'local_business'
  params: {
    name?: string
    [key: string]: string | number | null | undefined
  }
}

const ShowPageLog: React.FC<FirebaseLogProps> = ({ name, params }) => {
  const { nearbyLog, localBizLog } = useLogger()

  useDeepCompareEffect(() => {
    const _params = params ? compactMap(params) : undefined
    if (name === 'local_business') {
      localBizLog({
        name: 'show_page',
        params: {
          ..._params,
          name: 'show_page',
        },
      })

      return
    }

    nearbyLog({
      params: _params,
    })
  }, [nearbyLog])

  return null
}

export default ShowPageLog
