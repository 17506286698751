import axios, { type AxiosResponse } from 'axios'

import { getApp, getRegion, getUser } from '@src/bridgeInfo'
import { CLINET_VERSION, CLIENT_NAME, LOCAL_GATEWAY_API_ENDPOINT } from '@src/constants'
import { filterNil } from '@src/lib/F'

import { applyPlantae } from './plantae'

const MAX_RETRY_COUNT = 3
const RETRY_DELAY = 1000

const getFetcher = ({
  baseURL,
  getHeaders,
  shouldAuthenticate,
}: {
  baseURL: string
  getHeaders?: () => Promise<{
    [x: string]: any
  }>
  shouldAuthenticate?: boolean
}) => {
  const axiosInstance = axios.create({ baseURL })

  // plantae 적용 https://github.com/daangn/plantae
  applyPlantae({
    axiosInstance,
    useAuthPlugin: shouldAuthenticate,
    retryOptions: {
      retryCount: MAX_RETRY_COUNT,
      retryDelay: RETRY_DELAY,
    },
  })

  axiosInstance.interceptors.request.use(async (config) => {
    if (getHeaders) {
      await getHeaders().then((headers) => {
        Object.assign(config.headers || {}, filterNil(headers || {}))
      })
    }
    return config
  })

  axiosInstance.interceptors.response.use((response: AxiosResponse<{ error: any }>) => {
    const { data } = response

    if (data.error) {
      throw data.error
    }

    return response
  })

  // retry 로직 적용
  // addRetryInterceptor(axiosInstance, {
  //   retryCount: MAX_RETRY_COUNT,
  //   retryDelay: RETRY_DELAY,
  // })

  return axiosInstance
}

export default getFetcher

type DefaultHeader = {
  'X-USER-ID'?: number
  'X-USER-AGENT': string
  'DEVICE-IDENTITY': string
  'X-REGION-ID': number
  'X-APP-VERSION': string
}

let defaultHeader: null | DefaultHeader = null

export const getDefaultHeaders: () => Promise<DefaultHeader> = async () => {
  if (defaultHeader) {
    return defaultHeader
  }

  const [region, app, user] = await Promise.all([getRegion(), getApp(), getUser()])

  defaultHeader = {
    // 'X-USER-REFERER': !IS_LOCAL && SessionStorage.getReferrer(), // TODO. session storage 사용
    'X-USER-ID': user?.id,
    'X-USER-AGENT': app?.userAgent,
    'DEVICE-IDENTITY': app?.deviceIdentity,
    'X-REGION-ID': region?.id,
    'X-APP-VERSION': app?.appVersion,
  }

  return defaultHeader
}

/**
 * 내근처 게이트웨이 Fetcher
 */
export const LocalGatewayFetcher = getFetcher({
  baseURL: LOCAL_GATEWAY_API_ENDPOINT,
  getHeaders: async () => {
    const defaultHeaders = await getDefaultHeaders()

    return {
      ...defaultHeaders,
      // 내근처 서버 로깅용
      'client-name': CLIENT_NAME,
      'client-version': CLINET_VERSION,
    }
  },
  shouldAuthenticate: true,
})
