/**
 * @generated SignedSource<<09a2383f2429a4afc8c5da9224a32ce0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type PoiTabCategoryEnum = "BEAUTY" | "CAFE" | "EXERCISE" | "FOOD" | "HOSPITAL" | "PET";
import { FragmentRefs } from "relay-runtime";
export type HomeMapsTabList_tab$data = {
  readonly poiTags: ReadonlyArray<{
    readonly poi: {
      readonly coords: {
        readonly lat: number;
        readonly lng: number;
      };
      readonly distance: {
        readonly distance: number;
        readonly name: string;
      } | null;
      readonly id: string;
      readonly name: string;
    };
    readonly " $fragmentSpreads": FragmentRefs<"HomeMapsTabListItem_poiTag">;
  }>;
  readonly targetUri: string;
  readonly title: string;
  readonly type: PoiTabCategoryEnum;
  readonly " $fragmentType": "HomeMapsTabList_tab";
};
export type HomeMapsTabList_tab$key = {
  readonly " $data"?: HomeMapsTabList_tab$data;
  readonly " $fragmentSpreads": FragmentRefs<"HomeMapsTabList_tab">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HomeMapsTabList_tab",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "targetUri",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PoiTag",
      "kind": "LinkedField",
      "name": "poiTags",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Poi",
          "kind": "LinkedField",
          "name": "poi",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Coords",
              "kind": "LinkedField",
              "name": "coords",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "lat",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "lng",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "DistanceInfo",
              "kind": "LinkedField",
              "name": "distance",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "distance",
                  "storageKey": null
                },
                (v0/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "HomeMapsTabListItem_poiTag"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "PoiTab",
  "abstractKey": null
};
})();

(node as any).hash = "83876c90904fa2a34b20a6ac841f9ab1";

export default node;
