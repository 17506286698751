import { DEFAULT_POSITION } from './constants'
import { karrotBridge } from './sdk'

export type Position = {
  lat: number
  lng: number
}

function makeCurrentPositionResource() {
  type State =
    | {
        _t: 'pending'
      }
    | {
        _t: 'resolved'
        result: Position | null
      }

  let state: State = {
    _t: 'pending',
  }

  const promise = (async () => {
    try {
      const result = await getCurrentPosition()

      state = {
        _t: 'resolved',
        result,
      }
    } catch (error) {
      state = {
        _t: 'resolved',
        result: null,
      }
    }
  })()

  return {
    read() {
      switch (state._t) {
        case 'pending':
          throw promise
        case 'resolved':
          return state.result
      }
    },
  }
}

export const currentPositionResource = makeCurrentPositionResource()

export async function getCurrentPosition(): Promise<Position | null> {
  if (DEFAULT_POSITION) {
    return {
      lat: Number(DEFAULT_POSITION.lat),
      lng: Number(DEFAULT_POSITION.lng),
    }
  }

  const result = await karrotBridge.getCurrentPosition({})

  const {
    geolocation: { currentPosition },
  } = result

  return currentPosition?.position?.latitude && currentPosition?.position?.longitude
    ? {
        lat: currentPosition.position.latitude,
        lng: currentPosition.position.longitude,
      }
    : null
}
