import { type ExperimentKeys, ExperimentOverrideConfig, type ExperimentsTargets } from './ExperimentType'
import { parseExperimentTarget } from './parseExperimentType'
import { devStorage } from '../storage/storage'

const EXPERIMENT_TYPE_KEY = 'experimentTargets'

export function setExperimentTargets(experimentTargets: ExperimentsTargets) {
  devStorage.setItem(EXPERIMENT_TYPE_KEY, JSON.stringify(experimentTargets))
}

export function removeExperimentsTargets() {
  devStorage.removeItem(EXPERIMENT_TYPE_KEY)
}

export function getExperimentTaget(key: ExperimentKeys) {
  const experimentTargetsString = devStorage.getItem(EXPERIMENT_TYPE_KEY)

  if (!experimentTargetsString) {
    return null
  }

  try {
    const experimentTargets = JSON.parse(experimentTargetsString)

    if (experimentTargets[key]) {
      return parseExperimentTarget(experimentTargets[key])
    }
  } catch {
    return null
  }

  return null
}

export function getExperimentOverrideConfig(key: ExperimentKeys) {
  const experimentTarget = getExperimentTaget(key)
  const experimentConfig = ExperimentOverrideConfig[key]

  if (experimentTarget && experimentConfig) {
    return {
      userId: experimentConfig.userId[experimentTarget],
    }
  }
}
