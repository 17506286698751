import { omitBy } from 'lodash'
import { isNil } from 'lodash/fp'
import { useCallback, useMemo } from 'react'

import { IS_LOCAL } from '@src/constants'

import { karrotBridge } from '../sdk'

export function useBridgeAnalytics() {
  const log = useCallback(
    ({
      name,
      target = 'FIREBASE',
      params,
    }: Omit<Parameters<(typeof karrotBridge)['logEvent']>[0]['analytics'], 'params' | 'target'> & {
      refPageName?: string
      params?: {
        [key: string]: string | number | boolean | undefined | null
      }
      target?: Parameters<(typeof karrotBridge)['logEvent']>[0]['analytics']['target']
    }) => {
      const _params = omitBy(
        {
          ...params,
        },
        isNil
      )

      const strinfiedParams = JSON.stringify(_params)

      console.info(
        `%c정보%ckarrotbridge.logEvent%c\n${name}`,
        'background-color: #0b7285; color: #e3fafc; padding: .125rem .25rem; border-radius: .1875rem 0 0 .1875rem;',
        'background-color: #e3fafc; color: #0b7285; padding: .125rem .25rem; border-radius: 0 .1875rem .1875rem 0;',
        '',
        target,
        name,
        IS_LOCAL ? _params : strinfiedParams
      )

      if (karrotBridge.driver.getCurrentEnvironment() === 'Web') {
        return
      }

      karrotBridge.logEvent({
        analytics: {
          target,
          name,
          params: strinfiedParams,
        },
      })
    },
    []
  )

  return useMemo(
    () => ({
      log,
    }),
    [log]
  )
}
