import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import React, { type CSSProperties, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { CSSTransition } from 'react-transition-group'
import { useRecoilState, useRecoilValue } from 'recoil'

import IcWrite from '@src/assets/images/icons/IcWrite'
import { owningBizAccountsAtom } from '@src/features/bizAccount/state/owningBizAccount'

import { isCurrentViewingBizWriteNudgingTooltipAtom, isViewBizWriteNudgingTooltipAtom } from './state'
import BadgeNewIcon from '../../../../assets/images/icons/BadgeNewIcon'
import IcChevronRightOutlineS from '../../../../assets/images/icons/ic_chevron_right_fill.svg'
import IcCoupon from '../../../../assets/images/icons/icon_coupon.svg'
import { BIZ_WRITE_NUDGING_TOOLTIP_DELAY } from '../../../../constants'
import { useIntersectionObserver, type useIntersectionObserverProp } from '../../../../hooks/useIntersectionObserver'
import { type LogSectionType, useLogger } from '../../../../hooks/useLogger'
import { type PropOf } from '../../../../lib/T/utilTypes'
import { useOpenTarget } from '../../../../lib/target'
import { NonWebkitTabHighlight } from '../../../_lib/styles'
import InfoButton, { type InfoButtonPlaceholderPosition } from '../InfoButton'

const RIGHT_MORE_CLICK_AREA_EMPTY_SPACE = 20

export type SectionTitleTypo = 'subtitle1' | 'subtitle2' | 'title3' //  | 'caption1' | 'subtitle1' | 'title1'

const TYPO_MAPPER: Record<
  SectionTitleTypo,
  {
    root: {
      padding: string
    }
    title: {
      fontSize: string
      fontWeight: string
      lineHeight: string
      letterSpacing: string
      color?: string
    }
    subtitle: {
      fontSize: string
      fontWeight: string
      lineHeight: string
      letterSpacing: string
      color?: string
    }
  }
> = {
  title3: {
    root: {
      padding: '1.25rem 1rem 0.25rem',
    },
    title: {
      ...vars.$semantic.typography.title3Bold,
      color: vars.$scale.color.gray900,
    },
    subtitle: {
      ...vars.$semantic.typography.bodyM2Regular,
      color: vars.$scale.color.gray600,
    },
  },
  subtitle1: {
    root: {
      padding: '1.25rem 1rem 0.88rem',
    },
    title: {
      ...vars.$semantic.typography.subtitle1Bold,
      color: vars.$scale.color.gray900,
    },
    subtitle: {
      ...vars.$semantic.typography.bodyM2Regular,
      color: vars.$scale.color.gray900,
    },
  },
  subtitle2: {
    root: {
      padding: '1.25rem 1rem 0.25rem',
    },
    title: {
      ...vars.$semantic.typography.subtitle2Bold,
      color: vars.$scale.color.gray900,
    },
    subtitle: {
      ...vars.$semantic.typography.bodyM2Regular,
      color: vars.$scale.color.gray900,
    },
  },
}

interface SectionTitleProps {
  typo?: SectionTitleTypo
  title: React.ReactNode
  subtitle?: string | null
  infoButton?: {
    content: React.ReactNode | string
    placeholderPosition?: InfoButtonPlaceholderPosition
    onClick?: () => void
  }
  rightButton?: {
    noRightButtonArrow?: boolean
    content: React.ReactNode | string
    onClick?: () => void
    onView?: (isView: boolean) => void
  } | null
  rightButtonTooltip?: {
    isOpen: boolean
    content: React.ReactNode | string
    onClick?: () => void
    onView?: (isView: boolean) => void
  }
  isNew?: boolean
  onSubTitleAnimation?: boolean
  addPaddingBottom?: string
  styleOptions?: {
    justifyContent?: CSSProperties['justifyContent']
    padding?: CSSProperties['padding']
  }
}
const SectionTitle: React.FC<SectionTitleProps> = (props) => {
  const sectionTitleRef = useRef<HTMLDivElement>(null)

  const [subtitleView, setSubTitleView] = useState(!!props.subtitle && !props.onSubTitleAnimation)

  const titleRef = useRef<HTMLDivElement>(null)
  const rightButtonRef = useRef<HTMLDivElement>(null)

  const subTitleViewControl: useIntersectionObserverProp = useMemo(
    () => ({
      onChangeVisibility: (entry) => {
        if (entry.isIntersecting && !subtitleView && props.subtitle) {
          setSubTitleView(true)
        }
      },
      options: {
        rootMargin: '0% 0% -65% 0%',
      },
      targetElementRef: titleRef,
    }),
    [props.subtitle, subtitleView]
  )

  useIntersectionObserver(subTitleViewControl)

  const rightButtonViewControl: useIntersectionObserverProp = useMemo(
    () => ({
      onChangeVisibility: (entry) => {
        props.rightButton?.onView?.(entry.isIntersecting)
      },
      options: {
        rootMargin: '-50px 0% -100px 0%',
      },
      targetElementRef: rightButtonRef,
    }),
    [props.rightButton]
  )

  useIntersectionObserver(rightButtonViewControl)

  const [tooltipPosition, setTooltipPosition] = useState({
    top: 0,
    left: 0,
  })

  useEffect(() => {
    const rightButtonElement = rightButtonRef.current

    if (rightButtonElement) {
      const position = rightButtonElement.getBoundingClientRect()

      setTooltipPosition({
        top: position.right,
        left: position.left,
      })
    }
  }, [rightButtonRef])

  const [infoButtonClickAreaWidth, setInfoButtonClickAreaWidth] = useState('50%')

  useEffect(() => {
    const titleElement = titleRef.current

    if (props.infoButton && titleElement && titleElement.parentElement) {
      const parentElement = titleElement.parentElement

      setInfoButtonClickAreaWidth(
        `${parentElement?.clientWidth - titleElement.clientWidth - RIGHT_MORE_CLICK_AREA_EMPTY_SPACE}px`
      )
    }
  }, [titleRef, props.infoButton])

  const rightButtonTooltipRef = useRef<HTMLDivElement>(null)

  const rightButtonTooltipViewControll: useIntersectionObserverProp = useMemo(
    () => ({
      onChangeVisibility: (entry) => {
        props.rightButtonTooltip?.onView?.(entry.isIntersecting)
      },
      options: {},
      targetElementRef: rightButtonTooltipRef,
    }),
    [props.rightButtonTooltip]
  )

  useIntersectionObserver(rightButtonTooltipViewControll)

  if (props.title === '') {
    return null
  }

  return (
    <Container
      typo={props.typo}
      addPaddingBottom={props.addPaddingBottom}
      ref={sectionTitleRef}
      padding={props.styleOptions?.padding}>
      <TitleFlex justifyContent={props.styleOptions?.justifyContent}>
        <Title ref={titleRef} typo={props.typo}>
          {props.title}
          {props.infoButton && (
            <InfoButton
              tooltipContent={props.infoButton.content}
              placeholderPosition={props.infoButton.placeholderPosition}
              onClick={props.infoButton.onClick}
            />
          )}
          {props.isNew && <BadgeNewIcon />}
        </Title>
        {props.rightButton && (
          <>
            <RightButton ref={rightButtonRef}>
              {props.rightButton.content}
              {props.rightButton.noRightButtonArrow ? null : (
                <RightArrowIconContainer isNoneText={!props.rightButton.content}>
                  <IcChevronRightOutlineS />
                </RightArrowIconContainer>
              )}
            </RightButton>
            <RightButtonClickArea width={infoButtonClickAreaWidth} onClick={props.rightButton.onClick} />
          </>
        )}

        <CSSTransition
          nodeRef={rightButtonTooltipRef}
          in={props.rightButtonTooltip?.isOpen}
          timeout={200}
          mountOnEnter
          unmountOnExit>
          <RightButtonTooltip
            ref={rightButtonTooltipRef}
            tooltipPosition={tooltipPosition}
            onClick={props?.rightButtonTooltip?.onClick}>
            <TooltipContent>{props?.rightButtonTooltip?.content}</TooltipContent>
          </RightButtonTooltip>
        </CSSTransition>
      </TitleFlex>
      {props.subtitle && (
        <SubtitleFlex justifyContent={props.styleOptions?.justifyContent}>
          <Subtitle subTitleView={subtitleView} typo={props.typo}>
            {props.subtitle}
          </Subtitle>
        </SubtitleFlex>
      )}
    </Container>
  )
}

const Container = styled.div<{
  addPaddingBottom?: string
  typo?: SectionTitleTypo
  padding?: CSSProperties['padding']
}>`
  position: relative;
  ${({ typo }) => (typo ? TYPO_MAPPER[typo]?.root : TYPO_MAPPER.title3.root)};
  ${({ addPaddingBottom }) => (addPaddingBottom ? `padding-bottom: calc(4px + ${addPaddingBottom})` : '')};
  padding: ${({ padding }) => padding};

  .bp3-popover2-content {
    padding: 0px;
    transform: translateY(-2px) !important;
  }

  .bp3-popover2-arrow {
    transform: translate(10px, 1px) !important;
    svg {
      transform: rotate(90deg) scale(0.9) !important;
    }
  }
`

const TitleFlex = styled.div<{ justifyContent?: CSSProperties['justifyContent'] }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: ${({ justifyContent }) => justifyContent ?? 'space-between'};
`

const Title = styled.h2<{ typo?: SectionTitleTypo }>`
  display: flex;
  align-items: center;
  margin: 0;

  ${({ typo }) => (typo ? TYPO_MAPPER[typo]?.title : TYPO_MAPPER.title3.title)}

  svg {
    margin-left: 0.25rem;
  }
`

const SubtitleFlex = styled.div<{ justifyContent?: CSSProperties['justifyContent'] }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: ${({ justifyContent }) => justifyContent ?? 'space-between'};
`

const Subtitle = styled.div<{ subTitleView: boolean; typo?: SectionTitleTypo }>`
  margin-top: 0.375rem;
  opacity: 0;
  transition: 500ms;
  overflow: hidden;

  ${({ subTitleView }) =>
    subTitleView &&
    css`
      opacity: 1;
    `}

  ${({ typo }) => (typo ? TYPO_MAPPER[typo]?.subtitle : TYPO_MAPPER.title3.subtitle)}
`

export const RightButton = styled.div`
  display: flex;
  align-items: center;
  ${vars.$semantic.typography.label3Bold};
  color: ${vars.$scale.color.gray900};
  padding: 0.125rem 0.25rem;
  margin-right: -0.25rem;
  -webkit-tap-highlight-color: transparent;
  border-radius: 0.125rem;
  ${NonWebkitTabHighlight}

  svg {
    margin-bottom: 0.0625rem;
  }
`

const RightButtonClickArea = styled.div<{ width: string }>`
  position: absolute;
  right: 0;
  height: 100%;
  width: ${({ width }) => width};
  background-color: transparent;
`

const RightArrowIconContainer = styled.div<{ isNoneText: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 0.5rem;
  height: 0.75rem;
  margin: 0.063rem 0 0 0.25rem;
  box-sizing: content-box;

  ${({ isNoneText }) =>
    isNoneText &&
    css`
      width: 0.813rem;
      height: 1.25rem;
    `}
`

const RightButtonTooltip = styled.div<{
  tooltipPosition: { top: number; left: number }
}>`
  position: absolute;
  top: 48px;
  left: ${({ theme, tooltipPosition }) =>
    `calc(${tooltipPosition.left}px - ${theme.environment === 'android' ? 6 : 5}rem)`};

  z-index: 999;

  &:after {
    content: '';
    position: absolute;
    top: -5px;
    right: 20px;
    transform: rotate(45deg);
    width: 10px;
    height: 10px;
    background-color: ${vars.$scale.color.gray900};
    border-top-left-radius: 2px;
    opacity: 0;
  }

  &.enter-active:after {
    opacity: 1;
  }

  &.enter-done:after {
    opacity: 1;
  }

  &.exit:after {
    opacity: 0;
  }
`

const TooltipContent = styled.div`
  background-color: ${vars.$scale.color.gray900};
  color: ${vars.$scale.color.gray00};
  border-radius: 6px;

  padding: 8px 12px;
  font-weight: 700;
  font-size: 13px;
  line-height: 20px;
  word-wrap: break-word;
  word-break: keep-all;

  transition: all 200ms ease-in-out;
  opacity: 1;
  transform: scale(0.8) translate(4px, -9px);

  ${RightButtonTooltip}.enter-active & {
    opacity: 1;
    transform: scale(1.05) translate(-1px, 0.5px);
  }

  ${RightButtonTooltip}.enter-done & {
    opacity: 1;
    transform: scale(1);
  }

  ${RightButtonTooltip}.exit-active & {
    transition: all 0ms;
    opacity: 0;
    transform: scale(0.65) translate(30px, -17px);
  }
`

export default SectionTitle

export function useSectionTitleBizNudging({
  rightButtonContent,
  rightButtonTargetUri,
  sectionType,
}: {
  rightButtonContent?: React.ReactNode | string
  rightButtonTargetUri: string
  sectionType: LogSectionType
}) {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false)
  const { openTarget } = useOpenTarget()
  const { nearbyLog } = useLogger()

  const owningBizAccounts = useRecoilValue(owningBizAccountsAtom)
  const [isViewBizWriteNudgingTooltip, setIsViewBizWriteNudgingTooltip] = useRecoilState(
    isViewBizWriteNudgingTooltipAtom
  )
  const [isCurrentViewingBizWriteNudgingTooltip, setIsCurrentViewingBizWriteNudgingTooltip] = useRecoilState(
    isCurrentViewingBizWriteNudgingTooltipAtom
  )

  const isBizAccountUser = owningBizAccounts.length > 0

  const latestBizProfileId = owningBizAccounts.length > 0 ? owningBizAccounts.slice(-1)[0]?.externalId : 'unknown'

  useEffect(() => {
    if (isTooltipOpen) {
      nearbyLog({
        params: {
          name: 'show_write_post_nudge_tooltip',
          biz_profile_id: latestBizProfileId,
          section_type: sectionType,
        },
      })
    }
  }, [isTooltipOpen, latestBizProfileId, nearbyLog, sectionType])

  const setTooltipOpenWithGlobal = useCallback(
    (flag: boolean) => {
      setIsTooltipOpen(flag)

      if (!flag) {
        setIsViewBizWriteNudgingTooltip(true)
      }
    },
    [setIsViewBizWriteNudgingTooltip]
  )

  const handleTooltipClick = useCallback(() => {
    setTooltipOpenWithGlobal(false)
  }, [setTooltipOpenWithGlobal])

  const handleWriteBizClick = useCallback(() => {
    nearbyLog({
      params: {
        name: 'click_write_post_nudge',
        biz_profile_id: latestBizProfileId,
        section_type: sectionType,
      },
    })

    openTarget({
      targetUri: rightButtonTargetUri,
    })
  }, [openTarget, rightButtonTargetUri, latestBizProfileId, sectionType, nearbyLog])

  const handleWriteBizView = useCallback(
    (isView: boolean) => {
      if (!isBizAccountUser || isViewBizWriteNudgingTooltip) {
        return
      }

      if (isView && isCurrentViewingBizWriteNudgingTooltip) {
        return
      }

      setIsTooltipOpen(isView)
    },
    [isBizAccountUser, isCurrentViewingBizWriteNudgingTooltip, isViewBizWriteNudgingTooltip]
  )

  const timerIdRef = useRef<NodeJS.Timeout | null>(null)

  const handleTooltipView = useCallback(
    (isView: boolean) => {
      setIsCurrentViewingBizWriteNudgingTooltip(isView)

      if (isView && !timerIdRef.current) {
        timerIdRef.current = setTimeout(() => {
          setTooltipOpenWithGlobal(false)
        }, BIZ_WRITE_NUDGING_TOOLTIP_DELAY)

        return
      }

      if (!isView && timerIdRef.current) {
        clearTimeout(timerIdRef.current)
        timerIdRef.current = null
      }
    },
    [setIsCurrentViewingBizWriteNudgingTooltip, setTooltipOpenWithGlobal]
  )

  const sectionWriteButton: PropOf<typeof SectionTitle, 'rightButton'> = useMemo(
    () => ({
      content: (
        <BizWriteNudgingButton>
          {sectionType === 'coupon' ? <IcCoupon /> : <IcWrite />}
          {rightButtonContent}
        </BizWriteNudgingButton>
      ),
      noRightButtonArrow: true,
      onClick: handleWriteBizClick,
      onView: handleWriteBizView,
    }),
    [sectionType, handleWriteBizClick, handleWriteBizView, rightButtonContent]
  )

  const sectionWriteTooltip: PropOf<typeof SectionTitle, 'rightButtonTooltip'> = useMemo(
    () => ({
      content: (
        <div>
          <div>소식을 작성하면 이 공간에</div>
          <div>업체를 홍보할 수 있어요.</div>
        </div>
      ),
      isOpen: isTooltipOpen,
      onClick: handleTooltipClick,
      onView: handleTooltipView,
    }),
    [isTooltipOpen, handleTooltipClick, handleTooltipView]
  )

  return {
    isTooltipOpen,
    setIsTooltipOpen,
    sectionWriteButton: isBizAccountUser ? sectionWriteButton : undefined,
    sectionWriteTooltip: isBizAccountUser ? sectionWriteTooltip : undefined,
  }
}

const BizWriteNudgingButton = styled.div`
  display: flex;
  align-items: center;
  color: ${vars.$semantic.color.primary};

  svg {
    margin-right: 4px;

    path {
      fill: ${vars.$semantic.color.primary};
    }
  }
`
