import { type Experiment, type ExperimentSegmentType, ExperimentSegmentTypeSchema } from './ExperimentType'

export function parseExperimentTarget(input: string): ExperimentSegmentType {
  const result = ExperimentSegmentTypeSchema.safeParse(input)
  return result.success ? result.data : '0'
}

export function parseExperimentTargetSegment(experiment?: Experiment): ExperimentSegmentType {
  if (!experiment) return '0'

  return experiment.segments.some((segment) => segment.value === experiment.targetSegment)
    ? (experiment.targetSegment as ExperimentSegmentType)
    : '0'
}
