import { logEvent } from '@daangn/local-business-analytics'
import { karrotAnalyticsPlugin } from '@daangn/stackflow-plugin-karrot-analytics'
// import { scrollToTopPlugin } from '@daangn/stackflow-plugin-scroll-to-top'
import { vars } from '@seed-design/design-token'
import { basicUIPlugin } from '@stackflow/plugin-basic-ui'
import { historySyncPlugin } from '@stackflow/plugin-history-sync'
import { basicRendererPlugin } from '@stackflow/plugin-renderer-basic'
import { type ActivityComponentType, stackflow } from '@stackflow/react'

import { IS_LOCAL, IS_PRODUCTION } from '@src/constants'
import { type TupleToMap } from '@src/lib/T/utilTypes'

import depthChangePlugin from './customPlugins/depthChange'
import { globalComponentPlugin } from './customPlugins/globalComponentPlugin'
import { localBusinessAnalyticsStackflowPlugin } from './customPlugins/localBusinessAnalytics'
import { nearbyDevtoolsPlugin } from './customPlugins/nearbyDevtoolsPlugin'
import { analyticsPlugin as NearbyAnalyticsPlugin } from './gaPageViewPlugin'
import routes from './routes'
import { karrotBridge } from '../sdk'
import { appTheme } from '../styles/MyThemeProvider'

export const activities = routes.reduce(
  (activities, route) => ({ ...activities, [route.code]: route.activity }),
  {}
) as TupleToMap<typeof routes, 'code', 'activity'>
export const historyRoutes = routes.reduce(
  (activities, route) => ({ ...activities, [route.code]: route.path }),
  {}
) as TupleToMap<typeof routes, 'code', 'path'>

export type NearbyActivitys = typeof activities
export type NearbyActivityNames = keyof NearbyActivitys
export type NearbyActivityParams<T extends Extract<NearbyActivityNames, string>> = NearbyActivitys[T] extends
  | ActivityComponentType<infer U>
  | {
      component: ActivityComponentType<infer U>
    }
  ? U
  : Record<string, never>

const borderColor = appTheme === 'cupertino' ? vars.$semantic.color.divider3 : vars.$semantic.color.divider2

const localBusinessLogEvent = logEvent({
  bridge: {
    bridge: karrotBridge,
    type: 'KARROT_BRIDGE',
  },
})

export const { Stack, useFlow, useStepFlow } = stackflow({
  transitionDuration: 350,
  activities,
  plugins: [
    basicRendererPlugin(),
    basicUIPlugin({
      theme: appTheme,
      backgroundColor: vars.$semantic.color.paperDefault,
      appBar: {
        textColor: vars.$scale.color.gray900,
        iconColor: vars.$scale.color.gray900,
        borderColor,
      },
    }),
    historySyncPlugin({
      routes: historyRoutes,
      fallbackActivity: () => 'Home',
    }),
    depthChangePlugin({
      appTheme,
    }),
    karrotAnalyticsPlugin({
      bridge: karrotBridge,
      serviceName: 'nearby',
      itemId: {
        CategoryHome: ({ categoryId }) => {
          return categoryId
        },
        BizAccountReview: ({ reviewId }) => {
          return reviewId
        },
        CouponDetail: ({ couponId }) => {
          return couponId
        },
      },
    }),
    // scrollToTopPlugin(),
    nearbyDevtoolsPlugin(),
    globalComponentPlugin(),
    NearbyAnalyticsPlugin(),
    localBusinessAnalyticsStackflowPlugin({
      debug: IS_LOCAL || !IS_PRODUCTION,
      logPageView: true,
      commonPayloads: {
        referrer: null,
      },
      logEvent: ({ name, params }) => {
        const where = (params.where ?? '') as string

        localBusinessLogEvent({
          name,
          params: {
            ...params,
            where: REMOVE_NEARBY_PREFIXS.find((removePrefix) => where.startsWith(removePrefix))
              ? where
              : `nearby.${where.toLowerCase()}`,
          },
        })
      },
    }),
  ],
})

const REMOVE_NEARBY_PREFIXS = ['review.']
