import { useActivity } from '@stackflow/react'
import type { ZodObject, ZodRawShape, z } from 'zod'

interface usePageParamsProps<T extends ZodObject<ZodRawShape>> {
  zodSchema: T
  params: Record<string, string>
  pageName?: string
}

export function usePageParams<T extends ZodObject<ZodRawShape>>({
  zodSchema,
  params,
  pageName,
}: usePageParamsProps<T>): z.infer<T> {
  const parsedData = zodSchema.safeParse(params)
  const { name } = useActivity()

  if (!parsedData.success) {
    throw new Error(`in ${pageName ?? name}, get invalid params: ${JSON.stringify(params)}`)
  }

  return parsedData.data
}
