import { atom } from 'recoil'

import recoilKeyGenerator from '@src/lib/recoil-util'
import { nearbyStorage } from '@src/lib/storage/storage'
import syncWithStorage from '@src/store/effects/syncWithStorage'

const syncWithCommonStorage = syncWithStorage(nearbyStorage)

const generateKey = recoilKeyGenerator('home')

/**
 * 홈 피드 숏컷 온보딩 모달뷰 플래그
 */
export const isViewHomeFeedShortcutOnboardingAtom = atom<boolean>({
  key: generateKey('isViewHomeFeedShortcutOnboarding'),
  default: false,
  effects: [
    syncWithCommonStorage({
      key: 'isViewHomeFeedShortcutOnboardingV2',
    }),
  ],
})
