import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { FC } from 'react'

import IcLoadMore from '@src/assets/images/launch_service_icons/IcLoadMore'

import PurposeLaunchpadItem, { S_PurposeCategoryIconWrapper } from './PurposeLaunchpadItem'

interface PurposeCategoryMoreViewProps {
  expanded?: boolean
  setExpanded?: (expanded: boolean) => void
  onClick: () => void
}

const PurposeCategoryMoreView: FC<PurposeCategoryMoreViewProps> = ({ expanded, setExpanded, onClick }) => {
  const handleClick = () => {
    onClick()
    setExpanded?.(!expanded)
  }

  return (
    <PurposeLaunchpadItem
      id={`PurposeCategory_moreView`}
      onClick={handleClick}
      iconUrl={null}
      IconElement={
        <S_MoreCategoryIconWrapper>
          <LoadMoreIconWrapper>
            <IcLoadMore />
          </LoadMoreIconWrapper>
        </S_MoreCategoryIconWrapper>
      }
      name="더보기"
    />
  )
}

export default PurposeCategoryMoreView

const S_MoreCategoryIconWrapper = styled(S_PurposeCategoryIconWrapper)`
  box-sizing: border-box;
  svg {
    width: 100%;
    height: 100%;
  }
  background-color: ${vars.$semantic.color.paperDefault};
`

const LoadMoreIconWrapper = styled.div`
  display: flex;
  padding: 0.219rem;
`
