import styled from '@emotion/styled'
import { chunk } from 'lodash'
import { type FC, useLayoutEffect, useMemo } from 'react'
import { graphql, useLazyLoadQuery } from 'react-relay'

import { type CustomSection_requestInfo$data } from '@src/__relay__/CustomSection_requestInfo.graphql'
import { type LazyCustomListSectionQuery } from '@src/__relay__/LazyCustomListSectionQuery.graphql'
import { ListEntry } from '@src/components/_lib/ListSection/ListEntry'
import { useCurrentPosition } from '@src/hooks/useCurrentPosition'
import { useViewer } from '@src/hooks/useViewer'
import { minItemsPerPage } from '@src/lib/F'
import withErrorBoundary from '@src/lib/with-fallback/withErrorBoundary'
import { type SectionInfo } from '@src/types'
import { type BusinessAccountComponentEnum, type BusinessPostComponentEnum } from '@src/types/schemaEnums'

import BusinessPostComponentA from './Items/BusinessPostComponentA'
import { type OnItemClick, type OnListSwipe } from './Items/types'
import CarouselContent from '../Common/CarouselContent'

const VIEW_ITEM_COUNT = 3
const GET_ITEM_COUNT = 20

interface LazyCustomListSectionProps {
  requestInfo: CustomSection_requestInfo$data
  componentType: BusinessAccountComponentEnum | BusinessPostComponentEnum
  isBizPost: boolean
  isBizAccount: boolean
  sectionInfo: SectionInfo
  onSectionClose: () => void
  onItemClick: OnItemClick
  onListSwipe: OnListSwipe
}

const LazyCustomListSection: FC<LazyCustomListSectionProps> = ({
  requestInfo,
  componentType,
  isBizPost,
  isBizAccount,
  sectionInfo,
  onSectionClose,
  onItemClick,
  onListSwipe,
}) => {
  const viewer = useViewer()
  const { currentPosition } = useCurrentPosition()

  const contentSection = useLazyLoadQuery<LazyCustomListSectionQuery>(
    graphql`
      query LazyCustomListSectionQuery(
        $count: Int!
        $regionIds: [Int!]
        $coords: FeedCoordsInput
        $range: FeedRange!
        $includeCategoryIds: [Int!]
        $excludeCategoryIds: [Int!]
        $includeThemeIds: [Int!]
        $createdAfter: FeedLocalDateTime
        $createdInDays: Int
        $isBizPost: Boolean!
        $isBizAccount: Boolean!
        $bizPostFilter: FeedBusinessPostFilterInput
        $bizAccountFilter: FeedBusinessAccountFilterInput
      ) {
        contentCurator(
          regionIds: $regionIds
          coords: $coords
          range: $range
          businessAccountCreatedAfter: $createdAfter
          businessAccountCreatedInDays: $createdInDays
          businessAccountIncludeCategoryIds: $includeCategoryIds
          businessAccountExcludeCategoryIds: $excludeCategoryIds
          businessAccountIncludeThemeIds: $includeThemeIds
        ) {
          businessPosts(filter: $bizPostFilter, first: $count) @include(if: $isBizPost) {
            __typename
            edges {
              node {
                id
                ...BusinessPostComponentA_bizPost
              }
            }
          }
          businessAccounts(filter: $bizAccountFilter, first: $count) @include(if: $isBizAccount) {
            __typename
            edges {
              node {
                id
              }
            }
          }
        }
      }
    `,
    {
      count: GET_ITEM_COUNT,
      regionIds: [viewer.region.id ?? 0],
      coords: currentPosition
        ? {
            lat: currentPosition.lat,
            lng: currentPosition.lng,
          }
        : null,
      range: requestInfo.range,
      createdAfter: requestInfo.businessAccountCreatedAfter,
      createdInDays: requestInfo.businessAccountCreatedInDays,
      // @ts-ignore
      includeCategoryIds: requestInfo.businessAccountIncludeCategoryIds,
      // @ts-ignore
      excludeCategoryIds: requestInfo.businessAccountExcludeCategoryIds,
      // @ts-ignore
      includeThemeIds: requestInfo.businessAccountIncludeThemeIds,

      isBizPost,
      isBizAccount,

      // @ts-ignore
      bizPostFilter: requestInfo.businessPostFilter,
      bizAccountFilter: requestInfo.businessAccountFilter,
    },
    {
      fetchPolicy: 'store-and-network',
    }
  )

  const list = useMemo(() => {
    if (isBizPost) {
      return contentSection.contentCurator.businessPosts?.edges ?? []
    } else if (isBizAccount) {
      return contentSection.contentCurator.businessAccounts?.edges ?? []
    } else {
      return []
    }
  }, [
    contentSection.contentCurator.businessAccounts?.edges,
    contentSection.contentCurator.businessPosts?.edges,
    isBizAccount,
    isBizPost,
  ])

  const bizPostItems = useMemo(() => {
    const _list = minItemsPerPage([...list])

    return chunk(_list, VIEW_ITEM_COUNT).map((group, index) => (
      <ListContainer key={index}>
        {group.map((item, index) => {
          if (!item?.node) {
            return null
          }

          switch (componentType) {
            case 'BUSINESS_POST_COMPONENT_A':
              // @ts-ignore
              return <BusinessPostComponentA key={item.node.id} bizPostRef={item.node} onClick={onItemClick} />
          }
        })}
      </ListContainer>
    ))
  }, [list, componentType, onItemClick])

  const handleListSwipe = (offset: number) => {
    onListSwipe({
      sectionType: 'list',
      offset,
    })
  }

  useLayoutEffect(() => {
    if (bizPostItems.length === 0) {
      onSectionClose?.()
    }
  }, [bizPostItems.length, onSectionClose])

  return <CarouselContent listItems={bizPostItems} onListSwipe={handleListSwipe} sectionOrder={sectionInfo.order} />
}

export default withErrorBoundary(LazyCustomListSection, () => null)

const ListContainer = styled(ListEntry)`
  padding: 0;
`
