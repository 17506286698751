import { useCallback, useEffect } from 'react'
import { useRecoilState } from 'recoil'

import { currentPositionResource, getCurrentPosition } from '@src/currentPositionResource'
import { currentPositionAtom } from '@src/features/user/state/currentPosition'

export function useCurrentPosition() {
  const [_currentPosition, setCurrentPosition] = useRecoilState(currentPositionAtom)

  const currentPosition = _currentPosition ?? currentPositionResource.read()

  useEffect(() => {
    if (_currentPosition === null && currentPosition) {
      setCurrentPosition(currentPosition)
    }
  }, [_currentPosition, currentPosition, setCurrentPosition])

  const reloadCurrentPosition = useCallback(async () => {
    const newPosition = await getCurrentPosition()
    setCurrentPosition(newPosition)
  }, [setCurrentPosition])

  return {
    currentPosition,
    setCurrentPosition,
    reloadCurrentPosition,
  }
}
