import { usePullToRefreshScrollElement } from '@daangn/karrot-clothes/lib/pullToRefresh'
import { chunk } from 'lodash'
import { useCallback, useMemo } from 'react'
import { graphql, useFragment, useRelayEnvironment } from 'react-relay'

import { type HomeNativeReview_sectionNativeReview$key } from '@src/__relay__/HomeNativeReview_sectionNativeReview.graphql'
import { useBridgeStreamListener } from '@src/hooks/useBridgeStreamListener'
import { useLogger } from '@src/hooks/useLogger'
import { type PropOf } from '@src/lib/T/utilTypes'
import { useOpenTarget } from '@src/lib/target'
import { type SectionInfo } from '@src/types'

import HomeNativeReviewItem from './HomeNativeReviewItem'
import CarouselContent from '../Common/CarouselContent'
import HomeSectionBase from '../Common/HomeSectionBase'

interface HomeNativeReviewProps {
  sectionNativeReview: HomeNativeReview_sectionNativeReview$key
  sectionInfo: SectionInfo
}

const HomeNativeReview: React.FC<HomeNativeReviewProps> = ({ sectionInfo, ...props }) => {
  const scrollContainerRef = usePullToRefreshScrollElement()

  const { contentsSectionLog, localBizLog, loggingMetaLog } = useLogger()
  const { openTarget } = useOpenTarget()
  const environment = useRelayEnvironment()

  const section = useFragment<HomeNativeReview_sectionNativeReview$key>(
    graphql`
      fragment HomeNativeReview_sectionNativeReview on SectionNativeReview {
        ...HomeSectionBase_sectionBase
        baseInfo {
          title
          viewMoreInfo {
            targetUri
          }
        }
        loggingMeta {
          show
          click
          swipe
        }
        nativeReviews {
          id
          ...HomeNativeReviewItem_reviewNativeReview
        }
      }
    `,
    props.sectionNativeReview
  )

  const handleMoreClick = () => {
    contentsSectionLog('click', {
      contentsType: 'native_reviews',
      buttonType: 'more',
      params: {
        item_type: 'post',
        sectionOrder: sectionInfo.order,
      },
    })

    localBizLog({
      name: 'click_nearby_tobaki_section_more',
      params: {} as never,
    })

    loggingMetaLog(section.loggingMeta.click)

    const nativeReviewTargetUri = section.baseInfo?.viewMoreInfo?.targetUri
    nativeReviewTargetUri &&
      openTarget({
        targetUri: nativeReviewTargetUri,
      })
  }

  const handleImpression = () => {
    contentsSectionLog('show', {
      contentsType: 'native_reviews',
      sectionOrder: sectionInfo.order,
      params: {},
    })

    localBizLog({
      name: 'show_nearby_tobaki_section',
      params: {} as never,
    })

    if (sectionInfo.order === 0) {
      loggingMetaLog(section.loggingMeta.show)
    }
  }

  const handleCarouselListSwipe: PropOf<typeof CarouselContent, 'onListSwipe'> = useCallback(
    (offset) => {
      contentsSectionLog('swipe', {
        contentsType: 'native_reviews',
        page: offset + 1,
        params: {},
      })

      localBizLog({
        name: 'click_swipe_nearby_tobaki_section',
        params: {} as never,
      })

      loggingMetaLog(section.loggingMeta.swipe)
    },
    [contentsSectionLog, localBizLog, loggingMetaLog, section.loggingMeta.swipe]
  )

  const reviewItems = useMemo(() => {
    const items = section.nativeReviews.map((nativeReview) => (
      <HomeNativeReviewItem key={nativeReview.id} reviewNativeReview={nativeReview} />
    ))
    return chunk(items, 2)
  }, [section.nativeReviews])

  useBridgeStreamListener('place.poi.watch', ({ poiId, isWatched }) => {
    environment.commitUpdate((store) => {
      const poi = store.get(`Poi#${poiId}`)

      if (poi) {
        poi.setValue(isWatched, 'viewerIsWatched')
      }
    })
  })

  return (
    <HomeSectionBase
      sectionBase={section}
      ImpressionProps={{
        scrollContainerRef: scrollContainerRef,
        onImpression: handleImpression,
        threshold: 0.8,
      }}
      ServiceTitleProps={{
        serviceType: 'NATIVE_REVIEW',
      }}
      SectionBottomButtonProps={{
        content: '더보기',
        onClick: handleMoreClick,
      }}
      SectionTitleProps={{
        title: '인천 이웃 추천 맛집',
      }}>
      <CarouselContent listItems={reviewItems} onListSwipe={handleCarouselListSwipe} sectionOrder={sectionInfo.order} />
    </HomeSectionBase>
  )
}

export default HomeNativeReview
