import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { type FC, useMemo, useRef } from 'react'
import { graphql, useFragment } from 'react-relay'

import type {
  RealtySalesTypeEnum,
  RecentlyRealtyArticle_realtyArticle$data,
  RecentlyRealtyArticle_realtyArticle$key,
} from '../../../../__relay__/RecentlyRealtyArticle_realtyArticle.graphql'
import { secondToDuration } from '../../../../lib/unit'
import CoveredLazyLoadImage from '../../../_lib/CoveredLazyLoadImage'
import { useImpression } from '../../../_lib/Impression'
import { getEllipsisStyle } from '../../../_lib/styles'
import { TextWithDot } from '../../../_lib/styles/util'

interface RecentlyRealtyArticleProps {
  realtyArticle: RecentlyRealtyArticle_realtyArticle$key
  itemOrder: number
  itemScrollContainerRef: React.RefObject<HTMLElement>
  onClick: (article: RecentlyRealtyArticle_realtyArticle$data, itemOrder: number) => void
  onImpression: (
    article: RecentlyRealtyArticle_realtyArticle$data,
    itemOrder: number,
    impressionType: 'swipe' | 'show'
  ) => void
}

export const REALTY_SALES_TYPE_MAP: Record<RealtySalesTypeEnum, string> = {
  OPEN_ONE_ROOM: '오픈형 원룸',
  SPLIT_ONE_ROOM: '분리형 원룸',
  TWO_ROOM: '투룸 이상',
  APART: '아파트',
  STORE: '상가',
  ETC: '기타',
  UNSPECIFIED: '미지정',
  OFFICETEL: '오피스텔',
}

const RecentlyRealtyArticle: FC<RecentlyRealtyArticleProps> = (props) => {
  const realtyArticle = useFragment(
    graphql`
      fragment RecentlyRealtyArticle_realtyArticle on RealtyArticle {
        id
        externalId
        reatyArticleTitle: title
        reatyArticlePrice: price
        image {
          thumbnail
        }
        videoThumbnailUrl
        tradeType
        region {
          name3
        }
        realtyType
        videoDuration
        targetUri
      }
    `,
    props.realtyArticle
  )

  const itemRef = useRef<HTMLDivElement>(null)
  const duration = useMemo(() => secondToDuration(realtyArticle.videoDuration ?? 0), [realtyArticle.videoDuration])

  // swipe log
  useImpression(
    {
      ref: itemRef,
      scrollContainerRef: props.itemScrollContainerRef,
      disableImpressionOnload: true,
    },
    () => {
      props.onImpression(realtyArticle, props.itemOrder, 'swipe')
    },
    [props.itemOrder, props.onImpression]
  )

  // impression log
  useImpression(
    {
      ref: itemRef,
    },
    () => {
      props.onImpression(realtyArticle, props.itemOrder, 'show')
    },
    [props.itemOrder, props.onImpression]
  )

  const handleClick = () => {
    props.onClick(realtyArticle, props.itemOrder)
  }

  return (
    <S_RecentlyRealtyArticle ref={itemRef} onClick={handleClick}>
      <ImageWrapper>
        {duration && <VideoDuration>{duration}</VideoDuration>}
        <CoveredLazyLoadImage
          src={realtyArticle.videoThumbnailUrl ?? realtyArticle.image.thumbnail}
          alt={realtyArticle.reatyArticleTitle ?? realtyArticle.id}
          visibleByDefault
        />
      </ImageWrapper>
      <InfoWrapper>
        <RealtyType>{REALTY_SALES_TYPE_MAP[realtyArticle.realtyType]}</RealtyType>
        {realtyArticle.reatyArticleTitle && <Title>{TextWithDot(realtyArticle.reatyArticleTitle)}</Title>}
        <Price>{realtyArticle.reatyArticlePrice}</Price>
      </InfoWrapper>
      <RegionName>{realtyArticle.region.name3}</RegionName>
    </S_RecentlyRealtyArticle>
  )
}

export default RecentlyRealtyArticle

const S_RecentlyRealtyArticle = styled.article`
  display: inline-block;
  width: 8.75rem;
  color: ${vars.$scale.color.gray900};
  vertical-align: top;

  & > *:first-of-type {
    margin-bottom: 0.5rem;
  }

  & > *:nth-of-type(2) {
    margin-bottom: 0.375rem;
  }
`

const ImageWrapper = styled.div`
  position: relative;
  width: 8.75rem;
  height: 8.75rem;
`

const VideoDuration = styled.div`
  position: absolute;
  bottom: 6px;
  right: 6px;
  ${vars.$semantic.typography.label4Bold};
  color: ${vars.$static.color.staticWhite};
  z-index: 1;
`

const InfoWrapper = styled.div`
  & > *:not(:last-child) {
    margin-bottom: 0.188rem;
  }
`

const RealtyType = styled.div`
  ${vars.$semantic.typography.label3Regular};
  ${getEllipsisStyle(1)};
`

const Title = styled.h3`
  margin: 0;
  ${vars.$semantic.typography.label3Regular};
  ${getEllipsisStyle(1)};
`

const Price = styled.div`
  ${vars.$semantic.typography.label3Bold};
  ${getEllipsisStyle(1)};
`

const RegionName = styled.div`
  ${vars.$semantic.typography.caption2Regular};
  color: ${vars.$scale.color.gray600};
`
