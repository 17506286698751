import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { FC } from 'react'

import IcChevronUp from '@src/assets/images/icons/ic_chevron_up.svg'
import { FlexCenter, noneActiveEffect } from '@src/components/_lib/styles'
import { useLogger } from '@src/hooks/useLogger'

interface PurposeCategoryMoreViewProps {
  setExpanded: (expanded: boolean) => void
}

const PurposeCategoryFoldButton: FC<PurposeCategoryMoreViewProps> = ({ setExpanded }) => {
  const { nearbyLog } = useLogger()

  const handleClick = () => {
    setExpanded(false)

    nearbyLog({
      params: {
        name: 'click_directory_section_fold',
      },
    })
  }

  return (
    <Button onClick={handleClick}>
      <Content>
        접기
        <IconContainer>
          <IcChevronUp />
        </IconContainer>
      </Content>
    </Button>
  )
}

export default PurposeCategoryFoldButton

const Button = styled.div`
  ${FlexCenter}
  appearance: none;
  border: 0;
  background: ${vars.$semantic.color.paperDefault};
  color: ${vars.$scale.color.gray700};
  margin: 0.625rem 0;
  padding: 0.5rem 0;
  text-decoration: none;
  ${noneActiveEffect};
`

const Content = styled.div`
  ${FlexCenter}
  padding: 0.125rem 0;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: bold;
`

const IconContainer = styled.div`
  ${FlexCenter}
  width: 1rem;
  height: 1rem;
  margin-left: 0.25rem;
`
