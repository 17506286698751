/**
 * @generated SignedSource<<0d70e3dde61145f4087c081fcad7fad4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HomeNativeReviewItem_reviewNativeReview$data = {
  readonly content: string;
  readonly externalId: number;
  readonly id: string;
  readonly images: ReadonlyArray<{
    readonly thumbnail: string;
  }>;
  readonly poi: {
    readonly " $fragmentSpreads": FragmentRefs<"HomeNativeReviewItemPoi_poiNativeReview">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"HomeNativeReviewItemUser_userProfileNativeReview">;
  readonly " $fragmentType": "HomeNativeReviewItem_reviewNativeReview";
};
export type HomeNativeReviewItem_reviewNativeReview$key = {
  readonly " $data"?: HomeNativeReviewItem_reviewNativeReview$data;
  readonly " $fragmentSpreads": FragmentRefs<"HomeNativeReviewItem_reviewNativeReview">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HomeNativeReviewItem_reviewNativeReview",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "content",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "externalId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Image",
      "kind": "LinkedField",
      "name": "images",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "thumbnail",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HomeNativeReviewItemUser_userProfileNativeReview"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Poi",
      "kind": "LinkedField",
      "name": "poi",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "HomeNativeReviewItemPoi_poiNativeReview"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "NativeReview",
  "abstractKey": null
};

(node as any).hash = "6c45f492bd8849afe8607f53abfecfd3";

export default node;
