/**
 * @generated SignedSource<<280511a89b1c709bb80b9ac51661c1f4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type ViewTypeEnum = "BOTTOM" | "RIGHT";
import { FragmentRefs } from "relay-runtime";
export type useSectionBase_sectionBase$data = {
  readonly baseInfo: {
    readonly isNew: boolean;
    readonly subTitle: string | null;
    readonly title: string;
    readonly viewMoreInfo: {
      readonly label: string;
      readonly targetUri: string;
      readonly type: ViewTypeEnum;
    } | null;
  } | null;
  readonly " $fragmentType": "useSectionBase_sectionBase";
};
export type useSectionBase_sectionBase$key = {
  readonly " $data"?: useSectionBase_sectionBase$data;
  readonly " $fragmentSpreads": FragmentRefs<"useSectionBase_sectionBase">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "useSectionBase_sectionBase"
};

(node as any).hash = "86d5fe4909912329235410702c8f8267";

export default node;
