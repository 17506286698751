import styled from '@emotion/styled'

const TextDot = styled.span`
  margin: 0 0.25rem;
  height: 100%;
  transform: translateY(-0.075rem);

  &::after {
    content: '·';
  }
`

export default TextDot
