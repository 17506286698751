import { type StackflowReactPlugin } from '@stackflow/react'
import { createContext, lazy, useContext, useEffect, useRef, useState } from 'react'

import DevTools from '@src/components/DevTools/DevTools'
import { type PropsOf } from '@src/lib/T/utilTypes'

import { STAGE } from '../../constants'
import { useQueryParams } from '../../hooks/useQueryParams'

const DevtoolsContext = createContext<{
  showDevtools: (flag: boolean) => void
  showVConsole: (flag: boolean) => void
}>({
  showDevtools: () => {},
  showVConsole: () => {},
})

export function useDevtools() {
  return useContext(DevtoolsContext)
}

const isAlphaApp = STAGE === 'development' || STAGE === 'alpha'

let isLoadedVConsole = false

export const loadVConsole = async () => {
  if (isLoadedVConsole) return
  const { default: VConsole } = await import('vconsole')
  new VConsole()
  isLoadedVConsole = true
}

if (isAlphaApp) {
  loadVConsole()
}

type DevToolsRootProps = PropsOf<typeof DevTools>

const DevToolsRoot: React.FCWithChildren<DevToolsRootProps> = ({ children, curActivityName }) => {
  const { isDevMode } = useQueryParams()
  const devMode = isAlphaApp || isDevMode === 'true'

  const [isDevtoolView, setIsDevtoolView] = useState(devMode)
  const [isVConsoleView, setIsVConsoleView] = useState(devMode)
  const isVConsoleLoaded = useRef(false)

  const showDevtools = (flag: boolean) => {
    if (isAlphaApp || isDevtoolView === flag) {
      return
    }

    setIsDevtoolView(flag)
  }

  const showVConsole = (flag: boolean) => {
    if (isAlphaApp || isVConsoleView === flag) {
      return
    }

    setIsVConsoleView(flag)
  }

  if (isDevMode && !isVConsoleLoaded.current) {
    loadVConsole()
    isVConsoleLoaded.current = true
  }

  useEffect(() => {
    if (!isAlphaApp && isVConsoleView && !isVConsoleLoaded.current) {
      loadVConsole()
      isVConsoleLoaded.current = true
    }
  }, [isVConsoleView])

  return (
    <DevtoolsContext.Provider
      value={{
        showDevtools,
        showVConsole,
      }}>
      {children}
      {isDevtoolView && <DevTools curActivityName={curActivityName} />}
    </DevtoolsContext.Provider>
  )
}

export function nearbyDevtoolsPlugin(): StackflowReactPlugin {
  return () => ({
    key: 'nearbyDevtools',
    wrapStack({ stack }) {
      const lastActivity = stack.activities[stack.activities.length - 1].name

      return <DevToolsRoot curActivityName={lastActivity}>{stack.render()}</DevToolsRoot>
    },
  })
}
