/**
 * @generated SignedSource<<6a9339d7869b2cb17c6ac58827e0b470>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HomeRecentlyRealtyArticles_sectionRecentlyArticle$data = {
  readonly __typename: "SectionRecentlyRealtyArticle";
  readonly articles: ReadonlyArray<{
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"RecentlyRealtyArticle_realtyArticle">;
  }>;
  readonly loggingMeta: {
    readonly click: string;
    readonly show: string;
    readonly swipe: string;
  };
  readonly " $fragmentSpreads": FragmentRefs<"HomeSectionBase_sectionBase">;
  readonly " $fragmentType": "HomeRecentlyRealtyArticles_sectionRecentlyArticle";
};
export type HomeRecentlyRealtyArticles_sectionRecentlyArticle$key = {
  readonly " $data"?: HomeRecentlyRealtyArticles_sectionRecentlyArticle$data;
  readonly " $fragmentSpreads": FragmentRefs<"HomeRecentlyRealtyArticles_sectionRecentlyArticle">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HomeRecentlyRealtyArticles_sectionRecentlyArticle",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EventMeta",
      "kind": "LinkedField",
      "name": "loggingMeta",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "swipe",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "click",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "show",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RealtyArticle",
      "kind": "LinkedField",
      "name": "articles",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "RecentlyRealtyArticle_realtyArticle"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HomeSectionBase_sectionBase"
    }
  ],
  "type": "SectionRecentlyRealtyArticle",
  "abstractKey": null
};

(node as any).hash = "a6b9eb625fcdcaa272257c6591d3a54f";

export default node;
