import * as z from 'zod'

import { type ExperimentKeyEnum as ServerDrivenExperimentKeyEnum } from '@src/types/schemaEnums'

import { type ExperimentWithKey } from './coreTypes'
import { type FrontDrivenExperimentKeyEnum } from './FrontDrivenExperiments'

export type ExperimentKeys = ServerDrivenExperimentKeyEnum | FrontDrivenExperimentKeyEnum

export type Experiment = ExperimentWithKey<ExperimentKeys>

export type Experiments = {
  [key in ExperimentKeys]?: Experiment
}

export type ExperimentsTargets = {
  [key in ExperimentKeys]?: string
}

export const ExperimentSegmentTypeSchema = z.enum(['0', '1', '2', '3'])

export type ExperimentSegmentType = z.infer<typeof ExperimentSegmentTypeSchema>

export const ExperimentSegmentMap = {
  '0': 'A',
  '1': 'B',
  '2': 'C',
  '3': 'D',
}

export const ExperimentOverrideConfig: Partial<
  Record<
    ExperimentKeys,
    {
      userId: Partial<Record<ExperimentSegmentType, number>>
    }
  >
> = {}
