import { createContext, type FCWithChildren, useContext, useMemo } from 'react'

import { CATEGORY_HOME_MAP, type ManagedCategoryEnum } from '../constants'

type CategoryThemeContext = {
  category: ManagedCategoryEnum
  categoryId: string
  themeId: string | null
}

const CategoryThemeContext = createContext<CategoryThemeContext>(null as any)

interface CategoryThemeProviderProps {
  categoryId: string
  themeId: string | null
}

export const CategoryThemeProvider: FCWithChildren<CategoryThemeProviderProps> = ({
  children,
  categoryId,
  themeId,
}) => {
  const value = useMemo(
    () => ({
      category: categoryMapper(categoryId),
      categoryId,
      themeId,
    }),
    [categoryId, themeId]
  )

  return <CategoryThemeContext.Provider value={value}>{children}</CategoryThemeContext.Provider>
}

/**
 * 현재 category, theme 정보를 가져온다.
 *
 * theme 정보는 주로 로깅에 사용된다.
 **/
export const useCategoryTheme = () => {
  return useContext(CategoryThemeContext)
}

export const categoryMapper = (categoryId: string): ManagedCategoryEnum => {
  if (categoryId in CATEGORY_HOME_MAP) {
    return CATEGORY_HOME_MAP[categoryId as keyof typeof CATEGORY_HOME_MAP]
  }

  return 'Default'
}
