import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { type FC, useRef } from 'react'
import { graphql, useFragment } from 'react-relay'

import type {
  HotJobsPost_jobPost$data,
  HotJobsPost_jobPost$key,
} from '../../../../__relay__/HotJobsPost_jobPost.graphql'
import { SALARY_TYPE_MAP } from '../../../../constants/jobs'
import { getEllipsisString, maskPrice } from '../../../../lib/unit'
import { useImpression } from '../../../_lib/Impression'
import { getEllipsisStyle } from '../../../_lib/styles'

interface HotJobsPostProps {
  fragmentRef: HotJobsPost_jobPost$key
  itemOrder: number
  itemScrollContainerRef: React.RefObject<HTMLElement>
  onClick: (article: HotJobsPost_jobPost$data, itemOrder: number) => void
  onImpression: (article: HotJobsPost_jobPost$data, itemOrder: number) => void
}

const HotJobsPost: FC<HotJobsPostProps> = ({
  fragmentRef,
  itemOrder,
  itemScrollContainerRef,
  onClick,
  onImpression,
}) => {
  const jobPost = useFragment(
    graphql`
      fragment HotJobsPost_jobPost on JobPost {
        id
        externalId
        targetUri
        title
        companyName
        distance {
          name
          distance
        }
        salaryType
        salary
        watchCount
        viewCount
        closed
      }
    `,
    fragmentRef
  )

  const itemRef = useRef<HTMLDivElement>(null)

  // swipe log
  useImpression(
    {
      ref: itemRef,
      scrollContainerRef: itemScrollContainerRef,
      disableImpressionOnload: true,
    },
    () => {
      onImpression(jobPost, itemOrder)
    },
    [itemOrder, onImpression]
  )

  const handleClick = () => {
    onClick(jobPost, itemOrder)
  }

  return (
    <S_HotJobsPost ref={itemRef} onClick={handleClick}>
      <Top>
        <Title>
          <Ranking>{`${++itemOrder}위 `}</Ranking>
          {jobPost.title}
        </Title>
        <SubLabels>
          {jobPost.companyName && <SubLabel>{getEllipsisString(jobPost.companyName, 10)}</SubLabel>}
          {jobPost.distance && <SubLabel>{jobPost.distance.name}</SubLabel>}
        </SubLabels>
      </Top>
      <Salarys>
        {jobPost.closed && <JobClosed>마감</JobClosed>}
        <SalaryType>{SALARY_TYPE_MAP[jobPost.salaryType]}</SalaryType>
        <Salary>{`${maskPrice(jobPost.salary, {
          applyPrice: 1000000,
        })}`}</Salary>
      </Salarys>
    </S_HotJobsPost>
  )
}

export default HotJobsPost

const S_HotJobsPost = styled.article`
  padding: 1rem;
  width: 12.5rem;
  border-radius: 0.375rem;
  border: 1px solid ${vars.$semantic.color.divider2};
  color: ${vars.$scale.color.gray900};

  box-sizing: border-box;
  vertical-align: top;

  & > *:not(:last-child) {
    margin-bottom: 0.5rem;
  }
`

const Top = styled.div`
  width: 100%;
  & > *:not(:last-child) {
    margin-bottom: 0.125rem;
  }
`

const Title = styled.h3`
  margin: 0;
  width: 100%;
  min-height: 2.688rem;
  ${vars.$semantic.typography.subtitle1Bold};
  ${getEllipsisStyle(2)};
`

const Ranking = styled.span`
  color: ${vars.$scale.color.carrot500};
`

const SubLabels = styled.div`
  display: flex;
  min-height: 1rem;
  ${vars.$semantic.typography.caption2Regular};
  color: ${vars.$scale.color.gray600};
`

const JobClosed = styled.span`
  color: ${vars.$scale.color.gray500};
  margin-right: 0.25rem;
`

const SubLabel = styled.span`
  ${getEllipsisStyle(1)};

  &::before {
    content: '·';
    color: ${vars.$scale.color.gray600};
    margin: 0 0.25rem;
  }

  &:first-of-type {
    &::before {
      display: none;
    }
  }

  &:last-of-type {
    flex-shrink: 0;
  }
`

const Salarys = styled.div`
  display: flex;
  width: 100%;
  color: ${vars.$scale.color.gray900};
  ${vars.$semantic.typography.label3Bold};
`

const SalaryType = styled.div`
  flex-shrink: 0;
  margin-right: 0.25rem;
`

const Salary = styled.div`
  ${getEllipsisStyle(1)};
`
