/**
 * @generated SignedSource<<dc8c9c0ceb9b9d98892b169bc4a8505e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HomeReview_bizAccountReview$data = {
  readonly bizAccount: {
    readonly description: string | null;
    readonly externalId: string;
    readonly followerCount: number;
    readonly image: {
      readonly url: string;
    };
    readonly images: ReadonlyArray<{
      readonly url: string;
    }>;
    readonly name: string;
    readonly region: {
      readonly name3: string;
    } | null;
    readonly reviewCount: number;
  };
  readonly bizDeliveryLoggingMeta: {
    readonly click: string;
    readonly impression: string;
  } | null;
  readonly content: string;
  readonly distance: {
    readonly distance: number;
    readonly name: string;
  } | null;
  readonly externalId: string;
  readonly user: {
    readonly nickname: string;
  };
  readonly " $fragmentType": "HomeReview_bizAccountReview";
};
export type HomeReview_bizAccountReview$key = {
  readonly " $data"?: HomeReview_bizAccountReview$data;
  readonly " $fragmentSpreads": FragmentRefs<"HomeReview_bizAccountReview">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "externalId",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "url",
    "storageKey": null
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HomeReview_bizAccountReview",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "BizAccount",
      "kind": "LinkedField",
      "name": "bizAccount",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Image",
          "kind": "LinkedField",
          "name": "image",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Image",
          "kind": "LinkedField",
          "name": "images",
          "plural": true,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Region",
          "kind": "LinkedField",
          "name": "region",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name3",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "reviewCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "followerCount",
          "storageKey": null
        },
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "HoianUser",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "nickname",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "content",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DistanceInfo",
      "kind": "LinkedField",
      "name": "distance",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "distance",
          "storageKey": null
        },
        (v2/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BizDeliveryLoggingMeta",
      "kind": "LinkedField",
      "name": "bizDeliveryLoggingMeta",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "click",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "impression",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "BizAccountReview",
  "abstractKey": null
};
})();

(node as any).hash = "7bd190ff334e1b662a59ac2c0d802229";

export default node;
