import { HeartButton } from '@daangn/karrot-clothes'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { Boolean } from 'globalthis/implementation'
import React, { type MouseEvent, useMemo } from 'react'

import IconNewTopic from '@src/assets/images/icons/icon_newtopic.svg'
import BadgeSquare from '@src/components/_lib/BadgeSquare/BadgeSquare'
import { type BADGE_TYPE } from '@src/components/_lib/BadgeSquare/token'
import CoveredLazyLoadImage from '@src/components/_lib/CoveredLazyLoadImage'
import { StarRatingDisplay } from '@src/components/_lib/StarRating/StarRatingDisplay'
import { activeEffect, FlexJustifyCenter, getEllipsisStyle } from '@src/components/_lib/styles'
import { useLogger } from '@src/hooks/useLogger'
import { useToastSnackbar } from '@src/hooks/useToastSnackbar'
import { getEllipsisString } from '@src/lib/unit'

import { useUpdateBizStoreWatch } from '../../../hooks/mutations/useUpdateBizStoreWatchMutation'
import { useCategoryFilterOption } from '../hooks/useCategoryFilter'
import { useCategoryTheme } from '../hooks/useCategoryTheme'

type ViewType = 'default' | 'featured-image'

export interface Props {
  itemId: string
  externalItemId: string
  title: string
  category?: string | null
  useNewTag?: boolean
  descriptionInfo?: {
    text: string
    ellipsisLine?: number
  }
  imageUrls?: string[]
  indicators?: {
    title: string
    useBold?: boolean
  }[]
  badges?: {
    title: string
    type?: BADGE_TYPE
  }[]
  type?: ViewType
  isWatched: boolean
  starRating: number | null
  onClick?: () => void
  onClickWatch?: (props: { id: string; badges?: string[] }) => void
  itemType: string
}

const NewCardItem: React.FC<Props> = ({
  itemId,
  externalItemId,
  title,
  imageUrls,
  indicators,
  badges = [],
  starRating,
  useNewTag,
  category,
  descriptionInfo,
  type = 'default',
  isWatched,
  onClick,
  onClickWatch,
  itemType,
}) => {
  const { nearbyLog } = useLogger()
  const categoryTheme = useCategoryTheme()
  const { sortOption, toggleOptions } = useCategoryFilterOption()
  const { openSnackbar } = useToastSnackbar()
  const { onWatch } = useUpdateBizStoreWatch()

  const useFeaturedImage = useMemo(() => type === 'featured-image', [type])

  const imageInstance = useMemo(() => {
    if (useFeaturedImage)
      return (
        <EnhancedImageType>
          {imageUrls?.map(
            (imageUrl, idx) =>
              idx < 3 && (
                <S_Image key={imageUrl} insideStrokeDisabled alt="비즈프로필 이미지" src={imageUrl} visibleByDefault />
              )
          )}
        </EnhancedImageType>
      )

    return (
      <DefaultImageType>
        <S_Image insideStrokeDisabled alt="비즈프로필 이미지" src={imageUrls?.[0]} visibleByDefault />
      </DefaultImageType>
    )
  }, [imageUrls, useFeaturedImage])

  const indicatorInstance = useMemo(
    () => (
      <S_TagGroup>
        {starRating && (
          <>
            <StarRatingDisplay type="with-text" rating={starRating} size="small" />
          </>
        )}
        {indicators?.map(({ useBold = false, title }, index) => (
          <Tag key={title} useBold={useBold}>
            {index || starRating ? <> · {title}</> : title}
          </Tag>
        ))}
      </S_TagGroup>
    ),
    [indicators, starRating]
  )
  const badgeTagInstance = useMemo(
    () => (
      <S_FeaturedTags>
        {badges?.map(({ title, type }) => (
          <S_BadgeSquare key={title} type={type} label={title} />
        ))}
      </S_FeaturedTags>
    ),
    [badges]
  )

  const handleWatchItem = (e?: MouseEvent<HTMLButtonElement>) => {
    e?.stopPropagation()

    onClickWatch?.({ id: externalItemId, badges: badges.map(({ title }) => title) })

    categoryTheme &&
      nearbyLog({
        params: {
          name: 'click_category_home_watch',
          item_type: itemType,
          item_id: externalItemId,
          theme_id: categoryTheme.themeId ?? null,
          sortBy: sortOption,
          filterIds: toggleOptions.join(','),
        },
      })

    onWatch({
      variables: {
        input: {
          isWatched: !isWatched,
          targetNodeId: itemId,
        },
      },
      onSuccess: () => {
        !isWatched &&
          openSnackbar({
            body: '관심 업체에 추가했어요.',
          })
      },
      onError: () => {
        openSnackbar({
          body: `관심 업체 추가에 실패했어요. 다시 한번 시도해주세요.`,
        })
      },
    })
  }

  const onImageType = Boolean(!useFeaturedImage && imageUrls?.length)

  return (
    <S_CardItem useFeaturedImage={useFeaturedImage} onClick={onClick}>
      <TextGroup viewType={type}>
        <Header>
          <TitleWrapper>
            <S_Title>{getEllipsisString(title, 20)}</S_Title>

            {useNewTag && (
              <NewBadge>
                <IconNewTopic />
              </NewBadge>
            )}
            {category && <S_Category>{category}</S_Category>}
          </TitleWrapper>

          <IconWrapper useFeaturedImage={useFeaturedImage}>
            <HeartButton
              onClick={handleWatchItem}
              filled={isWatched}
              onImage={onImageType}
              strokeColor={onImageType ? vars.$static.color.staticWhite : vars.$scale.color.gray600}
            />
          </IconWrapper>
        </Header>

        {descriptionInfo?.text && (
          <S_Description useFeaturedImage={useFeaturedImage} ellipsisNum={descriptionInfo?.ellipsisLine}>
            {descriptionInfo.text}
          </S_Description>
        )}

        {!!indicators?.length && indicatorInstance}

        {!!badges?.length && badgeTagInstance}
      </TextGroup>

      {!!imageUrls?.length && imageInstance}
    </S_CardItem>
  )
}

export default NewCardItem

const ResetParagraph = styled.p`
  margin: 0;
`

const S_CardItem = styled.div<{ useFeaturedImage?: boolean }>`
  ${FlexJustifyCenter};
  margin: 0 1rem;
  padding: 1rem 0;
  flex-direction: ${({ useFeaturedImage }) => (useFeaturedImage ? 'column' : 'row')};
  position: relative;
`

const TextGroup = styled.div<{ viewType: ViewType }>`
  flex: 1;

  ${({ viewType }) =>
    viewType === 'default' &&
    css`
      width: calc(100% - 6.75rem);
    `}
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.25rem;
  vertical-align: center;
`

const TitleWrapper = styled.div`
  & > *:not(:last-child) {
    margin-right: 0.25rem;
  }
`

const S_Title = styled(ResetParagraph)`
  ${vars.$semantic.typography.subtitle1Bold};
  color: ${vars.$scale.color.gray900};
  display: inline;
`

const IconWrapper = styled.div<{ useFeaturedImage: boolean }>`
  ${activeEffect()}

  position: absolute;
  z-index: 1;
  top: ${({ useFeaturedImage }) => (useFeaturedImage ? '1rem' : '1.3125rem')};
  right: ${({ useFeaturedImage }) => (useFeaturedImage ? '0.375rem' : '0.3125rem')};

  &:active {
    background-color: transparent;
  }
  button {
    padding: 0;
  }
`

const NewBadge = styled.span`
  width: 1.125rem;
  vertical-align: text-top;
  & > svg {
    width: inherit;
    height: inherit;
  }
`

const S_Category = styled(ResetParagraph)`
  display: inline;
  ${vars.$semantic.typography.caption1Regular};

  color: ${vars.$scale.color.gray600};
  vertical-align: text-top;
  word-break: keep-all;
`

const S_Description = styled(ResetParagraph)<{ useFeaturedImage: boolean; ellipsisNum?: number }>`
  ${vars.$semantic.typography.bodyL2Regular};

  margin-right: ${({ useFeaturedImage }) => (useFeaturedImage ? '2.6875rem' : '0')};
  margin-bottom: 0.125rem;
  color: ${vars.$scale.color.gray900};
  white-space: break-spaces;

  ${({ ellipsisNum }) => (ellipsisNum ? getEllipsisStyle(ellipsisNum) : ' ')};
`

const S_TagGroup = styled.div`
  display: flex;
  align-items: center;
`
const Tag = styled.span<{ useBold: boolean }>`
  white-space: break-spaces;
  color: ${vars.$scale.color.gray600};
  ${({ useBold }) => (useBold ? vars.$semantic.typography.caption1Bold : vars.$semantic.typography.caption1Regular)};
`

const DefaultImageType = styled.div`
  flex-shrink: 0;
  margin-left: 1.125rem;
  height: 5.75rem;
  width: 5.75rem;
`
const EnhancedImageType = styled.div`
  margin-top: 0.875rem;
  width: 100%;
  height: 7.0625rem;
  display: flex;
`

const S_FeaturedTags = styled(ResetParagraph)`
  margin-top: 0.4375rem;
  display: flex;
`

const S_BadgeSquare = styled(BadgeSquare)`
  &:nth-of-type(n + 2) {
    margin-left: 0.375rem;
  }
`

const S_Image = styled(CoveredLazyLoadImage)`
  flex: 1;
  &:nth-of-type(2) {
    border-radius: 0;
  }
  &:first-of-type {
    border-radius: 0.375rem 0 0 0.375rem;
  }
  &:last-of-type {
    border-radius: 0 0.375rem 0.375rem 0;
  }
  &:only-child {
    border-radius: 0.375rem;
  }
  & + & {
    margin-left: 0.125rem;
  }
`
