import { css, Global, useTheme } from '@emotion/react'
import { vars } from '@seed-design/design-token'

export const MyGlobalStyles: React.FC = () => {
  const theme = useTheme()

  return (
    <Global
      styles={css`
        .kf-navbar {
          background-color: ${vars.$semantic.color.paperDefault};
          color: ${vars.$scale.color.gray900};

          & > div {
            ${theme.environment === 'android' &&
            css`
              box-shadow: inset 0px -1px 0 ${vars.$scale.color.gray300};
            `}
            ${theme.environment === 'cupertino' &&
            css`
              box-shadow: inset 0px -1px 0 ${vars.$scale.color.gray300};
            `}
          }
        }
        .kf-frame {
          background-color: ${vars.$semantic.color.paperDefault};
        }

        .bp3-tooltip2,
        .bp3-popover2 {
          box-shadow: none;

          .bp3-popover2-content {
            background-color: ${vars.$scale.color.gray900};
            border-radius: 0.375rem;
            color: ${vars.$scale.color.gray00};
          }
          .bp3-popover2-arrow::before {
            box-shadow: none;
          }
          .bp3-popover2-arrow-fill {
            fill: ${vars.$scale.color.gray900};
          }
          .bp3-popover2-arrow-border {
            display: none;
          }
        }

        .karrot-toast {
          .bp3-toast[class*='bp3-intent-'] {
            background-color: ${vars.$scale.color.gray900};
            display: flex;
            align-items: center;
            border-radius: 0.25rem;
            box-shadow: 0px 3px 4px ${vars.$scale.color.grayAlpha200};
            width: 100%;
            min-width: none;

            .bp3-toast-message {
              padding: 0 0 0 1rem;
              font-size: 0.875rem;
              color: ${vars.$scale.color.gray00};
            }
            .bp3-button-group {
              padding: 0.375rem 0.5rem;
            }
            a.bp3-button {
              display: flex;
              padding: 0.53125rem 0.375rem;
              border-radius: 0.25rem;
              &:hover {
                background: none !important;
              }
              &:active {
                background-color: #ffffff1a !important;
              }
              .bp3-button-text {
                font-size: 0.875rem;
                color: ${vars.$scale.color.carrot500};
                font-weight: bold;
              }
            }
            button.bp3-button {
              display: none;
            }
          }
        }
      `}
    />
  )
}
