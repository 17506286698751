import { Marker as KarrotMapMarker, useMap } from '@daangn/karrotmaps-react'
import { useEffect, useMemo, type FC } from 'react'
import { useRecoilValue } from 'recoil'

import IconPin from './MarkerPresenters/Pins/IconPin'
import ImagePin from './MarkerPresenters/Pins/ImagePin'
import Pin from './MarkerPresenters/Pins/Pin'
import { PinLabel } from './MarkerPresenters/Pins/PinLabel'
import UserPosition from './MarkerPresenters/UserPosition'
import { type TMarker } from './types'
import { activeBizAccountAtom } from '../../state/activeStates'

interface MarkerProps {
  marker: TMarker
  useSymbolLabel?: boolean
}

const Marker: FC<MarkerProps> = ({ marker, useSymbolLabel }) => {
  const { current: map } = useMap()

  const active = useRecoilValue(activeBizAccountAtom) // TODO. activeBizAccountAtom이랑 결합도 떨어뜨려야됨. activeBizAccountAtom -> activeCouponId
  const isSelected = active?.couponId === marker.id
  const selectedMarker = marker.selectedMarker
  const selectedStatus = isSelected && selectedMarker

  const title = useMemo(() => {
    let _title = marker.label ?? ''
    if (_title.length > 12) {
      // 6글자 지점과 12글자 지점에서 줄바꿈 문자를 삽입합니다.
      _title = _title.slice(0, 6) + '\n' + _title.slice(6, 12) + '...'
    } else if (_title.length > 6) {
      // 6글자 지점에서 줄바꿈 문자를 삽입합니다.
      _title = _title.slice(0, 6) + '\n' + _title.slice(6)
    }

    return _title
  }, [marker.label])

  const renderMarkerPresenter = (marker: TMarker, isSelected?: boolean) => {
    switch (marker.type) {
      case 'User':
        return <UserPosition />
      case 'Pin':
        return <Pin {...marker} />
      case 'ImagePin':
        return <ImagePin {...marker} isSelected={isSelected} />
      case 'IconPin':
        return <IconPin {...marker} isSelected={isSelected} />
      default:
        throw new Error('지원안됨')
    }
  }

  useEffect(() => {
    const possibleSymbolLabel = useSymbolLabel && !map.getLayer('marker-labels')
    const id = `symbol-label:${marker.id}`
    const viewSymbol = possibleSymbolLabel && !selectedStatus

    if (viewSymbol) {
      map.addLayer({
        id,
        type: 'symbol',
        source: {
          type: 'geojson',
          data: {
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [marker.position.lng, marker.position.lat],
            },
            properties: {
              title,
            },
          },
        },
        layout: {
          'text-field': ['get', 'title'],
          'text-font': ['Pretendard Bold'],
          'text-size': 12,
          'text-anchor': 'top',
          'text-offset': [0, 1.5],
          'symbol-placement': 'point',
          'symbol-avoid-edges': true,
          'text-allow-overlap': !!selectedStatus,
          'symbol-z-order': 'viewport-y',
        },
        paint: {
          'text-color': '#000',
          'text-halo-color': '#fff',
          'text-halo-width': 2,
        },
      })
    }

    return () => {
      if (possibleSymbolLabel && viewSymbol) {
        map.removeLayer(id)
        map.removeSource(id)
      }
    }
  }, [map, marker.id, marker.label, marker.position.lat, marker.position.lng, selectedStatus, title, useSymbolLabel])

  const MarkerCom = useMemo(() => {
    return selectedStatus ? (
      <KarrotMapMarker position={selectedMarker.position} anchor={'bottom'} offset={[0, 10]}>
        {renderMarkerPresenter(selectedMarker, true)}
      </KarrotMapMarker>
    ) : (
      <KarrotMapMarker position={marker.position} anchor={'bottom'} offset={[0, 10]}>
        {renderMarkerPresenter(marker)}
      </KarrotMapMarker>
    )
  }, [marker, selectedMarker, selectedStatus])

  return (
    <>
      {MarkerCom}
      {(!useSymbolLabel || selectedStatus) && (
        <KarrotMapMarker position={marker.position} anchor={'top'} offset={[0, 11]}>
          <PinLabel size="large">
            {title.split('\n').map((text) => (
              <div key={text}>{text}</div>
            ))}
          </PinLabel>
        </KarrotMapMarker>
      )}
    </>
  )
}

export default Marker
