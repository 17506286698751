import { identity } from 'lodash/fp'
import { type AtomEffect } from 'recoil'

import { nearbyStorage, type NearbyStorageKind } from '@src/lib/storage/storage'

const syncWithStorage =
  (storage: NearbyStorageKind = nearbyStorage) =>
  <T>({ key, decoder = identity }: { key: string; decoder?: (value: string) => T }): AtomEffect<T> =>
  ({ setSelf, onSet }) => {
    const savedValue = storage.getItem(key)
    if (savedValue != null) {
      setSelf(decoder(JSON.parse(savedValue)))
    }

    onSet((newValue, _, isReset) => {
      if (isReset) {
        storage.removeItem(key)
      } else {
        storage.setItem(key, JSON.stringify(newValue))
      }
    })
  }

export default syncWithStorage
