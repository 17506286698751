import { useCallback, useMemo } from 'react'
import { graphql, readInlineData } from 'react-relay'

import {
  type useSectionBase_sectionBase$key,
  type ViewTypeEnum,
} from '../../__relay__/useSectionBase_sectionBase.graphql'
import type SectionBottomButton from '../../components/Home/lib/SectionBottomButton'
import type SectionTitle from '../../components/Home/lib/SectionTitle/SectionTitle'
import { type PropsOfNoneChildren } from '../../lib/T/utilTypes'

interface useSectionBaseProps {
  sectionBase: useSectionBase_sectionBase$key
  SectionTitleProps?: Partial<PropsOfNoneChildren<typeof SectionTitle>>
  SectionBottomButtonProps?: PropsOfNoneChildren<typeof SectionBottomButton>
  onMoreClick?: (props: { type: ViewTypeEnum; targetUri?: string }) => void
  moreButtonType?: 'BOTTOM' | 'RIGHT'
}

export function useSectionBase({
  SectionTitleProps,
  SectionBottomButtonProps,
  onMoreClick,
  moreButtonType,
  ...props
}: useSectionBaseProps) {
  const sectionBase = readInlineData(
    graphql`
      fragment useSectionBase_sectionBase on SectionBaseInfo @inline {
        baseInfo {
          title
          subTitle
          viewMoreInfo {
            type
            label
            targetUri
          }
          isNew
        }
      }
    `,
    props.sectionBase
  )

  const viewMoreInfo = sectionBase?.baseInfo?.viewMoreInfo
  const viewMoreType = moreButtonType ?? viewMoreInfo?.type

  const handleViewMoreClick = useCallback(() => {
    if (onMoreClick && viewMoreInfo) {
      onMoreClick({
        type: viewMoreInfo?.type,
        targetUri: viewMoreInfo?.targetUri,
      })
    }
  }, [onMoreClick, viewMoreInfo])

  const sectionBaseViewMoreInfo = useMemo(
    () => ({
      content: viewMoreInfo?.label ?? '더보기',
      onClick: handleViewMoreClick,
    }),
    [handleViewMoreClick, viewMoreInfo]
  )

  const SectionBottomButtonPropsWithBaseInfo = useMemo(
    () =>
      (viewMoreInfo && viewMoreType === 'BOTTOM') || (onMoreClick && SectionBottomButtonProps?.content)
        ? { ...sectionBaseViewMoreInfo, ...SectionBottomButtonProps }
        : SectionBottomButtonProps,
    [SectionBottomButtonProps, onMoreClick, sectionBaseViewMoreInfo, viewMoreInfo, viewMoreType]
  )

  const SectionTitlePropsWithBaseInfo: PropsOfNoneChildren<typeof SectionTitle> = useMemo(
    () => ({
      title: sectionBase?.baseInfo?.title ?? 'Unknown',
      subtitle: sectionBase?.baseInfo?.subTitle,
      isNew: sectionBase?.baseInfo?.isNew,
      rightButton: viewMoreInfo && viewMoreType === 'RIGHT' ? sectionBaseViewMoreInfo : null,
      ...SectionTitleProps,
    }),
    [
      SectionTitleProps,
      sectionBase?.baseInfo?.subTitle,
      sectionBase?.baseInfo?.title,
      sectionBase?.baseInfo?.isNew,
      viewMoreInfo,
      viewMoreType,
      sectionBaseViewMoreInfo,
    ]
  )

  return useMemo(
    () => ({
      SectionTitlePropsWithBaseInfo,
      SectionBottomButtonPropsWithBaseInfo,
    }),
    [SectionBottomButtonPropsWithBaseInfo, SectionTitlePropsWithBaseInfo]
  )
}
