import { useCallback } from 'react'
import { graphql, readInlineData } from 'relay-runtime'

import { type usePoiWatch_poi$key } from '@src/__relay__/usePoiWatch_poi.graphql'
import { APP_SCHEME } from '@src/constants'
import { useUpdateBizStoreWatch } from '@src/hooks/mutations/useUpdateBizStoreWatchMutation'
import { useToastSnackbar } from '@src/hooks/useToastSnackbar'

interface usePoiWatchProps {
  poiRef: usePoiWatch_poi$key
}

/**
 * Poi 관심의 경우 BizAccount가 있으면 BizAccount 관심(watch)을 사용하고, 없으면 Poi 관심(watch)을 사용한다.
 */
export function usePoiWatch({ poiRef }: usePoiWatchProps) {
  const poi = readInlineData(
    graphql`
      fragment usePoiWatch_poi on Poi @inline {
        id
        viewerIsWatched
        bizAccount {
          id
          viewerIsWatched
        }
      }
    `,
    poiRef
  )

  const { onWatch } = useUpdateBizStoreWatch()
  const { openSnackbar } = useToastSnackbar()

  const viewerIsWatched = poi.bizAccount ? poi.bizAccount.viewerIsWatched : poi.viewerIsWatched

  const watchPoi = useCallback(() => {
    const watchTargetNodId = poi.bizAccount ? poi.bizAccount.id : poi.id

    onWatch({
      variables: {
        input: {
          isWatched: !viewerIsWatched,
          targetNodeId: watchTargetNodId,
        },
      },
      onSuccess: () => {
        !viewerIsWatched &&
          openSnackbar({
            body: '관심 업체에 추가했어요.',
            button: {
              action: {
                link: {
                  path: `${APP_SCHEME}my_watches`,
                },
              },
              body: '관심 업체 보기',
            },
          })
      },
      onError: () => {
        openSnackbar({
          body: `관심 업체 추가에 실패했어요. 다시 한번 시도해주세요.`,
        })
      },
    })
  }, [onWatch, openSnackbar, poi.bizAccount, poi.id, viewerIsWatched])

  return {
    viewerIsWatched,
    watchPoi,
  }
}
