import { AppScreen } from '@stackflow/plugin-basic-ui'
import { useCallback, useEffect, useRef, useState } from 'react'

import { usePageVisibleChange } from '@src/hooks/usePageVisibleChange'

import { type PropOf } from '../../lib/T/utilTypes'
import type { NearbyActivityNames } from '../../stackflow/stackflow'
interface AppScrollerProps {
  activityName: NearbyActivityNames
  activityVisibility: boolean
}

const AppScroller: React.FCWithChildren<AppScrollerProps> = ({ children }) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const lastScrollRef = useRef<HTMLElement>()

  useEffect(() => {
    const container = containerRef.current

    const handleScroll = (e: Event) => {
      const target = e.target as HTMLElement

      if (target.scrollWidth > target.clientWidth || target.scrollHeight === target.clientHeight) {
        return
      }

      if (lastScrollRef.current && lastScrollRef.current?.contains(target)) {
        return
      }

      lastScrollRef.current = target
    }

    if (container) {
      container.addEventListener('scroll', handleScroll, true)
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll, true)
      }
    }
  }, [])

  // useScrollToTopEffect(async () => {
  //   if (!activityVisibility) {
  //     return
  //   }

  //   if (environment === 'cupertino') {
  //     nearbyLog({
  //       params: {
  //         name: 'click_scroll_to_top',
  //         page: activityName,
  //       },
  //     })

  //     scroll({ top: 0 })
  //     lastScrollRef.current?.scroll?.({ top: 0, behavior: 'smooth' })
  //   }
  // }, [scroll, lastScrollRef, nearbyLog, environment, activityVisibility])

  return (
    <div ref={containerRef} style={{ height: '100%', position: 'relative' }}>
      {children}
    </div>
  )
}

interface ActivityLayoutProps {
  appBar?: PropOf<typeof AppScreen, 'appBar'>
  activityName: NearbyActivityNames
  onAcitivityVisibilityChange?: (isVisible: boolean) => void
}

const ActivityLayout: React.FCWithChildren<ActivityLayoutProps> = ({
  appBar,
  activityName,
  children,
  onAcitivityVisibilityChange,
}) => {
  const [isActivityVisible, setIsActivityVisible] = useState(true)

  const handleVisibilityChange = useCallback(
    (isVisible: boolean) => {
      setIsActivityVisible(isVisible)

      onAcitivityVisibilityChange?.(isVisible)
    },
    [onAcitivityVisibilityChange]
  )

  usePageVisibleChange(handleVisibilityChange)

  return (
    <AppScreen appBar={appBar}>
      <AppScroller activityName={activityName} activityVisibility={isActivityVisible}>
        {children}
      </AppScroller>
    </AppScreen>
  )
}

export default ActivityLayout
