import { useEffect } from 'react'
import { graphql, useLazyLoadQuery } from 'react-relay'

import { type HomeContentsQuery } from '@src/__relay__/HomeContentsQuery.graphql'
import { useCurrentPosition } from '@src/hooks/useCurrentPosition'
import { useLogger } from '@src/hooks/useLogger'
import { usePageInit } from '@src/hooks/usePageInit'
import { useViewer } from '@src/hooks/useViewer'

import HomeSections from './HomeSections'

interface HomeContentsProps {
  fetchKey: number
}

const HomeContents: React.FC<HomeContentsProps> = ({ fetchKey }) => {
  const { currentPosition } = useCurrentPosition()

  const { nearbyLog } = useLogger()

  const viewer = useViewer()

  const query = useLazyLoadQuery<HomeContentsQuery>(
    graphql`
      query HomeContentsQuery($regionId: Int!, $currentCoords: CoordsInput) {
        ...usePageInit_pageInit @arguments(regionId: $regionId)
        ...HomeSections_sections @arguments(regionId: $regionId, currentCoords: $currentCoords)

        viewer {
          isEmployee
        }
      }
    `,
    {
      regionId: viewer.region.id || 0,
      currentCoords: currentPosition
        ? {
            lat: currentPosition.lat,
            lng: currentPosition.lng,
          }
        : null,
    },
    { fetchKey, fetchPolicy: 'store-and-network' }
  )

  useEffect(() => {
    ;(async () => {
      try {
        const lat = currentPosition?.lat
        const lng = currentPosition?.lng

        if (lat && lng) {
          nearbyLog({
            params: {
              name: 'show_onload_position',
              region_id: viewer.region.id,
              lat,
              lng,
            },
          })
        }
      } catch {
        /* empty */
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  usePageInit({
    queryRef: query,
    fetchKey,
  })

  return (
    <>
      <HomeSections fetchKey={fetchKey} currentPosition={currentPosition} query={query} />
    </>
  )
}

export default HomeContents
