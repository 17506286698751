import { graphql, useMutation } from 'react-relay'

import { useCouponMutations_ClaimCouponMutation } from '../../../../__relay__/useCouponMutations_ClaimCouponMutation.graphql'
import { useCouponMutations_followBizAccountMutation } from '../../../../__relay__/useCouponMutations_followBizAccountMutation.graphql'

export function useCouponMutations() {
  const [commitFollowBizAccount] = useMutation<useCouponMutations_followBizAccountMutation>(graphql`
    mutation useCouponMutations_followBizAccountMutation($input: CreateFollowBizAccountInput!) {
      createFollowBizAccount(input: $input)
    }
  `)

  const [commitClaimCoupon] = useMutation<useCouponMutations_ClaimCouponMutation>(graphql`
    mutation useCouponMutations_ClaimCouponMutation($input: ClaimToCouponBoxInput!) @raw_response_type {
      claimToCouponBox(input: $input) {
        __typename

        ... on ClaimToCouponBoxOutput_Result {
          result {
            couponBox {
              viewerHasClaimed
              remainCount
              totalClaimedUserCount
              claimedUsers(first: 3) {
                edges {
                  node {
                    id
                    image {
                      thumbnail
                    }
                  }
                }
              }
            }
          }
        }
        ... on ToastError {
          toastError {
            message
          }
        }
        ... on PopupError {
          popupError {
            message
            buttons {
              variant
              text
              redirectTargetUri
            }
          }
        }
      }
    }
  `)

  return {
    commitFollowBizAccount,
    commitClaimCoupon,
  }
}
