import { usePullToRefreshScrollElement } from '@daangn/karrot-clothes/lib/pullToRefresh'
import { chunk } from 'lodash'
import { type FC, useCallback, useMemo } from 'react'
import { graphql, useFragment } from 'react-relay'

import ClassBizPostItem from './ClassBizPostItem'
import { type HomeClass_sectionClassBizPost$key } from '../../../../__relay__/HomeClass_sectionClassBizPost.graphql'
import { useLogger } from '../../../../hooks/useLogger'
import { type PropOf } from '../../../../lib/T/utilTypes'
import { useOpenTarget } from '../../../../lib/target'
import type { SectionInfo } from '../../../../types'
import { Divider } from '../../../_lib/ListSection/Divider'
import { ListEntry } from '../../../_lib/ListSection/ListEntry'
import { useSectionTitleBizNudging } from '../../lib/SectionTitle/SectionTitle'
import CarouselContent from '../Common/CarouselContent'
import HomeSectionBase from '../Common/HomeSectionBase'

interface HomeClassProps {
  sectionClassBizPost: HomeClass_sectionClassBizPost$key
  sectionInfo: SectionInfo
}

const VIEW_ITEM_COUNT = 3

const HomeClass: FC<HomeClassProps> = ({ sectionInfo, ...props }) => {
  const sectionClassBizPost = useFragment(
    graphql`
      fragment HomeClass_sectionClassBizPost on SectionClassBizPost {
        __typename
        bizPosts {
          id
          ...ClassBizPostItem_bizPost
        }
        loggingMeta {
          swipe
          click
          show
        }
        targetUri
        writeBizTargetUri
        ...HomeSectionBase_sectionBase
      }
    `,
    props.sectionClassBizPost
  )

  const scrollContainerRef = usePullToRefreshScrollElement()
  const { contentsSectionLog, nearbyLog, loggingMetaLog } = useLogger()
  const { openTarget } = useOpenTarget()

  const handleImpression = () => {
    nearbyLog({
      params: {
        name: 'show_contents_section',
        contents_type: 'class',
        sectionOrder: sectionInfo.order,
      },
    })

    if (sectionInfo.order === 1) {
      loggingMetaLog(sectionClassBizPost.loggingMeta.show)
    }
  }

  const handleMoreClick: NonNullable<PropOf<typeof HomeSectionBase, 'onMoreClick'>> = useCallback(
    ({ targetUri }) => {
      contentsSectionLog('click', {
        contentsType: 'class',
        buttonType: 'more',
        params: {
          sectionOrder: sectionInfo.order,
        },
      })

      loggingMetaLog(sectionClassBizPost.loggingMeta.click)

      if (targetUri) {
        openTarget({
          targetUri,
        })
      }
    },
    [contentsSectionLog, loggingMetaLog, openTarget, sectionInfo.order, sectionClassBizPost.loggingMeta.click]
  )

  const handleCarouselListItemSwipe: PropOf<typeof CarouselContent, 'onListSwipe'> = useCallback(
    (offset) => {
      nearbyLog({
        params: {
          name: 'swipe_contents_section',
          contentsType: 'class',
          sectionOrder: sectionInfo.order,
          page: offset,
        },
      })

      loggingMetaLog(sectionClassBizPost.loggingMeta.swipe)
    },
    [nearbyLog, loggingMetaLog, sectionInfo.order, sectionClassBizPost.loggingMeta]
  )

  const handleClassBizPostItemClick: PropOf<typeof ClassBizPostItem, 'onClick'> = useCallback(
    ({ targetUri, badges }) => {
      contentsSectionLog('click', {
        contentsType: 'class',
        buttonType: 'list',
        params: {
          sectionOrder: sectionInfo.order,
          ...(badges.length > 0 ? { badges: badges.join(',') } : null),
        },
      })

      loggingMetaLog(sectionClassBizPost.loggingMeta.click)

      openTarget({
        targetUri,
      })
    },
    [contentsSectionLog, loggingMetaLog, openTarget, sectionInfo.order, sectionClassBizPost.loggingMeta.click]
  )

  const classBizPostListItems = useMemo(
    () =>
      chunk(sectionClassBizPost.bizPosts, VIEW_ITEM_COUNT).map((classBizPosts, index) => (
        <ListEntry key={index}>
          {classBizPosts.map((classBizPost, index) => (
            <div key={classBizPost.id}>
              <ClassBizPostItem classBizPost={classBizPost} onClick={handleClassBizPostItemClick} />
              {index !== classBizPosts.length - 1 && <Divider />}
            </div>
          ))}
        </ListEntry>
      )),
    [handleClassBizPostItemClick, sectionClassBizPost.bizPosts]
  )

  // 업체소식 작성 넛지
  // todo. 서버에서 writeBizTargetUri를 nullable로 해주면 그거 반영하기
  const { sectionWriteButton, sectionWriteTooltip } = useSectionTitleBizNudging({
    rightButtonContent: '소식 작성',
    rightButtonTargetUri: sectionClassBizPost.writeBizTargetUri,
    sectionType: 'class',
  })

  return (
    <HomeSectionBase
      sectionBase={sectionClassBizPost}
      ImpressionProps={{
        scrollContainerRef,
        onImpression: handleImpression,
        threshold: 0.9,
      }}
      ServiceTitleProps={{ serviceType: 'CLASS' }}
      SectionTitleProps={{
        rightButton: sectionWriteButton,
        rightButtonTooltip: sectionWriteTooltip,
      }}
      onMoreClick={handleMoreClick}>
      <CarouselContent
        listItems={classBizPostListItems}
        onListSwipe={handleCarouselListItemSwipe}
        sectionOrder={sectionInfo.order}
      />
    </HomeSectionBase>
  )
}

export default HomeClass
