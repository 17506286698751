import { ImpressionArea } from '@daangn/business-review-sdk'
import styled from '@emotion/styled'
import { useActivity, useActivityParams } from '@stackflow/react'
import compact from 'lodash/compact'
import { useCallback, useMemo, type CSSProperties } from 'react'
import { graphql, useRefetchableFragment } from 'react-relay'
import { z } from 'zod'

import { type HomeRequestReview_sectionRequestReview$key } from '@src/__relay__/HomeRequestReview_sectionRequestReview.graphql'
import { usePageParams } from '@src/hooks/usePageParams'
import { useViewer } from '@src/hooks/useViewer'
import { businessReviewAnalyticsManager, businessReviewImpressionManager, businessReviewRoutingManager } from '@src/sdk'
import { type SectionInfo } from '@src/types'

import { ReviewRequestStarRating } from './ReviewRequestStarRating'
import { normalizeBizReviewTarget, type NormalizedBizReviewTarget } from './utils/normalizeBizReviewTarget'
import HomeSectionBase from '../Common/HomeSectionBase'

/**
 * 내근처 컨텐츠 섹션에서 별점 클릭으로 후기 작성 화면 진입 시 사용해야 하는 referrer
 */
const NEARBY_CONTENTS_SECTION_REVIEW_REQUEST = 'nearby.contents_section.review_request'

interface HomeRequestReviewProps {
  sectionRequestReview: HomeRequestReview_sectionRequestReview$key
  sectionInfo: SectionInfo
}
const HomeRequestReview = (props: HomeRequestReviewProps) => {
  const { name: activityName } = useActivity()
  const params = useActivityParams()
  const { entry, referrer } = usePageParams({
    zodSchema: z.object({ referrer: z.string().optional(), entry: z.string().optional() }),
    params,
    pageName: activityName,
  })
  const screen = `nearby.${activityName}`.toLowerCase()

  const { user } = useViewer()

  const [section, refetch] = useRefetchableFragment(
    graphql`
      fragment HomeRequestReview_sectionRequestReview on SectionRequestReview
      @refetchable(queryName: "HomeRequestReviewRefetchQuery") {
        __typename
        bizReviewTargets {
          __typename
          bizStore {
            __typename
            ... on BizAccount {
              externalId
              name
              image {
                url
              }
              regionBizAccount: region {
                name3
              }
              categoryId
              categoryName
              targetUri
            }
            ... on Poi {
              externalId
              name
              images {
                url
              }
              regionPoi: region {
                name3
              }
              category {
                externalId
                name
              }
              targetUri
            }
          }
          tier
        }
        ...HomeSectionBase_sectionBase
      }
    `,
    props.sectionRequestReview
  )

  const handleRefresh = useCallback(() => {
    refetch({}, { fetchPolicy: 'store-and-network' })
  }, [refetch])

  const handleDocumentVisibilityChange = useCallback(
    (isVisible: boolean) => {
      if (isVisible) {
        handleRefresh()
      }
    },
    [handleRefresh]
  )

  const handleImpression = useCallback(
    ({ bizReviewTargets }: { bizReviewTargets: NormalizedBizReviewTarget[] }) => {
      const target = bizReviewTargets?.[0]
      const userId = user?.id

      if (!userId) {
        return
      }

      businessReviewAnalyticsManager.logEnrichedEvent({
        type: 'show',
        name: 'SHOW_REVIEW_NUDGE',
        params: {
          user_id: user.id,
          resources: [
            {
              resource_id: target.id,
              resource_type: target.__typename === 'BizAccount' ? 'BUSINESS_ACCOUNT' : 'POI',
              tier: target.tier,
            },
          ],
          referrer: referrer ?? null,
          entry: entry ?? null,
          screen: screen,
        },
      })

      if (target.__typename === 'BizAccount') {
        businessReviewImpressionManager.collectNudgedLocalProfileImpression({
          type: 'BUSINESS_ACCOUNT',
          business_account_id: Number(target.id),
          user_id: userId,
        })
      } else if (target.__typename === 'Poi') {
        businessReviewImpressionManager.collectNudgedLocalProfileImpression({
          type: 'POI',
          poi_id: Number(target.id),
          user_id: userId,
        })
      }
    },
    [entry, referrer, screen, user?.id]
  )

  const handleClickWriteReview = useCallback(
    async ({ bizReviewTarget, starRating }: { bizReviewTarget: NormalizedBizReviewTarget; starRating?: number }) => {
      if (user?.id) {
        await businessReviewAnalyticsManager.logEnrichedEvent({
          type: 'click',
          name: 'CLICK_WRITE_REVIEW',
          params: {
            user_id: user.id,
            resources: [
              {
                resource_id: bizReviewTarget.id,
                resource_type: bizReviewTarget.__typename === 'BizAccount' ? 'BUSINESS_ACCOUNT' : 'POI',
                tier: bizReviewTarget.tier,
              },
            ],
            referrer: referrer ?? null,
            entry: entry ?? null,
            screen: screen,
          },
        })
      }

      if (bizReviewTarget.__typename === 'BizAccount') {
        businessReviewRoutingManager.openCreateReviewPage({
          type: 'BUSINESS_ACCOUNT',
          business_account_id: bizReviewTarget.id,
          star_rating: typeof starRating === 'number' ? starRating : (undefined as any),
          referrer: NEARBY_CONTENTS_SECTION_REVIEW_REQUEST,
          screen_name: screen,
          local_profile_tier: bizReviewTarget.tier,
        })
      } else if (bizReviewTarget.__typename === 'Poi') {
        businessReviewRoutingManager.openCreateReviewPage({
          type: 'POI',
          poi_id: bizReviewTarget.id,
          star_rating: typeof starRating === 'number' ? starRating : (undefined as any),
          referrer: NEARBY_CONTENTS_SECTION_REVIEW_REQUEST,
          screen_name: screen,
          local_profile_tier: bizReviewTarget.tier,
        })
      }
    },
    [entry, referrer, screen, user?.id]
  )

  const handleClickProfile = useCallback(({ bizReviewTarget }: { bizReviewTarget: NormalizedBizReviewTarget }) => {
    return (window.location.href = bizReviewTarget.targetUri)
  }, [])

  const normalizedBizReviewTargets = useMemo(
    () => compact(section.bizReviewTargets.map(normalizeBizReviewTarget)),
    [section.bizReviewTargets]
  )
  const bizReviewTarget = normalizedBizReviewTargets[0]
  if (!bizReviewTarget?.id) {
    return null
  }
  const { name, image, region, category } = bizReviewTarget
  return (
    <ImpressionArea
      unique={['scroll']}
      threshold={1}
      onImpression={() => handleImpression({ bizReviewTargets: normalizedBizReviewTargets })}
      onDocumentVisibilityChange={handleDocumentVisibilityChange}>
      <HomeSectionBase
        sectionBase={section}
        ImpressionProps={{}}
        SectionTitleProps={{
          title: '혹시 여기 이용해보셨나요?',
          styleOptions: { justifyContent: 'center', padding: '1.5rem 1rem 0' },
        }}>
        <Wrapper padding="1rem 1rem 1.5rem">
          <ReviewRequestStarRating
            profile={{ name, image: image.url ?? '', onClick: () => handleClickProfile({ bizReviewTarget }) }}
            category={{ name: category?.name ?? '' }}
            region={{ name: region?.name ?? '' }}
            starRating={{ onClick: (starRating) => handleClickWriteReview({ bizReviewTarget, starRating }) }}
          />
        </Wrapper>
      </HomeSectionBase>
    </ImpressionArea>
  )
}

export default HomeRequestReview

const Wrapper = styled('div')<{
  padding?: CSSProperties['padding']
  gap?: CSSProperties['gap']
}>(({ padding, gap }) => [
  {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  {
    padding,
    gap,
  },
])
