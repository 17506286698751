import plantaeAuthPlugin from '@daangn/plantae-plugin-auth'
import retryPlugin from '@plantae/plugin-retry'
import { type AxiosInstance } from 'axios'
import { createAxiosInterceptors, type Plugin } from 'plantae'

import { getUser } from '@src/bridgeInfo'
import { karrotBridge } from '@src/sdk'

export const applyPlantae = ({
  axiosInstance,
  useAuthPlugin,
  retryOptions,
}: {
  axiosInstance: AxiosInstance
  useAuthPlugin?: boolean
  retryOptions?: { retryCount: number; retryDelay: number }
}) => {
  const plugins: Array<Plugin> = []

  if (useAuthPlugin) {
    const authPlugin = plantaeAuthPlugin({
      bridge: karrotBridge,
      options: {
        fallbackAuthToken: false,
      },
    })

    plugins.push(authPlugin)

    if (!authPlugin.context?.isAccessTokenSupported) {
      axiosInstance.interceptors.request.use(async (config) => {
        const user = await getUser()

        Object.assign(config.headers || {}, {
          'X-AUTH-TOKEN': user?.authToken,
        })

        return config
      })
    }
  }

  if (retryOptions) {
    plugins.push(
      retryPlugin({
        limit: retryOptions.retryCount,
        maxRetryAfter: retryOptions.retryDelay,
      })
    )
  }

  const { request, response } = createAxiosInterceptors({
    client: axiosInstance,
    plugins,
  })

  axiosInstance.interceptors.request.use(request)
  axiosInstance.interceptors.response.use(response)
}
