import { type StackflowReactPlugin } from '@stackflow/react'

import GaPageViewWrapper from './GaPageViewWrapper'

export function analyticsPlugin(): StackflowReactPlugin {
  return () => ({
    key: 'analytics',
    wrapActivity({ activity }) {
      return (
        <GaPageViewWrapper name={activity.name} params={activity.params}>
          {activity.render()}
        </GaPageViewWrapper>
      )
    },
  })
}
