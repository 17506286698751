/**
 * @generated SignedSource<<87bf2cb217a0314cb0c86870c56ecedb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HomeActionCentricUIsQuery$variables = {
  regionId: number;
};
export type HomeActionCentricUIsQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"HomeNavbar_query" | "HomeWriteFloatingActionButton_query">;
};
export type HomeActionCentricUIsQuery = {
  response: HomeActionCentricUIsQuery$data;
  variables: HomeActionCentricUIsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "regionId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "regionId",
    "variableName": "regionId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "externalId",
  "storageKey": null
},
v4 = [
  (v2/*: any*/),
  (v3/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name3",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name4",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "HomeActionCentricUIsQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "HomeNavbar_query"
      },
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "HomeWriteFloatingActionButton_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "HomeActionCentricUIsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "KarrotUser",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Region",
            "kind": "LinkedField",
            "name": "region",
            "plural": false,
            "selections": (v4/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Region",
            "kind": "LinkedField",
            "name": "regions",
            "plural": true,
            "selections": (v4/*: any*/),
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "BizAccount",
            "kind": "LinkedField",
            "name": "owningBizAccounts",
            "plural": true,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Region",
        "kind": "LinkedField",
        "name": "regionByRegionId",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "WriteLaunchpadIcon",
            "kind": "LinkedField",
            "name": "writeLaunches",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              },
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "externalLaunchServiceId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isNew",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "app",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "targetUri",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "writeRequireBizAccount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "path",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "iconUrl",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "nearbyIconUrl",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3e4d5cdd697e453189c4bb97fcbb4eb2",
    "id": null,
    "metadata": {},
    "name": "HomeActionCentricUIsQuery",
    "operationKind": "query",
    "text": "query HomeActionCentricUIsQuery(\n  $regionId: Int!\n) {\n  ...HomeNavbar_query\n  ...HomeWriteFloatingActionButton_query_bQZbN\n}\n\nfragment HomeNavbarRegionModal_viewer on KarrotUser {\n  region {\n    id\n    externalId\n    name3\n    name4\n  }\n  regions {\n    id\n    externalId\n    name3\n    name4\n  }\n}\n\nfragment HomeNavbar_query on Query {\n  viewer {\n    region {\n      id\n    }\n    ...HomeNavbarRegionModal_viewer\n    id\n  }\n}\n\nfragment HomeWriteFloatingActionButton_query_bQZbN on Query {\n  ...WriteLaunches_query_bQZbN\n  viewer {\n    owningBizAccounts {\n      id\n    }\n    id\n  }\n}\n\nfragment WriteLaunchIcon_icon on WriteLaunchpadIcon {\n  iconUrl\n  nearbyIconUrl\n}\n\nfragment WriteLaunch_launch on WriteLaunchpadIcon {\n  title\n  externalId\n  externalLaunchServiceId\n  isNew\n  name\n  app\n  targetUri\n  writeRequireBizAccount\n  path\n  ...WriteLaunchIcon_icon\n}\n\nfragment WriteLaunches_query_bQZbN on Query {\n  regionByRegionId(regionId: $regionId) {\n    writeLaunches {\n      id\n      ...WriteLaunch_launch\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "23bf004e1a12139c1d3e4064be325808";

export default node;
