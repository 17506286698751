import { useRegisterAppCommonPayload } from '@daangn/local-business-analytics'
import { useActivity, useActivityParams } from '@stackflow/react'
import { useEffect, useMemo } from 'react'
import { graphql, readInlineData, useFragment } from 'react-relay'
import { useSetRecoilState } from 'recoil'
import { z } from 'zod'

import { type usePageInit_pageInit$key } from '@src/__relay__/usePageInit_pageInit.graphql'
import { experimentsAtom } from '@src/features/_global/state/experiments'
import { owningBizAccountsAtom } from '@src/features/bizAccount/state/owningBizAccount'
import { isDebugUserAtom } from '@src/features/user/state/user'
import { type Experiments } from '@src/lib/experiment/ExperimentType'
import { progressExperiments, type FrontDrivenExperimentScheme } from '@src/lib/experiment/FrontDrivenExperiments'
import { getExperimentTaget } from '@src/lib/experiment/storage'
import { useDevtools } from '@src/stackflow/customPlugins/nearbyDevtoolsPlugin'

import { useStreamListen } from './useBridgeStreamListener'
import { usePageParams } from './usePageParams'
import { useViewer } from './useViewer'

const PageActivityAccessParamSchema = z.object({
  referrer: z.string().optional(),
  referrer_context: z.string().optional(),
  entry: z.string().optional(),
})

interface usePageInitProps {
  queryRef: usePageInit_pageInit$key
  where?: string
  fetchKey?: number
}

export function usePageInit({ queryRef, fetchKey = 0, where }: usePageInitProps) {
  const { user } = useViewer()
  const handleRegistCommonPayload = useRegisterAppCommonPayload()
  const { name: activityName } = useActivity()
  const params = useActivityParams()
  const { referrer, entry, referrer_context } = usePageParams<typeof PageActivityAccessParamSchema>({
    zodSchema: PageActivityAccessParamSchema,
    params,
    pageName: activityName,
  })

  const setExperiments = useSetRecoilState(experimentsAtom)
  const setOwningBizAccounts = useSetRecoilState(owningBizAccountsAtom)
  const setIsDebugUser = useSetRecoilState(isDebugUserAtom)

  const { showDevtools, showVConsole } = useDevtools()

  const query = readInlineData(
    graphql`
      fragment usePageInit_pageInit on Query @argumentDefinitions(regionId: { type: "Int!" }) @inline {
        viewer {
          id
          nickname
          isDebugUser
          progressExperiments(regionId: $regionId) {
            key
            name
            segments {
              name
              value
            }
            targetSegment
          }
          owningBizAccounts {
            externalId
            categoryId
            individual
          }
          isDeveloper
        }
      }
    `,
    queryRef
  )

  useEffect(() => {
    showDevtools(!!query.viewer?.isDebugUser)
    showVConsole(!!query.viewer?.isDeveloper)
  })

  useStreamListen()

  // 실험 정보 가져오기
  const experiments = useMemo(() => {
    const serverDrivenExperiments = query.viewer?.progressExperiments.reduce<Experiments>(
      (acc, ex) => ({
        ...acc,
        [ex.key]: {
          ...ex,
          targetSegment: getExperimentTaget(ex.key) ?? ex.targetSegment,
        },
      }),
      {}
    )

    const frontDrivenExperiments = Object.entries<FrontDrivenExperimentScheme>(progressExperiments).reduce<Experiments>(
      // @ts-ignore
      (acc, [key, value]: [FrontDrivenExperimentKeyEnum, FrontDrivenExperimentScheme]) => ({
        ...acc,
        [key]: {
          key,
          ...value,
          targetSegment:
            getExperimentTaget(key) ?? (user?.id ? value.getTargetSegment({ userId: user?.id }) : value.targetSegment),
        },
      }),
      {}
    )

    return {
      ...serverDrivenExperiments,
      ...frontDrivenExperiments,
    }
  }, [query.viewer?.progressExperiments, user?.id])

  const bizAccountsWithNoIndividual = useMemo(
    () => query?.viewer?.owningBizAccounts.filter((bizAccount) => !bizAccount.individual) ?? [],
    [query?.viewer?.owningBizAccounts]
  )

  useEffect(() => {
    // recoil setting
    setExperiments(experiments)
    setOwningBizAccounts(bizAccountsWithNoIndividual)
    setIsDebugUser(!!query.viewer?.isDebugUser)
  }, [
    bizAccountsWithNoIndividual,
    experiments,
    fetchKey,
    query.viewer?.isDebugUser,
    setExperiments,
    setIsDebugUser,
    setOwningBizAccounts,
  ])

  // 지사실 LogDataLayer에 세팅
  handleRegistCommonPayload('referrer', referrer ?? null)
  handleRegistCommonPayload('entry', entry ?? null)

  if (where) {
    handleRegistCommonPayload('where', where)
  }

  return {
    referrer: referrer ?? null,
    entry: entry ?? referrer ?? null,
    where: where ?? `nearby.${activityName}`,
    referrer_context: referrer_context ?? null,
  }
}
