import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'

import { IS_PRODUCTION } from '../../constants'
import { gaException } from '../analytics/gtag'

type ReactComponent = keyof JSX.IntrinsicElements | React.JSXElementConstructor<any>

export default function withErrorBoundary<TComponent extends ReactComponent>(
  Component: TComponent,
  FallbackComponent: ReactComponent
): React.FC<React.ComponentProps<TComponent>> {
  return (props) => {
    const handleError = (error: Error) => {
      if (!IS_PRODUCTION) {
        console.info(`%c ${error}`, 'background: red; color: white')
      }
      gaException({
        description: error.message,
        fatal: true,
      })
    }

    return (
      <ErrorBoundary onError={handleError} fallback={<FallbackComponent {...props} />}>
        <Component {...props} />
      </ErrorBoundary>
    )
  }
}
