import { lazy } from 'react'

import PageHome from '@src/components/_pages/PageHome'

import BottomSheet from '../components/_bottomSheets/BottomSheet'
import Modal from '../components/_modals/Modal'

// const Home = lazy(() => import('../components/_pages/PageHome'))
const Purpose = lazy(() => import('../components/_pages/PagePurpose'))
const CategoryHome = lazy(() => import('../components/_pages/PageCategoryHome'))
const PurposeCategoryAllView = lazy(() => import('../components/_pages/PagePurposeCategoryAllView'))
const Write = lazy(() => import('../components/_pages/write/PageWrite'))
const SpotlightOnboarding = lazy(() => import('../components/_pages/spotlight_onboarding/PageSpotlightOnboarding'))
const RecentlyView = lazy(() => import('../components/_pages/recently_view/PageRecentlyView'))
const BizAccountReview = lazy(() => import('../components/_pages/PageBizAccountReview'))
const RecommendedPosts = lazy(() => import('../components/_pages/PageRecommendedPosts'))
const CouponMapLanding = lazy(() => import('../components/_pages/PageCouponMapLanding/PageCouponMapLanding'))
const CouponMap = lazy(() => import('../components/_pages/PageCouponMap/PageCouponMap'))
const CouponDetail = lazy(() => import('../components/_pages/PageCouponDetail/PageCouponDetail'))
const HomeFeedShortcutOnboarding = lazy(
  () => import('@src/components/_bottomSheets/HomeFeedShortcutOnboardingBottomSheet')
)

const routes = [
  {
    name: '내근처 홈',
    code: 'Home',
    path: '/',
    activity: PageHome,
  },
  {
    name: '목적성 페이지',
    code: 'Purpose',
    path: '/purpose',
    activity: Purpose,
  },
  {
    name: '카테고리 홈',
    code: 'CategoryHome',
    path: '/category_home/:categoryId',
    activity: CategoryHome,
  },
  {
    name: '목적성 카테고리 전체보기',
    code: 'PurposeCategoryAllView',
    path: '/purpose_category_all_view',
    activity: PurposeCategoryAllView,
  },
  {
    name: '동네 글쓰기 카테고리',
    code: 'Write',
    path: '/write',
    activity: Write,
  },
  {
    name: '스팟라이트 온보딩',
    code: 'SpotlightOnboarding',
    path: '/spotlight_onboarding',
    activity: SpotlightOnboarding,
  },
  {
    name: '최근 본 소식',
    code: 'RecentlyView',
    path: '/recently_view',
    activity: RecentlyView,
  },
  {
    name: '후기 상세',
    code: 'BizAccountReview',
    path: '/bizaccount_review/:reviewId',
    activity: BizAccountReview,
  },
  {
    name: '단골 ⸱ 추천소식',
    code: 'RecommendedPosts',
    path: '/recommended_posts',
    activity: RecommendedPosts,
  },
  {
    name: '쿠폰지도 소개',
    code: 'CouponMapLanding',
    path: '/coupon_map_landing/:bizAccountId',
    activity: CouponMapLanding,
  },
  {
    name: '동네 쿠폰',
    code: 'CouponMap',
    path: '/coupon_map',
    activity: CouponMap,
  },
  {
    name: '쿠폰 상세',
    code: 'CouponDetail',
    path: '/coupons/:couponId',
    activity: CouponDetail,
  },
  {
    name: '바텀 시트',
    code: 'BottomSheet',
    path: '/bottomsheet',
    activity: BottomSheet,
  },
  {
    name: '모달',
    code: 'Modal',
    path: '/modal',
    activity: Modal,
  },
  {
    name: 'HomeFeed 숏컷 바텀시트',
    code: 'HomeFeedShortcutBottomSheet',
    path: '/home_feed_shortcut_bottom_sheet',
    activity: HomeFeedShortcutOnboarding,
  },
] as const

export default routes
