/**
 * @generated SignedSource<<b6ad6ef217d24fd9684533264b366e3e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HomeCoupons_sectionCoupon$data = {
  readonly couponBoxes: ReadonlyArray<{
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"HomeCoupon_couponBox">;
  }>;
  readonly couponBoxesTargetUri: string;
  readonly loggingMeta: {
    readonly click: string;
    readonly show: string;
    readonly swipe: string;
  };
  readonly writeBizTargetUri: string;
  readonly " $fragmentSpreads": FragmentRefs<"HomeSectionBase_sectionBase">;
  readonly " $fragmentType": "HomeCoupons_sectionCoupon";
};
export type HomeCoupons_sectionCoupon$key = {
  readonly " $data"?: HomeCoupons_sectionCoupon$data;
  readonly " $fragmentSpreads": FragmentRefs<"HomeCoupons_sectionCoupon">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HomeCoupons_sectionCoupon",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "couponBoxesTargetUri",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CouponBox",
      "kind": "LinkedField",
      "name": "couponBoxes",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "HomeCoupon_couponBox"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EventMeta",
      "kind": "LinkedField",
      "name": "loggingMeta",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "swipe",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "click",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "show",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "writeBizTargetUri",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HomeSectionBase_sectionBase"
    }
  ],
  "type": "SectionCoupon",
  "abstractKey": null
};

(node as any).hash = "babd851c75a36d6dcf68d8c8741ce063";

export default node;
