import { shim as shimGlobalThis } from 'globalthis'
import { polyfill as shimSmoothScroll } from 'smoothscroll-polyfill'

if (typeof globalThis === 'undefined') {
  shimGlobalThis()
}

shimSmoothScroll()

export {}
