import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { forwardRef, type ReactNode, useImperativeHandle, useRef } from 'react'

import MapsCategoryIcons from '@src/components/Home/Sections/HomeMap/MapsCategoryIcons'
import { type PoiTabCategoryEnum } from '@src/types/schemaEnums'

import { useImpression } from '../../../_lib/Impression'
import { FlexCenter } from '../../../_lib/styles'

interface TabButtonProps {
  id: string
  activeTabKey: string
  tabKey: string
  tabLabel: string | ReactNode
  itemOrder?: number
  showIcon?: boolean
  padding?: string
  onImpressionExceptOnload?: (itemOrder: number) => void
}

const TabButton = forwardRef<HTMLDivElement, TabButtonProps>(
  (
    { id, activeTabKey, tabKey, tabLabel, itemOrder, showIcon = true, padding = '8px 0', onImpressionExceptOnload },
    ref
  ) => {
    const internalRef = useRef<HTMLDivElement>(null)
    useImperativeHandle(ref, () => internalRef.current as HTMLDivElement, [])

    useImpression(
      {
        ref: internalRef,
        disableImpressionOnload: true,
      },
      () => {
        itemOrder && onImpressionExceptOnload?.(itemOrder)
      },
      [itemOrder]
    )

    return (
      <TabButtonItem ref={internalRef} id={id} isActive={activeTabKey === tabKey} padding={padding}>
        {showIcon && <MapsCategoryIcons category={tabKey as PoiTabCategoryEnum} />}
        <TabLabel>{tabLabel}</TabLabel>
      </TabButtonItem>
    )
  }
)

export default TabButton

const TabButtonItem = styled.div<{ isActive: boolean; padding: string }>`
  ${FlexCenter};
  padding: ${({ padding }) => (padding ? padding : '0')};
  color: ${vars.$scale.color.gray600};

  ${({ isActive }) => isActive && `color: ${vars.$scale.color.gray900}; font-weight: bold;`}
`

const TabLabel = styled.span`
  margin-left: 2px;
  ${vars.$semantic.typography.label3Bold};
`
