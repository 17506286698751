/* eslint-disable import/no-duplicates */
import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { type FC, useRef } from 'react'
import { graphql, useFragment } from 'react-relay'
import { match } from 'ts-pattern'

import { getDateDiffFromNow } from '@src/lib/date/date'

import {
  type NearbyBizPostItem_bizPost$data,
  type NearbyBizPostItem_bizPost$key,
} from '../../../../__relay__/NearbyBizPostItem_bizPost.graphql'
import { useLogger } from '../../../../hooks/useLogger'
import { distanceToUnion } from '../../../../lib/union'
import { getEllipsisString } from '../../../../lib/unit'
import BizPostItem from '../../../_lib/BizPosts/BizPostItem'
import CoveredLazyLoadImage from '../../../_lib/CoveredLazyLoadImage'
import { useImpression } from '../../../_lib/Impression'
import { DotSeparator, Flex, getEllipsisStyle } from '../../../_lib/styles'
import { Badges } from '../../lib/Badge'

interface NearbyBizPostItemProps {
  bizPost: NearbyBizPostItem_bizPost$key
  onClick: (bizPost: NearbyBizPostItem_bizPost$data) => void
}

const NearbyBizPostItem: FC<NearbyBizPostItemProps> = (props) => {
  const bizPost = useFragment(
    graphql`
      fragment NearbyBizPostItem_bizPost on BizPost {
        title
        bookmarkCount
        commentCount
        image {
          thumbnail
        }
        bizAccount {
          name
        }
        distance {
          distance
          name
        }
        createdAt
        targetUri
        badges
        ...BizPostItem_common
        ...Badges_badges
      }
    `,
    props.bizPost
  )

  const itemRef = useRef<HTMLDivElement>(null)
  const { nearbyLog } = useLogger()

  const handlebizPostClick = () => {
    props.onClick?.(bizPost)
  }

  useImpression(
    {
      ref: itemRef,
    },
    () => {
      nearbyLog({
        params: {
          name: 'show_contents_section_item',
          contentsType: 'nearby_post',
          ...(bizPost.badges.length > 0 ? { badges: bizPost.badges.join(',') } : null),
        },
      })
    },
    []
  )

  return (
    <S_BizPostItem itemRef={itemRef} bizPost={bizPost} onClick={handlebizPostClick}>
      {bizPost.image?.thumbnail && (
        <Left>
          <S_CoveredLazyLoadImage effect="opacity" src={bizPost.image.thumbnail} visibleByDefault />
        </Left>
      )}
      <Right>
        <BizPost>
          <BizPostInfos>
            {bizPost.bizAccount?.name && (
              <BizAccountName>{getEllipsisString(bizPost.bizAccount.name, 10)}</BizAccountName>
            )}
            {bizPost.distance &&
              match(distanceToUnion(bizPost.distance))
                .with({ _TAG: 'name' }, ({ name }) => <EmployerInfo>{name}</EmployerInfo>)
                .with({ _TAG: 'm' }, ({ m }) => <EmployerInfo>{`${m}m`}</EmployerInfo>)
                .with({ _TAG: 'km' }, ({ km }) => <EmployerInfo>{`${km}km`}</EmployerInfo>)
                .otherwise(() => null)}
          </BizPostInfos>
          <Title>{bizPost.title}</Title>
          <Informations>
            <Information>{getDateDiffFromNow(new Date(bizPost.createdAt))}전</Information>
            {!!bizPost.bookmarkCount && <Information>{`관심 ${bizPost.bookmarkCount}`}</Information>}
            {!!bizPost.commentCount && <Information>{`댓글 ${bizPost.commentCount}`}</Information>}
          </Informations>
        </BizPost>
        <Badges badgesRef={bizPost} />
      </Right>
    </S_BizPostItem>
  )
}

export default NearbyBizPostItem

const S_BizPostItem = styled(BizPostItem)`
  color: ${vars.$scale.color.gray900};
  height: 100%;

  & > *:not(:last-child) {
    margin-right: 1rem;
  }
`

const Right = styled.div`
  flex: 1;
  width: calc(100% - 6.5rem);

  & > *:not(:last-child) {
    margin-bottom: 0.5rem;
  }
`

const Left = styled.div``

const S_CoveredLazyLoadImage = styled(CoveredLazyLoadImage)`
  height: 4.5rem;
  width: 4.5rem;
  overflow: hidden;
`

const BizPost = styled.div`
  & > *:not(:last-child) {
    margin-bottom: 0.25rem;
  }
`

const BizPostInfos = styled.div`
  ${Flex};
  width: 100%;
  color: ${vars.$scale.color.gray600};
`

const EmployerInfo = styled.div`
  ${getEllipsisStyle(1)};
  ${vars.$semantic.typography.caption1Regular};
  ${DotSeparator};
`

const BizAccountName = styled(EmployerInfo)`
  ${vars.$semantic.typography.caption1Bold};
`

const Title = styled.h3`
  margin: 0;
  ${vars.$semantic.typography.subtitle1Regular};
  ${getEllipsisStyle(1)};
`

const Informations = styled.div`
  ${Flex};
  ${vars.$semantic.typography.caption1Regular};
  color: ${vars.$scale.color.gray600};
`

const Information = styled.span`
  ${DotSeparator};
`
