import { init, type BrowserOptions, setTag, setUser } from '@sentry/react'

export const initSentry = (options: BrowserOptions) => {
  init(options)
}

export const setSentryUserConfig = ({
  userId,
  deviceIdentity,
  region,
}: {
  userId: string
  deviceIdentity?: string
  region: string
}) => {
  setUser({ id: userId })
  setTag('region', region)
  setTag('deviceIdentity', deviceIdentity ?? 'unknown')
}
