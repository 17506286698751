import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import React from 'react'

import Spinner from './Spinner'

interface LoadingFallbackProps {
  className?: string
}

const LoadingFallback: React.FC<LoadingFallbackProps> = ({ className }) => {
  return (
    <Container className={className}>
      <Centered>
        <Spinner size={36} />
      </Centered>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: ${vars.$scale.color.gray00};
  z-index: 1;
`

const Centered = styled.div`
  text-align: center;
`

export default LoadingFallback
