import { atom } from 'recoil'

import { type BridgeUserInfo } from '@src/bridgeInfo'

import { generateUserStateKey } from './key'

/**
 * 현재 유저 정보
 */
export const userAtom = atom<BridgeUserInfo | null>({
  key: generateUserStateKey('user'),
  default: null as any,
})

export const isDebugUserAtom = atom({
  key: generateUserStateKey('isDebugUser'),
  default: false,
})
