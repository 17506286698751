import '@seed-design/stylesheet/global.css'

import { document } from 'globalthis/implementation'

export function checkDarkTheme() {
  const el = document.documentElement

  return el.getAttribute('data-seed-scale-color') === 'dark'
}

// Seed Design의 컬러 토큰은 <html /> 태그의 data-seed, data-seed-scale-color 속성을 통해 라이트/다크모드 전환을 해요.
// 따라서, prefers-color-scheme 속성을 listen하면서, 해당 필드를 교체하는 JavaScript Snippet이에요.
// 또한 OS별 타이포그래피 지원을 향상시키기 위해 `data-seed-scale-letter-spacing` 어트리뷰트를 root 요소에 지정할 수 있어요.
// 지정하지 않아도 letter-spacing을 제외한 속성들은 정상적으로 적용되지만, 디자인 의도를 최대한 반영하기 위해 적용하는 것을 권장해요.
;(function () {
  const el = document.documentElement
  el.dataset.seed = ''

  const prefersLight = window.matchMedia('(prefers-color-scheme: light)')
  const prefersDark = window.matchMedia('(prefers-color-scheme: dark)')

  if (prefersLight.matches) {
    if ('addEventListener' in prefersLight) {
      prefersLight.addEventListener('change', apply)
    } else if ('addListener' in prefersLight) {
      // @ts-ignore
      prefersLight.addListener(apply)
    }
  } else if (prefersDark.matches) {
    if ('addEventListener' in prefersDark) {
      prefersDark.addEventListener('change', apply)
    } else if ('addListener' in prefersDark) {
      // @ts-ignore
      prefersDark.addListener(apply)
    }
  }

  function apply() {
    const c = isIOS()
    el.dataset.seedScaleLetterSpacing = c ? 'ios' : 'android'
    el.dataset.seedScaleColor = prefersDark.matches ? 'dark' : 'light'
    // Stackflow의 Basic UI는 <html /> 태그의 data-stackflow-basic-ui-theme 속성을 통해
    // 초기 렌더링에서 Cupertino/Android 테마를 정적으로 셋팅해요.
    el.dataset.stackflowBasicUiTheme = c ? 'cupertino' : 'android'
  }

  apply()
})()

// navigator.userAgent를 통해, 초기 테마를 가져와요.
function isIOS() {
  return /iphone|ipad|ipod/i.test(window.navigator.userAgent.toLowerCase())
}
