import { type FCWithChildren } from 'react'

import SectionBottomButton from './SectionBottomButton'
import SectionContainer from './SectionContainer'
import SectionTitle from './SectionTitle/SectionTitle'
import { type PropsOfNoneChildren } from '../../../lib/T/utilTypes'
import Impression from '../../_lib/Impression'

interface SectionProps {
  ImpressionProps: PropsOfNoneChildren<typeof Impression>
  SectionTitleProps: PropsOfNoneChildren<typeof SectionTitle>
  SectionBottomButtonProps?: PropsOfNoneChildren<typeof SectionBottomButton> | null
}
const Section: FCWithChildren<SectionProps> = ({
  children,
  ImpressionProps,
  SectionTitleProps,
  SectionBottomButtonProps,
}) => {
  return (
    <Impression {...ImpressionProps}>
      <SectionContainer>
        <SectionTitle {...SectionTitleProps} />
        {children}
        {SectionBottomButtonProps && <SectionBottomButton {...SectionBottomButtonProps} />}
      </SectionContainer>
    </Impression>
  )
}

export default Section
