import { z } from 'zod'

import { useBridgeRouter } from './useBridgeRouter'
import { useBridgeStreamListener } from './useBridgeStreamListener'
import { FAB_WRITE_CLOSE_EVENT, FAB_WRITE_CLOSE_EVENT_WRITEPAGE } from '../constants'
import { delay } from '../lib/delay'
import { useFlow } from '../stackflow/stackflow'

const SchemaCloseStreamData = z.object({
  redirect: z.union([
    z.object({
      remote: z.string(),
      navbar: z.boolean().optional(),
      scrollable: z.boolean().optional(),
    }),
    z.object({
      app: z.string(),
      path: z.string(),
      navbar: z.boolean().optional(),
      scrollable: z.boolean().optional(),
    }),
  ]),
})

export type useNearbyCategoryWriteProp = {
  type: 'WRITE_PAGE' | 'FAB'
  onWirteAfter?: () => void
}

/**
 * 동네 글쓰기 카테고리 혹은 내근처 홈 FAB에서 글 작성 후 페이지 이동시키는 hook
 */
export function useNearbyCategoryWrite({ type, onWirteAfter }: useNearbyCategoryWriteProp) {
  const router = useBridgeRouter()
  const { pop } = useFlow()

  useBridgeStreamListener(type === 'FAB' ? FAB_WRITE_CLOSE_EVENT : FAB_WRITE_CLOSE_EVENT_WRITEPAGE, async (data) => {
    const { redirect } = SchemaCloseStreamData.parse(data)

    pop()
    await delay(100)

    onWirteAfter?.()

    return router.replace({
      router: redirect,
    })
  })
}
