/**
 * @generated SignedSource<<96cd6b4c90694fa741861758e147afaf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type VerticalContentsCloseOnboardingSectionQuery$variables = {};
export type VerticalContentsCloseOnboardingSectionQuery$data = {
  readonly viewer: {
    readonly verticalSectionRemoveOnboardingServices: ReadonlyArray<string>;
  } | null;
};
export type VerticalContentsCloseOnboardingSectionQuery = {
  response: VerticalContentsCloseOnboardingSectionQuery$data;
  variables: VerticalContentsCloseOnboardingSectionQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "verticalSectionRemoveOnboardingServices",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "VerticalContentsCloseOnboardingSectionQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "KarrotUser",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "VerticalContentsCloseOnboardingSectionQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "KarrotUser",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "fca067b1edd0a7f6407823cc6004936a",
    "id": null,
    "metadata": {},
    "name": "VerticalContentsCloseOnboardingSectionQuery",
    "operationKind": "query",
    "text": "query VerticalContentsCloseOnboardingSectionQuery {\n  viewer {\n    verticalSectionRemoveOnboardingServices\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "84de04ec7527b639aafbeab399062c2f";

export default node;
