/**
 * @generated SignedSource<<8a12ab72eab51e7d40a5c121e0cf98a9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HomeNearbyExerciseBizAccount_sectionNearbyExerciseBizAccount$data = {
  readonly __typename: "SectionNearbyExerciseBizAccount";
  readonly baseInfo: {
    readonly viewMoreInfo: {
      readonly label: string;
      readonly targetUri: string;
    } | null;
  } | null;
  readonly bizAccounts: ReadonlyArray<{
    readonly categoryName: string | null;
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"BizAccountListItem_BizAccount">;
  }>;
  readonly id: string;
  readonly loggingMeta: {
    readonly click: string;
    readonly show: string;
    readonly swipe: string;
  };
  readonly " $fragmentSpreads": FragmentRefs<"HomeSectionBase_sectionBase">;
  readonly " $fragmentType": "HomeNearbyExerciseBizAccount_sectionNearbyExerciseBizAccount";
};
export type HomeNearbyExerciseBizAccount_sectionNearbyExerciseBizAccount$key = {
  readonly " $data"?: HomeNearbyExerciseBizAccount_sectionNearbyExerciseBizAccount$data;
  readonly " $fragmentSpreads": FragmentRefs<"HomeNearbyExerciseBizAccount_sectionNearbyExerciseBizAccount">;
};

import HomeNearbyExerciseBizAccountRefetchQuery_graphql from './HomeNearbyExerciseBizAccountRefetchQuery.graphql';

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": HomeNearbyExerciseBizAccountRefetchQuery_graphql,
      "identifierField": "id"
    }
  },
  "name": "HomeNearbyExerciseBizAccount_sectionNearbyExerciseBizAccount",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EventMeta",
      "kind": "LinkedField",
      "name": "loggingMeta",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "swipe",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "click",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "show",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BizAccount",
      "kind": "LinkedField",
      "name": "bizAccounts",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "categoryName",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "BizAccountListItem_BizAccount"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BaseInfo",
      "kind": "LinkedField",
      "name": "baseInfo",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ViewMoreInfo",
          "kind": "LinkedField",
          "name": "viewMoreInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "targetUri",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "label",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HomeSectionBase_sectionBase"
    },
    (v0/*: any*/)
  ],
  "type": "SectionNearbyExerciseBizAccount",
  "abstractKey": null
};
})();

(node as any).hash = "b357dddea2882e70c2313ea6299bf128";

export default node;
