import { useCallback, useRef } from 'react'

import { usePageScrollTopChecker } from '../../components/Home/PageScrollTopChecker'
import { useTouchGesture } from '../useTouchGesture'

interface useSwipeSectionProps {
  swipeContainerRef: React.RefObject<HTMLElement>
  sectionOrder?: number
}

export function useSwipeSection({ swipeContainerRef }: useSwipeSectionProps) {
  const { pageScrollTopState } = usePageScrollTopChecker()
  const propgationFlagRef = useRef(false)

  const handleOnGestureSwipe: Required<Parameters<typeof useTouchGesture>[0]>['onSwipe'] = useCallback(
    ({ moveType }, event) => {
      if (moveType === 'Horizontal') {
        propgationFlagRef.current = true
      }

      if (propgationFlagRef.current) {
        event.stopPropagation()
      }
    },
    []
  )

  const handleOnGestureEnd: Required<Parameters<typeof useTouchGesture>[0]>['onGestureEnd'] = useCallback(() => {
    propgationFlagRef.current = false
  }, [])

  const { isTouchMove } = useTouchGesture({
    targetElementRef: swipeContainerRef,
    onSwipe: handleOnGestureSwipe,
    onGestureEnd: handleOnGestureEnd,
    disable: !pageScrollTopState,
  })

  return {
    isSwiping: isTouchMove,
  }
}
