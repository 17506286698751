import { atom } from 'recoil'

import recoilKeyGenerator from '@src/lib/recoil-util'

import { type SpotlightTargetInfo } from '../hooks/useSpotlight'

const spotlightKey = recoilKeyGenerator('spotlight')

export const spotlightTargetInfoAtom = atom<SpotlightTargetInfo>({
  key: spotlightKey('spotlightTargetInfo'),
  default: {
    targetId: null,
    tooltipTargetId: null,
    referrer: null,
  },
})
