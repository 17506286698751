import { useEffect } from 'react'
import { useRecoilState } from 'recoil'

import { useViewer } from '@src/hooks/useViewer'
import { useFlow } from '@src/stackflow/stackflow'

import { isViewHomeFeedShortcutOnboardingAtom } from '../state/isViewHomeFeedShortcutOnboarding'

export const useHomeFeedShortcutOnboarding = ({
  isVerticalServiceOnboardingUser,
}: {
  isVerticalServiceOnboardingUser?: boolean
}) => {
  const [isViewHomeFeedShortcutOnboarding, setIsViewHomeFeedShortcutOnboarding] = useRecoilState(
    isViewHomeFeedShortcutOnboardingAtom
  )

  const { push } = useFlow()
  const viewer = useViewer()

  if (viewer?.user?.id === 39982) {
    localStorage.clear()
  }

  useEffect(() => {
    if (isVerticalServiceOnboardingUser && !isViewHomeFeedShortcutOnboarding) {
      push('HomeFeedShortcutBottomSheet', {})

      setIsViewHomeFeedShortcutOnboarding(true)
    }
  }, [
    isVerticalServiceOnboardingUser,
    isViewHomeFeedShortcutOnboarding,
    push,
    setIsViewHomeFeedShortcutOnboarding,
    viewer?.user?.id,
  ])
}
