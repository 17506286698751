/**
 * @generated SignedSource<<cc5020738c65cdd083cdf22c5c3d098e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HomeMapsTabListItem_poiTag$data = {
  readonly poi: {
    readonly " $fragmentSpreads": FragmentRefs<"BannerPoi_poi">;
  };
  readonly taggedBy: {
    readonly __typename: "StoryArticle";
    readonly author: {
      readonly checkinCount: number;
      readonly image: {
        readonly thumbnail: string;
      };
      readonly nickname: string;
      readonly region: {
        readonly name3: string;
      } | null;
    };
    readonly content: string;
    readonly externalId: string;
    readonly firstImage: {
      readonly thumbnail: string;
    } | null;
    readonly images: ReadonlyArray<{
      readonly id: string;
    }>;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
  readonly targetUri: string;
  readonly " $fragmentType": "HomeMapsTabListItem_poiTag";
};
export type HomeMapsTabListItem_poiTag$key = {
  readonly " $data"?: HomeMapsTabListItem_poiTag$data;
  readonly " $fragmentSpreads": FragmentRefs<"HomeMapsTabListItem_poiTag">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "thumbnail",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HomeMapsTabListItem_poiTag",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Poi",
      "kind": "LinkedField",
      "name": "poi",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "BannerPoi_poi"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "taggedBy",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "__typename",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "externalId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "content",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "HoianUser",
              "kind": "LinkedField",
              "name": "author",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "nickname",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Image",
                  "kind": "LinkedField",
                  "name": "image",
                  "plural": false,
                  "selections": (v0/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Region",
                  "kind": "LinkedField",
                  "name": "region",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "name3",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "checkinCount",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Image",
              "kind": "LinkedField",
              "name": "firstImage",
              "plural": false,
              "selections": (v0/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Image",
              "kind": "LinkedField",
              "name": "images",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "StoryArticle",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "targetUri",
      "storageKey": null
    }
  ],
  "type": "PoiTag",
  "abstractKey": null
};
})();

(node as any).hash = "46364c71a7d93d70bc349388822205e5";

export default node;
