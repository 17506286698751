/**
 * @generated SignedSource<<8725c4b74d48e2b56946d66ed5c3c124>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ShortFormPost_shortForm$data = {
  readonly description: string;
  readonly duration: number;
  readonly externalId: number;
  readonly id: string;
  readonly impressionCallbackUrl: string | null;
  readonly itemDescription: string;
  readonly itemTitle: string;
  readonly targetUri: string;
  readonly thumbnailImageUrl: string;
  readonly " $fragmentType": "ShortFormPost_shortForm";
};
export type ShortFormPost_shortForm$key = {
  readonly " $data"?: ShortFormPost_shortForm$data;
  readonly " $fragmentSpreads": FragmentRefs<"ShortFormPost_shortForm">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ShortFormPost_shortForm",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "externalId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "duration",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "impressionCallbackUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "itemDescription",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "itemTitle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "targetUri",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "thumbnailImageUrl",
      "storageKey": null
    }
  ],
  "type": "ShortForm",
  "abstractKey": null
};

(node as any).hash = "07756e1898fc174dc2ddd6a1da55cda0";

export default node;
