import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { useState } from 'react'

import { Avatar } from '@src/components/_lib/Avatar'
import { StarRating } from '@src/components/_lib/StarRating/StarRating'
import TagGroup from '@src/components/_lib/TagGroup'
import { BIZ_PROFILE_THUMBNAIL_PLACEHOLDER } from '@src/constants/bizProfile'
import { usePageVisibleChange } from '@src/hooks/usePageVisibleChange'

interface ReviewRequestStarRatingProps {
  profile: {
    name: string
    image?: string
    onClick: () => void
  }
  category?: {
    name: string
  }
  region?: {
    name: string
  }
  starRating: {
    onClick: (starRating: number) => void
  }
}
export const ReviewRequestStarRating = ({ profile, category, region, starRating }: ReviewRequestStarRatingProps) => {
  const [rating, setRating] = useState<number | undefined>(undefined)

  usePageVisibleChange((isVisible) => {
    if (isVisible) {
      setRating(undefined)
    } else {
      setRating(0)
    }
  })

  return (
    <Base>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}>
        <Wrapper onClick={profile.onClick}>
          <Avatar
            type="Biz"
            size="medium"
            src={profile.image || BIZ_PROFILE_THUMBNAIL_PLACEHOLDER}
            alt={`${profile.name} 프로필 이미지`}
            flexShrink={0}
          />
          <Info>
            <Name>{profile.name}</Name>
            <S_TagGroup size="medium" tags={[{ text: region?.name }, { text: category?.name }]} />
          </Info>
        </Wrapper>
      </div>
      <StarRating size="large" onClick={(_e, { value }) => starRating.onClick(value)} rating={rating} />
    </Base>
  )
}

const Base = styled('div')([
  {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    alignItems: 'center',
    overflow: 'hidden',
  },
])

const Wrapper = styled('div')([
  {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    gap: '0.5rem',
    alignItems: 'center',
  },
])
const Info = styled('div')([
  {
    maxWidth: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
])
const Name = styled('div')([
  {
    width: '100%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    color: vars.$scale.color.gray900,
  },
  vars.$semantic.typography.subtitle1Regular,
])
const S_TagGroup = styled(TagGroup)([
  {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
])
