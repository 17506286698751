/**
 * @generated SignedSource<<c68d977cf7dfdcac994ab9c118d9a990>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type SectionTypeEnum = "AUTO_REQUEST_SERVICE_FIELD_CURATION" | "BANNER" | "BEST_BIZ_ACCOUNT" | "BIZ_FITNESS_PRODUCT" | "BIZ_RECOMMEND_PRODUCT" | "BIZ_THEME" | "CAR_SAVED_COST" | "CLASS_BIZ_POST" | "COMMUNITY_POST" | "COUPON" | "CUSTOM" | "EXPERT_RECOMMEND" | "HOT_BIZ_ACCOUNT" | "HOT_CAR_ARTICLE" | "HOT_JOBS" | "HOT_KEYWORD" | "LOCAL_FOOD" | "MAP" | "MINI" | "MY_DAANGN_STORY" | "NATIVE_REVIEW" | "NEARBY_BIZ_POST" | "NEARBY_EXERCISE_BIZ_ACCOUNT" | "NEARBY_JOBS" | "POPULAR_SERVICE_FIELD" | "PUBLIC_BIZ_POST" | "PURPOSE" | "RECENTLY_BIZ_ACCOUNT" | "RECENTLY_REALTY_ARTICLE" | "RECOMMEND_POST" | "REQUEST_REVIEW" | "SHORT_FORM";
import { FragmentRefs } from "relay-runtime";
export type HomeSection_section$data = {
  readonly __typename: string;
  readonly baseInfo?: {
    readonly externalId: number;
    readonly sectionType: SectionTypeEnum;
  } | null;
  readonly poiTags?: ReadonlyArray<{
    readonly __typename: "PoiTag";
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"AutoRequestServiceFieldCuration_SectionAutoRequestServiceFieldCuration" | "ExpertRecommend_SectionExpertRecommend" | "HomeBizLocalFoods_sectionBizProduct" | "HomeBizThemes_sectionBizTheme" | "HomeCarSavedCost_sectionCarSavedCost" | "HomeClass_sectionClassBizPost" | "HomeCoupons_sectionCoupon" | "HomeCustomSection_sectionCustom" | "HomeHotBizAccounts_sectionNearbyBizPost" | "HomeHotCarArticle_section" | "HomeHotJobPosts_sectionHotJobs" | "HomeMaps_sectionMap" | "HomeNativeReview_sectionNativeReview" | "HomeNearbyBizPost_sectionNearbyBizPost" | "HomeNearbyExerciseBizAccount_sectionNearbyExerciseBizAccount" | "HomeNearbyJobs_sectionNearbyJobs" | "HomePopularServiceField_SectionPopularServiceField" | "HomePublicBizPosts_sectionPublicBizPost" | "HomePurpose_section" | "HomeRecentlyRealtyArticles_sectionRecentlyArticle" | "HomeRecommendPosts_sectionRecommendPost" | "HomeRequestReview_sectionRequestReview" | "HomeReviews_sectionBestBizAccount" | "HomeShortForms_section">;
  readonly " $fragmentType": "HomeSection_section";
};
export type HomeSection_section$key = {
  readonly " $data"?: HomeSection_section$data;
  readonly " $fragmentSpreads": FragmentRefs<"HomeSection_section">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sectionType",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "externalId",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HomeSection_section",
  "selections": [
    (v0/*: any*/),
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "BaseInfo",
          "kind": "LinkedField",
          "name": "baseInfo",
          "plural": false,
          "selections": [
            (v1/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "type": "SectionBaseInfo",
      "abstractKey": "__isSectionBaseInfo"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PoiTag",
          "kind": "LinkedField",
          "name": "poiTags",
          "plural": true,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "HomeMaps_sectionMap"
        }
      ],
      "type": "SectionMap",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "HomeBizThemes_sectionBizTheme"
        }
      ],
      "type": "SectionBizTheme",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "HomeCoupons_sectionCoupon"
        }
      ],
      "type": "SectionCoupon",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "HomeReviews_sectionBestBizAccount"
        }
      ],
      "type": "SectionBestBizAccount",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "HomeNearbyJobs_sectionNearbyJobs"
        }
      ],
      "type": "SectionNearbyJobs",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "HomeNearbyBizPost_sectionNearbyBizPost"
        }
      ],
      "type": "SectionNearbyBizPost",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "HomeClass_sectionClassBizPost"
        }
      ],
      "type": "SectionClassBizPost",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "HomeBizLocalFoods_sectionBizProduct"
        }
      ],
      "type": "SectionLocalFood",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "HomePurpose_section"
        }
      ],
      "type": "SectionPurpose",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "HomeHotCarArticle_section"
        }
      ],
      "type": "SectionHotCarArticle",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "HomeHotBizAccounts_sectionNearbyBizPost"
        }
      ],
      "type": "SectionHotBizAccount",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "HomeCarSavedCost_sectionCarSavedCost"
        }
      ],
      "type": "SectionCarSavedCost",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "HomeRecentlyRealtyArticles_sectionRecentlyArticle"
        }
      ],
      "type": "SectionRecentlyRealtyArticle",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "HomeHotJobPosts_sectionHotJobs"
        }
      ],
      "type": "SectionHotJobs",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "HomeNearbyExerciseBizAccount_sectionNearbyExerciseBizAccount"
        }
      ],
      "type": "SectionNearbyExerciseBizAccount",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "HomeRecommendPosts_sectionRecommendPost"
        }
      ],
      "type": "SectionRecommendPost",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "BaseInfo",
          "kind": "LinkedField",
          "name": "baseInfo",
          "plural": false,
          "selections": [
            (v2/*: any*/)
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "HomeCustomSection_sectionCustom"
        }
      ],
      "type": "SectionCustom",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "HomeNativeReview_sectionNativeReview"
        }
      ],
      "type": "SectionNativeReview",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "HomePublicBizPosts_sectionPublicBizPost"
        }
      ],
      "type": "SectionPublicBizPost",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "BaseInfo",
          "kind": "LinkedField",
          "name": "baseInfo",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            (v1/*: any*/)
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "HomeShortForms_section"
        }
      ],
      "type": "SectionShortForm",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "HomeRequestReview_sectionRequestReview"
        }
      ],
      "type": "SectionRequestReview",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "AutoRequestServiceFieldCuration_SectionAutoRequestServiceFieldCuration"
        }
      ],
      "type": "SectionAutoRequestServiceFieldCuration",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ExpertRecommend_SectionExpertRecommend"
        }
      ],
      "type": "SectionExpertRecommend",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "HomePopularServiceField_SectionPopularServiceField"
        }
      ],
      "type": "SectionPopularServiceField",
      "abstractKey": null
    }
  ],
  "type": "Section",
  "abstractKey": "__isSection"
};
})();

(node as any).hash = "f7805bbaa423752084ef9f325fbc1046";

export default node;
