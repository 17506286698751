/**
 * @generated SignedSource<<3cb7d7ba9d46cec8a706bfc767d5858b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type ExperimentKeyEnum = "kr_commerce_post_product_link_ui_web_v1" | "kr_coupon_detail_page_view_change_v1" | "kr_place_native_review_title_web_v1" | "nearby_recommend_post_view_type_change_v1" | "nearby_request_review_section_experiment_v1" | "nearby_short_form_section_view_ab_test_v1";
import { FragmentRefs } from "relay-runtime";
export type usePageInit_pageInit$data = {
  readonly viewer: {
    readonly id: string;
    readonly isDebugUser: boolean;
    readonly isDeveloper: boolean;
    readonly nickname: string;
    readonly owningBizAccounts: ReadonlyArray<{
      readonly categoryId: number | null;
      readonly externalId: string;
      readonly individual: boolean;
    }>;
    readonly progressExperiments: ReadonlyArray<{
      readonly key: ExperimentKeyEnum;
      readonly name: string;
      readonly segments: ReadonlyArray<{
        readonly name: string;
        readonly value: string | null;
      }>;
      readonly targetSegment: string;
    }>;
  } | null;
  readonly " $fragmentType": "usePageInit_pageInit";
};
export type usePageInit_pageInit$key = {
  readonly " $data"?: usePageInit_pageInit$data;
  readonly " $fragmentSpreads": FragmentRefs<"usePageInit_pageInit">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "usePageInit_pageInit"
};

(node as any).hash = "29814dabaa48ef248681428a49861c63";

export default node;
