/**
 * @generated SignedSource<<28a68c4a8b86446e542d7434af1e9a1f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type NearbyBadgeEnum = "COUPON_EXISTS" | "DELIVERY" | "FAST_RESPONSE" | "FREE_DELIVERY" | "HAS_TRIAL" | "MOST_REVIEW" | "MOST_VIEW" | "SAME_DAY_DELIVERY" | "WALK_10_MIN";
import { FragmentRefs } from "relay-runtime";
export type ClassBizPostItem_bizPost$data = {
  readonly badges: ReadonlyArray<NearbyBadgeEnum>;
  readonly bizAccount: {
    readonly name: string;
  } | null;
  readonly content: string;
  readonly createdAt: string;
  readonly distance: {
    readonly distance: number;
    readonly name: string;
  } | null;
  readonly image: {
    readonly thumbnail: string;
  };
  readonly targetUri: string;
  readonly title: string;
  readonly " $fragmentSpreads": FragmentRefs<"Badges_badges" | "BizPostItem_common">;
  readonly " $fragmentType": "ClassBizPostItem_bizPost";
};
export type ClassBizPostItem_bizPost$key = {
  readonly " $data"?: ClassBizPostItem_bizPost$data;
  readonly " $fragmentSpreads": FragmentRefs<"ClassBizPostItem_bizPost">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ClassBizPostItem_bizPost",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "content",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Image",
      "kind": "LinkedField",
      "name": "image",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "thumbnail",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BizAccount",
      "kind": "LinkedField",
      "name": "bizAccount",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DistanceInfo",
      "kind": "LinkedField",
      "name": "distance",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "distance",
          "storageKey": null
        },
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "targetUri",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "badges",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BizPostItem_common"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Badges_badges"
    }
  ],
  "type": "BizPost",
  "abstractKey": null
};
})();

(node as any).hash = "7f156fa7ea0866cb991710eb90e1dad0";

export default node;
