import { createContext, useContext, useEffect, useState } from 'react'

import { CategoryFilterSortEnum } from '@src/types/schemaEnums'

export type CategoryFilterOption = {
  toggleOptions: string[]
  subCategoryFilterOption: string | null
  sortOption: CategoryFilterSortEnum
}

const DEFAULT_SORT_OPTION: CategoryFilterSortEnum = 'BA_POI_SCORE_DESC'
export const DEFAULT_FILTER_OPTION: CategoryFilterOption = {
  toggleOptions: [],
  subCategoryFilterOption: 'all',
  sortOption: DEFAULT_SORT_OPTION,
}

const CategoryFilterDispatchContext = createContext<React.Dispatch<React.SetStateAction<CategoryFilterOption>>>(
  null as any
)

const CategoryFilterContext = createContext<CategoryFilterOption>(DEFAULT_FILTER_OPTION)

export const CategoryFilterContextProvider: React.FCWithChildren<{
  filterState: CategoryFilterOption
  dispatcher: React.Dispatch<React.SetStateAction<CategoryFilterOption>>
}> = ({ filterState, dispatcher, children }) => {
  return (
    <CategoryFilterContext.Provider value={filterState}>
      <CategoryFilterDispatchContext.Provider value={dispatcher}>{children}</CategoryFilterDispatchContext.Provider>
    </CategoryFilterContext.Provider>
  )
}

/**
 *  카테고리 옵션 정보를 가져온다 (컴포넌트에서 사용)
 **/
export const useCategoryFilterOption = () => {
  return useContext(CategoryFilterContext)
}

/**
 * 카테고리 필터를 변경하는 디스패쳐를 가져온다 (컴포넌트에서 사용)
 * 사용시 주의사항: 디스패쳐를 사용하면 Page Query를 리로드 한다.
 */
export const useCategoryFilterDispatch = () => {
  return useContext(CategoryFilterDispatchContext)
}

/**
 * 카테고리 필터 세팅 (페이지에서 사용)
 */
export const useCategoryFilterOptionInit = ({
  subCategoryFilterOption = null,
  sortOption = DEFAULT_SORT_OPTION,
  toggleOptions = [],
  onFilterOptionChange,
}: Partial<CategoryFilterOption> & {
  onFilterOptionChange?: (filterOption: CategoryFilterOption) => void
}) => {
  const [filterOptions, filterOptionsDispatcher] = useState<CategoryFilterOption>({
    sortOption,
    toggleOptions,
    subCategoryFilterOption,
  })

  useEffect(() => {
    onFilterOptionChange?.(filterOptions)
  }, [filterOptions, onFilterOptionChange])

  return {
    filterOptions,
    filterOptionsDispatcher,
  }
}
