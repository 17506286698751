import {
  type NearbyStreamData as SDKNearbyStreamData,
  type NearbyStreamEventNames as SDKNearbyStreamEventNames,
} from '@daangn/nearby-sdk'
import { useEffect } from 'react'

import { type NATIVE_STREAM_EVENT, type NativeStreamData } from './nativeStream'
import { type OTHER_WEBVIEW_STREAM_EVENT, type OtherWebviewStreamData } from './otherWebviewStream'
import { karrotBridge } from '../../sdk'

type NearbyStreamEventNames = OTHER_WEBVIEW_STREAM_EVENT | NATIVE_STREAM_EVENT | SDKNearbyStreamEventNames

type NearbyStreamData<T extends NearbyStreamEventNames> = T extends OTHER_WEBVIEW_STREAM_EVENT
  ? OtherWebviewStreamData<T>
  : T extends NATIVE_STREAM_EVENT
  ? NativeStreamData<T>
  : SDKNearbyStreamData<T>

type StreamListener<T extends NearbyStreamEventNames> = (data: NearbyStreamData<T>) => void

const activatedBridgeStreamListeners: Partial<{
  [key in NearbyStreamEventNames]: StreamListener<key>
}> = {}

export const useBridgeStreamListener = <T extends NearbyStreamEventNames>(
  eventName: T,
  streamListener: StreamListener<T>
) => {
  useEffect(() => {
    activatedBridgeStreamListeners[eventName] =
      streamListener as (typeof activatedBridgeStreamListeners)[typeof eventName]

    return () => {
      delete activatedBridgeStreamListeners[eventName]
    }
  }, [eventName, streamListener])
}

export const useStreamListen = () => {
  useEffect(() => {
    karrotBridge.dangerouslySubscribeStream({}, (error, response) => {
      if (error) throw error
      if (!response) return

      const { stream } = response

      const eventName = stream.eventName as NearbyStreamEventNames

      if (eventName in activatedBridgeStreamListeners) {
        const data = stream.data ? JSON.parse(stream.data) : {}

        if (data.service === 'nearby') {
          return
        }

        const func = activatedBridgeStreamListeners[eventName]

        func?.(data)
      }
    })
  }, [])
}

export const emitStream = <T extends NearbyStreamEventNames>(
  eventName: NearbyStreamEventNames,
  data: NearbyStreamData<T>
) => {
  karrotBridge.emitToStream({
    stream: {
      eventName,
      data: JSON.stringify(data),
    },
  })
}
