import { formatDistanceToNow } from 'date-fns'
import ko from 'date-fns/locale/ko'

/**
 * pad를 추가한 숫자를 반환. (eg. 1 -> 01 / 10 -> 10)
 */
export const getPadNumber = (number: number) => {
  return `0${number}`.slice(-2)
}

/**
 * Date객체의 년, 월, 일, 시, 초를 포맷팅하여 반환.
 */
export const getDateFormatWithPad = (date: Date) => {
  const year = `${date.getFullYear()}`
  const month = getPadNumber(date.getMonth() + 1)
  const _date = getPadNumber(date.getDate())
  const hour = getPadNumber(date.getHours())
  const minute = getPadNumber(date.getMinutes())

  return {
    year,
    month,
    date: _date,
    hour,
    minute,
  }
}

/**
 * 현재 날짜로부터 얼마나 지났는지 반환.
 */
export const getDateDiffFromNow = (date: Date) =>
  formatDistanceToNow(new Date(date), {
    locale: ko,
  })
