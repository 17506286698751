/**
 * @generated SignedSource<<6630e1311b8cea7e553e6ff0d1afbec3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HomeClass_sectionClassBizPost$data = {
  readonly __typename: "SectionClassBizPost";
  readonly bizPosts: ReadonlyArray<{
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"ClassBizPostItem_bizPost">;
  }>;
  readonly loggingMeta: {
    readonly click: string;
    readonly show: string;
    readonly swipe: string;
  };
  readonly targetUri: string;
  readonly writeBizTargetUri: string;
  readonly " $fragmentSpreads": FragmentRefs<"HomeSectionBase_sectionBase">;
  readonly " $fragmentType": "HomeClass_sectionClassBizPost";
};
export type HomeClass_sectionClassBizPost$key = {
  readonly " $data"?: HomeClass_sectionClassBizPost$data;
  readonly " $fragmentSpreads": FragmentRefs<"HomeClass_sectionClassBizPost">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HomeClass_sectionClassBizPost",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BizPost",
      "kind": "LinkedField",
      "name": "bizPosts",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ClassBizPostItem_bizPost"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EventMeta",
      "kind": "LinkedField",
      "name": "loggingMeta",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "swipe",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "click",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "show",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "targetUri",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "writeBizTargetUri",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HomeSectionBase_sectionBase"
    }
  ],
  "type": "SectionClassBizPost",
  "abstractKey": null
};

(node as any).hash = "f55dd8e8a494373f1d9f25c02592c1d8";

export default node;
