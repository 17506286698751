/**
 * @generated SignedSource<<e057560d7578fa68ae9332266ecdecb7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type NearbyBadgeEnum = "COUPON_EXISTS" | "DELIVERY" | "FAST_RESPONSE" | "FREE_DELIVERY" | "HAS_TRIAL" | "MOST_REVIEW" | "MOST_VIEW" | "SAME_DAY_DELIVERY" | "WALK_10_MIN";
import { FragmentRefs } from "relay-runtime";
export type HomeBizLocalFoodsItem_bizProduct$data = {
  readonly badges: ReadonlyArray<NearbyBadgeEnum>;
  readonly bizAccount: {
    readonly name: string;
  } | null;
  readonly bookmarkCount: number;
  readonly discountPercentage: number;
  readonly distance: {
    readonly distance: number;
    readonly name: string;
  } | null;
  readonly externalId: string;
  readonly id: string;
  readonly image: {
    readonly thumbnail: string;
  };
  readonly salePrice: number;
  readonly targetUri: string;
  readonly title: string;
  readonly viewerIsBookmarked: boolean;
  readonly " $fragmentSpreads": FragmentRefs<"Badges_badges" | "BizProductItem_common">;
  readonly " $fragmentType": "HomeBizLocalFoodsItem_bizProduct";
};
export type HomeBizLocalFoodsItem_bizProduct$key = {
  readonly " $data"?: HomeBizLocalFoodsItem_bizProduct$data;
  readonly " $fragmentSpreads": FragmentRefs<"HomeBizLocalFoodsItem_bizProduct">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HomeBizLocalFoodsItem_bizProduct",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "externalId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "discountPercentage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "salePrice",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "targetUri",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bookmarkCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DistanceInfo",
      "kind": "LinkedField",
      "name": "distance",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "distance",
          "storageKey": null
        },
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Image",
      "kind": "LinkedField",
      "name": "image",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "thumbnail",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BizAccount",
      "kind": "LinkedField",
      "name": "bizAccount",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "viewerIsBookmarked",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "badges",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BizProductItem_common"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Badges_badges"
    }
  ],
  "type": "BizProduct",
  "abstractKey": null
};
})();

(node as any).hash = "0e8232b83a6354acc7ef8287c21b4d28";

export default node;
