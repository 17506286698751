import cjkSlug from 'cjk-slug'

import { type Coordinates } from '@src/components/_lib/MapUtils/useMap'
import { karrotBridge } from '@src/sdk'

import {
  APP_SCHEME,
  BIZ_PLATFORM_APP,
  BIZ_PROFILE_REMOTE_APP,
  HOME_TAB_SCHEME,
  PERMALINK_DOMAIN,
  REPORT_REMOTE_APP,
  SHARE_DOMAIN,
  EXPERT_WEBVIEW_DOMAIN,
  NEARBY_TAB_SCHEME,
} from '../constants'

const getAppScheme = (app: string, path: string) => {
  return `${APP_SCHEME}minikarrot/router?scrollable=false&navbar=false&app=${encodeURIComponent(
    `${app}`
  )}&path=${encodeURIComponent(path)}`
}

const getRemoteAppScheme = (remote: string, path?: string) =>
  `${APP_SCHEME}minikarrot/router?scrollable=false&navbar=false&remote=${encodeURIComponent(`${remote}${path}`)}`

export const bizPostScheme = (postId: string) =>
  getAppScheme(BIZ_PLATFORM_APP, `/posts/detail/${postId}?referrer=share.post.detail`)

// 공유하기 스킴
export const getShareBizPostSchemeUrl = (postId: string) => {
  if (SHARE_DOMAIN) {
    return `${SHARE_DOMAIN}/posts/${postId}`
  }
  return bizPostScheme(postId)
}

export const getShareBizProfileHomeUrl = (bizPublicId: string, bizProfileName: string, params?: string) => {
  return `${PERMALINK_DOMAIN}/kr/business-profiles/${cjkSlug(bizProfileName)}-${bizPublicId}?${params ?? ''}`
}

// 신고하기 스킴
// 참고: https://www.notion.so/daangn/remote-55cfa400389a4308815f5ac20602f4f8
export const getReportAppScheme = (path: string) => `${getRemoteAppScheme(REPORT_REMOTE_APP, path)}` // TODO. 신고하기 리모트웹뷰 전환

export const reportPost = (postId: string) => {
  window.location.href = getReportAppScheme(`?kind=biz_post&biz_post_id=${postId}`)
}

export const reportBizAccountReview = (reviewId: string) => {
  window.location.href = getReportAppScheme(`?kind=biz_review&review_id=${reviewId}`)
}
// 비즈프로필 홈 스킴
export const getBizProfileHomeScheme = (baId: string, referrer: string) => {
  return getRemoteAppScheme(BIZ_PROFILE_REMOTE_APP, `/biz_accounts/${baId}/viewer/home?referrer=${referrer}`)
}

// 유저 리뷰모음 스킴
export const goUserReviewsScheme = (userId: string) => {
  window.location.href = getRemoteAppScheme(BIZ_PROFILE_REMOTE_APP, `/users/${userId}/reviews`)
}

// 내 단골 비즈프로필 스킴
export const goMyFollowingBizProfilesScheme = (referrer: string) => {
  window.location.href = getRemoteAppScheme(BIZ_PROFILE_REMOTE_APP, `/me/following_profile?referrer=${referrer}`)
}

// 견적 요청 스킴 생성기
export const getExpertQuestScheme = (expertId: string) => {
  return (referrer: string) => {
    return getRemoteAppScheme(EXPERT_WEBVIEW_DOMAIN, `/quest/dynamic/${expertId}?referrer=${referrer}`)
  }
}

const getSelectExpertScheme = (expertIds: string[]) => {
  return (referrer: string) => {
    return getRemoteAppScheme(
      EXPERT_WEBVIEW_DOMAIN,
      `/quest/dynamic/select/serviceId?serviceIds=${JSON.stringify(expertIds)}&referrer=${referrer}`
    )
  }
}

// 용달(1) + 이사(14) 견적 요청 스킴
export const getYongdalMatchingScheme = getSelectExpertScheme(['1', '14'])
// 용달(1) + 견적 요청 스킴
export const getYongdalOnlyMatchingScheme = getExpertQuestScheme('1')
// 이사(14) 견적 요청 스킴
export const getMovinglMatchingScheme = getExpertQuestScheme('14')
// 에어컨 설치 견적 요청 스킴
export const getInstallAirconMatchingScheme = getExpertQuestScheme('6')
// 에어컨 청소 견적 요청 스킴
export const getCleanAirconMatchingScheme = getExpertQuestScheme('7')
// 에어컨 청소 견적 요청 스킴
export const getRepairAirconMatchingScheme = getExpertQuestScheme('8')

// 비즈프로필 상품탭 스킴
export const goBizProfileProductTabScheme = (bizId: string, referrer: string) =>
  (window.location.href = getRemoteAppScheme(
    BIZ_PROFILE_REMOTE_APP,
    `/biz_accounts/${bizId}/viewer/home?tab=PRODUCT&referrer=${referrer}`
  ))

// 비즈프로필 특정 후기로 이동하기 스킴
export const goBizProfileReviewScheme = ({
  bizId,
  reviewId,
  referrer,
}: {
  bizId: string
  reviewId?: string
  referrer: string
}) => {
  window.location.href = getRemoteAppScheme(
    BIZ_PROFILE_REMOTE_APP,
    `/biz_accounts/${bizId}/viewer/home?tab=REVIEW${
      reviewId ? `&highlightReviewId=${reviewId}` : ''
    }&referrer=${referrer}`
  )
}

export const myCouponScheme = (referrer: string) =>
  getRemoteAppScheme(BIZ_PROFILE_REMOTE_APP, `/me/coupons?referrer=${referrer}`)

//받은 쿠폰함 스킴
export const goMyCouponsScheme = (referrer: string) => (window.location.href = myCouponScheme(referrer))

// 비즈프로필 사장님 쿠폰 수정 스킴
export const goBizProfileUpdateCouponScheme = (bizId: string, couponBoxId: string, referrer: string) => {
  window.location.href = getRemoteAppScheme(
    BIZ_PROFILE_REMOTE_APP,
    `/biz_accounts/${bizId}/manager/coupons/detail/${couponBoxId}/update?referrer=${referrer}`
  )
}

export const goCouponBookScheme = (referrer: string) => {
  window.location.href = getRemoteAppScheme(
    BIZ_PROFILE_REMOTE_APP,
    `/coupon_book/?sortType=distance&referrer=${referrer}`
  )
}

export const goHomeTab = () => {
  window.location.href = HOME_TAB_SCHEME
}

export const goNearbyTab = () => {
  window.location.href = NEARBY_TAB_SCHEME
}

// 업체 위치보기 스킴
export const openKarrotMap = ({
  coordinates,
  name,
  address,
}: {
  coordinates: Coordinates
  name: string
  address: string
}) => {
  const params = new URLSearchParams({
    lat: coordinates.lat.toString(),
    lng: coordinates.lng.toString(),
    name,
    address,
  }).toString()

  const url = `https://map.kr.karrotmarket.com/marker?${params}`

  karrotBridge.pushRouter({
    router: {
      remote: url,
      navbar: true,
    },
  })
}

const CLASS_DIRECTORY_SERVICE_FIELD_IDS = [
  '86',
  '87',
  '70',
  '77',
  '75',
  '78',
  '79',
  '69',
  '74',
  '73',
  '80',
  '76',
  '71',
  '72',
]
const EXERCISE_DIRECTORY_SERVICE_FIELD_IDS = [
  '45',
  '46',
  '47',
  '48',
  '49',
  '50',
  '51',
  '52',
  '53',
  '54',
  '55',
  '56',
  '57',
  '58',
  '63',
  '65',
  '66',
  '59',
  '60',
  '67',
  '68',
]

export const getExpertDirectorySchemeFromServiceFieldId = (serviceFieldId: string, referrer: string) => {
  if (CLASS_DIRECTORY_SERVICE_FIELD_IDS.includes(serviceFieldId)) {
    const searchParams = new URLSearchParams({
      selectedServiceField: serviceFieldId,
      referrer,
    })
    return getRemoteAppScheme(EXPERT_WEBVIEW_DOMAIN, `/expert/home/serviceDirectory/6?${searchParams.toString()}`)
  } else if (EXERCISE_DIRECTORY_SERVICE_FIELD_IDS.includes(serviceFieldId)) {
    const searchParams = new URLSearchParams({
      selectedServiceField: serviceFieldId,
      referrer,
    })
    return getRemoteAppScheme(EXPERT_WEBVIEW_DOMAIN, `/expert/home/serviceDirectory/5?${searchParams.toString()}`)
  }
  const searchParams = new URLSearchParams({
    selectedServiceField: serviceFieldId,
    referrer,
  })
  return getRemoteAppScheme(EXPERT_WEBVIEW_DOMAIN, `/expert/home/serviceDirectory/6?${searchParams.toString()}`)
}
