/**
 *
 * daangn-map category icons can be found here
 * https://www.notion.so/daangn/id-url-d66cda2952eb48ccbb71c22fb800dd87?pvs=4
 */

import { IS_PRODUCTION } from '@src/constants'

export const FOOD_ICON = IS_PRODUCTION
  ? 'https://dnvefa72aowie.cloudfront.net/poi/202306201650/icon_category_restaurant.png'
  : 'https://d1rb42tpk2uaws.cloudfront.net/poi/202306201659/icon_category_restaurant.png'

export const CAFE_ICON = IS_PRODUCTION
  ? 'https://dnvefa72aowie.cloudfront.net/poi/202306201735/icon_category_cafe.png'
  : 'https://d1rb42tpk2uaws.cloudfront.net/poi/202306201734/icon_category_cafe.png'

export const HOSPITAL_ICON = IS_PRODUCTION
  ? 'https://dnvefa72aowie.cloudfront.net/poi/202306201650/icon_category_medical.png'
  : 'https://d1rb42tpk2uaws.cloudfront.net/poi/202306201659/icon_category_medical.png'

export const BEAUTY_ICON = IS_PRODUCTION
  ? 'https://dnvefa72aowie.cloudfront.net/poi/202306201650/icon_category_beautyshop.png'
  : 'https://d1rb42tpk2uaws.cloudfront.net/poi/202306201659/icon_category_beautyshop.png'

export const EXERCISE_ICON = IS_PRODUCTION
  ? 'https://dnvefa72aowie.cloudfront.net/poi/202306201650/icon_category_fitness.png'
  : 'https://d1rb42tpk2uaws.cloudfront.net/poi/202306201659/icon_category_fitness.png'

export const PET_ICON = IS_PRODUCTION
  ? 'https://dnvefa72aowie.cloudfront.net/poi/202306201650/icon_category_pet.png'
  : 'https://d1rb42tpk2uaws.cloudfront.net/poi/202306201659/icon_category_pet.png'
