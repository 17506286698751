import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { IconReviewStarFill } from '@seed-design/icon'
import { type MouseEvent, useState, useEffect } from 'react'

const TOTAL_STAR_COUNT = 5

export type StarRatingProps = {
  rating?: number
  count?: number
  onClick?: (e: MouseEvent<HTMLButtonElement>, { value }: { value: number }) => void
  size: 'large' | 'small'
}
export const StarRating = ({ count = TOTAL_STAR_COUNT, size, ...props }: StarRatingProps) => {
  const [rating, setRating] = useState(props.rating ?? 0)

  const handleClick = (e: MouseEvent<HTMLButtonElement>, { value }: { value: number }) => {
    setRating(value)
    props.onClick?.(e, { value })
  }

  useEffect(() => {
    if (typeof props.rating === 'number') {
      setRating(props.rating)
    }
  }, [props.rating])

  return (
    <S_RatingGroup size={size}>
      {Array.from({ length: count }).map((_, idx) => {
        const _rating = idx + 1

        return (
          <S_Button type="button" key={idx} onClick={(e) => handleClick(e, { value: _rating })}>
            <S_IconReviewStarFill size={size} filled={_rating <= rating ? 'true' : 'false'} />
          </S_Button>
        )
      })}
    </S_RatingGroup>
  )
}

const S_RatingGroup = styled('div')<{ size: 'small' | 'large' }>(({ size }) => [
  {
    display: 'flex',
  },
  size === 'large' && {
    gap: '0.5rem',
  },
])
const S_IconReviewStarFill = styled(IconReviewStarFill)<{
  size: 'small' | 'large'
  filled: 'true' | 'false'
}>(({ size, filled }) => [
  {
    width: '0.9375rem',
    height: '0.9375rem',
    color: vars.$scale.color.gray300,
  },
  size === 'large' && { width: '2.5rem', height: '2.5rem' },
  filled === 'true' && { color: vars.$semantic.color.primary },
])
const S_Button = styled('button')([
  {
    margin: 0,
    padding: 0,
    border: 0,
    fontSize: '100%',
    font: 'inherit',
    verticalAlign: 'baseline',
    background: 'transparent',
    WebkitAppearance: 'none',
    WebkitFontSmoothing: 'inherit',
    MozOsxFontSmoothing: 'inherit',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
])
