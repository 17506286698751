import { type SizeIconProps } from '@daangn/karrot-clothes/lib/types/svg'
import styled from '@emotion/styled'
import React from 'react'

const InterestList: React.FC<SizeIconProps> = ({ size = 12, color = 'currentColor' }) => {
  return (
    <Svg width={size} height={size} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.38139 1.1152C5.575 0.514334 6.42512 0.514334 6.61874 1.1152L7.62059 4.22441H10.7175C11.3505 4.22441 11.6101 5.03704 11.0944 5.40401L8.57057 7.19994L9.54235 10.1837C9.7375 10.7829 9.05134 11.2814 8.54182 10.9106L6.00006 9.06079L3.4583 10.9106C2.94878 11.2814 2.26262 10.7829 2.45777 10.1837L3.42956 7.19994L0.905724 5.40401C0.390022 5.03704 0.649647 4.22441 1.28258 4.22441H4.37953L5.38139 1.1152Z"
        fill={color}
      />
    </Svg>
  )
}

export default React.memo(InterestList)

const Svg = styled.svg`
  display: block;
`
