import { usePullToRefreshScrollElement } from '@daangn/karrot-clothes/lib/pullToRefresh'
import styled from '@emotion/styled'
import { type FC, useCallback, useRef } from 'react'
import { graphql, useFragment } from 'react-relay'

import { ScrollableX } from '@src/components/_lib/Scrollable'
import { useSwipeSection } from '@src/hooks/sections/useSwipeSection'
import { useOpenTarget } from '@src/lib/target'

import HotCarArticle from './HotCarArticle'
import { type HomeHotCarArticle_section$key } from '../../../../__relay__/HomeHotCarArticle_section.graphql'
import { useLogger } from '../../../../hooks/useLogger'
import { type PropOf } from '../../../../lib/T/utilTypes'
import type { SectionInfo } from '../../../../types'
import HomeSectionBase from '../Common/HomeSectionBase'

interface HomeHotCarArticleProps {
  sectionHotCarArticle: HomeHotCarArticle_section$key
  sectionInfo: SectionInfo
}

const HomeHotCarArticle: FC<HomeHotCarArticleProps> = ({ sectionInfo, ...props }) => {
  const section = useFragment(
    graphql`
      fragment HomeHotCarArticle_section on SectionHotCarArticle {
        __typename
        loggingMeta {
          swipe
          click
          show
        }
        articles {
          id
          ...HotCarArticle_carArticle
        }
        numOfUsersTraded
        ...HomeSectionBase_sectionBase
      }
    `,
    props.sectionHotCarArticle
  )

  const scrollContainerRef = usePullToRefreshScrollElement()
  const { contentsSectionLog, loggingMetaLog } = useLogger()
  const { openTarget } = useOpenTarget()
  const itemScrollContainerRef = useRef<HTMLDivElement>(null)

  useSwipeSection({
    swipeContainerRef: itemScrollContainerRef,
    sectionOrder: sectionInfo.order,
  })

  const handleImpression = useCallback(() => {
    contentsSectionLog('show', {
      contentsType: 'car_hot_articles',
      sectionOrder: sectionInfo.order,
    })

    if (sectionInfo.order === 0) {
      loggingMetaLog(section.loggingMeta.show)
    }
  }, [contentsSectionLog, loggingMetaLog, sectionInfo.order, section.loggingMeta.show])

  const handleMoreClick: NonNullable<PropOf<typeof HomeSectionBase, 'onMoreClick'>> = useCallback(
    ({ targetUri }) => {
      contentsSectionLog('click', {
        contentsType: 'car_hot_articles',
        buttonType: 'more',
        params: {
          sectionOrder: sectionInfo.order,
        },
      })

      loggingMetaLog(section.loggingMeta.click)

      if (targetUri) {
        openTarget({
          targetUri,
        })
      }
    },
    [contentsSectionLog, loggingMetaLog, openTarget, sectionInfo.order, section.loggingMeta.click]
  )

  const handleArticleClick: PropOf<typeof HotCarArticle, 'onClick'> = useCallback(
    (article, itemOrder) => {
      contentsSectionLog('click', {
        contentsType: 'car_hot_articles',
        buttonType: 'list',
        params: {
          item_id: article.externalId,
          view_cnt: article.viewCountInLast3Hours,
          item_order: itemOrder,
          sectionOrder: sectionInfo.order,
        },
      })

      loggingMetaLog(section.loggingMeta.click)

      if (article.targetUri) {
        openTarget({
          targetUri: article.targetUri,
        })
      }
    },
    [contentsSectionLog, loggingMetaLog, openTarget, sectionInfo.order, section.loggingMeta.click]
  )

  const handleSwipeItem: PropOf<typeof HotCarArticle, 'onImpression'> = useCallback(
    (article, itemOrder) => {
      if (itemOrder === 0) {
        return
      }

      contentsSectionLog('swipe', {
        contentsType: 'car_hot_articles',
        itemOrder: itemOrder,
        params: {
          sectionOrder: sectionInfo.order,
        },
      })

      loggingMetaLog(section.loggingMeta.swipe)
    },
    [contentsSectionLog, loggingMetaLog, sectionInfo.order, section.loggingMeta.swipe]
  )

  return (
    <HomeSectionBase
      sectionBase={section}
      SectionTitleProps={{
        subtitle: `오늘 ${section.numOfUsersTraded}명이 거래했어요`,
      }}
      ImpressionProps={{
        scrollContainerRef,
        onImpression: handleImpression,
        threshold: 0.9,
      }}
      onMoreClick={handleMoreClick}>
      <Scrollable ref={itemScrollContainerRef}>
        {section.articles.map((article, index) => (
          <HotCarArticle
            key={article.id}
            carArticle={article}
            itemOrder={index}
            itemScrollContainerRef={itemScrollContainerRef}
            onClick={handleArticleClick}
            onImpression={handleSwipeItem}
          />
        ))}
      </Scrollable>
    </HomeSectionBase>
  )
}

export default HomeHotCarArticle

const Scrollable = styled(ScrollableX)`
  padding: 0.75rem 1rem 1rem;
`
