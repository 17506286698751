import { type FC } from 'react'
import { graphql, useLazyLoadQuery } from 'react-relay'

import { useCurrentPosition } from '@src/hooks/useCurrentPosition'

import BizThemeLoading from './BizThemeLoading'
import HomeBizTheme from './HomeBizTheme'
import { type BizPostThemeType, type HomeLazyBizThemeQuery } from '../../../../__relay__/HomeLazyBizThemeQuery.graphql'
import { useViewer } from '../../../../hooks/useViewer'
import { type PropsOf } from '../../../../lib/T/utilTypes'
import withSuspense from '../../../../lib/with-fallback/withSuspense'

interface HomeLazyBizThemeProps {
  bizPostThemeType: BizPostThemeType
  HomeBizThemeProps: Omit<PropsOf<typeof HomeBizTheme>, 'bizPostTheme'>
}

const HomeLazyBizTheme: FC<HomeLazyBizThemeProps> = ({ bizPostThemeType, HomeBizThemeProps }) => {
  const viewer = useViewer()
  const { currentPosition } = useCurrentPosition()

  const lazyQuery = useLazyLoadQuery<HomeLazyBizThemeQuery>(
    graphql`
      query HomeLazyBizThemeQuery(
        $sectionType: SectionTypeEnum!
        $regionId: Int!
        $currentCoords: CoordsInput
        $bizPostThemeType: BizPostThemeType
      ) {
        section(input: { sectionType: $sectionType, regionId: $regionId, currentCoords: $currentCoords }) {
          __typename

          ... on SectionBizTheme {
            bizPostTheme(type: $bizPostThemeType) {
              ...HomeBizTheme_bizPostTheme
            }
          }
        }
      }
    `,
    {
      sectionType: 'BIZ_THEME',
      regionId: viewer.region.id || 0,
      currentCoords: currentPosition
        ? {
            lat: currentPosition.lat,
            lng: currentPosition.lng,
          }
        : null,
      bizPostThemeType,
    }
  )

  if (lazyQuery.section?.__typename !== 'SectionBizTheme') {
    return null
  }

  return <HomeBizTheme bizPostTheme={lazyQuery.section?.bizPostTheme} {...HomeBizThemeProps} />
}

export default withSuspense(HomeLazyBizTheme, () => <BizThemeLoading />)
