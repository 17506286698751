import type { Activity } from '@stackflow/core'
import { stackDepthChangePlugin } from '@stackflow/plugin-stack-depth-change'

import { karrotBridge } from '../../sdk'

const handleRouteStyle = ({
  depth,
  activities,
  appTheme,
}: {
  depth: number
  activities: Activity[]
  appTheme: 'android' | 'cupertino'
}) => {
  const activeActivity = activities.find((a) => a.isActive)

  if (document.visibilityState === 'visible') {
    karrotBridge.styleCurrentRouter({
      router: {
        backSwipable: depth <= 1,
        scrollable: false,
        navbar:
          appTheme === 'cupertino' &&
          activeActivity &&
          (activeActivity.name === 'Home' || activeActivity.name === 'HomeFeedShortcutBottomSheet'),
      },
    })
  }
}

interface DepthChangePluginOptions {
  appTheme: 'android' | 'cupertino'
}

const depthChangePlugin = ({ appTheme }: DepthChangePluginOptions) =>
  stackDepthChangePlugin({
    onInit: ({ depth, activities }) => {
      handleRouteStyle({ depth, activities, appTheme })
    },
    onDepthChanged: ({ depth, activities }) => {
      handleRouteStyle({ depth, activities, appTheme })
    },
  })

export default depthChangePlugin
