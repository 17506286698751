import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { AnimatePresence, motion } from 'framer-motion'
import React, { type FC, useEffect, useRef, useState } from 'react'

import IcInfo from '../../../assets/images/icons/IcInfo'
import { FlexCenter } from '../../_lib/styles'

export type InfoButtonPlaceholderPosition = 'left' | 'right' | 'middle'

interface InfoButtonProps {
  tooltipOpen?: boolean
  tooltipContent: string | JSX.Element | React.ReactNode
  placeholderPosition?: InfoButtonPlaceholderPosition
  onClick?: () => void
  onTooltipContentClick?: () => void
}

const InfoButton: FC<InfoButtonProps> = ({
  tooltipOpen,
  tooltipContent,
  placeholderPosition = 'left',
  onClick,
  onTooltipContentClick,
}) => {
  const [isOpen, setIsOpen] = useState(tooltipOpen || false)
  const [tooltipPosition, setTooltipPosition] = useState({
    top: -1,
    left: -1,
    right: -1,
  })
  const infoButtonRef = useRef<HTMLDivElement>(null)
  const tooltipContentRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const infoButtonElement = infoButtonRef.current

    if (infoButtonElement) {
      const { top, left, right } = infoButtonElement.getBoundingClientRect()

      setTooltipPosition({ top, left, right: window.innerWidth - right })
    }
  }, [infoButtonRef, placeholderPosition])

  const handleClick = () => {
    onClick?.()
    setIsOpen(!isOpen)
  }

  const handleTooltipContentClick = () => {
    onTooltipContentClick?.()
  }

  const handleCloseTooltip = () => {
    setIsOpen(false)
  }

  const tooltipContentElement =
    typeof tooltipContent === 'string'
      ? tooltipContent.split('\n').map((content) => <Text key={content}>{content}</Text>)
      : tooltipContent

  return (
    <TooltipWrapper>
      <S_InfoButton ref={infoButtonRef} onClick={handleClick}>
        <IcInfo />
      </S_InfoButton>

      <Tooltip
        tooltipPosition={tooltipPosition}
        placeholderPosition={placeholderPosition}
        onClick={handleTooltipContentClick}>
        <AnimatePresence>
          {isOpen && (
            <motion.div
              initial={{
                opacity: 0,
                scale: 0.9,
              }}
              animate={{
                opacity: 1,
                scale: 1,
                transition: { duration: 0.1 },
              }}
              exit={{
                opacity: 0,
                scale: 1,
                transition: { duration: 0.1 },
              }}>
              <TooltipContent closeTooltip={handleCloseTooltip} tooltipContentRef={tooltipContentRef}>
                {tooltipContentElement}
              </TooltipContent>
            </motion.div>
          )}
        </AnimatePresence>
      </Tooltip>
      <TooltipArrowWrapper tooltipPosition={tooltipPosition}>
        <AnimatePresence>
          {isOpen && (
            <motion.div
              initial={{ opacity: 0, scale: 1 }}
              animate={{
                opacity: 1,
                scale: 1,
                transition: { duration: 0.1 },
              }}
              exit={{
                opacity: 0,
                scale: 1,
                transition: { duration: 0.1 },
              }}>
              <svg width="38" height="6" viewBox="0 0 38 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M24 6L19.7682 0.921865C19.3684 0.442111 18.6316 0.442112 18.2318 0.921866L14 6H24Z"
                  fill="currentColor"
                />
              </svg>
            </motion.div>
          )}
        </AnimatePresence>
      </TooltipArrowWrapper>
    </TooltipWrapper>
  )
}

export default InfoButton

const TooltipContent: React.FCWithChildren<{
  tooltipContentRef: React.RefObject<HTMLDivElement>
  closeTooltip: () => void
}> = ({ children, closeTooltip, tooltipContentRef }) => {
  const handleClose = (e: MouseEvent) => {
    if (!tooltipContentRef.current || !tooltipContentRef.current.contains(e.target as Node)) {
      closeTooltip()
    }
  }

  useEffect(() => {
    setTimeout(() => {
      window.addEventListener('click', handleClose)
    }, 0)

    return () => {
      window.removeEventListener('click', handleClose)
    }
  })
  return <S_TooltipContent ref={tooltipContentRef}>{children}</S_TooltipContent>
}

const TooltipWrapper = styled.div`
  z-index: 1;
`

const S_InfoButton = styled.div`
  ${FlexCenter};
  width: 1.5rem;
  height: 1.5rem;
  color: ${vars.$scale.color.gray600};
`

const Tooltip = styled.div<{
  tooltipPosition: { top: number; left: number; right: number }
  placeholderPosition: InfoButtonPlaceholderPosition
}>`
  position: absolute;
  top: 1.8rem;

  left: ${({ tooltipPosition, placeholderPosition }) =>
    placeholderPosition === 'left' ? `calc(${tooltipPosition.left}px - 1.5rem)` : 'auto'};

  right: ${({ tooltipPosition, placeholderPosition }) =>
    placeholderPosition === 'right' ? `calc(${tooltipPosition.right}px - 1.5rem)` : 'auto'};

  transform: ${({ placeholderPosition }) => placeholderPosition === 'middle' && `translateX(calc(-50% + 0.75rem))`};

  width: max-content;
  z-index: 999;
`

const Text = styled.div``

const TooltipArrowWrapper = styled.div<{
  tooltipPosition: { top: number; left: number }
}>`
  position: absolute;
  top: 0.7rem;
  left: ${({ tooltipPosition }) => `calc(${tooltipPosition.left}px - 1.6rem)`};
  color: ${vars.$scale.color.gray900};
`

const S_TooltipContent = styled.div`
  background-color: ${vars.$scale.color.gray900};
  color: ${vars.$scale.color.gray00};
  ${vars.$semantic.typography.caption1Bold};
  border-radius: 0.375rem;

  padding: 0.5rem 0.75rem;
  word-wrap: break-word;
  word-break: keep-all;
`
