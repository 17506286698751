import { type UseMutationConfig } from 'react-relay'
import { type Disposable, type MutationParameters } from 'relay-runtime'

export const asyncMutate = async <TMutation extends MutationParameters>(
  commitMutation: (config: UseMutationConfig<TMutation>) => Disposable,
  options: Omit<UseMutationConfig<TMutation>, 'onCompleted' | 'onError'>
): Promise<TMutation['response']> => {
  return new Promise((resolve, reject) => {
    commitMutation({
      variables: options.variables,
      onCompleted: (response) => {
        resolve(response)
      },
      onError: (error) => {
        reject(error)
      },
    })
  })
}
