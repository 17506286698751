import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { appTheme } from '@src/styles/MyThemeProvider'

export const TextWithDot = (text: string, margin = '0 0.063rem', iosMargin = '0 0.125rem 0 0.063rem') => {
  const splitedText = text.split('·')

  return (
    <>
      {splitedText.map((word, index) => (
        <span key={`textDot${word}${index}`}>
          {index > 0 && <TextDot margin={margin} iosMargin={iosMargin} />}
          {word}
        </span>
      ))}
    </>
  )
}

const TextDot = styled.span<{ margin?: string; iosMargin?: string }>`
  display: inline-block;
  margin: ${({ margin }) => `${margin}`};
  ${({ iosMargin, theme }) =>
    iosMargin &&
    theme.environment === 'cupertino' &&
    css`
      margin: ${iosMargin};
    `};

  ${({ theme }) =>
    theme.environment === 'cupertino' &&
    css`
      transform: translateY(-0.063rem);
    `}

  &::after {
    content: '·';
  }
`

export const TextDotStyle = css`
  content: '·';
`

const LineSplitText = (string: string): JSX.Element[] => {
  return string.split('\n').map((content) => <div key={content}>{content}</div>)
}

export default LineSplitText

type SafeAreaDirection = 'top' | 'bottom' | 'left' | 'right'

export const getSafeAreaValue = (direction: SafeAreaDirection) => {
  const rootElement = document.getElementById('root')
  const safeAreaEnv =
    rootElement && window.getComputedStyle(rootElement).getPropertyValue(`--safe-area-inset-${direction}-env`)
  const safeAreaConstant =
    rootElement && window.getComputedStyle(rootElement).getPropertyValue(`--safe-area-inset-${direction}-constant`)

  return safeAreaEnv || safeAreaConstant || '0px'
}

export const APP_BAR_HEIGHT = appTheme === 'cupertino' ? 44 : 56
