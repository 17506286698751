/**
 * @generated SignedSource<<356afec66f72fff7a0e5c755e7fbe4fe>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HomeRequestReview_sectionRequestReview$data = {
  readonly __typename: "SectionRequestReview";
  readonly bizReviewTargets: ReadonlyArray<{
    readonly __typename: "BizReviewTarget";
    readonly bizStore: {
      readonly __typename: "BizAccount";
      readonly categoryId: number | null;
      readonly categoryName: string | null;
      readonly externalId: string;
      readonly image: {
        readonly url: string;
      };
      readonly name: string;
      readonly regionBizAccount: {
        readonly name3: string;
      } | null;
      readonly targetUri: string;
    } | {
      readonly __typename: "Poi";
      readonly category: {
        readonly externalId: number;
        readonly name: string;
      } | null;
      readonly externalId: string;
      readonly images: ReadonlyArray<{
        readonly url: string;
      }>;
      readonly name: string;
      readonly regionPoi: {
        readonly name3: string;
      };
      readonly targetUri: string;
    } | {
      // This will never be '%other', but we need some
      // value in case none of the concrete values match.
      readonly __typename: "%other";
    };
    readonly tier: number;
  }>;
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"HomeSectionBase_sectionBase">;
  readonly " $fragmentType": "HomeRequestReview_sectionRequestReview";
};
export type HomeRequestReview_sectionRequestReview$key = {
  readonly " $data"?: HomeRequestReview_sectionRequestReview$data;
  readonly " $fragmentSpreads": FragmentRefs<"HomeRequestReview_sectionRequestReview">;
};

import HomeRequestReviewRefetchQuery_graphql from './HomeRequestReviewRefetchQuery.graphql';

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "externalId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "url",
    "storageKey": null
  }
],
v4 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name3",
    "storageKey": null
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "targetUri",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": HomeRequestReviewRefetchQuery_graphql,
      "identifierField": "id"
    }
  },
  "name": "HomeRequestReview_sectionRequestReview",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "BizReviewTarget",
      "kind": "LinkedField",
      "name": "bizReviewTargets",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "bizStore",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "kind": "InlineFragment",
              "selections": [
                (v1/*: any*/),
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Image",
                  "kind": "LinkedField",
                  "name": "image",
                  "plural": false,
                  "selections": (v3/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": "regionBizAccount",
                  "args": null,
                  "concreteType": "Region",
                  "kind": "LinkedField",
                  "name": "region",
                  "plural": false,
                  "selections": (v4/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "categoryId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "categoryName",
                  "storageKey": null
                },
                (v5/*: any*/)
              ],
              "type": "BizAccount",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                (v1/*: any*/),
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Image",
                  "kind": "LinkedField",
                  "name": "images",
                  "plural": true,
                  "selections": (v3/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": "regionPoi",
                  "args": null,
                  "concreteType": "Region",
                  "kind": "LinkedField",
                  "name": "region",
                  "plural": false,
                  "selections": (v4/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PoiCategory",
                  "kind": "LinkedField",
                  "name": "category",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    (v2/*: any*/)
                  ],
                  "storageKey": null
                },
                (v5/*: any*/)
              ],
              "type": "Poi",
              "abstractKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tier",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HomeSectionBase_sectionBase"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "SectionRequestReview",
  "abstractKey": null
};
})();

(node as any).hash = "e410ed6246ded1b8219537a86d40dbef";

export default node;
