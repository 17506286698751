import { usePullToRefreshScrollElement } from '@daangn/karrot-clothes/lib/pullToRefresh'
import { type FC, useCallback, useState } from 'react'
import { graphql, useFragment } from 'react-relay'
import { z } from 'zod'

import { type HomeCustomSection_sectionCustom$key } from '@src/__relay__/HomeCustomSection_sectionCustom.graphql'
import { type LogSectionType, useLogger } from '@src/hooks/useLogger'
import { type PropOf } from '@src/lib/T/utilTypes'
import { useOpenTarget } from '@src/lib/target'
import { type SectionInfo } from '@src/types'

import CustomSection from './CustomSection'
import HomeSectionBase from '../Common/HomeSectionBase'

const ExtraInfoSchema = z.object({
  contents_type: z.string().optional(),
})

interface HomeCustomSectionProps {
  sectionCustomRef: HomeCustomSection_sectionCustom$key
  sectionInfo: SectionInfo
}

const HomeCustomSection: FC<HomeCustomSectionProps> = ({ sectionCustomRef, sectionInfo }) => {
  const scrollContainerRef = usePullToRefreshScrollElement()
  const { contentsSectionLog } = useLogger()
  const { openTarget } = useOpenTarget()
  const [sectionClose, setSectionClose] = useState(false)

  const section = useFragment(
    graphql`
      fragment HomeCustomSection_sectionCustom on SectionCustom {
        baseInfo {
          extra
          requestInfo {
            ...CustomSection_requestInfo
            ...CustomSection_componentType
          }
        }
        ...HomeSectionBase_sectionBase
      }
    `,
    sectionCustomRef
  )

  const extraData = ExtraInfoSchema.safeParse(JSON.parse(section.baseInfo?.extra ?? '{}'))
  const contentsType = extraData.success ? extraData.data.contents_type ?? 'CUSTOM' : 'CUSTOM'

  const handleImpression = useCallback(() => {
    contentsSectionLog('show', {
      contentsType: contentsType as LogSectionType,
      sectionOrder: sectionInfo.order,
    })
  }, [contentsSectionLog, contentsType, sectionInfo.order])

  const handleMoreClick: NonNullable<PropOf<typeof HomeSectionBase, 'onMoreClick'>> = useCallback(
    ({ targetUri }) => {
      contentsSectionLog('click', {
        contentsType: contentsType as LogSectionType,
        buttonType: 'more',
        params: {
          section_order: sectionInfo.order,
        },
      })

      if (targetUri) {
        openTarget({
          targetUri: targetUri,
        })
      }
    },
    [contentsSectionLog, contentsType, openTarget, sectionInfo.order]
  )

  const handleItemClick: PropOf<typeof CustomSection, 'onItemClick'> = useCallback(
    ({ externalId, targetUri }) => {
      contentsSectionLog('click', {
        contentsType: contentsType as LogSectionType,
        buttonType: 'list',
        params: {
          item_id: externalId,
          section_order: sectionInfo.order,
        },
      })

      if (targetUri) {
        openTarget({
          targetUri: targetUri,
          updateReferrer: () => 'nearby',
        })
      }
    },
    [contentsSectionLog, contentsType, openTarget, sectionInfo.order]
  )

  const handleListSwipe: PropOf<typeof CustomSection, 'onListSwipe'> = useCallback(
    ({ sectionType, offset }) => {
      const params =
        sectionType === 'list'
          ? {
              page: offset,
            }
          : {
              item_order: offset,
            }

      contentsSectionLog('swipe', {
        contentsType: contentsType as LogSectionType,
        params,
      })
    },
    [contentsSectionLog, contentsType]
  )

  const handleSectionClose = useCallback(() => {
    setSectionClose(true)
  }, [])

  if (!section.baseInfo?.requestInfo || !extraData.success || sectionClose) {
    return null
  }

  return (
    <HomeSectionBase
      sectionBase={section}
      ImpressionProps={{
        scrollContainerRef,
        onImpression: handleImpression,
        threshold: 0.7,
      }}
      onMoreClick={handleMoreClick}>
      <CustomSection
        onItemClick={handleItemClick}
        onListSwipe={handleListSwipe}
        onSectionClose={handleSectionClose}
        sectionInfo={sectionInfo}
        requestInfoRef={section.baseInfo.requestInfo}
        customTypeRef={section.baseInfo?.requestInfo}
      />
    </HomeSectionBase>
  )
}

export default HomeCustomSection
