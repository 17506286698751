import { type FCWithChildren } from 'react'
import { graphql, useFragment } from 'react-relay'

import { type HomeSectionBase_sectionBase$key } from '../../../../__relay__/HomeSectionBase_sectionBase.graphql'
import { useSectionBase } from '../../../../hooks/sections/useSectionBase'
import { type PropsOf } from '../../../../lib/T/utilTypes'
import type Impression from '../../../_lib/Impression'
import Section from '../../lib/Section'
import type SectionBottomButton from '../../lib/SectionBottomButton'
import type SectionTitle from '../../lib/SectionTitle/SectionTitle'

type PropsOfNoneChildren<T extends React.JSXElementConstructor<any>> = Omit<PropsOf<T>, 'children'>

interface HomeSectionBaseProps {
  sectionBase: HomeSectionBase_sectionBase$key
  ImpressionProps: PropsOfNoneChildren<typeof Impression>
  SectionTitleProps?: Partial<PropsOfNoneChildren<typeof SectionTitle>>
  SectionBottomButtonProps?: PropsOfNoneChildren<typeof SectionBottomButton>
  onMoreClick?: Parameters<typeof useSectionBase>[0]['onMoreClick']
  [key: string]: any
}

const HomeSectionBase: FCWithChildren<HomeSectionBaseProps> = ({
  ImpressionProps,
  SectionTitleProps,
  SectionBottomButtonProps,
  onMoreClick,
  children,
  ...props
}) => {
  const sectionBase = useFragment(
    graphql`
      fragment HomeSectionBase_sectionBase on SectionBaseInfo {
        ...useSectionBase_sectionBase
      }
    `,
    props.sectionBase
  )

  const { SectionTitlePropsWithBaseInfo, SectionBottomButtonPropsWithBaseInfo } = useSectionBase({
    sectionBase,
    SectionTitleProps,
    SectionBottomButtonProps,
    onMoreClick,
  })

  return (
    <Section
      ImpressionProps={ImpressionProps}
      SectionTitleProps={SectionTitlePropsWithBaseInfo}
      SectionBottomButtonProps={SectionBottomButtonPropsWithBaseInfo}
      {...props}>
      {children}
    </Section>
  )
}

export default HomeSectionBase
