import { usePullToRefreshScrollElement } from '@daangn/karrot-clothes/lib/pullToRefresh'
import styled from '@emotion/styled'
import React, { useCallback, useRef } from 'react'
import { graphql, useFragment } from 'react-relay'

import HomeReview from './HomeReview'
import { type HomeReviews_sectionBestBizAccount$key } from '../../../../__relay__/HomeReviews_sectionBestBizAccount.graphql'
import { useSwipeSection } from '../../../../hooks/sections/useSwipeSection'
import { useLogger } from '../../../../hooks/useLogger'
import type { SectionInfo } from '../../../../types'
import { ScrollableX } from '../../../_lib/Scrollable'
import HomeSectionBase from '../Common/HomeSectionBase'

interface HomeReviewsProps {
  sectionBestBizAccount: HomeReviews_sectionBestBizAccount$key
  sectionInfo: SectionInfo
}
const HomeReviews: React.FC<HomeReviewsProps> = ({ sectionInfo, ...props }) => {
  const scrollContainerRef = usePullToRefreshScrollElement()
  const itemScrollContainerRef = useRef<HTMLDivElement>(null)

  const { nearbyLog, loggingMetaLog } = useLogger()

  const sectionBestBizAccount = useFragment(
    graphql`
      fragment HomeReviews_sectionBestBizAccount on SectionBestBizAccount {
        bestBizAccountReviews {
          id
          bizAccount {
            images {
              url
            }
          }
          ...HomeReview_bizAccountReview
        }
        loggingMeta {
          swipe
          click
          show
        }
        ...HomeSectionBase_sectionBase
      }
    `,
    props.sectionBestBizAccount
  )

  const isReviewVisible =
    sectionBestBizAccount.bestBizAccountReviews.filter(
      (review) => !!review.bizAccount?.images && review.bizAccount.images.length > 0
    ).length > 0

  const handleImpression = () => {
    nearbyLog({
      params: {
        name: 'show_contents_section',
        contents_type: 'review',
        sectionOrder: sectionInfo.order,
      },
    })

    if (sectionInfo.order === 1) {
      loggingMetaLog(sectionBestBizAccount.loggingMeta.show)
    }
  }

  useSwipeSection({
    swipeContainerRef: itemScrollContainerRef,
    sectionOrder: sectionInfo.order,
  })

  const handleSwipeItem = useCallback(
    (itemOrder: number) => {
      if (itemOrder === 0) {
        return
      }

      nearbyLog({
        params: {
          name: 'swipe_contents_section',
          contentsType: 'review',
          sectionOrder: sectionInfo.order,
          itemOrder,
        },
      })

      loggingMetaLog(sectionBestBizAccount.loggingMeta.swipe)
    },
    [nearbyLog, loggingMetaLog, sectionInfo.order, sectionBestBizAccount.loggingMeta.swipe]
  )

  if (!isReviewVisible) {
    return null
  }

  return (
    <HomeSectionBase
      sectionBase={sectionBestBizAccount}
      ImpressionProps={{
        scrollContainerRef: scrollContainerRef,
        onImpression: handleImpression,
        threshold: 0.9,
      }}
      ServiceTitleProps={{
        serviceType: 'BIZ_STORE',
      }}
      SectionTitleProps={{
        infoButton: {
          content: (
            <div>
              최신성, 품질, 조회수 등을 <br />
              점수화하여 높은 순으로 <br />
              정렬해요.
            </div>
          ),
          placeholderPosition: 'middle',
        },
      }}>
      <Scrollable ref={itemScrollContainerRef}>
        {sectionBestBizAccount.bestBizAccountReviews
          .filter((review) => review.bizAccount && review.bizAccount.images && review.bizAccount.images.length > 0)
          .map((review, index) => (
            <HomeReview
              key={review.id}
              itemOrder={index}
              bizAccountReview={review}
              sectionOrder={sectionInfo.order}
              sectionLoggingMeta={sectionBestBizAccount.loggingMeta}
              itemScrollContainerRef={itemScrollContainerRef}
              onImpression={handleSwipeItem}
            />
          ))}
      </Scrollable>
    </HomeSectionBase>
  )
}

const Scrollable = styled(ScrollableX)`
  padding: 0.375rem 1rem 1rem;
`

export default HomeReviews
