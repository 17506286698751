import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { type FC, useRef } from 'react'
import { graphql, useFragment } from 'react-relay'
import { CSSTransition } from 'react-transition-group'

import IcAdd from '@src/assets/images/icons/IcAdd'

import WriteBizPost from './WriteBizPost'
import WriteLaunches from './WriteLaunches'
import { type HomeWriteFloatingActionButton_query$key } from '../../../../__relay__/HomeWriteFloatingActionButton_query.graphql'
import IcClose from '../../../../assets/images/icons/icon_close.svg'
import { FAB_WRITE_CLOSE_EVENT } from '../../../../constants'
import { useLogger } from '../../../../hooks/useLogger'
import { useOpenTarget } from '../../../../lib/target'

interface HomeWriteFloatingActionButtonProps {
  query: HomeWriteFloatingActionButton_query$key
  openModal: boolean
  onModalOpenChange: (isOpen: boolean) => void
}
const closeEventName = FAB_WRITE_CLOSE_EVENT

const HomeWriteFloatingActionButton: FC<HomeWriteFloatingActionButtonProps> = ({
  openModal,
  onModalOpenChange,
  ...props
}) => {
  const writeModalRef = useRef<HTMLDivElement>(null)
  const { log } = useLogger()
  const { openTarget } = useOpenTarget()

  const query = useFragment(
    graphql`
      fragment HomeWriteFloatingActionButton_query on Query @argumentDefinitions(regionId: { type: "Int!" }) {
        ...WriteLaunches_query @arguments(regionId: $regionId)

        viewer {
          owningBizAccounts {
            id
          }
        }
      }
    `,
    props.query
  )

  const handleWriteFABClick = () => {
    log({
      name: 'nearby_event',
      params: {
        name: 'click_fab_write_button',
      },
    })

    onModalOpenChange(!openModal)
  }

  const hasBizAccount = query.viewer ? query.viewer.owningBizAccounts.length > 0 : false

  return (
    <>
      {/* @ts-ignore */}
      <CSSTransition nodeRef={writeModalRef} in={openModal} timeout={200} mountOnEnter unmountOnExit>
        <>
          <Modal ref={writeModalRef}>
            {hasBizAccount && (
              <WriteBizPost
                onClick={(target) => {
                  log({
                    name: 'nearby_event',
                    params: {
                      name: 'click_fab_write',
                      service_id: 'BIZ_POST',
                    },
                  })

                  console.info(
                    `%c write open target : ${JSON.stringify({
                      ...target,
                      additionalQueryParams: {
                        e: closeEventName,
                        entry: 'write_from_nearby',
                      },
                    })}`,
                    'background: orange; color: white'
                  )

                  openTarget({
                    ...target,
                    additionalQueryParams: {
                      e: closeEventName,
                      entry: 'write_from_nearby',
                    },
                  })
                }}
              />
            )}
            <WriteLaunches
              query={query}
              onLaunchClick={(target, { launch }) => {
                log({
                  name: 'nearby_event',
                  params: {
                    name: 'click_fab_write',
                    write_launch_id: launch.externalId,
                    service_id: launch.externalLaunchServiceId,
                  },
                })

                console.info(
                  `%c write open target : ${JSON.stringify({
                    ...target,
                    additionalQueryParams: {
                      e: closeEventName,
                      referrer: 'nearby-fab',
                    },
                  })}`,
                  'background: orange; color: white'
                )

                openTarget({
                  ...target,
                  additionalQueryParams: {
                    e: closeEventName,
                    referrer: 'nearby-fab',
                  },
                })
              }}
            />
          </Modal>
          <Dim onClick={() => onModalOpenChange(false)} />
        </>
      </CSSTransition>

      <Container active={openModal} onClick={handleWriteFABClick}>
        {openModal ? <IcClose /> : <IcAdd />}
      </Container>
    </>
  )
}

export default HomeWriteFloatingActionButton

const Container = styled.div<{ active: boolean }>`
  position: fixed;
  bottom: 0.5rem;
  right: 1rem;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  background-color: ${vars.$scale.color.carrot500};
  filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.1));

  ${(props) =>
    props.active &&
    css`
      background-color: ${vars.$semantic.color.paperFloating};

      svg {
        path {
          fill: ${vars.$scale.color.gray900};
        }
      }
    `}
`

const Modal = styled.div`
  position: fixed;
  right: 1.25rem;
  bottom: 4.5rem;
  padding: 1rem 1.375rem 1rem 1rem;
  min-width: 11.25rem;
  background-color: ${vars.$semantic.color.paperFloating};
  border-radius: 0.875rem;
  opacity: 0;
  z-index: 3;
  transition: opacity 300ms;
  box-sizing: border-box;

  &.enter-active {
    opacity: 1;
  }

  &.enter-done {
    opacity: 1;
  }
`

const Dim = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
  width: 100vw;
  height: 100vh;
  background-color: ${vars.$semantic.color.overlayLow};
  opacity: 0;
  transition: opacity 300ms;

  ${Modal}.enter-active + & {
    opacity: 1;
  }
  ${Modal}.enter-done + & {
    opacity: 1;
  }

  z-index: 2;
`
