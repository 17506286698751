import { BottomSheet } from '@daangn/karrot-clothes'
import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import React from 'react'

import { activeEffect } from '../../../components/_lib/styles'

export interface Sheet {
  name: React.ReactNode
  onClick: () => void
  color?: string
}

interface Props {
  isOpen: boolean
  sheets: Sheet[]
  onClickOverlay: () => void
}

const BottomSheetModalComponent: React.FC<Props> = ({ isOpen, sheets, onClickOverlay }) => {
  return (
    <BottomSheet open={isOpen} onClose={onClickOverlay} zIndex={9000}>
      <SheetItemList>
        {sheets.map(({ name, color, onClick }, idx) => (
          <SheetItem key={idx} onClick={onClick}>
            <SheetItemText color={color}>{name}</SheetItemText>
          </SheetItem>
        ))}
      </SheetItemList>
    </BottomSheet>
  )
}

export default BottomSheetModalComponent

const SheetItemList = styled.ul`
  margin: 0;
  padding-left: 0;
  padding-bottom: env(safe-area-inset-bottom);
  list-style: none;
  background: ${vars.$semantic.color.paperDefault};
`

const SheetItem = styled.li`
  & + & {
    border-top: 1px solid ${vars.$semantic.color.divider1};
  }

  ${activeEffect()};
`
const SheetItemText = styled.p<{ color?: string }>`
  margin: 0;
  min-height: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: center;
  letter-spacing: -0.02em;
  color: ${({ color }) => (color ? color : 'inherit')};
  cursor: pointer;
`
