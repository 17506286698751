import { atom } from 'recoil'

import recoilKeyGenerator from '@src/lib/recoil-util'
import { nearbyStorage } from '@src/lib/storage/storage'
import syncWithStorage from '@src/store/effects/syncWithStorage'

const syncWithCommonStorage = syncWithStorage(nearbyStorage)

const generateKey = recoilKeyGenerator('component#sectionTitle')

/**
 * BizWrite Nudging ToolTip을 보여줄지 여부
 */
export const isViewBizWriteNudgingTooltipAtom = atom({
  key: generateKey('isViewBizWriteNudgingTooltip'),
  default: false,
  effects: [
    syncWithCommonStorage({
      key: 'isViewBizWriteNudgingTooltip',
    }),
  ],
})

/**
 * 현재 BizWrite Nudging Tooltip이 보여지고있는지 여부
 */
export const isCurrentViewingBizWriteNudgingTooltipAtom = atom({
  key: generateKey('isCurrentViewingBizWriteNudgingTooltip'),
  default: false,
})
