/**
 * @generated SignedSource<<955cda6d56ce1f10f040bb9c7ba6c002>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type PopupErrorButtonVariant = "PRIMARY" | "SECONDARY";
export type ClaimToCouponBoxInput = {
  couponBoxId: string;
};
export type useCouponMutations_ClaimCouponMutation$variables = {
  input: ClaimToCouponBoxInput;
};
export type useCouponMutations_ClaimCouponMutation$data = {
  readonly claimToCouponBox: {
    readonly __typename: "ClaimToCouponBoxOutput_Result";
    readonly result: {
      readonly couponBox: {
        readonly claimedUsers: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly id: string;
              readonly image: {
                readonly thumbnail: string;
              };
            };
          }>;
        };
        readonly remainCount: number | null;
        readonly totalClaimedUserCount: number;
        readonly viewerHasClaimed: boolean;
      };
    };
  } | {
    readonly __typename: "PopupError";
    readonly popupError: {
      readonly buttons: ReadonlyArray<{
        readonly redirectTargetUri: string | null;
        readonly text: string;
        readonly variant: PopupErrorButtonVariant;
      }>;
      readonly message: string;
    };
  } | {
    readonly __typename: "ToastError";
    readonly toastError: {
      readonly message: string;
    };
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
};
export type useCouponMutations_ClaimCouponMutation$rawResponse = {
  readonly claimToCouponBox: {
    readonly __typename: "ClaimToCouponBoxOutput_Result";
    readonly result: {
      readonly couponBox: {
        readonly claimedUsers: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly id: string;
              readonly image: {
                readonly id: string;
                readonly thumbnail: string;
              };
            };
          }>;
        };
        readonly id: string;
        readonly remainCount: number | null;
        readonly totalClaimedUserCount: number;
        readonly viewerHasClaimed: boolean;
      };
    };
  } | {
    readonly __typename: "PopupError";
    readonly popupError: {
      readonly buttons: ReadonlyArray<{
        readonly redirectTargetUri: string | null;
        readonly text: string;
        readonly variant: PopupErrorButtonVariant;
      }>;
      readonly message: string;
    };
  } | {
    readonly __typename: "ToastError";
    readonly toastError: {
      readonly message: string;
    };
  } | {
    readonly __typename: string;
  };
};
export type useCouponMutations_ClaimCouponMutation = {
  rawResponse: useCouponMutations_ClaimCouponMutation$rawResponse;
  response: useCouponMutations_ClaimCouponMutation$data;
  variables: useCouponMutations_ClaimCouponMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "viewerHasClaimed",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "remainCount",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalClaimedUserCount",
  "storageKey": null
},
v6 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 3
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "thumbnail",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
},
v10 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ToastErrorPayload",
      "kind": "LinkedField",
      "name": "toastError",
      "plural": false,
      "selections": [
        (v9/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "ToastError",
  "abstractKey": null
},
v11 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "PopupErrorPayload",
      "kind": "LinkedField",
      "name": "popupError",
      "plural": false,
      "selections": [
        (v9/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "PopupErrorButton",
          "kind": "LinkedField",
          "name": "buttons",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "variant",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "text",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "redirectTargetUri",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "PopupError",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useCouponMutations_ClaimCouponMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "claimToCouponBox",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ClaimToCouponBoxOutput_ResultPayload",
                "kind": "LinkedField",
                "name": "result",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CouponBox",
                    "kind": "LinkedField",
                    "name": "couponBox",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": (v6/*: any*/),
                        "concreteType": "ClaimedUserConnection",
                        "kind": "LinkedField",
                        "name": "claimedUsers",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ClaimedUserEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "HoianUser",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v7/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Image",
                                    "kind": "LinkedField",
                                    "name": "image",
                                    "plural": false,
                                    "selections": [
                                      (v8/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": "claimedUsers(first:3)"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "ClaimToCouponBoxOutput_Result",
            "abstractKey": null
          },
          (v10/*: any*/),
          (v11/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useCouponMutations_ClaimCouponMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "claimToCouponBox",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ClaimToCouponBoxOutput_ResultPayload",
                "kind": "LinkedField",
                "name": "result",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CouponBox",
                    "kind": "LinkedField",
                    "name": "couponBox",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": (v6/*: any*/),
                        "concreteType": "ClaimedUserConnection",
                        "kind": "LinkedField",
                        "name": "claimedUsers",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ClaimedUserEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "HoianUser",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v7/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Image",
                                    "kind": "LinkedField",
                                    "name": "image",
                                    "plural": false,
                                    "selections": [
                                      (v8/*: any*/),
                                      (v7/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": "claimedUsers(first:3)"
                      },
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "ClaimToCouponBoxOutput_Result",
            "abstractKey": null
          },
          (v10/*: any*/),
          (v11/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b61e8a3e3c779dbc717e9428fa89e059",
    "id": null,
    "metadata": {},
    "name": "useCouponMutations_ClaimCouponMutation",
    "operationKind": "mutation",
    "text": "mutation useCouponMutations_ClaimCouponMutation(\n  $input: ClaimToCouponBoxInput!\n) {\n  claimToCouponBox(input: $input) {\n    __typename\n    ... on ClaimToCouponBoxOutput_Result {\n      result {\n        couponBox {\n          viewerHasClaimed\n          remainCount\n          totalClaimedUserCount\n          claimedUsers(first: 3) {\n            edges {\n              node {\n                id\n                image {\n                  thumbnail\n                  id\n                }\n              }\n            }\n          }\n          id\n        }\n      }\n    }\n    ... on ToastError {\n      toastError {\n        message\n      }\n    }\n    ... on PopupError {\n      popupError {\n        message\n        buttons {\n          variant\n          text\n          redirectTargetUri\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "cc87b8f21b653a74d0cd16578aee44e4";

export default node;
