import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import React, { useCallback } from 'react'
import { graphql, useFragment } from 'react-relay'

import { type PropOf } from '@src/lib/T/utilTypes'

import { type HomeMapsTabListItem_poiTag$key } from '../../../../__relay__/HomeMapsTabListItem_poiTag.graphql'
import BannerPoi from '../../../../features/poi/components/BannerPoi'
import { useLogger } from '../../../../hooks/useLogger'
import { useOpenTarget } from '../../../../lib/target'
import { type PoiTabCategoryEnum } from '../../../../types/schemaEnums'
import { Avatar } from '../../../_lib/Avatar'
import { Flex, FlexAlignCenter, getEllipsisStyle, ListItemDivider } from '../../../_lib/styles'

interface HomeMapsTabListItemProps {
  poiTag: HomeMapsTabListItem_poiTag$key
  itemOrder: number
  sectionOrder: number
  activeTabCategory: {
    activeTabKey: PoiTabCategoryEnum
    activeTabLabel: string
  }
  onItemClick: () => void
  isViewAlphabet: boolean
}
const HomeMapsTabListItem: React.FC<HomeMapsTabListItemProps> = ({
  activeTabCategory,
  itemOrder,
  sectionOrder,
  onItemClick,
  isViewAlphabet,
  ...props
}) => {
  const { contentsSectionLog } = useLogger()
  const { openTarget } = useOpenTarget()

  const { activeTabKey, activeTabLabel } = activeTabCategory

  const poiTag = useFragment(
    graphql`
      fragment HomeMapsTabListItem_poiTag on PoiTag {
        poi {
          ...BannerPoi_poi
        }
        taggedBy {
          ... on StoryArticle {
            __typename
            externalId
            content
            author {
              nickname
              image {
                thumbnail
              }
              region {
                name3
              }
              checkinCount
            }
            firstImage {
              thumbnail
            }
            images {
              id
            }
          }
        }
        targetUri
      }
    `,
    props.poiTag
  )

  const handleTaggingMapPoiTagClick = () => {
    if (!poiTag.targetUri) {
      return
    }

    switch (poiTag.taggedBy.__typename) {
      case 'StoryArticle': {
        contentsSectionLog('click', {
          contentsType: 'map',
          buttonType: 'list',
          params: {
            story_article_id: poiTag.taggedBy.externalId,
            item_type: 'story_article',
            categoryType: activeTabKey,
            categoryName: activeTabLabel,
            sectionOrder: sectionOrder,
          },
        })

        onItemClick()

        return openTarget({
          targetUri: poiTag.targetUri,
        })
      }
      default: {
        return
      }
    }
  }

  const handlePoiWatchClick: NonNullable<PropOf<typeof BannerPoi, 'onWatchClick'>> = useCallback(
    (poi, viewerIsWatched) => {
      if (!poi) {
        return
      }

      const { externalId } = poi

      contentsSectionLog('click', {
        contentsType: 'map',
        buttonType: 'watch',
        params: {
          poi_id: externalId,
          categoryType: activeTabKey,
          categoryName: activeTabLabel,
          sectionOrder: sectionOrder,
          is_watched: !viewerIsWatched,
        },
      })
    },
    [activeTabKey, activeTabLabel, contentsSectionLog, sectionOrder]
  )

  const handlePoiClick: NonNullable<PropOf<typeof BannerPoi, 'onClick'>> = useCallback(
    (poi) => {
      if (!poi) {
        return
      }

      const { externalId, targetUri } = poi

      contentsSectionLog('click', {
        contentsType: 'map',
        buttonType: 'list',
        params: {
          poi_id: externalId,
          categoryType: activeTabKey,
          categoryName: activeTabLabel,
          sectionOrder: sectionOrder,
          item_type: 'poi',
        },
      })

      if (targetUri) {
        openTarget({
          targetUri,
        })
      }
    },
    [activeTabKey, activeTabLabel, contentsSectionLog, openTarget, sectionOrder]
  )

  const { taggedBy: storyArticle } = poiTag

  if (storyArticle.__typename !== 'StoryArticle') {
    return null
  }

  const checkinCountText = storyArticle.author
    ? storyArticle.author.checkinCount > 0 && storyArticle.author?.region?.name3
      ? `${storyArticle.author?.region.name3} 인증 ${storyArticle.author?.checkinCount}회`
      : storyArticle.author?.region?.name3 ?? ''
    : ''

  return (
    <Container onClick={handleTaggingMapPoiTagClick}>
      <StoryInfo>
        <Author>
          <Avatar
            size="xxsmall"
            src={storyArticle.author.image.thumbnail}
            alt={storyArticle.author.nickname}
            type="User"
          />
          <AuthorName>{storyArticle.author.nickname}</AuthorName>
          <AuthorCheckinCount>{`${checkinCountText}`}</AuthorCheckinCount>
        </Author>
        <StoryContentWrapper>
          <StoryContent>{storyArticle.content}</StoryContent>
        </StoryContentWrapper>
      </StoryInfo>
      <BannerPoi
        poiRef={poiTag.poi}
        replaceImageUrl={storyArticle.firstImage?.thumbnail}
        onClick={handlePoiClick}
        onWatchClick={handlePoiWatchClick}
      />
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  padding: 1.25rem 1rem;
  ${ListItemDivider};
  color: ${vars.$scale.color.gray900};

  & > *:not(:last-child) {
    margin-bottom: 0.75rem;
  }
`

const StoryInfo = styled.div`
  ${Flex};
  flex-direction: column;
  & > *:not(:last-child) {
    margin-bottom: 0.5rem;
  }
`

const Author = styled.div`
  ${FlexAlignCenter};

  & > :nth-of-type(1) {
    margin-right: 0.375rem;
  }

  & > :nth-of-type(2) {
    margin-right: 0.375rem;
  }
`

const AuthorName = styled.div`
  ${getEllipsisStyle(1)};
  ${vars.$semantic.typography.label3Bold};
`

const AuthorCheckinCount = styled.div`
  flex-shrink: 0;
  color: ${vars.$scale.color.gray600};
  ${vars.$semantic.typography.caption2Regular};
`

const StoryContentWrapper = styled.div`
  ${FlexAlignCenter};
  min-height: 2.688rem;
`

const StoryContent = styled.p`
  margin: 0;
  ${vars.$semantic.typography.bodyM1Regular};
  ${getEllipsisStyle(2)};
`

export default HomeMapsTabListItem
