import { atom } from 'recoil'

import { type BridgeAppInfo } from '@src/bridgeInfo'

import { generateGlobalStateKey } from './key'

export type OS = 'IOS' | 'ANDROID' | 'UNKNOWN'

export type AppInfo = BridgeAppInfo & {
  os: OS
  version: string
}

/**
 * 현재 앱 정보
 */
export const appAtom = atom<AppInfo>({
  key: generateGlobalStateKey('app'),
  default: {
    os: 'UNKNOWN',
    version: '0.0.0',
  } as any,
})
