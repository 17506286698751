import { usePullToRefreshScrollElement } from '@daangn/karrot-clothes/lib/pullToRefresh'
import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { IconChevronRightFill } from '@seed-design/icon'
import { isAfter, isBefore } from 'date-fns'
import React, { useState, type FC } from 'react'
import { useRecoilValue } from 'recoil'

import Impression from '@src/components/_lib/Impression'
import { FlexAlignCenter, getEllipsisStyle } from '@src/components/_lib/styles'
import { IS_PRODUCTION } from '@src/constants'
import { regionAtom } from '@src/features/user/state/region'
import { useLogger } from '@src/hooks/useLogger'
import { useOpenTarget } from '@src/lib/target'

import BlueBellImg from './blue-bell.svg'

type EVENT_BANNER_TYPE = {
  title?: string
  text: string
  img: string
  scheme: string
  contentType: string
  backgroundColor: string
  fontColor: string
  startDate: string
  endDate: string
  padding?: string
  region2Ids: number[]
  region3Ids: number[]
}

const EVENT_BANNER_DATA: EVENT_BANNER_TYPE[] = [
  {
    title: "'내 근처'가 '동네지도'로 새로워져요!",
    text: '이제 동네지도에서 자주 가는 동네 업체를\n찾아볼 수 있어요',
    img: '',
    scheme:
      'karrot://minikarrot/router?remote=https%3A%2F%2Fwww.daangn.com%2Fwv%2Fnotices%2F8900&navbar=true&scrollable=true',
    contentType: `nearby-close`,
    backgroundColor: vars.$scale.color.blueAlpha50,
    fontColor: vars.$scale.color.blue950,
    startDate: '2024-09-10T17:00',
    endDate: '9999-01-31T23:50',
    region2Ids: [0],
    region3Ids: [],
  },
]

const EventBanner: FC<{ padding?: string }> = ({ padding }) => {
  const { openTarget } = useOpenTarget()
  const { nearbyLog } = useLogger()
  const scrollContainerRef = usePullToRefreshScrollElement()
  const region = useRecoilValue(regionAtom)

  const currentBanners = EVENT_BANNER_DATA.filter(
    (banner) =>
      banner.region2Ids[0] === 0 || banner.region2Ids.includes(region.name2Id) || banner.region3Ids.includes(region.id)
  )

  const [randomNumber] = useState(Math.floor(Math.random() * currentBanners.length))

  if (currentBanners.length === 0) {
    return null
  }

  const eventBanner = currentBanners[randomNumber]

  const curDate = new Date()

  const isNotEventPeriod =
    (IS_PRODUCTION && isBefore(curDate, new Date(eventBanner.startDate))) ||
    isAfter(curDate, new Date(eventBanner.endDate))

  const splitText = eventBanner.text.split('\n')

  const Text =
    splitText.length > 1 ? (
      <>
        {splitText.map((text) => (
          <div key={text}>{text}</div>
        ))}
      </>
    ) : (
      <TextLine2>{eventBanner.text}</TextLine2>
    )

  const handleImpression = () => {
    nearbyLog({
      params: {
        name: `show_banner_section`,
        bannerType: eventBanner.contentType,
      },
    })
  }

  const handleClickBanner = () => {
    nearbyLog({
      params: {
        name: `click_banner_section`,
        bannerType: eventBanner.contentType,
      },
    })

    openTarget({
      targetUri: eventBanner.scheme,
    })
  }

  if (isNotEventPeriod || currentBanners.length === 0) {
    return null
  }

  return (
    <Impression scrollContainerRef={scrollContainerRef} onImpression={handleImpression} threshold={1}>
      <S_EventBanner padding={padding}>
        <Banner
          onClick={handleClickBanner}
          backgroundColor={eventBanner.backgroundColor}
          fontColor={eventBanner.fontColor}>
          <Left>
            <ImageWrapper padding={eventBanner.padding}>
              <BlueBellImg />
            </ImageWrapper>
          </Left>
          <Middle>
            <Title>{eventBanner.title}</Title>
            <Texts>{Text}</Texts>
          </Middle>
          <Right>
            <IconChevronRightFill width={16} height={16} />
          </Right>
        </Banner>
      </S_EventBanner>
    </Impression>
  )
}

export default React.memo(EventBanner)

const S_EventBanner = styled.div<{ padding?: string }>`
  transition: padding 0.25s ease-in-out;
  padding: ${({ padding }) => padding};
`

const Middle = styled.div`
  flex: 1;
  overflow: hidden;
`

const Left = styled.div`
  margin-right: 0.63rem;
  box-sizing: border-box;
`

const Banner = styled.div<{ backgroundColor: string; fontColor: string }>`
  display: flex;
  justify-content: space-around;
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ fontColor }) => fontColor};
  border-radius: 10px;
  box-sizing: border-box;
  overflow: hidden;
  padding: 0.88rem;
  @media only screen and (max-width: 356px) {
    height: auto;
  }
`

const Title = styled.h2`
  ${vars.$semantic.typography.subtitle2Bold};
  padding: 0;
  margin: 0;
  margin-bottom: 2px;
  ${getEllipsisStyle(1)};
`

const Texts = styled.div`
  ${vars.$semantic.typography.bodyM2Regular};
  word-break: keep-all;
`

const TextLine2 = styled.div`
  ${getEllipsisStyle(2)};
  word-break: keep-all;
`

const Right = styled.div`
  display: flex;
  align-items: center;
`

const ImageWrapper = styled.div<{ padding?: string }>`
  ${FlexAlignCenter};
  position: relative;
  padding: ${({ padding }) => padding ?? 'auto'};
  height: 100%;
  width: 100%;
`
