import React, { useEffect } from 'react'
import { graphql, useFragment } from 'react-relay'

import {
  type HomePurposeLaunchCategory_purposeCategory$key,
  type HomePurposeLaunchCategory_purposeCategory$data,
} from '@src/__relay__/HomePurposeLaunchCategory_purposeCategory.graphql'
import { LOCAL_WEBVIEW_DOMAIN } from '@src/constants'
import { useSpotlightReferrer } from '@src/features/spotlight/hooks/useSpotlightReferrer'
import { type UpdateReferrer } from '@src/lib/target'
import { karrotBridge } from '@src/sdk'

import PurposeLaunchpadItem from './PurposeLaunchpadItem'

interface HomeLaunchAppProps {
  purposeCategory: HomePurposeLaunchCategory_purposeCategory$key
  itemOrder: number
  onClick: (
    launchCategory: HomePurposeLaunchCategory_purposeCategory$data,
    purposeLaunchpadItemId: string,
    itemOrder: number,
    updateReferrer?: UpdateReferrer
  ) => void
}
const HomePurposeLaunchCategory: React.FC<HomeLaunchAppProps> = (props) => {
  const purposeCategory = useFragment(
    graphql`
      fragment HomePurposeLaunchCategory_purposeCategory on PurposeCategory {
        id
        externalId
        name
        targetUri(referrer: "nearby_directory")
        isNew
        app
        iconUrl
        isVerticalService
      }
    `,
    props.purposeCategory
  )

  const purposeLaunchpadItemId = `PurposeCategory_${purposeCategory.externalId}`

  const { updateReferrer } = useSpotlightReferrer({
    checkId: purposeLaunchpadItemId,
  })

  useEffect(() => {
    if (purposeCategory.app && purposeCategory.app.includes(LOCAL_WEBVIEW_DOMAIN)) {
      karrotBridge.prefetchRouter({
        router: {
          app: purposeCategory.app,
        },
      })
    }
  }, [purposeCategory.app])

  const handleClick = () => {
    props.onClick(purposeCategory, purposeLaunchpadItemId, props.itemOrder, updateReferrer)
  }

  return (
    <PurposeLaunchpadItem
      id={purposeLaunchpadItemId}
      onClick={handleClick}
      iconUrl={purposeCategory.iconUrl}
      name={purposeCategory.name}
      isNew={purposeCategory.isNew}
    />
  )
}

export default HomePurposeLaunchCategory
