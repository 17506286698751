import styled from '@emotion/styled'
import {
  createContext,
  type Dispatch,
  type FC,
  type SetStateAction,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react'

import { useIntersectionObserver } from '../../hooks/useIntersectionObserver'

const PageScrollTopChecker: FC<{ onChange?: (visible: boolean) => void }> = ({ onChange }) => {
  const checkerElementRef = useRef<HTMLDivElement>(null)
  const { setPageScrollTopState } = usePageScrollTopChecker()

  useIntersectionObserver({
    onChangeVisibility: ({ isIntersecting }) => {
      setPageScrollTopState(isIntersecting)
      onChange?.(isIntersecting)
    },
    targetElementRef: checkerElementRef,
    options: {
      threshold: 1,
    },
  })
  return <PageTopChecker ref={checkerElementRef} />
}

export default PageScrollTopChecker

const PageTopChecker = styled.div``

const PageTopCheckerContext = createContext<{
  pageScrollTopState: boolean
  setPageScrollTopState: Dispatch<SetStateAction<boolean>>
}>({
  pageScrollTopState: false,
  setPageScrollTopState: null as any,
})

export const PageTopCheckerProvider: React.FCWithChildren = ({ children }) => {
  const [pageScrollTopState, setPageScrollTopState] = useState(false)

  const value = useMemo(
    () => ({
      pageScrollTopState,
      setPageScrollTopState,
    }),
    [pageScrollTopState]
  )

  return <PageTopCheckerContext.Provider value={value}>{children}</PageTopCheckerContext.Provider>
}

export const usePageScrollTopChecker = () => {
  return useContext(PageTopCheckerContext)
}
