import { useCallback } from 'react'
import { useMutation } from 'react-relay'
import { graphql } from 'relay-runtime'

import { type useUpdateBizStoreWatchMutation } from '@src/__relay__/useUpdateBizStoreWatchMutation.graphql'
import { captureException } from '@src/lib/capture-exception'

import { type MutationHookProps } from './types'

export const useUpdateBizStoreWatch = () => {
  const [commit] = useMutation<useUpdateBizStoreWatchMutation>(graphql`
    mutation useUpdateBizStoreWatchMutation($input: UpdateBizStoreWatchInput!) {
      updateBizStoreWatch(input: $input) {
        ... on UpdateBizStoreWatchResult {
          bizStore {
            ... on BizAccount {
              externalId
              id
              viewerIsWatched
            }
            ... on Poi {
              externalId
              id
              viewerIsWatched
            }
          }
        }
        ... on UpdateBizStoreWatchError {
          errorMessage
        }
      }
    }
  `)

  const handleWatchBizStore = useCallback(
    ({ variables, onSuccess, onError }: MutationHookProps<typeof commit>) => {
      commit({
        variables,
        optimisticUpdater: (store) => {
          const state = store.get(variables.input.targetNodeId)

          if (state) {
            state.setValue(variables.input.isWatched, 'viewerIsWatched')
          }
        },
        onCompleted: (result) => {
          if (result.updateBizStoreWatch.bizStore) {
            onSuccess?.(result)
          } else if (result.updateBizStoreWatch.errorMessage) {
            onError?.(result.updateBizStoreWatch.errorMessage)
            captureException(new Error(result.updateBizStoreWatch.errorMessage))
          }
        },
      })
    },
    [commit]
  )

  return {
    onWatch: handleWatchBizStore,
  }
}
