/**
 * @generated SignedSource<<2846c68a5f18d9471120110949e66c26>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type NearbyBadgeEnum = "COUPON_EXISTS" | "DELIVERY" | "FAST_RESPONSE" | "FREE_DELIVERY" | "HAS_TRIAL" | "MOST_REVIEW" | "MOST_VIEW" | "SAME_DAY_DELIVERY" | "WALK_10_MIN";
export type SalaryEnum = "DAILY" | "HOURLY" | "MONTHLY" | "PER_CASE";
import { FragmentRefs } from "relay-runtime";
export type NearbyJobPostItem_jobPost$data = {
  readonly badges: ReadonlyArray<NearbyBadgeEnum>;
  readonly companyName: string | null;
  readonly distance: {
    readonly distance: number;
    readonly name: string;
  } | null;
  readonly externalId: string;
  readonly image: {
    readonly thumbnail: string;
  } | null;
  readonly salary: number;
  readonly salaryType: SalaryEnum;
  readonly targetUri: string;
  readonly title: string;
  readonly " $fragmentSpreads": FragmentRefs<"Badges_badges">;
  readonly " $fragmentType": "NearbyJobPostItem_jobPost";
};
export type NearbyJobPostItem_jobPost$key = {
  readonly " $data"?: NearbyJobPostItem_jobPost$data;
  readonly " $fragmentSpreads": FragmentRefs<"NearbyJobPostItem_jobPost">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NearbyJobPostItem_jobPost",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "externalId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "companyName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "salaryType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "salary",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Image",
      "kind": "LinkedField",
      "name": "image",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "thumbnail",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DistanceInfo",
      "kind": "LinkedField",
      "name": "distance",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "distance",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "targetUri",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "badges",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Badges_badges"
    }
  ],
  "type": "JobPost",
  "abstractKey": null
};

(node as any).hash = "4f8cbbe6472f98b26c0b7757672e015a";

export default node;
