import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { type FC } from 'react'

const USER_POSITION_COLOR = '#2b6eff'

const UserPosition: FC = () => {
  return (
    <S_UserPosition>
      <UserPositionDot />
      <UserPositionBackground />
    </S_UserPosition>
  )
}

export default UserPosition

const S_UserPosition = styled.div`
  @keyframes ping {
    0% {
      transform: scale3d(1, 1, 1);
    }
    25% {
      transform: scale3d(0.7, 0.7, 1);
    }
    50% {
      transform: scale3d(1, 1, 1);
    }
    75% {
      transform: scale3d(0.7, 0.7, 1);
    }
    100% {
      transform: scale3d(1, 1, 1);
    }
  }
`

const UserPositionDot = styled.div`
  position: relative;
  height: 12px;
  width: 12px;
  background-color: ${USER_POSITION_COLOR};
  border-radius: 50%;
  border: solid 2px ${vars.$static.color.staticWhite};
  display: block;
`

const UserPositionBackground = styled.div`
  content: '';
  height: 40px;
  width: 40px;
  /** @todo: seed-design */
  background: rgba(43, 110, 255, 0.25);
  border-radius: 50%;
  position: absolute;
  display: block;
  top: -12px;
  left: -12px;
  animation-duration: 3s;
  animation-name: ping;
`
