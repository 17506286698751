import { usePullToRefreshScrollElement } from '@daangn/karrot-clothes/lib/pullToRefresh'
import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import React, { useCallback, useRef } from 'react'
import { graphql, useFragment, useRelayEnvironment } from 'react-relay'

import IcChevronRightOutlineS from '@src/assets/images/icons/ic_chevron_right_fill.svg'
import { useSpotlightReferrer } from '@src/features/spotlight/hooks/useSpotlightReferrer'

import HomeCoupon, { type HomeCoupon_couponBox$data } from './HomeCoupon'
import { type HomeCoupons_sectionCoupon$key } from '../../../../__relay__/HomeCoupons_sectionCoupon.graphql'
import { BIZ_PLATFORM_APP } from '../../../../constants'
import { useSwipeSection } from '../../../../hooks/sections/useSwipeSection'
import { useBridgeStreamListener } from '../../../../hooks/useBridgeStreamListener'
import { useLogger } from '../../../../hooks/useLogger'
import { type PropOf } from '../../../../lib/T/utilTypes'
import { type Target, useOpenTarget } from '../../../../lib/target'
import type { SectionInfo } from '../../../../types'
import { ScrollableX } from '../../../_lib/Scrollable'
import HomeSectionBase from '../Common/HomeSectionBase'

const REFERRER = 'nearby.contents_section.coupon'

interface HomeCouponsProps {
  sectionCoupon: HomeCoupons_sectionCoupon$key
  sectionInfo: SectionInfo
  onCouponBoxesClick?: (target: Target) => void
}
const HomeCoupons: React.FC<HomeCouponsProps> = ({ sectionInfo, ...props }) => {
  const scrollContainerRef = usePullToRefreshScrollElement()
  const itemScrollContainerRef = useRef<HTMLDivElement>(null)

  const { nearbyLog, localBizLog, contentsSectionLog, loggingMetaLog } = useLogger()
  const { openTarget } = useOpenTarget()
  const environment = useRelayEnvironment()

  const sectionCoupon = useFragment(
    graphql`
      fragment HomeCoupons_sectionCoupon on SectionCoupon {
        couponBoxesTargetUri
        couponBoxes {
          id
          ...HomeCoupon_couponBox
        }
        loggingMeta {
          swipe
          click
          show
        }
        writeBizTargetUri

        ...HomeSectionBase_sectionBase
      }
    `,
    props.sectionCoupon
  )

  const { updateReferrer } = useSpotlightReferrer({
    checkId: sectionInfo.id,
  })

  useBridgeStreamListener('local_business.coupon.claim', ({ couponBoxId }) => {
    environment.commitUpdate((store) => {
      const couponBox = store.get<HomeCoupon_couponBox$data>(`CouponBox#${couponBoxId}`)

      if (couponBox) {
        const prevClaimedUserCount = couponBox.getValue('totalClaimedUserCount')?.valueOf()

        couponBox.setValue(true, 'viewerHasClaimed')
        couponBox.setValue(prevClaimedUserCount + 1, 'totalClaimedUserCount')
      }
    })
  })

  const handleImpression = () => {
    contentsSectionLog('show', {
      contentsType: 'coupon',
      sectionOrder: sectionInfo.order,
    })

    if (sectionInfo.order === 1) {
      loggingMetaLog(sectionCoupon.loggingMeta.show)
    }
  }

  const handleCouponBoxesClick: NonNullable<PropOf<typeof HomeSectionBase, 'onMoreClick'>> = ({ targetUri }) => {
    contentsSectionLog('click', {
      contentsType: 'coupon',
      buttonType: 'more',
      params: {
        name: 'click_coupons_more',
        sectionOrder: sectionInfo.order,
      },
    })

    loggingMetaLog(sectionCoupon.loggingMeta.click)

    if (targetUri) {
      openTarget({
        targetUri,
        updateReferrer,
      })
    }
  }

  const handleMyCouponsClick = useCallback(() => {
    nearbyLog({
      params: {
        name: 'click_my_coupons',
        sectionOrder: sectionInfo.order,
      },
    })

    loggingMetaLog(sectionCoupon.loggingMeta.click)

    openTarget({
      remote: `${BIZ_PLATFORM_APP}/me/coupons`,
      additionalQueryParams: {
        referrer: REFERRER,
      },
      updateReferrer,
    })
  }, [loggingMetaLog, nearbyLog, openTarget, sectionInfo.order, sectionCoupon.loggingMeta.click, updateReferrer])

  useSwipeSection({
    swipeContainerRef: itemScrollContainerRef,
    sectionOrder: sectionInfo.order,
  })

  const handleSwipeItem = useCallback(
    (itemOrder: number, bizId: number, isClaimed: boolean, couponBoxId: string) => {
      if (itemOrder === 0) {
        return
      }

      localBizLog({
        name: 'show_couponCard',
        params: {
          biz_id: bizId,
          biz_post_id: null,
          is_claimed: isClaimed,
          coupon_box_id: couponBoxId,
        },
      })
      nearbyLog({
        params: {
          name: 'swipe_contents_section',
          contentsType: 'coupon',
          sectionOrder: sectionInfo.order,
          itemOrder,
        },
      })

      loggingMetaLog(sectionCoupon.loggingMeta.swipe)
    },
    [localBizLog, nearbyLog, sectionInfo.order, loggingMetaLog, sectionCoupon.loggingMeta.swipe]
  )

  return (
    <HomeSectionBase
      sectionBase={sectionCoupon}
      ImpressionProps={{
        scrollContainerRef: scrollContainerRef,
        onImpression: handleImpression,
        threshold: 0.9,
      }}
      ServiceTitleProps={{
        serviceType: 'COUPON',
      }}
      SectionTitleProps={{
        rightButton: {
          content: (
            <ButtonContentWrapper>
              받은 쿠폰함
              <IconWrapper>
                <IcChevronRightOutlineS />
              </IconWrapper>
            </ButtonContentWrapper>
          ),
          noRightButtonArrow: true,
          onClick: handleMyCouponsClick,
        },
        addPaddingBottom: '6px',
        infoButton: {
          content: (
            <div>
              최신성, 품질, 조회수 등을 <br />
              점수화하여 높은 순으로 <br />
              정렬해요.
            </div>
          ),
          placeholderPosition: 'middle',
        },
      }}
      onMoreClick={handleCouponBoxesClick}>
      <Scrollable ref={itemScrollContainerRef}>
        {sectionCoupon.couponBoxes.map((couponBox, index) => (
          <HomeCoupon
            key={couponBox.id}
            itemOrder={index}
            couponBox={couponBox}
            sectionOrder={sectionInfo.order}
            sectionLoggingMeta={sectionCoupon.loggingMeta}
            itemScrollContainerRef={itemScrollContainerRef}
            onImpression={handleSwipeItem}
            referrer={updateReferrer?.(REFERRER) ?? REFERRER}
          />
        ))}
      </Scrollable>
    </HomeSectionBase>
  )
}

const Scrollable = styled(ScrollableX)`
  display: block;
  padding: 0.375rem 1rem 1rem;

  & > *:not(:last-child) {
    margin-right: 0.5rem;
  }
`

const ButtonContentWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${vars.$scale.color.gray600};
`

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 0.5rem;
  height: 0.75rem;
  margin: 0.063rem 0 0 0.25rem;
  box-sizing: content-box;
`

export default HomeCoupons
