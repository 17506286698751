import { useEffect } from 'react'

export type useScrollProp = {
  onScroll: (scrollContainer: HTMLElement) => void
  targetElement: HTMLElement | null
  disable?: boolean
  deps?: any[]
}

export function useScroll({ onScroll, targetElement, disable, deps = [] }: useScrollProp) {
  useEffect(() => {
    if (disable) return

    if (targetElement) {
      const handleScroll = () => onScroll(targetElement)

      targetElement.addEventListener('scroll', handleScroll)

      return () => {
        targetElement.removeEventListener('scroll', handleScroll)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetElement, onScroll, disable, ...deps])
}
