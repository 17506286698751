import { useCallback, useMemo, useState } from 'react'

import { useNearbyCategoryWrite } from './useNearbyCategoryWrite'

export function useHomeFAB() {
  // todo. Native FAB으로 전환이 완료되면, 지워야 되는 코드.
  const [openFABModal, setOpenFABModal] = useState(false)

  const handleFABModalOpenChange = useCallback((isOpen: boolean) => {
    setOpenFABModal(isOpen)
  }, [])

  const handleFABWriteAfter = useCallback(() => {
    setOpenFABModal(false)
  }, [])

  useNearbyCategoryWrite({
    type: 'FAB',
    onWirteAfter: handleFABWriteAfter, // todo. Native FAB으로 전환이 완료되면, 지워야 되는 코드.
  })

  return useMemo(
    () => ({
      openFABModal,
      handleFABModalOpenChange,
      handleFABWriteAfter,
    }),
    [handleFABModalOpenChange, handleFABWriteAfter, openFABModal]
  )
}
