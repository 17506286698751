/**
 * @generated SignedSource<<ebe6096acff7d05664f08ec314d78b94>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateBizStoreWatchInput = {
  isWatched: boolean;
  targetNodeId: string;
};
export type useUpdateBizStoreWatchMutation$variables = {
  input: UpdateBizStoreWatchInput;
};
export type useUpdateBizStoreWatchMutation$data = {
  readonly updateBizStoreWatch: {
    readonly bizStore?: {
      readonly externalId?: string;
      readonly id?: string;
      readonly viewerIsWatched?: boolean;
    };
    readonly errorMessage?: string;
  };
};
export type useUpdateBizStoreWatchMutation = {
  response: useUpdateBizStoreWatchMutation$data;
  variables: useUpdateBizStoreWatchMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "externalId",
    "storageKey": null
  },
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "viewerIsWatched",
    "storageKey": null
  }
],
v4 = {
  "kind": "InlineFragment",
  "selections": (v3/*: any*/),
  "type": "BizAccount",
  "abstractKey": null
},
v5 = {
  "kind": "InlineFragment",
  "selections": (v3/*: any*/),
  "type": "Poi",
  "abstractKey": null
},
v6 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "errorMessage",
      "storageKey": null
    }
  ],
  "type": "UpdateBizStoreWatchError",
  "abstractKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useUpdateBizStoreWatchMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "updateBizStoreWatch",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "bizStore",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "UpdateBizStoreWatchResult",
            "abstractKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useUpdateBizStoreWatchMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "updateBizStoreWatch",
        "plural": false,
        "selections": [
          (v7/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "bizStore",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v2/*: any*/)
                    ],
                    "type": "Node",
                    "abstractKey": "__isNode"
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "UpdateBizStoreWatchResult",
            "abstractKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6d76b71f7fa912a4a5e7ffd7d6127e3b",
    "id": null,
    "metadata": {},
    "name": "useUpdateBizStoreWatchMutation",
    "operationKind": "mutation",
    "text": "mutation useUpdateBizStoreWatchMutation(\n  $input: UpdateBizStoreWatchInput!\n) {\n  updateBizStoreWatch(input: $input) {\n    __typename\n    ... on UpdateBizStoreWatchResult {\n      bizStore {\n        __typename\n        ... on BizAccount {\n          externalId\n          id\n          viewerIsWatched\n        }\n        ... on Poi {\n          externalId\n          id\n          viewerIsWatched\n        }\n        ... on Node {\n          __isNode: __typename\n          id\n        }\n      }\n    }\n    ... on UpdateBizStoreWatchError {\n      errorMessage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "26b4398c0d7a1fa03338ff36c50e40fe";

export default node;
