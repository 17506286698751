import { useRegisterAppCommonPayload } from '@daangn/local-business-analytics'
import { type Nearby } from '@daangn/nearby-sdk'
import { type RefObject, useEffect, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'

import { STAGE } from '../../../constants'
import { useLogger } from '../../../hooks/useLogger'
import { delay } from '../../../lib/delay'
import type { SectionTypeEnum } from '../../../types/schemaEnums'
import { spotlightTargetInfoAtom } from '../state/spotlightTargetInfo'

export const SPOTLIGHT_ANIMATION_DURATION = 300
const SPOTLIGHT_MIN_MARGIN = 85

export interface SpotlightBox {
  dimmed: boolean
  opened: boolean
  top: number
  left: number
  width: number
  height: number
}

export interface SpotlightTooltipBox {
  opened: boolean
  top: number
  left: number
  width: number
  height: number
  placement?: 'top' | 'left' | 'right' | 'bottom'
  content: string
}

export interface SpotlightTargetInfo {
  targetId?: string | null
  tooltipTargetId?: string | null
  referrer?: string | null
}

interface UseSpotlightProp {
  nearbySDK: Nearby
  scrollElementRef?: RefObject<HTMLDivElement>
  beforeSpotlight?: () => void
}

const REPLACE_SECTION_TARGET_ID: Record<string, SectionTypeEnum> = {
  HomeRecentlyBizAccount: 'RECENTLY_BIZ_ACCOUNT', // 최근 본 업체
  HomeCarArticle: 'HOT_CAR_ARTICLE', // 중고차 인기 매물
  HomeBizThemes: 'BIZ_THEME', // 우리동네 업체 소식
  HomeCoupon: 'COUPON', // 쿠폰
}

const BRAND_COUPON_TARGET_ID = `COUPON_Brand`

export function useSpotlight({ nearbySDK, scrollElementRef, beforeSpotlight }: UseSpotlightProp) {
  const { nearbyLog } = useLogger()
  const handleRegistCommonPayload = useRegisterAppCommonPayload()

  const [spotlightTargetInfo, setSpotlightTargetInfo] = useRecoilState(spotlightTargetInfoAtom)
  const [spotlightBox, setSpotlightBox] = useState<SpotlightBox>({
    dimmed: true,
    opened: false,
    top: 0,
    left: 0,
    width: 0,
    height: 0,
  })

  const [spotlightTooltipBox, setSpotlightTooltipBox] = useState<SpotlightTooltipBox>({
    opened: false,
    top: 0,
    left: 0,
    width: 0,
    height: 0,
    content: '',
  })

  useEffect(() => {
    const disposeOnOpenSpotlight = nearbySDK.onOpenSpotlight(
      async ({
        targetId: _targetId,
        tooltipContent,
        tooltipTargetId,
        tooltipPlacement,
        duration = 2000,
        dimmed = true,
        referrer,
      }) => {
        if (beforeSpotlight) {
          beforeSpotlight?.()
        }

        // 지사실 이벤트 로그 처리를 위해 지사실 이벤트로그 DataLayer 설정
        handleRegistCommonPayload('referrer', referrer ? `spotlgiht.${referrer}` : null)

        // 이전 런치페드의 스킴 대응 (알바, 중고차, 부동산, 미니) + 섹션 어드민으로 id값이 바뀌어서 대응
        const targetId = (() => {
          if (_targetId in REPLACE_SECTION_TARGET_ID) {
            return REPLACE_SECTION_TARGET_ID[_targetId]
          }

          // brand coupon 대응
          if (_targetId === BRAND_COUPON_TARGET_ID) {
            return document.getElementById(_targetId) ? _targetId : 'COUPON'
          }

          return _targetId
        })()

        nearbyLog({
          params: {
            name: 'show_spotlight',
            target_id: targetId,
            tooltip_target_id: tooltipTargetId,
            referrer,
          },
        })

        if (!scrollElementRef) {
          return
        }

        const $scrollContainer = scrollElementRef.current

        if (!$scrollContainer) {
          return
        }

        setSpotlightTargetInfo({
          targetId,
          tooltipTargetId,
          referrer,
        })

        const target = await getElementIdPromise(targetId, 200, 10)

        if (!target) {
          return
        }

        const checkScrollBoundingRects = target.getBoundingClientRect()

        const offsetTop = $scrollContainer.scrollTop + checkScrollBoundingRects.top

        const isScrolling = offsetTop + target.clientHeight > $scrollContainer?.clientHeight

        const targetScrollTop = isScrolling ? offsetTop - SPOTLIGHT_MIN_MARGIN : 0

        $scrollContainer.scrollTo({
          top: targetScrollTop,
        })

        $scrollContainer.style.overflow = 'hidden'

        await delay(SPOTLIGHT_ANIMATION_DURATION)

        const targetBoundingRects = target.getBoundingClientRect()

        setSpotlightBox({
          dimmed,
          opened: true,
          width: targetBoundingRects.width,
          height: targetBoundingRects.height,
          top: targetBoundingRects.top,
          left: targetBoundingRects.left,
        })

        await delay(SPOTLIGHT_ANIMATION_DURATION)

        const tooltipTarget = tooltipTargetId ? document.getElementById(tooltipTargetId) : null

        const tooltipTargetBoundingRects = tooltipTarget?.getBoundingClientRect()

        await delay(SPOTLIGHT_ANIMATION_DURATION)

        setSpotlightTooltipBox({
          opened: true,
          content: tooltipContent,
          width: tooltipTargetBoundingRects?.width ?? targetBoundingRects.width,
          height: tooltipTargetBoundingRects?.height ?? targetBoundingRects.height,
          top: tooltipTargetBoundingRects?.top ?? targetBoundingRects.top,
          left: tooltipTargetBoundingRects?.left ?? targetBoundingRects.left,
          placement: tooltipPlacement ?? undefined,
        })

        await delay(duration)

        setSpotlightBox((prevState) => ({
          ...prevState,
          opened: false,
        }))
        setSpotlightTooltipBox((prevState) => ({
          ...prevState,
          opened: false,
        }))

        $scrollContainer.style.overflow = ''
      }
    )

    return () => {
      disposeOnOpenSpotlight()
    }
  }, [
    nearbyLog,
    setSpotlightBox,
    setSpotlightTooltipBox,
    nearbySDK,
    beforeSpotlight,
    handleRegistCommonPayload,
    scrollElementRef,
    setSpotlightTargetInfo,
  ])

  return {
    spotlightBox,
    spotlightTooltipBox,
    spotlightTargetInfo,
  }
}

/**
 * id로 element를 반복해서 찾는다.
 * @param elementId
 * @param delay default 200
 * @param retry default 10
 */
function getElementIdPromise(elementId: string, delay = 100, retry = 5): Promise<HTMLElement | null> {
  return new Promise((resolve) => {
    let _retry = 1

    const interval = setInterval(() => {
      const element = document.getElementById(elementId)
      if (element) {
        clearInterval(interval)
        resolve(element)
      }

      _retry += 1

      if (_retry > retry) {
        clearInterval(interval)
        resolve(null)
      }
    }, delay)
  })
}
