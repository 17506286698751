/**
 * @generated SignedSource<<9bf2117975ce7f06c4ebe1946ea690f1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HomeWriteFloatingActionButton_query$data = {
  readonly viewer: {
    readonly owningBizAccounts: ReadonlyArray<{
      readonly id: string;
    }>;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"WriteLaunches_query">;
  readonly " $fragmentType": "HomeWriteFloatingActionButton_query";
};
export type HomeWriteFloatingActionButton_query$key = {
  readonly " $data"?: HomeWriteFloatingActionButton_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"HomeWriteFloatingActionButton_query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "regionId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "HomeWriteFloatingActionButton_query",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "regionId",
          "variableName": "regionId"
        }
      ],
      "kind": "FragmentSpread",
      "name": "WriteLaunches_query"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "KarrotUser",
      "kind": "LinkedField",
      "name": "viewer",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "BizAccount",
          "kind": "LinkedField",
          "name": "owningBizAccounts",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "366eff601a269ee57d04e38779b771a3";

export default node;
