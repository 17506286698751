/**
 * @generated SignedSource<<63b417171688ee25e634d847ec67f84c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HomePurposeLaunchCategory_purposeCategory$data = {
  readonly app: string | null;
  readonly externalId: number;
  readonly iconUrl: string | null;
  readonly id: string;
  readonly isNew: boolean;
  readonly isVerticalService: boolean;
  readonly name: string;
  readonly targetUri: string | null;
  readonly " $fragmentType": "HomePurposeLaunchCategory_purposeCategory";
};
export type HomePurposeLaunchCategory_purposeCategory$key = {
  readonly " $data"?: HomePurposeLaunchCategory_purposeCategory$data;
  readonly " $fragmentSpreads": FragmentRefs<"HomePurposeLaunchCategory_purposeCategory">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HomePurposeLaunchCategory_purposeCategory",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "externalId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "referrer",
          "value": "nearby_directory"
        }
      ],
      "kind": "ScalarField",
      "name": "targetUri",
      "storageKey": "targetUri(referrer:\"nearby_directory\")"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isNew",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "app",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "iconUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isVerticalService",
      "storageKey": null
    }
  ],
  "type": "PurposeCategory",
  "abstractKey": null
};

(node as any).hash = "5168712ae89449016fd9f45f1fcb6d09";

export default node;
