import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import React from 'react'

import Placeholder from '../../../_lib/Placeholder'
import { activeEffect } from '../../../_lib/styles'

const BizThemeLoading: React.FC = () => {
  return (
    <LoadingBizPosts>
      <PostItem>
        <Button>
          <Placeholder marginRight="1rem" width="5.5rem" height="5.5rem" borderRadius="0.375rem" />
        </Button>
      </PostItem>
      <PostItem>
        <Button>
          <Placeholder marginRight="1rem" width="5.5rem" height="5.5rem" borderRadius="0.375rem" />
        </Button>
      </PostItem>
      <PostItem>
        <Button>
          <Placeholder marginRight="1rem" width="5.5rem" height="5.5rem" borderRadius="0.375rem" />
        </Button>
      </PostItem>
    </LoadingBizPosts>
  )
}

const LoadingBizPosts = styled.div`
  padding: 0 1rem;
  height: 100%;
  position: relative;
  min-height: 24.223rem;
`

const PostItem = styled.div`
  padding: 0.625rem 0;
  box-shadow: 0 1px 0 0 ${vars.$semantic.color.divider1};

  &:last-of-type {
    box-shadow: none;
  }
`

const Button = styled.div`
  display: flex;
  padding: 0.5rem;
  margin: 0 -0.5rem;
  border-radius: 0.5rem;

  ${activeEffect()};
`

export default BizThemeLoading
