/**
 * @generated SignedSource<<15654ca6a0f6f291e72ef6a5e554ab59>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BizPostThemeType = "ALL" | "BEAUTY" | "CLOSING_SALE" | "EDUCATION" | "FOLLOWING_AND_RECOMMENDATION" | "FOOD" | "HEALTH" | "LIFE" | "RECENTLY";
export type SectionTypeEnum = "AUTO_REQUEST_SERVICE_FIELD_CURATION" | "BANNER" | "BEST_BIZ_ACCOUNT" | "BIZ_FITNESS_PRODUCT" | "BIZ_RECOMMEND_PRODUCT" | "BIZ_THEME" | "CAR_SAVED_COST" | "CLASS_BIZ_POST" | "COMMUNITY_POST" | "COUPON" | "CUSTOM" | "EXPERT_RECOMMEND" | "HOT_BIZ_ACCOUNT" | "HOT_CAR_ARTICLE" | "HOT_JOBS" | "HOT_KEYWORD" | "LOCAL_FOOD" | "MAP" | "MINI" | "MY_DAANGN_STORY" | "NATIVE_REVIEW" | "NEARBY_BIZ_POST" | "NEARBY_EXERCISE_BIZ_ACCOUNT" | "NEARBY_JOBS" | "POPULAR_SERVICE_FIELD" | "PUBLIC_BIZ_POST" | "PURPOSE" | "RECENTLY_BIZ_ACCOUNT" | "RECENTLY_REALTY_ARTICLE" | "RECOMMEND_POST" | "REQUEST_REVIEW" | "SHORT_FORM";
export type CoordsInput = {
  lat: number;
  lng: number;
};
export type HomeLazyBizThemeQuery$variables = {
  bizPostThemeType?: BizPostThemeType | null;
  currentCoords?: CoordsInput | null;
  regionId: number;
  sectionType: SectionTypeEnum;
};
export type HomeLazyBizThemeQuery$data = {
  readonly section: {
    readonly __typename: "SectionBizTheme";
    readonly bizPostTheme: {
      readonly " $fragmentSpreads": FragmentRefs<"HomeBizTheme_bizPostTheme">;
    };
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null;
};
export type HomeLazyBizThemeQuery = {
  response: HomeLazyBizThemeQuery$data;
  variables: HomeLazyBizThemeQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "bizPostThemeType"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "currentCoords"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "regionId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sectionType"
},
v4 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "currentCoords",
        "variableName": "currentCoords"
      },
      {
        "kind": "Variable",
        "name": "regionId",
        "variableName": "regionId"
      },
      {
        "kind": "Variable",
        "name": "sectionType",
        "variableName": "sectionType"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v6 = [
  {
    "kind": "Variable",
    "name": "type",
    "variableName": "bizPostThemeType"
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "externalId",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v10 = [
  (v8/*: any*/)
],
v11 = {
  "kind": "InlineFragment",
  "selections": (v10/*: any*/),
  "type": "Node",
  "abstractKey": "__isNode"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "HomeLazyBizThemeQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "section",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v6/*: any*/),
                "concreteType": "BizPostTheme",
                "kind": "LinkedField",
                "name": "bizPostTheme",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "HomeBizTheme_bizPostTheme"
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "SectionBizTheme",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "HomeLazyBizThemeQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "section",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v6/*: any*/),
                "concreteType": "BizPostTheme",
                "kind": "LinkedField",
                "name": "bizPostTheme",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "bizPosts",
                    "plural": true,
                    "selections": [
                      (v5/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v8/*: any*/),
                          (v7/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "title",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "content",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "DistanceInfo",
                            "kind": "LinkedField",
                            "name": "distance",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "distance",
                                "storageKey": null
                              },
                              (v9/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Image",
                            "kind": "LinkedField",
                            "name": "image",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "thumbnail",
                                "storageKey": null
                              },
                              (v8/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Region",
                            "kind": "LinkedField",
                            "name": "region",
                            "plural": false,
                            "selections": [
                              (v7/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "name3",
                                "storageKey": null
                              },
                              (v8/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "BizAccount",
                            "kind": "LinkedField",
                            "name": "bizAccount",
                            "plural": false,
                            "selections": [
                              (v9/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "categoryName",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "badges",
                                "storageKey": null
                              },
                              (v8/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "CouponBox",
                            "kind": "LinkedField",
                            "name": "couponBox",
                            "plural": false,
                            "selections": (v10/*: any*/),
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "createdAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "targetUri",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "BizPostExtension",
                            "kind": "LinkedField",
                            "name": "extensions",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "BizPostClosingTimeSaleExtension",
                                "kind": "LinkedField",
                                "name": "closingTimeSale",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "status",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "closeTime",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "bookmarkCount",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "commentCount",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "BizPostMetadata",
                            "kind": "LinkedField",
                            "name": "meta",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "type",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "entry",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "BizDeliveryLoggingMeta",
                                "kind": "LinkedField",
                                "name": "bizDeliveryLoggingMeta",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "impression",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "click",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "type": "BizLoggingMeta",
                            "abstractKey": "__isBizLoggingMeta"
                          }
                        ],
                        "type": "BizPost",
                        "abstractKey": null
                      },
                      (v11/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v8/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "SectionBizTheme",
            "abstractKey": null
          },
          (v11/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c15336928991c152d134acc3f5de87fc",
    "id": null,
    "metadata": {},
    "name": "HomeLazyBizThemeQuery",
    "operationKind": "query",
    "text": "query HomeLazyBizThemeQuery(\n  $sectionType: SectionTypeEnum!\n  $regionId: Int!\n  $currentCoords: CoordsInput\n  $bizPostThemeType: BizPostThemeType\n) {\n  section(input: {sectionType: $sectionType, regionId: $regionId, currentCoords: $currentCoords}) {\n    __typename\n    ... on SectionBizTheme {\n      bizPostTheme(type: $bizPostThemeType) {\n        ...HomeBizTheme_bizPostTheme\n        id\n      }\n    }\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n}\n\nfragment HomeBizThemePost_bizPost on BizPost {\n  externalId\n  title\n  content\n  distance {\n    distance\n    name\n  }\n  image {\n    thumbnail\n    id\n  }\n  region {\n    externalId\n    name3\n    id\n  }\n  bizAccount {\n    name\n    categoryName\n    badges\n    id\n  }\n  couponBox {\n    id\n  }\n  createdAt\n  targetUri\n  extensions {\n    closingTimeSale {\n      status\n      closeTime\n    }\n  }\n  bookmarkCount\n  commentCount\n  meta {\n    type\n    entry\n  }\n  ...useBizLoggingMeta_bizLoggingMeta\n}\n\nfragment HomeBizTheme_bizPostTheme on BizPostTheme {\n  externalId\n  bizPosts {\n    __typename\n    ... on BizPost {\n      id\n      ...HomeBizThemePost_bizPost\n    }\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n}\n\nfragment useBizLoggingMeta_bizLoggingMeta on BizLoggingMeta {\n  __isBizLoggingMeta: __typename\n  bizDeliveryLoggingMeta {\n    impression\n    click\n  }\n}\n"
  }
};
})();

(node as any).hash = "ec14bc5542b8a1a990533cde4b909f78";

export default node;
