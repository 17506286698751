import { css } from '@emotion/react'
import { vars } from '@seed-design/design-token'
import { type CSSProperties } from 'react'

import type { BadgeProps } from './index'

type Variant = BadgeProps['variant']
type Type = BadgeProps['type']

const basic: Record<Type, CSSProperties> = {
  normal: {
    color: vars.$scale.color.gray700,
    backgroundColor: vars.$scale.color.gray100,
  },
  outlined: {
    color: vars.$scale.color.gray900,
    border: `1px solid ${vars.$scale.color.gray900}`,
    backgroundColor: 'transparent',
  },
  bold: {
    color: vars.$scale.color.gray00,
    backgroundColor: vars.$scale.color.gray700,
  },
}

const success: Record<Type, CSSProperties> = {
  normal: {
    color: vars.$scale.color.green700,
    backgroundColor: vars.$semantic.color.successLow,
  },
  outlined: {
    color: vars.$semantic.color.success,
    border: `1px solid ${vars.$semantic.color.success}`,
    backgroundColor: 'transparent',
  },
  bold: {
    color: vars.$static.color.staticWhite,
    backgroundColor: vars.$semantic.color.success,
  },
}

const error: Record<Type, CSSProperties> = {
  normal: {
    color: vars.$semantic.color.danger,
    backgroundColor: vars.$semantic.color.dangerLow,
  },
  outlined: {
    color: vars.$semantic.color.danger,
    border: `1px solid ${vars.$semantic.color.danger}`,
    backgroundColor: 'transparent',
  },
  bold: {
    color: vars.$static.color.staticWhite,
    backgroundColor: vars.$semantic.color.danger,
  },
}

const primary: Record<Type, CSSProperties> = {
  normal: {
    color: vars.$semantic.color.primary,
    backgroundColor: vars.$scale.color.carrotAlpha100,
  },
  outlined: {
    color: vars.$semantic.color.primary,
    border: `1px solid ${vars.$semantic.color.primary}`,
    backgroundColor: 'transparent',
  },
  bold: {
    color: vars.$static.color.staticWhite,
    backgroundColor: vars.$semantic.color.primary,
  },
}

const accent: Record<Type, CSSProperties> = {
  normal: {
    color: vars.$scale.color.blue800,
    backgroundColor: vars.$scale.color.blue50,
  },
  outlined: {
    color: vars.$scale.color.blue700,
    border: `1px solid ${vars.$scale.color.blue700}`,
    backgroundColor: 'transparent',
  },
  bold: {
    color: vars.$static.color.staticWhite,
    backgroundColor: vars.$semantic.color.accent,
  },
}

const variants: Record<Variant, Record<Type, CSSProperties>> = {
  basic,
  success,
  error,
  primary,
  accent,
}

export const styleVariant = (variant: Variant, type: Type) => css({ ...variants[variant][type] })
