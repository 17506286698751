import { BottomSheet, BottomSheetItem, Modal } from '@daangn/karrot-clothes'
import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import React, { useState } from 'react'
import { useSetRecoilState } from 'recoil'

import { LOCAL_STORAGE_LAND_TO_LOCAL_MAP_KEY, LOCAL_STORAGE_STATE_KEY } from '@src/constants'
import { type NearbyActivityNames } from '@src/stackflow/stackflow'

import BigFontSimultationModalView from './Modal/BigFontSimultationModalView'
import ExperimentGroupConfigModalView from './Modal/ExperimentGroupConfigModalView'
import PageMoveModalView from './Modal/PageMoveModalView'
import SettingMapOptionsModalView from './Modal/SettingMapOptionsModalView'
import SpotlightDevModalView from './Modal/SpotlightDevModalView'
import { mapOptionDebugViewAtom } from './state/mapOptionDebugViewAtom'
import { initialPersistedState } from '../../lib/storage'
import { moveLocalMap } from '../Home/hooks/useLocalMap'

interface DevToolsHalfViewProps {
  isOpen: boolean
  onClose: () => void
  curActivityName: string
}

type ModalView = 'spotlight' | 'experimentGroupConfig' | 'pageMove' | 'settingMapOptions' | 'bigFontSimulation' | null
type OPTION_ITEM =
  | {
      text: string
      modalView: ModalView
      bgcolor?: string
    }
  | {
      text: string
      action: () => void
      bgcolor?: string
    }

const DevToolsHalfView: React.FC<DevToolsHalfViewProps> = ({ isOpen, onClose, curActivityName }) => {
  const _curActivityName = curActivityName as NearbyActivityNames

  const [modalView, setModalView] = useState<ModalView>(null)

  const setMapOptionView = useSetRecoilState(mapOptionDebugViewAtom)

  function closeAll() {
    setModalView(null)
    onClose()
  }

  const renderOptionItem = (option: OPTION_ITEM) => {
    if ('modalView' in option) {
      return (
        <S_BottomSheetItem onClick={() => setModalView(option.modalView)} key={option.text} bgcolor={option.bgcolor}>
          {option.text}
        </S_BottomSheetItem>
      )
    }

    return (
      <S_BottomSheetItem onClick={option.action} key={option.text} bgcolor={option.bgcolor}>
        {option.text}
      </S_BottomSheetItem>
    )
  }

  const globalOptions: OPTION_ITEM[] = [
    {
      text: '실험 그룹 설정',
      modalView: 'experimentGroupConfig',
    },
    {
      text: '동네지도 이동',
      action: () => {
        localStorage.setItem(LOCAL_STORAGE_LAND_TO_LOCAL_MAP_KEY, 'true')
        moveLocalMap(true)
      },
    },
    {
      text: '페이지 이동',
      modalView: 'pageMove',
    },
    {
      text: '스토리지 초기화',
      action: () => {
        {
          initialPersistedState()
          for (let i = localStorage.length - 1; i >= 0; i--) {
            const key = localStorage.key(i)
            if (key !== null && key.startsWith(LOCAL_STORAGE_STATE_KEY)) {
              localStorage.removeItem(key)
            }
          }
          closeAll()
        }
      },
    },
    {
      text: '빅폰트 시뮬레이션',
      modalView: 'bigFontSimulation',
    },
  ]

  const nearbyHomeOptions: OPTION_ITEM[] = [
    {
      text: 'Spotlight 테스트',
      modalView: 'spotlight',
      bgcolor: vars.$scale.color.carrotAlpha50,
    },
  ]

  const couponMapOptions: OPTION_ITEM[] = [
    {
      text: '지도 설정값 보기',
      action: () => {
        setMapOptionView((prev) => !prev)
      },
      bgcolor: vars.$scale.color.carrotAlpha50,
    },
    {
      text: '지도 설정값 조정',
      modalView: 'settingMapOptions',
      bgcolor: vars.$scale.color.carrotAlpha50,
    },
  ]

  return (
    <>
      <BottomSheetStyled open={isOpen} onClose={onClose} zIndex={9000}>
        {globalOptions.map(renderOptionItem)}
        {_curActivityName === 'Home' && nearbyHomeOptions.map(renderOptionItem)}
        {_curActivityName === 'CouponMap' && couponMapOptions.map(renderOptionItem)}
      </BottomSheetStyled>

      <Modal open={!!modalView} onClose={() => setModalView(null)} zIndex={9001}>
        {modalView === 'pageMove' && <PageMoveModalView closeAll={closeAll} />}
        {modalView === 'spotlight' && <SpotlightDevModalView closeAll={closeAll} />}
        {modalView === 'experimentGroupConfig' && <ExperimentGroupConfigModalView />}
        {modalView === 'settingMapOptions' && <SettingMapOptionsModalView closeAll={closeAll} />}
        {modalView === 'bigFontSimulation' && <BigFontSimultationModalView closeAll={closeAll} />}
      </Modal>
    </>
  )
}

export default DevToolsHalfView

const BottomSheetStyled = styled(BottomSheet)`
  ul {
    max-height: 40vh;
    overflow-y: scroll;
  }
`

const S_BottomSheetItem = styled(BottomSheetItem)<{ bgcolor?: string }>`
  background-color: ${({ bgcolor }) => (bgcolor ? bgcolor : 'auto')};
`
