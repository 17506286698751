import { v4 as uuidv4 } from 'uuid'

import { LOCAL_STORAGE_DEV_KEY, LOCAL_STORAGE_STATE_KEY } from '@src/constants'

export const StorageKey = {
  GAClientID: 'ga:clientId1',
}

class BaseStorage {
  prefix: string
  storage: Storage

  constructor(prefix: string, storage: Storage) {
    this.prefix = prefix
    this.storage = storage
  }

  protected getKey(key: string) {
    return `${this.prefix}#${key}`
  }

  setItem(key: string, value: string) {
    this.storage.setItem(this.getKey(key), value)
  }

  getItem(key: string): string | null {
    return this.storage.getItem(this.getKey(key))
  }

  removeItem(key: string) {
    this.storage.removeItem(this.getKey(key))
  }
}

class NearbyStorage extends BaseStorage {
  getGaClientId(): string {
    const clientId = this.getItem(StorageKey.GAClientID) ?? uuidv4()

    this.setItem(StorageKey.GAClientID, clientId)

    return clientId
  }
}

class SessionStorage extends BaseStorage {
  getReferrer(): string | null {
    return this.getItem('referrer')
  }

  setReferrer(referrer?: string) {
    if (!this.getReferrer() && referrer) {
      this.setItem('referrer', referrer)
    }
  }
}

export const nearbyStorage = new NearbyStorage(LOCAL_STORAGE_STATE_KEY, window.localStorage)
export const devStorage = new NearbyStorage(LOCAL_STORAGE_DEV_KEY, window.localStorage)
// TODO. Session Storage가 필요할 때 사용
// export const sessionStorage = new SessionStorage(LOCAL_SESSION_STORAGE_STATE_KEY, window.sessionStorage)

export type NearbyStorageKind = NearbyStorage | SessionStorage | BaseStorage
