import styled from '@emotion/styled'

import { noScrollbar } from './styles'

export const ScrollableX = styled.div`
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;

  ${noScrollbar};
  box-sizing: border-box;

  & > * {
    display: inline-block;
  }

  & > *:not(:last-child) {
    margin-right: 0.625rem;
  }
`

export const ScrollableY = styled.div`
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
`

export default ScrollableX
