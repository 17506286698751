import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import React, { type ImgHTMLAttributes, type PropsWithChildren } from 'react'
import { LazyLoadImage as LazyLoadImageLib, type LazyLoadImageProps } from 'react-lazy-load-image-component'

import { type PropsOf } from '../../lib/T/utilTypes'

type CoveredLazyLoadImageProps = PropsWithChildren<LazyLoadImageProps> & {
  className?: string
  isLazy?: boolean
  insideStrokeDisabled?: boolean
  containerRef?: React.Ref<HTMLDivElement>
} & PropsOf<typeof LazyLoadImageLib>

const CoveredLazyLoadImage: React.FC<CoveredLazyLoadImageProps> = ({
  children,
  className,
  insideStrokeDisabled,
  isLazy = true,
  containerRef,
  visibleByDefault = true,
  ...props
}) => {
  return (
    <ImageContainer ref={containerRef} className={className} insideStrokeDisabled={insideStrokeDisabled}>
      {props.src &&
        (isLazy ? (
          <LazyLoadImageLib {...props} visibleByDefault={visibleByDefault} />
        ) : (
          <img src={props.src} alt={props.alt} {...(props as ImgHTMLAttributes<HTMLImageElement>)} />
        ))}
      {children}
    </ImageContainer>
  )
}

const ImageContainer = styled.div<{ insideStrokeDisabled?: boolean }>`
  isolation: isolate;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.375rem;

  img {
    position: absolute;
    z-index: 0;

    width: 100%;
    height: 100%;

    object-fit: cover;
    border-radius: inherit;
  }

  ${({ insideStrokeDisabled }) =>
    insideStrokeDisabled &&
    css`
      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        border: 1px solid ${vars.$scale.color.grayAlpha50};
        border-radius: inherit;
        box-sizing: border-box;
      }
    `}
`

export default CoveredLazyLoadImage
