import { type SizeIconProps } from '@daangn/karrot-clothes/lib/types/svg'
import styled from '@emotion/styled'
import React from 'react'

const Check: React.FC<SizeIconProps> = ({ size = 25, color = 'currentColor' }) => {
  return (
    <Svg width={size} height={size} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.6163 4.67823C22.0324 4.98082 22.1244 5.56339 21.8218 5.97945L11.5067 19.7876C11.3419 20.0142 11.0839 20.1546 10.8042 20.1699C10.5244 20.1851 10.2526 20.0736 10.0641 19.8663L3.24225 12.4622C2.89619 12.0816 2.92425 11.4925 3.30491 11.1464C3.68558 10.8003 4.27471 10.8284 4.62077 11.2091L10.673 17.7665L20.3151 4.88368C20.6177 4.46763 21.2003 4.37564 21.6163 4.67823Z"
        fill={color}
      />
    </Svg>
  )
}

export default React.memo(Check)

const Svg = styled.svg`
  display: block;
`
