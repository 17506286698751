import { type SizeIconProps } from '@daangn/karrot-clothes/lib/types/svg'
import styled from '@emotion/styled'
import React from 'react'

const GroupPurchase: React.FC<SizeIconProps> = ({ size = 24, color = 'currentColor' }) => {
  return (
    <Svg width={size} height={size} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.0799 3.71013H6.66992L5.48992 1.18013C5.34492 0.865126 4.96992 0.730126 4.65992 0.880126C4.34492 1.02513 4.20992 1.40013 4.35992 1.71013L5.29492 3.71013H0.919922C0.574922 3.71013 0.294922 3.99013 0.294922 4.33513C0.294922 4.68013 0.574922 4.96013 0.919922 4.96013H1.20492L2.13992 10.2551C2.17992 10.4951 2.38992 10.6701 2.62992 10.6701H9.36992C9.61492 10.6701 9.81992 10.4951 9.85992 10.2551L10.7949 4.96013H11.0799C11.4249 4.96013 11.7049 4.68013 11.7049 4.33513C11.7049 3.99013 11.4249 3.71013 11.0799 3.71013ZM5.14492 8.54513C5.14492 8.76513 4.96492 8.94513 4.74492 8.94513C4.52492 8.94513 4.34492 8.76513 4.34492 8.54513V6.48513C4.34492 6.26513 4.52492 6.08513 4.74492 6.08513C4.96492 6.08513 5.14492 6.26513 5.14492 6.48513V8.54513ZM7.65492 8.54513C7.65492 8.76513 7.47492 8.94513 7.25492 8.94513C7.03492 8.94513 6.85492 8.76513 6.85492 8.54513V6.48513C6.85492 6.26513 7.03492 6.08513 7.25492 6.08513C7.47492 6.08513 7.65492 6.26513 7.65492 6.48513V8.54513Z"
        fill={color}
      />
    </Svg>
  )
}

export default React.memo(GroupPurchase)

const Svg = styled.svg`
  display: block;
`
