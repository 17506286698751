type Pos = { lat: number; lng: number }

export const getDistanceBetweenCoordinates = (lat1: number, lng1: number, lat2: number, lng2: number) => {
  const pos1 = { lat: lat1, lng: lng1 }
  const pos2 = { lat: lat2, lng: lng2 }
  const meterDistance = calcHaversineMeterDistance(pos1, pos2)

  return {
    meterDistance,
    text: getDistanceText(meterDistance),
  }
}

/**
 * 도보 소요시간을 구하는 함수(70m/min 가정)
 * @param meter
 */
export const getWalkingDurationSeconds = (meter: number) => {
  const WALKING_METER_PER_MINUTES = 70
  const WALKING_METER_PER_SECONDS = WALKING_METER_PER_MINUTES / 60

  return Math.ceil(meter / WALKING_METER_PER_SECONDS)
}

/**
 * 하버사인 공식을 이용한 두 위경도 사이의 거리 계산
 * @param pos1
 * @param pos2
 */
const calcHaversineMeterDistance = (pos1: Pos, pos2: Pos) => {
  const R = 6371
  const toRadian = Math.PI / 180
  const dLat = Math.abs(pos2.lat - pos1.lat) * toRadian
  const dLng = Math.abs(pos2.lng - pos1.lng) * toRadian
  const sinDeltaLat = Math.sin(dLat / 2)
  const sinDeltaLng = Math.sin(dLng / 2)
  const squareRoot = Math.sqrt(
    sinDeltaLat * sinDeltaLat +
      Math.cos(pos2.lat * toRadian) * Math.cos(pos1.lat * toRadian) * sinDeltaLng * sinDeltaLng
  )

  const kilometerDistance = 2 * R * Math.asin(squareRoot)
  return kilometerDistance * 1000
}

export const getDistanceText = (meterDistance: number) => {
  const kilometerText = parseFloat((meterDistance / 1000).toFixed(1)) + 'km'
  const meterText = Math.round(meterDistance).toString() + 'm'

  return meterDistance > 1000 ? kilometerText : meterText
}

export const getWalkingDurationText = (durationSeconds: number) => {
  return (durationSeconds / 60 + 1).toFixed(0) + '분'
}
