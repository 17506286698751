import { css } from '@emotion/react'
import { type CSSProperties } from 'react'

import type { BadgeProps } from './index'

type Size = BadgeProps['size']
type Shape = BadgeProps['shape']

const pill: Record<Size, CSSProperties> = {
  small: {
    padding: '0.125rem 0.25rem',
    borderRadius: '62.5rem',
  },
  medium: {
    padding: '0.125rem 0.375rem',
    borderRadius: '62.5rem',
  },
  large: {
    padding: '0.25rem 0.5rem',
    borderRadius: '62.5rem',
  },
}

const square: Record<Size, CSSProperties> = {
  small: {
    padding: '0.125rem 0.25rem',
    borderRadius: '0.125rem',
  },
  medium: {
    padding: '0.125rem 0.375rem',
    borderRadius: '0.125rem',
  },
  large: {
    padding: '0.25rem 0.5rem',
    borderRadius: '0.25rem',
  },
}

const shapes: Record<Shape, Record<Size, CSSProperties>> = {
  pill,
  square,
}

export const styleShape = (shape: Shape, size: Size) => css({ ...shapes[shape][size] })
