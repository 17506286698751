import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { type FC, useRef } from 'react'
import { graphql, useFragment } from 'react-relay'

import {
  type SavedCostArticle_carArticle$data,
  type SavedCostArticle_carArticle$key,
} from '../../../../__relay__/SavedCostArticle_carArticle.graphql'
import { maskPrice, numberWithComma } from '../../../../lib/unit'
import BadgeSquare from '../../../_lib/BadgeSquare/BadgeSquare'
import CoveredLazyLoadImage from '../../../_lib/CoveredLazyLoadImage'
import { useImpression } from '../../../_lib/Impression'
import { Flex, getEllipsisStyle } from '../../../_lib/styles'

interface SavedCostArticleProps {
  carArticle: SavedCostArticle_carArticle$key
  itemOrder: number
  itemScrollContainerRef: React.RefObject<HTMLElement>
  onClick: (article: SavedCostArticle_carArticle$data, itemOrder: number) => void
  onImpression: (article: SavedCostArticle_carArticle$data, itemOrder: number) => void
}

const SavedCostArticle: FC<SavedCostArticleProps> = (props) => {
  const carArticle = useFragment(
    graphql`
      fragment SavedCostArticle_carArticle on CarArticle {
        id
        externalId
        carName
        title
        price
        targetUri
        image {
          thumbnail
        }
        leaseMonthlyFee
        rentalMonthlyFee
        manufacturer
        articleManufacturerString
        totalDiscountedExpense
      }
    `,
    props.carArticle
  )

  const { price, rentalMonthlyFee, leaseMonthlyFee } = carArticle
  const hasPrice = price ?? leaseMonthlyFee ?? rentalMonthlyFee

  const itemRef = useRef<HTMLDivElement>(null)

  // swipe log
  useImpression(
    {
      ref: itemRef,
      scrollContainerRef: props.itemScrollContainerRef,
      disableImpressionOnload: true,
    },
    () => {
      props.onImpression(carArticle, props.itemOrder)
    },
    [props.itemOrder, props.onImpression]
  )

  const handleClick = () => {
    props.onClick(carArticle, props.itemOrder)
  }

  return (
    <S_CarArticle ref={itemRef} onClick={handleClick}>
      <ImageWrapper>
        <CoveredLazyLoadImage src={carArticle.image.thumbnail} alt={carArticle.title} visibleByDefault />
      </ImageWrapper>
      <InfoWrapper>
        <Manufacturer>{carArticle.articleManufacturerString}</Manufacturer>
        <Title>{carArticle.title}</Title>
        {hasPrice && (
          <Price>
            {price
              ? `${numberWithComma(price)}만원`
              : leaseMonthlyFee
              ? `리스 ${numberWithComma(leaseMonthlyFee)}만원/월`
              : rentalMonthlyFee
              ? `렌트 ${numberWithComma(rentalMonthlyFee)}만원/월`
              : null}
          </Price>
        )}
      </InfoWrapper>
      <SavedPriceBadgeWrapper>
        <BadgeSquare
          type="accent"
          size="medium"
          label={`${maskPrice(carArticle.totalDiscountedExpense, {
            roundPrice: 10000,
          })} 절약`}
        />
      </SavedPriceBadgeWrapper>
    </S_CarArticle>
  )
}

export default SavedCostArticle

const S_CarArticle = styled.article`
  display: inline-block;
  width: 8.75rem;
  color: ${vars.$scale.color.gray900};
  vertical-align: top;

  & > *:not(:last-child) {
    margin-bottom: 0.5rem;
  }
`

const ImageWrapper = styled.div`
  width: 8.75rem;
  height: 8.75rem;
`

const InfoWrapper = styled.div`
  & > *:not(:last-child) {
    margin-bottom: 0.063rem;
  }
`

const Manufacturer = styled.div`
  ${vars.$semantic.typography.caption2Regular};
  color: ${vars.$scale.color.gray600};
  ${getEllipsisStyle(1)};
`

const Title = styled.h3`
  margin: 0;
  ${vars.$semantic.typography.subtitle2Regular};
  ${getEllipsisStyle(1)};
`

const Price = styled.div`
  ${vars.$semantic.typography.label3Bold};
  ${getEllipsisStyle(1)};
`

const SavedPriceBadgeWrapper = styled.div`
  ${Flex};
`
