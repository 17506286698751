import { usePullToRefreshScrollElement } from '@daangn/karrot-clothes/lib/pullToRefresh'
import styled from '@emotion/styled'
import { chunk } from 'lodash'
import { useCallback, useMemo } from 'react'
import { graphql, useFragment } from 'react-relay'

import { type HomeRecommendPosts_sectionRecommendPost$key } from '@src/__relay__/HomeRecommendPosts_sectionRecommendPost.graphql'
import { ListEntry } from '@src/components/_lib/ListSection/ListEntry'
import { useLogger } from '@src/hooks/useLogger'
import { type PropOf } from '@src/lib/T/utilTypes'
import { useOpenTarget, type Target } from '@src/lib/target'
import { type SectionInfo } from '@src/types'

import CarouselContent from '../Common/CarouselContent'
import HomeSectionBase from '../Common/HomeSectionBase'
import HomeBizThemePost from '../HomeBizTheme/HomeBizThemePost'

interface HomeRecommendPostsProps {
  sectionRecommendPost: HomeRecommendPosts_sectionRecommendPost$key
  sectionInfo: SectionInfo
}

const VIEW_ITEM_COUNT = 3

const HomeRecommendPosts: React.FC<HomeRecommendPostsProps> = ({ sectionInfo, ...props }) => {
  const scrollContainerRef = usePullToRefreshScrollElement()

  const { contentsSectionLog, loggingMetaLog } = useLogger()
  const { openTarget } = useOpenTarget()

  const section = useFragment<HomeRecommendPosts_sectionRecommendPost$key>(
    graphql`
      fragment HomeRecommendPosts_sectionRecommendPost on SectionRecommendPost {
        bizPosts {
          externalId
          ...HomeBizThemePost_bizPost
        }
        __typename
        loggingMeta {
          swipe
          click
          show
        }

        baseInfo {
          viewMoreInfo {
            label
            targetUri
          }
        }
        ...HomeSectionBase_sectionBase
      }
    `,
    props.sectionRecommendPost
  )

  const handleBizPostClick = useCallback(
    (target: Target, info: Parameters<NonNullable<PropOf<typeof HomeBizThemePost, 'onClick'>>>[1]) => {
      const { bizPost } = info
      const itemOrder = section.bizPosts.map((post) => post.externalId).indexOf(bizPost.externalId) + 1

      contentsSectionLog('click', {
        contentsType: 'recommended_post',
        buttonType: 'list',
        params: {
          item_type: 'post',
          item_id: bizPost.externalId,
          item_order: itemOrder,
        },
      })

      loggingMetaLog([section.loggingMeta.click])

      openTarget({
        ...target,
      })
    },
    [contentsSectionLog, section.bizPosts, section.loggingMeta.click, loggingMetaLog, openTarget]
  )

  const handleMoreClick = () => {
    contentsSectionLog('click', {
      contentsType: 'recommended_post',
      buttonType: 'more',
      params: {
        item_type: 'post',
        sectionOrder: sectionInfo.order,
      },
    })

    loggingMetaLog(section.loggingMeta.click)

    const bizPostsTargetUri = section.baseInfo?.viewMoreInfo?.targetUri
    bizPostsTargetUri &&
      openTarget({
        targetUri: bizPostsTargetUri,
      })
  }

  const handleImpression = () => {
    contentsSectionLog('show', {
      contentsType: 'recommended_post',
      sectionOrder: sectionInfo.order,
    })

    if (sectionInfo.order === 0) {
      loggingMetaLog(section.loggingMeta.show)
    }
  }

  const postItems = useMemo(
    () =>
      chunk(section.bizPosts, VIEW_ITEM_COUNT).map((bizPosts, index) => (
        <ListContainer key={index}>
          {bizPosts.map((post) => (
            <HomeBizThemePost key={post.externalId} bizPost={post} onClick={handleBizPostClick} />
          ))}
        </ListContainer>
      )),
    [section.bizPosts, handleBizPostClick]
  )

  const handleCarouselListSwipe: PropOf<typeof CarouselContent, 'onListSwipe'> = useCallback(
    (offset) => {
      contentsSectionLog('swipe', {
        contentsType: 'recommended_post',
        page: offset + 1,
      })

      loggingMetaLog(section.loggingMeta.swipe)
    },
    [contentsSectionLog, loggingMetaLog, section.loggingMeta.swipe]
  )

  return (
    <HomeSectionBase
      sectionBase={section}
      ImpressionProps={{
        scrollContainerRef: scrollContainerRef,
        onImpression: handleImpression,
        threshold: 0.8,
      }}
      ServiceTitleProps={{
        serviceType: 'BIZ_STORE',
      }}
      SectionTitleProps={{
        infoButton: {
          content: (
            <div>
              최신성, 품질, 조회수 등을 <br />
              점수화하여 높은 순으로 <br />
              정렬해요.
            </div>
          ),
          placeholderPosition: 'middle',
        },
      }}
      SectionBottomButtonProps={{
        content: section.baseInfo?.viewMoreInfo?.label,
        onClick: handleMoreClick,
      }}>
      <CarouselContent listItems={postItems} onListSwipe={handleCarouselListSwipe} sectionOrder={sectionInfo.order} />
    </HomeSectionBase>
  )
}

const ListContainer = styled(ListEntry)``

export default HomeRecommendPosts
