import { PullToRefresh, type PullToRefreshRef } from '@daangn/karrot-clothes/lib/pullToRefresh'
import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { type ActivityComponentType } from '@stackflow/react'
import { Suspense, useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react'
import { useRecoilValue } from 'recoil'

import HomeCreatePoiOrBizProfile from '@src/components/Home/HomeCreatePoiOrBizProfile'
import { appAtom } from '@src/features/_global/state/app'
import Spotlight from '@src/features/spotlight/components/Spotlight'
import { useAPIHealthCheck } from '@src/hooks/useAPIHealthCheck'

import { NEARBY_REMOTE_APP, NEARBY_V2_VERSION_CHECK, STAGE } from '../../constants'
import { useBridgeStreamListener } from '../../hooks/useBridgeStreamListener'
import { type NearbyTabClickData } from '../../hooks/useBridgeStreamListener/nativeStream'
import { useLogger } from '../../hooks/useLogger'
import { useRemoteWebview } from '../../hooks/useRemoteWebview'
import { delay } from '../../lib/delay'
import { type PropOf } from '../../lib/T/utilTypes'
import { satisfyVersion } from '../../lib/version-check'
import withSuspense from '../../lib/with-fallback/withSuspense'
import { nearbySDK } from '../../sdk'
import ActivityLayout from '../_lib/ActivityLayout'
import LoadingFallback from '../_lib/LoadingFallback'
import ShowPageLog from '../_lib/ShowPageLog'
import HomeDirectory from '../Home/Directory/HomeDirectory'
import HomeActionCentricUIs from '../Home/HomeActionCentricUIs'
import HomeContents from '../Home/HomeContents'
import HomeIOSNavDivider from '../Home/HomeIOSNavDivider'
import { useExtendFAB } from '../Home/hooks/useFabExtend'
import PageScrollTopChecker from '../Home/PageScrollTopChecker'

const PageHome: ActivityComponentType = ({ params }) => {
  console.info(`%c get query string: ${JSON.stringify(params)}`, 'background: orange; color: white')
  const [fetchKey, refetch] = useReducer((key) => key + 1, 0)

  const { os, version } = useRecoilValue(appAtom)

  const { nearbyLog } = useLogger()

  useRemoteWebview(NEARBY_REMOTE_APP, () => {
    nearbyLog({
      params: {
        name: 'access_local_webview',
        os: os,
        version: version,
      },
    })
  })

  useAPIHealthCheck()

  const [mainElement, setMainElement] = useState<HTMLDivElement | null>(null)
  const pullToRefreshRef = useRef<PullToRefreshRef>(null)
  const $scrollElementRef = pullToRefreshRef.current?.scrollElementRef
  const $scrollContainer = $scrollElementRef?.current

  useEffect(() => {
    ;(window as any).nearby = nearbySDK

    const disposeListen = nearbySDK.listen({
      interval: 1000,
    })

    return () => {
      disposeListen()
    }
  }, [])

  useEffect(() => {
    const disposeOnReloadHome = nearbySDK.onReloadHome(async () => {
      if (STAGE !== 'development' && window.history.length > 1) {
        window.history.go(-window.history.length + 1)
        await delay(50)
      }

      if ($scrollContainer) {
        $scrollContainer.scrollTo({
          top: 0,
        })
      }
      await delay(50)
    })

    return () => {
      disposeOnReloadHome()
    }
  }, [$scrollContainer])

  const handlePullToReferesh: PropOf<typeof PullToRefresh, 'onPullToRefresh'> = useCallback(async () => {
    nearbyLog({
      params: {
        name: 'pull_to_refresh',
      },
    })

    // TODO THIS WORK
    // reloadCurrentPosition()
    refetch()

    await delay(500)
  }, [nearbyLog])

  const handleVisibilityChange = useCallback((isVisible: boolean) => {
    console.info(
      `%c visibility change: %c ${document.visibilityState}`,
      'background: green; color: white',
      'background: white; color: green'
    )

    if (isVisible) {
      nearbySDK.resume()
      return
    }

    nearbySDK.pause()
  }, [])

  const handleTabClick = useCallback(
    async (data: NearbyTabClickData) => {
      console.info(`%c NEARBY_TABCLICK_FROM_NATIVE: ${JSON.stringify(data)}`, 'background: green; color: white')

      if (data.clickedTab === 'nearby') {
        if ($scrollContainer) {
          $scrollContainer.scrollTo({
            top: 0,
            behavior: 'smooth',
          })
        }

        await delay(50)
      }
    },
    [$scrollContainer]
  )

  const isNearbyV2 = useMemo(
    () =>
      satisfyVersion({
        os: os,
        version: version,
        androidVersionCheck: NEARBY_V2_VERSION_CHECK.android,
        iosVersionCheck: NEARBY_V2_VERSION_CHECK.ios,
      }),
    [os, version]
  )

  console.log('#isNearbyV2', os, isNearbyV2)

  useBridgeStreamListener('NEARBY_TABCLICK_FROM_NATIVE', handleTabClick)

  const { changeFABExtends } = useExtendFAB()

  return (
    <ActivityLayout activityName="Home" onAcitivityVisibilityChange={handleVisibilityChange}>
      <ShowPageLog name="nearby_event" params={{ name: 'show_onload', ...params }} />
      <Container>
        {!isNearbyV2 && (
          <>
            <HomeActionCentricUIs />
          </>
        )}
        {<HomeIOSNavDivider mainElement={mainElement} />}
        <Stretched>
          <PullToRefresh
            ref={pullToRefreshRef}
            onPullToRefresh={handlePullToReferesh}
            backgroundColor={vars.$semantic.color.paperBackground}>
            <Main ref={setMainElement}>
              <PageScrollTopChecker onChange={changeFABExtends} />
              <HomeDirectory />
              <Suspense fallback={<ContentsLoading />}>
                <HomeContents fetchKey={fetchKey} />
              </Suspense>
              <HomeCreatePoiOrBizProfile />
            </Main>
            <Spotlight scrollElementRef={$scrollElementRef} />
          </PullToRefresh>
        </Stretched>
      </Container>
    </ActivityLayout>
  )
}

export default withSuspense(PageHome, () => null)

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`

const Stretched = styled.div`
  flex: 1;
  overflow: hidden;
  position: relative;
`

const Main = styled.div`
  overflow: hidden;
  min-height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  padding-bottom: env(safe-area-inset-bottom);
`

const ContentsLoading = styled(LoadingFallback)`
  position: static;
  flex: 1;
`
