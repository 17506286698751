/**
 * @generated SignedSource<<b1d4f48313878800ed1379f69dbbc4ab>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type BusinessAccountSortOptionEnum = "CREATED_AT_DESC" | "DISTANCE_ASC" | "FOLLOWERS_COUNT_DESC" | "RECOMMENDATION" | "REVIEWS_COUNT_DESC";
export type BusinessPostSortOptionEnum = "CREATED_AT_DESC" | "SCORE_DESC";
export type RangeEnum = "ADJACENT" | "MY" | "RANGE2" | "RANGE3";
import { FragmentRefs } from "relay-runtime";
export type CustomSection_requestInfo$data = {
  readonly businessAccountCreatedAfter: string | null;
  readonly businessAccountCreatedInDays: number | null;
  readonly businessAccountExcludeCategoryIds: ReadonlyArray<number> | null;
  readonly businessAccountFilter: {
    readonly sortOption: BusinessAccountSortOptionEnum;
  } | null;
  readonly businessAccountIncludeCategoryIds: ReadonlyArray<number> | null;
  readonly businessAccountIncludeThemeIds: ReadonlyArray<number> | null;
  readonly businessAccountRequiredDescription: boolean | null;
  readonly businessPostFilter: {
    readonly createdAfter: string | null;
    readonly createdInDays: number | null;
    readonly hasCoupon: boolean | null;
    readonly keywordInContent: ReadonlyArray<string> | null;
    readonly keywordInPost: ReadonlyArray<string> | null;
    readonly keywordInTitle: ReadonlyArray<string> | null;
    readonly minimumImageCount: number | null;
    readonly onePostPerBusinessAccount: boolean | null;
    readonly sortOption: BusinessPostSortOptionEnum;
  } | null;
  readonly range: RangeEnum;
  readonly " $fragmentType": "CustomSection_requestInfo";
};
export type CustomSection_requestInfo$key = {
  readonly " $data"?: CustomSection_requestInfo$data;
  readonly " $fragmentSpreads": FragmentRefs<"CustomSection_requestInfo">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sortOption",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CustomSection_requestInfo",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "businessAccountCreatedAfter",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "businessAccountCreatedInDays",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "businessAccountIncludeCategoryIds",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "businessAccountExcludeCategoryIds",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "businessAccountIncludeThemeIds",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "businessAccountRequiredDescription",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BusinessPostFilter",
      "kind": "LinkedField",
      "name": "businessPostFilter",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "createdAfter",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "createdInDays",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hasCoupon",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "keywordInContent",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "keywordInPost",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "keywordInTitle",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "minimumImageCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "onePostPerBusinessAccount",
          "storageKey": null
        },
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BusinessAccountFilter",
      "kind": "LinkedField",
      "name": "businessAccountFilter",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "range",
      "storageKey": null
    }
  ],
  "type": "RequestInfo",
  "abstractKey": null
};
})();

(node as any).hash = "2fcbfa4c21a490de194007a0ae7ef78d";

export default node;
