import { Portal } from '@daangn/karrot-clothes'
import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import React from 'react'

import DevToolsHalfView from './DevToolsHalfView'
import useSimulateBigFont from './hooks/useSimulateBigFont'

interface DevToolsProps {
  curActivityName: string
}

const DevTools: React.FC<DevToolsProps> = ({ curActivityName }) => {
  const [isDevToolsOpen, setIsDevToolsOpen] = React.useState(false)

  useSimulateBigFont()

  const handleClick = React.useMemo(() => {
    const doubleClickCounter = () => {
      let count = 0
      let timeout: NodeJS.Timeout | null = null

      return () => {
        count++

        if (!timeout) {
          timeout = setTimeout(() => {
            count = 0
            timeout = null
          }, 1000)
        }

        if (count > 1) {
          setIsDevToolsOpen(true)
          clearTimeout(timeout)
          timeout = null
          count = 0
        }
      }
    }

    return doubleClickCounter()
  }, [])

  const handleCloseClick = () => {
    setIsDevToolsOpen(false)
  }

  return (
    <>
      <Portal>
        <HiddenMenu onClick={handleClick}>DEV</HiddenMenu>
      </Portal>

      {<DevToolsHalfView isOpen={isDevToolsOpen} onClose={handleCloseClick} curActivityName={curActivityName} />}
    </>
  )
}

export default DevTools

const HiddenMenu = styled.button`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 50px;
  height: 30px;
  border: none;
  background: ${vars.$scale.color.grayAlpha200};
  color: ${vars.$scale.color.gray00};
  font-weight: bold;
  z-index: 9000;
`
