import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { FC } from 'react'
import { __, match } from 'ts-pattern'

import { distanceToUnion } from '../../../lib/union'

interface BizProfileProps {
  bizAccountName?: string
  distance: {
    distance: number
    name: string
  } | null
}

const BizProfile: FC<BizProfileProps> = ({ bizAccountName, distance }) => {
  return (
    <Container>
      {bizAccountName && <BizProfileName>{bizAccountName}</BizProfileName>}
      {distance &&
        match(distanceToUnion(distance))
          .with({ _TAG: 'name' }, ({ name }) => <BizProfileLocalName>{name}</BizProfileLocalName>)
          .with({ _TAG: 'm' }, ({ m }) => <BizProfileDistance>{`${m}m`}</BizProfileDistance>)
          .with({ _TAG: 'km' }, ({ km }) => <BizProfileDistance> {`${km}km`}</BizProfileDistance>)
          .with(__, () => null)
          .exhaustive()}
    </Container>
  )
}

export default BizProfile

const Container = styled.div`
  display: flex;
  margin-bottom: 0.125rem;
  width: 100%;

  ${vars.$semantic.typography.caption1Regular};
`

const BizProfileName = styled.span`
  color: ${vars.$scale.color.gray600};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

const BizProfileDistance = styled.span`
  display: inline-block;
  flex-shrink: 0;
  color: ${vars.$scale.color.gray600};

  &::before {
    content: '·';
    color: ${vars.$scale.color.gray600};
    margin: 0 0.25rem;
  }
`

const BizProfileLocalName = styled(BizProfileDistance)`
  flex-shrink: 0;
  color: ${vars.$scale.color.gray600};
`
