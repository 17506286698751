import { type SeedSnackbarOptions, useSnackbarAdapter } from '@daangn/sprout-components-snackbar'
import { useActivity } from '@stackflow/react'
import { useCallback, useMemo } from 'react'
import { useRecoilValue } from 'recoil'

import { appAtom } from '@src/features/_global/state/app'
import { satisfyVersion } from '@src/lib/version-check'

import { useOpenTarget } from '../lib/target'
import { karrotBridge } from '../sdk'

type OpenToastProp = Parameters<(typeof karrotBridge)['openToast']>[0]['toast']

export interface ActionProps {
  onClick: () => void
}

export function useToastSnackbar() {
  const snackbarAdapter = useSnackbarAdapter()

  const { os, version } = useRecoilValue(appAtom)
  const isWeb = karrotBridge.driver.getCurrentEnvironment() === 'Web'
  const { name } = useActivity()
  const isNearbyHome = name !== 'Home'

  const { openTarget } = useOpenTarget()

  const openWebviewSnackbar = useCallback(
    (props: SeedSnackbarOptions) => {
      snackbarAdapter.create(props)
    },
    [snackbarAdapter]
  )

  //TODO: openTarget 함수 자체를 넘길 수 있도록 리팩토링 필요
  const openSnackbar = useCallback(
    (props: Omit<OpenToastProp, 'type'>) => {
      const useWebviewSnackbar =
        !isNearbyHome ||
        isWeb ||
        satisfyVersion({
          os,
          version,
          androidVersionCheck: '<22.38.1',
          iosVersionCheck: '<6.8.8',
        })

      if (useWebviewSnackbar) {
        const action = props.button?.action
        const actionLink = action && 'link' in action ? action.link : null
        const actionProps = actionLink
          ? {
              actionLabel: props.button?.body,
              onAction: () => {
                openTarget({
                  targetUri: actionLink.path,
                })
              },
            }
          : null

        return openWebviewSnackbar({
          message: props.body,
          type: 'default',
          timeout: 3000,
          removeDelay: 0,
          shouldCloseOnAction: true,
          ...actionProps,
        })
      }

      karrotBridge.openToast({
        toast: { ...props, type: 'SNACKBAR' },
      })
    },
    [isNearbyHome, isWeb, os, version, openWebviewSnackbar, openTarget]
  )

  const openToast = useCallback((body: OpenToastProp['body']) => {
    karrotBridge.openToast({
      toast: {
        body,
        type: 'TOAST',
      },
    })
  }, [])

  return useMemo(
    () => ({
      openSnackbar,
      openToast,
    }),
    [openSnackbar, openToast]
  )
}
