import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import React, { useEffect } from 'react'
import { graphql, useFragment } from 'react-relay'

import WriteLaunchIcon from './WriteLaunchIcon'
import {
  type WriteLaunch_launch$data,
  type WriteLaunch_launch$key,
} from '../../../../__relay__/WriteLaunch_launch.graphql'
import BadgeEtcNew from '../../../../assets/images/icons/badge_etc_new.svg'
import { type OriginalTarget } from '../../../../lib/target'
import { karrotBridge } from '../../../../sdk'

interface WriteLaunchProps {
  launch: WriteLaunch_launch$key
  onClick: (
    target: OriginalTarget,
    info: {
      launch: WriteLaunch_launch$data
    }
  ) => void
}
const WriteLaunch: React.FC<WriteLaunchProps> = (props) => {
  const launch = useFragment(
    graphql`
      fragment WriteLaunch_launch on WriteLaunchpadIcon {
        title
        externalId
        externalLaunchServiceId
        isNew
        name
        app
        targetUri
        writeRequireBizAccount
        path
        ...WriteLaunchIcon_icon
      }
    `,
    props.launch
  )

  useEffect(() => {
    if (launch.app) {
      karrotBridge.prefetchRouter({
        router: {
          app: launch.app,
        },
      })
    }
  }, [launch.app])

  const onClick = () => {
    if (launch.targetUri) {
      props.onClick(
        {
          targetUri: launch.targetUri,
        },
        {
          launch: launch,
        }
      )
    }

    if (launch.app && launch.path) {
      props.onClick(
        {
          app: launch.app,
          path: launch.path,
          present: 'top',
        },
        {
          launch: launch,
        }
      )
    }
  }

  if (!launch) {
    return null
  }

  return (
    <Button onClick={onClick}>
      <Item>
        <IconContainer>
          <WriteLaunchIcon launch={launch} />
        </IconContainer>
        <ItemLabel>{launch.title}</ItemLabel>
        {launch.isNew && (
          <ItemBadge>
            <BadgeEtcNew />
          </ItemBadge>
        )}
      </Item>
    </Button>
  )
}

const Item = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
`

const Button = styled.div`
  margin-bottom: 1.25rem;
  -webkit-tap-highlight-color: transparent;

  &:last-of-type {
    margin-bottom: 0rem;
  }
`

const IconContainer = styled.div`
  width: 1.5rem;
  height: 1.5rem;

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`

const ItemLabel = styled.div`
  color: ${vars.$scale.color.gray900};
  margin-left: 0.75rem;
  font-size: 1rem;
  line-height: 1.625rem;
`

const ItemBadge = styled.div`
  width: 1rem;
  height: 1rem;
  margin-left: 0.25rem;
  margin-top: 0.0625rem;
  display: flex;
`

export default WriteLaunch
