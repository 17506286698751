import { usePullToRefreshScrollElement } from '@daangn/karrot-clothes/lib/pullToRefresh'
import { chunk } from 'lodash'
import { type FC, useCallback, useMemo } from 'react'
import { graphql, useFragment } from 'react-relay'

import NearbyBizPostItem from './NearbyBizPostItem'
import { type HomeNearbyBizPost_sectionNearbyBizPost$key } from '../../../../__relay__/HomeNearbyBizPost_sectionNearbyBizPost.graphql'
import { useLogger } from '../../../../hooks/useLogger'
import { type PropOf } from '../../../../lib/T/utilTypes'
import { useOpenTarget } from '../../../../lib/target'
import type { SectionInfo } from '../../../../types'
import { Divider } from '../../../_lib/ListSection/Divider'
import { ListEntry } from '../../../_lib/ListSection/ListEntry'
import CarouselContent from '../Common/CarouselContent'
import HomeSectionBase from '../Common/HomeSectionBase'
interface HomeNearbyBizPostProps {
  sectionNearbyBizPost: HomeNearbyBizPost_sectionNearbyBizPost$key
  sectionInfo: SectionInfo
}

const VIEW_ITEM_COUNT = 3

const HomeNearbyBizPost: FC<HomeNearbyBizPostProps> = ({ sectionInfo, ...props }) => {
  const sectionNearbyBizPost = useFragment(
    graphql`
      fragment HomeNearbyBizPost_sectionNearbyBizPost on SectionNearbyBizPost {
        __typename
        bizPosts {
          id
          ...NearbyBizPostItem_bizPost
        }
        loggingMeta {
          swipe
          click
          show
        }
        targetUri
        writeBizTargetUri
        ...HomeSectionBase_sectionBase
      }
    `,
    props.sectionNearbyBizPost
  )

  const scrollContainerRef = usePullToRefreshScrollElement()
  const { nearbyLog, contentsSectionLog, loggingMetaLog } = useLogger()
  const { openTarget } = useOpenTarget()

  const handleImpression = () => {
    contentsSectionLog('show', {
      contentsType: 'nearby_post',
      sectionOrder: sectionInfo.order,
    })

    if (sectionInfo.order === 1) {
      loggingMetaLog(sectionNearbyBizPost.loggingMeta.show)
    }
  }

  const handleMoreClick: NonNullable<PropOf<typeof HomeSectionBase, 'onMoreClick'>> = ({ targetUri }) => {
    contentsSectionLog('click', {
      contentsType: 'nearby_post',
      buttonType: 'more',
      params: {
        sectionOrder: sectionInfo.order,
      },
    })

    loggingMetaLog(sectionNearbyBizPost.loggingMeta.click)

    if (targetUri) {
      openTarget({
        targetUri,
      })
    }
  }

  const handleCarouselListSwipe: PropOf<typeof CarouselContent, 'onListSwipe'> = useCallback(
    (offset) => {
      nearbyLog({
        params: {
          name: 'swipe_contents_section',
          contentsType: 'nearby_post',
          page: offset,
          sectionOrder: sectionInfo.order,
        },
      })

      loggingMetaLog(sectionNearbyBizPost.loggingMeta.swipe)
    },
    [loggingMetaLog, nearbyLog, sectionInfo.order, sectionNearbyBizPost.loggingMeta.swipe]
  )

  const handleBizPostItemClick: PropOf<typeof NearbyBizPostItem, 'onClick'> = useCallback(
    ({ targetUri, badges }) => {
      contentsSectionLog('click', {
        contentsType: 'nearby_post',
        buttonType: 'list',
        params: {
          name: 'click_nearby_post',
          sectionOrder: sectionInfo.order,
          ...(badges.length > 0 ? { badges: badges.join(',') } : null),
        },
      })

      loggingMetaLog(sectionNearbyBizPost.loggingMeta.click)

      openTarget({
        targetUri,
      })
    },
    [contentsSectionLog, loggingMetaLog, openTarget, sectionInfo.order, sectionNearbyBizPost.loggingMeta.click]
  )

  const bizPostItems = useMemo(
    () =>
      chunk(sectionNearbyBizPost.bizPosts, VIEW_ITEM_COUNT).map((bizPosts, index) => (
        <ListEntry key={index}>
          {bizPosts.map((bizPost, index) => (
            <div key={bizPost.id}>
              <NearbyBizPostItem bizPost={bizPost} onClick={handleBizPostItemClick} />
              {index !== bizPosts.length - 1 && <Divider />}
            </div>
          ))}
        </ListEntry>
      )),
    [handleBizPostItemClick, sectionNearbyBizPost.bizPosts]
  )

  return (
    <HomeSectionBase
      sectionBase={sectionNearbyBizPost}
      ImpressionProps={{
        scrollContainerRef,
        onImpression: handleImpression,
        threshold: 0.9,
      }}
      onMoreClick={handleMoreClick}>
      <CarouselContent
        listItems={bizPostItems}
        onListSwipe={handleCarouselListSwipe}
        sectionOrder={sectionInfo.order}
      />
    </HomeSectionBase>
  )
}

export default HomeNearbyBizPost
