import React, { type FC } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { graphql, useFragment } from 'react-relay'

import AutoRequestServiceFieldCuration from './Sections/AutoRequestServiceFieldCuration/AutoRequestServiceFieldCuration'
import ExpertRecommend from './Sections/ExpertRecommend/ExpertRecommend'
import HomeBizLocalFoods from './Sections/HomeBizFoods/HomeBizLocalFoods'
import HomeBizThemes from './Sections/HomeBizTheme/HomeBizThemes'
import HomeCarSavedCost from './Sections/HomeCarSavedCost/HomeCarSavedCost'
import HomeClass from './Sections/HomeClass/HomeClass'
import HomeCoupons from './Sections/HomeCoupons/HomeCoupons'
import HomeCustomSection from './Sections/HomeCustomSection/HomeCustomSection'
import HomeHotBizStore from './Sections/HomeHotBizAccounts/HomeHotBizAccounts'
import HomeHotCarArticle from './Sections/HomeHotCarArticle/HomeHotCarArticle'
import HomeHotJobPosts from './Sections/HomeHotJobPosts/HomeHotJobPosts'
import HomeMaps from './Sections/HomeMap/HomeMaps'
import HomeNativeReview from './Sections/HomeNativeReview/HomeNativeReview'
import HomeNearbyBizPost from './Sections/HomeNearbyBizPost/HomeNearbyBizPost'
import HomeNearbyExerciseBizAccount from './Sections/HomeNearbyExerciseBizAccount/HomeNearbyExerciseBizAccount'
import HomeNearbyJobs from './Sections/HomeNearbyJobs/HomeNearbyJobs'
import HomePopularServiceField from './Sections/HomePopularServiceField/HomePopularServiceField'
import HomePublicBizPost from './Sections/HomePublicBizPost/HomePublicBizPost'
import HomePurpose from './Sections/HomePurpose/HomePurpose'
import HomeRecentlyRealtyArticles from './Sections/HomeRecentlyRealtyArticles/HomeRecentlyRealtyArticles'
import HomeRecommendPosts from './Sections/HomeRecommendPosts/HomeRecommendPosts'
import HomeRequestReview from './Sections/HomeRequestReview/HomeRequestReview'
import HomeReviews from './Sections/HomeReviews/HomeReviews'
import HomeShortForms from './Sections/HomeShortForm/HomeShortForms'
import { type HomeSection_query$key } from '../../__relay__/HomeSection_query.graphql'
import { type HomeSection_section$key, type SectionTypeEnum } from '../../__relay__/HomeSection_section.graphql'
import { pxToRem } from '../../lib/unit'
import type { SectionInfo } from '../../types'

export interface HomeSectionProps {
  sectionInfo: SectionInfo
  section: HomeSection_section$key
  query: HomeSection_query$key
}

const HomeSection: FC<HomeSectionProps> = ({ sectionInfo, ...props }) => {
  const section = useFragment(
    graphql`
      fragment HomeSection_section on Section {
        __typename
        ... on SectionBaseInfo {
          baseInfo {
            sectionType
          }
        }
        ... on SectionMap {
          poiTags {
            __typename
          }
          ...HomeMaps_sectionMap
        }
        ... on SectionBizTheme {
          ...HomeBizThemes_sectionBizTheme
        }
        ... on SectionCoupon {
          ...HomeCoupons_sectionCoupon
        }
        ... on SectionBestBizAccount {
          ...HomeReviews_sectionBestBizAccount
        }
        ... on SectionNearbyJobs {
          ...HomeNearbyJobs_sectionNearbyJobs
        }
        ... on SectionNearbyBizPost {
          ...HomeNearbyBizPost_sectionNearbyBizPost
        }
        ... on SectionClassBizPost {
          ...HomeClass_sectionClassBizPost
        }
        ... on SectionLocalFood {
          ...HomeBizLocalFoods_sectionBizProduct
        }
        ... on SectionPurpose {
          ...HomePurpose_section
        }
        ... on SectionHotCarArticle {
          ...HomeHotCarArticle_section
        }
        ... on SectionHotBizAccount {
          ...HomeHotBizAccounts_sectionNearbyBizPost
        }
        ... on SectionCarSavedCost {
          ...HomeCarSavedCost_sectionCarSavedCost
        }
        ... on SectionRecentlyRealtyArticle {
          ...HomeRecentlyRealtyArticles_sectionRecentlyArticle
        }
        ... on SectionHotJobs {
          ...HomeHotJobPosts_sectionHotJobs
        }
        ... on SectionHotJobs {
          ...HomeHotJobPosts_sectionHotJobs
        }
        ... on SectionNearbyExerciseBizAccount {
          ...HomeNearbyExerciseBizAccount_sectionNearbyExerciseBizAccount
        }
        ... on SectionRecommendPost {
          ...HomeRecommendPosts_sectionRecommendPost
        }
        ... on SectionCustom {
          baseInfo {
            externalId
          }
          ...HomeCustomSection_sectionCustom
        }
        ... on SectionNativeReview {
          ...HomeNativeReview_sectionNativeReview
        }
        ... on SectionPublicBizPost {
          ...HomePublicBizPosts_sectionPublicBizPost
        }
        ... on SectionShortForm {
          baseInfo {
            externalId
            sectionType
          }
          ...HomeShortForms_section
        }
        ... on SectionRequestReview {
          ...HomeRequestReview_sectionRequestReview
        }
        ... on SectionAutoRequestServiceFieldCuration {
          ...AutoRequestServiceFieldCuration_SectionAutoRequestServiceFieldCuration
        }
        ... on SectionExpertRecommend {
          ...ExpertRecommend_SectionExpertRecommend
        }
        ... on SectionPopularServiceField {
          ...HomePopularServiceField_SectionPopularServiceField
        }
      }
    `,
    props.section
  )

  const query = useFragment(
    graphql`
      fragment HomeSection_query on Query {
        viewer {
          id
        }
      }
    `,
    props.query
  )

  const SectionComponent = () => {
    switch (section.__typename) {
      case 'SectionMap':
        if (!query.viewer || !section?.poiTags?.length) {
          return null
        }
        return <HomeMaps sectionMap={section} sectionInfo={sectionInfo} />
      case 'SectionBizTheme':
        if (!query.viewer) {
          return null
        }
        return <HomeBizThemes sectionBizTheme={section} sectionInfo={sectionInfo} />
      case 'SectionRecommendPost':
        if (!query.viewer) {
          return null
        }
        return <HomeRecommendPosts sectionRecommendPost={section} sectionInfo={sectionInfo} />
      case 'SectionCoupon':
        if (!query.viewer) {
          return null
        }
        return <HomeCoupons sectionCoupon={section} sectionInfo={sectionInfo} />
      case 'SectionBestBizAccount':
        return <HomeReviews sectionBestBizAccount={section} sectionInfo={sectionInfo} />
      case 'SectionNearbyJobs':
        return <HomeNearbyJobs sectionNearbyJobs={section} sectionInfo={sectionInfo} />
      case 'SectionNearbyBizPost':
        return <HomeNearbyBizPost sectionNearbyBizPost={section} sectionInfo={sectionInfo} />
      case 'SectionClassBizPost':
        return <HomeClass sectionClassBizPost={section} sectionInfo={sectionInfo} />
      case 'SectionLocalFood':
        return <HomeBizLocalFoods sectionLocalFoods={section} sectionInfo={sectionInfo} />
      case 'SectionPurpose':
        return <HomePurpose sectionPurpose={section} sectionInfo={sectionInfo} />
      case 'SectionHotCarArticle':
        return <HomeHotCarArticle sectionHotCarArticle={section} sectionInfo={sectionInfo} />
      case 'SectionHotBizAccount':
        return <HomeHotBizStore sectionHotBizAccounts={section} sectionInfo={sectionInfo} />
      case 'SectionCarSavedCost':
        return <HomeCarSavedCost sectionCarSavedCost={section} sectionInfo={sectionInfo} />
      case 'SectionRecentlyRealtyArticle':
        return <HomeRecentlyRealtyArticles sectionRealtyArticle={section} sectionInfo={sectionInfo} />
      case 'SectionHotJobs':
        return <HomeHotJobPosts sectionHotJobs={section} sectionInfo={sectionInfo} />
      case 'SectionNearbyExerciseBizAccount':
        return <HomeNearbyExerciseBizAccount sectionNearbyFitnessBizAccount={section} sectionInfo={sectionInfo} />
      case 'SectionCustom':
        return <HomeCustomSection sectionCustomRef={section} sectionInfo={sectionInfo} />
      case 'SectionNativeReview':
        return <HomeNativeReview sectionNativeReview={section} sectionInfo={sectionInfo} />
      case 'SectionPublicBizPost':
        return <HomePublicBizPost sectionPublicBizPost={section} sectionInfo={sectionInfo} />
      case 'SectionShortForm':
        return <HomeShortForms sectionShortForm={section} sectionInfo={sectionInfo} />
      case 'SectionRequestReview':
        return (
          <ErrorBoundary fallbackRender={() => null}>
            <HomeRequestReview sectionRequestReview={section} sectionInfo={sectionInfo} />
          </ErrorBoundary>
        )
      case 'SectionAutoRequestServiceFieldCuration':
        return (
          <AutoRequestServiceFieldCuration sectionAutoRequestServiceFieldCuration={section} sectionInfo={sectionInfo} />
        )
      case 'SectionExpertRecommend':
        return <ExpertRecommend expertRecommendRef={section} sectionInfo={sectionInfo} />
      case 'SectionPopularServiceField':
        return <HomePopularServiceField sectionPopularServiceFieldRef={section} sectionInfo={sectionInfo} />
      default:
        return null
    }
  }

  if (!SectionComponent) {
    return null
  }

  const sectionId =
    sectionInfo.id === 'SectionCustom' || sectionInfo.id === 'CUSTOM'
      ? `CUSTOM_${section.baseInfo?.externalId}`
      : sectionInfo.id

  return (
    <div id={sectionId}>
      <SectionComponent />
    </div>
  )
}

export default React.memo(HomeSection)

export const SECTION_INFO: Record<SectionTypeEnum, { loadingHeight?: string; loadingTitle?: string }> = {
  MAP: {
    loadingHeight: pxToRem(637),
  },
  BIZ_THEME: {
    loadingHeight: pxToRem(530),
  },
  MINI: {},
  BIZ_RECOMMEND_PRODUCT: {},
  COUPON: {
    loadingHeight: pxToRem(270),
  },
  BEST_BIZ_ACCOUNT: {
    loadingHeight: pxToRem(279),
  },
  NEARBY_JOBS: {
    loadingHeight: pxToRem(382),
  },
  NEARBY_BIZ_POST: {
    loadingHeight: pxToRem(389),
  },
  PURPOSE: {
    loadingHeight: pxToRem(128),
  },
  CLASS_BIZ_POST: {
    loadingHeight: pxToRem(418),
  },
  LOCAL_FOOD: {
    loadingHeight: pxToRem(268),
  },
  RECENTLY_BIZ_ACCOUNT: {},
  HOT_CAR_ARTICLE: {
    loadingHeight: pxToRem(245),
  },
  HOT_BIZ_ACCOUNT: {
    // loadingHeight: pxToRem(358),
  },
  CAR_SAVED_COST: {
    loadingHeight: pxToRem(252),
  },
  RECENTLY_REALTY_ARTICLE: {
    loadingHeight: pxToRem(255),
  },
  NEARBY_EXERCISE_BIZ_ACCOUNT: {
    loadingHeight: pxToRem(504),
  },
  HOT_JOBS: {
    loadingHeight: pxToRem(168),
  },
  RECOMMEND_POST: {
    loadingHeight: pxToRem(516),
  },
  NATIVE_REVIEW: {
    loadingHeight: pxToRem(523),
  },
  PUBLIC_BIZ_POST: {
    loadingHeight: pxToRem(394),
  },
  BANNER: {},
  BIZ_FITNESS_PRODUCT: {},
  HOT_KEYWORD: {},
  MY_DAANGN_STORY: {},
  COMMUNITY_POST: {},
  CUSTOM: {},
  SHORT_FORM: {},
  REQUEST_REVIEW: {},
  EXPERT_RECOMMEND: {},
  AUTO_REQUEST_SERVICE_FIELD_CURATION: {},
  POPULAR_SERVICE_FIELD: {},
}
