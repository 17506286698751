import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import React, { useEffect } from 'react'

import { captureException } from '@src/lib/capture-exception'

import { activeEffect } from './styles'
import { STAGE } from '../../constants'
import { useLogger } from '../../hooks/useLogger'

interface NearbyError extends Error {
  cause: Record<string, any> & {
    errorViewMessagㅇe?: string
  }
}

interface ErrorFallbackProps {
  retry?: () => void
  overrides?: {
    text?: string
    retryButtonText?: string
  }
  error?: Error
  ignoreSentryForwarding?: boolean
  className?: string
}
const ErrorFallback: React.FC<ErrorFallbackProps> = ({
  ignoreSentryForwarding = false,
  className,
  overrides,
  error,
  retry,
}) => {
  const nearbyError = error as NearbyError

  const text =
    overrides?.text ??
    nearbyError?.cause?.errorViewMessage ??
    '알 수 없는 오류가 발생했어요\n인터넷 연결을 확인해주세요'
  const retryButtonText = overrides?.retryButtonText ?? '다시 시도'

  const { nearbyLog } = useLogger()

  useEffect(() => {
    if (ignoreSentryForwarding) {
      return
    }

    nearbyLog({
      params: {
        name: 'show_unknown_error_view',
        errorMsg: error?.message,
        error: JSON.stringify(error),
      },
    })

    console.info(`%c ${error}`, 'background: red; color: white')

    if (STAGE === 'production') {
      captureException(error)
    }
  }, [error, nearbyLog, ignoreSentryForwarding])

  const onRetryClick = () => {
    nearbyLog({
      params: {
        name: 'click_unknown_error_retry_button',
      },
    })
    retry?.()
  }

  return (
    <Container className={className}>
      <Centered>
        <Text>{text}</Text>
        {retry && <Button onClick={onRetryClick}>{retryButtonText}</Button>}
      </Centered>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: ${vars.$semantic.color.paperDefault};
  z-index: 1;
`

const Centered = styled.div`
  text-align: center;
`

const Text = styled.div`
  color: ${vars.$scale.color.gray700};
  line-height: 166%;
  margin-bottom: 1rem;
  white-space: pre-wrap;
`

const Button = styled.div`
  color: ${vars.$scale.color.gray900};
  display: inline-block;
  padding: 0.625rem 0.875rem;
  color: ${vars.$scale.color.gray900};
  background-color: ${vars.$scale.color.gray00};
  box-shadow: inset 0 0 0 1px ${vars.$scale.color.gray300};
  border-radius: 0.25rem;
  line-height: 100%;
  font-weight: bold;
  font-size: 0.875rem;

  ${activeEffect()};
`

export default ErrorFallback
