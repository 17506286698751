import { vars } from '@seed-design/design-token'

import { type PurposeSubThemeEnum, type PurposeThemeEnum } from '../../types/schemaEnums'

export type PurposeTheme_ = PurposeThemeEnum
export type PurposeTheme = Lowercase<PurposeTheme_>
export type PurposeSubTheme = PurposeSubThemeEnum

export const SUBTHEME_LIST: Record<PurposeSubTheme, string> = {
  HEALTH: '헬스',
  PILATES: '필라테스',
  YOGA: '요가',
  HOME_REPAIR: '집수리',
  INTERIOR: '인테리어',
  FOOD: '음식',
}

export const DEFULAT_SUBTHEME: Record<PurposeTheme, PurposeSubTheme> = {
  exercise: 'HEALTH',
  remodeling: 'HOME_REPAIR',
  etc: 'FOOD',
}

export const PURPOSE_THEME_INFO: Record<
  PurposeTheme,
  {
    appTitle: string
    desc: string
    style: { backColor: string; color: string }
  }
> = {
  exercise: {
    appTitle: '우리동네 운동',
    desc: '동네에서 꾸준하게 운동해요.',
    style: {
      backColor: `${vars.$scale.color.green50}`,
      color: `${vars.$scale.color.gray900}`,
    },
  },
  remodeling: {
    appTitle: '우리동네 집 수리',
    desc: '당근에서 하면 어렵지 않아요.',
    style: {
      backColor: `${vars.$scale.color.yellow50}`,
      color: `${vars.$scale.color.gray900}`,
    },
  },
  etc: {
    appTitle: '',
    desc: '',
    style: {
      backColor: `${vars.$scale.color.gray50}`,
      color: `${vars.$scale.color.gray900}`,
    },
  },
}

export const getThemeTitleFromSubthemes = (subThemes: ReadonlyArray<PurposeSubTheme>) =>
  subThemes.map((subTheme) => SUBTHEME_LIST[subTheme]).join(' / ')

export const PURPOSE_PAGE_APP_BAR_HEIGHT = '44px'
