import { useCallback, useMemo } from 'react'

import { NEARBY_REMOTE_APP } from '../constants'
import { useOpenTarget } from '../lib/target'
import { karrotBridge } from '../sdk'
import { type NearbyActivityNames, type NearbyActivityParams, useFlow } from '../stackflow/stackflow'

export function useRouter() {
  const flow = useFlow()
  const { openTarget } = useOpenTarget()

  const push = useCallback(
    <T extends NearbyActivityNames>(activity: T, path: string, params: NearbyActivityParams<T>) => {
      if (karrotBridge.driver.getCurrentEnvironment() === 'Web') {
        return flow.push(activity, params)
      }

      const queryParam = new URLSearchParams(params).toString()

      openTarget({
        remote: `${NEARBY_REMOTE_APP}/${path}?${queryParam}`,
        navbar: false,
        scrollable: false,
      })
    },
    [flow, openTarget]
  )

  const pop = useCallback(() => {
    if (karrotBridge.driver.getCurrentEnvironment() === 'Web') {
      return flow.pop()
    }

    karrotBridge.closeRouter({})
  }, [flow])

  const replace = useCallback(
    <T extends NearbyActivityNames>(activity: T, path: string, params: NearbyActivityParams<T>) => {
      if (karrotBridge.driver.getCurrentEnvironment() === 'Web') {
        return flow.replace(activity, params)
      }

      const queryParam = new URLSearchParams(params).toString()

      karrotBridge.replaceRouter({
        router: {
          remote: `${NEARBY_REMOTE_APP}/${path}?${queryParam}`,
        },
      })
    },
    [flow]
  )

  return useMemo(
    () => ({
      push,
      pop,
      replace,
    }),
    [push, pop, replace]
  )
}
