import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { type FC } from 'react'

type Size = 'small' | 'medium'

export interface Tag {
  text?: string | null
  hilight?: 'default' | 'primary' | 'gray' | 'gray700'
}

interface TagGroupProps {
  className?: string
  tags: Tag[]
  size: Size
}

const TagGroup: FC<TagGroupProps> = ({ className, tags, size }) => {
  return (
    <S_TagGroup className={className} size={size}>
      {tags.map((tag) =>
        tag.text ? (
          <S_Tag key={tag.text} hilight={tag.hilight}>
            {tag.text}
          </S_Tag>
        ) : null
      )}
    </S_TagGroup>
  )
}

export default TagGroup

const S_TagGroup = styled.ul<{ size: Size }>`
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;

  ${({ size }) => {
    switch (size) {
      case 'medium':
        return css`
          ${vars.$semantic.typography.caption1Regular};
        `
      case 'small':
        return css`
          ${vars.$semantic.typography.caption2Regular};
        `
    }
  }};
`

const S_Tag = styled.li<{ hilight: Tag['hilight'] }>`
  white-space: break-spaces;
  color: ${vars.$scale.color.gray600};

  ${({ hilight }) => {
    switch (hilight) {
      case 'gray':
        return css`
          font-weight: 700;
        `
      case 'primary':
        return css`
          font-weight: 700;
          color: ${vars.$semantic.color.primary};
        `
      case 'gray700':
        return css`
          font-weight: 700;
          color: ${vars.$scale.color.gray700};
        `
    }
  }};

  &:not(:last-of-type)::after {
    content: '·';
    color: ${vars.$scale.color.gray600};
    margin: 0 0.25rem;
  }
`
