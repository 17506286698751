import { match } from 'ts-pattern'

import { distanceToUnion } from '../union'

export const numberWithComma = (original: number | string): string => {
  const _original = typeof original !== 'string' ? original.toString() : original

  return _original.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const toFloatingPoint = (n: number, fractionDigit = 1) => {
  return (Math.floor(n * 10) / 10).toFixed(fractionDigit)
}

const ASCII_UPPER = 65
const ASCII_LOWER = 97

export const numberToAlphabet = (num: number, isUpperCase = true): string => {
  if (num > 25) {
    throw Error('알파뱃 개수보다 클 수 없음')
  }

  const ascii = isUpperCase ? ASCII_UPPER : ASCII_LOWER

  return String.fromCharCode(ascii + num)
}

export const getEllipsisString = (str: string, limit: number): string =>
  str.length > limit ? `${str.substring(0, limit)}...` : str

const priceUnit = {
  억: 100000000,
  만: 10000,
  천: 1000,
  '': 1,
}
// 514,000
export const maskPrice = (
  numberPrice?: number | null,
  options?: {
    applyPrice?: number // 이 금액 이하로는 바로 적용
    roundPrice?: number // 이 단위로 반올림
  }
) => {
  if (!numberPrice) return numberPrice

  const { applyPrice, roundPrice } = options || {}

  if (applyPrice && numberPrice < applyPrice) {
    return `${numberWithComma(numberPrice)}원`
  }

  if (roundPrice) {
    numberPrice = Math.round(numberPrice / roundPrice) * roundPrice
  }

  const maskedPrice = Object.entries(priceUnit).reduce(
    (acc, [key, value]) => {
      const divPrice = Math.floor(acc.price / value)
      const restPrice = acc.price % value

      if (divPrice <= 1) {
        return { price: acc.price, formattedString: acc.formattedString }
      }

      const formattedPrice = `${numberWithComma(divPrice)}${key}`
      const formattedString = acc.formattedString ? `${acc.formattedString} ${formattedPrice}` : formattedPrice

      return { price: restPrice, formattedString }
    },
    { price: numberPrice, formattedString: '' }
  )

  return `${maskedPrice.formattedString}원`
}

export const pxToRem = (px: number | string) => {
  let base = px

  const fontSize = parseInt(window.getComputedStyle(document.documentElement).fontSize, 10)

  if (typeof px === 'string') {
    if (!px.endsWith('px')) {
      throw Error('px 단위가 아닙니다.')
    }

    base = Number(px.replace('px', ''))
  }

  return `${(base as number) / fontSize}rem`
}

export const fillZero = (width: number, n: number | string, isLeft = true) => {
  const strN = typeof n === 'string' ? n : n.toString()
  return isLeft ? strN.padStart(width, '0') : strN.padEnd(width, '0')
}

export const secondToDuration = (duration: number) => {
  if (!duration) {
    return ''
  }
  const minute = String(Math.floor(duration / 60)).padStart(2, '0')
  const second = String(Math.floor(duration % 60)).padStart(2, '0')

  return [minute, second].join(':')
}

export const formatDistance = (distance: { name?: string; distance: number }) => {
  return match(distanceToUnion(distance))
    .with({ _TAG: 'name' }, ({ name }) => name)
    .with({ _TAG: 'm' }, ({ m }) => `${m}m`)
    .with({ _TAG: 'km' }, ({ km }) => `${km}km`)
    .otherwise(() => null)
}
