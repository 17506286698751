import { usePullToRefreshScrollElement } from '@daangn/karrot-clothes/lib/pullToRefresh'
import styled from '@emotion/styled'
import { FCWithChildren } from 'react'
import { graphql, useFragment } from 'react-relay'

import { BizPostItem_common$key } from '../../../__relay__/BizPostItem_common.graphql'
import { useBizLoggingMeta } from '../../../hooks/useBizLoggingMeta'
import { ListItem } from '../ListSection/ListItem'
import { Flex } from '../styles'

interface BizPostItemProps {
  bizPost: BizPostItem_common$key
  onClick?: () => void
  onImpression?: () => void
  itemRef: React.RefObject<HTMLElement>
  className?: string
  [key: string]: any
}

const BizPostItem: FCWithChildren<BizPostItemProps> = ({
  bizPost,
  onClick,
  onImpression,
  itemRef,
  children,
  className,
}) => {
  const scrollContainerRef = usePullToRefreshScrollElement()

  const bizPostInfo = useFragment(
    graphql`
      fragment BizPostItem_common on BizPost {
        ...useBizLoggingMeta_bizLoggingMeta
      }
    `,
    bizPost
  )

  useBizLoggingMeta({
    targetRef: itemRef,
    scrollContainerRef,
    bizLoggingMetaRef: bizPostInfo,
    onClick,
    onImpression,
  })

  return (
    <BizPostItemStyle ref={itemRef} className={className}>
      {children}
    </BizPostItemStyle>
  )
}

export default BizPostItem

export const BizPostItemStyle = styled(ListItem)`
  ${Flex};
`
