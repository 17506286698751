import pRetry from 'p-retry'
import { useCallback, useEffect } from 'react'
import { graphql, readInlineData } from 'react-relay'

import { useEventLogFetcher } from './useEventLogFetcher'
import { type useBizLoggingMeta_bizLoggingMeta$key } from '../__relay__/useBizLoggingMeta_bizLoggingMeta.graphql'
import { useImpression } from '../components/_lib/Impression'

interface useBizLoggingMetaProps {
  targetRef: React.RefObject<HTMLElement>
  scrollContainerRef?: React.RefObject<HTMLElement>
  bizLoggingMetaRef: useBizLoggingMeta_bizLoggingMeta$key
  onClick?: () => void
  onImpression?: () => void
  disableClickLogging?: boolean
  impressOption?: {
    rootMargin?: string
    threshold?: number
  }
}

export function useBizLoggingMeta({
  targetRef,
  scrollContainerRef,
  bizLoggingMetaRef,
  onClick,
  onImpression,
  disableClickLogging = false,
  impressOption = {
    threshold: 1,
  },
}: useBizLoggingMetaProps) {
  const bizLogging = readInlineData(
    graphql`
      fragment useBizLoggingMeta_bizLoggingMeta on BizLoggingMeta @inline {
        bizDeliveryLoggingMeta {
          impression
          click
        }
      }
    `,
    bizLoggingMetaRef
  )

  const eventLogFetcher = useEventLogFetcher()

  const handleClick = useCallback(() => {
    if (bizLogging.bizDeliveryLoggingMeta?.click) {
      const apiCall = eventLogFetcher(bizLogging.bizDeliveryLoggingMeta.click)

      pRetry(() => apiCall, {
        retries: 3,
      })
    }
    onClick?.()
  }, [bizLogging.bizDeliveryLoggingMeta?.click, eventLogFetcher, onClick])

  const handleImpression = useCallback(() => {
    if (bizLogging.bizDeliveryLoggingMeta?.impression) {
      const apiCall = eventLogFetcher(bizLogging?.bizDeliveryLoggingMeta.impression)
      pRetry(() => apiCall, {
        retries: 3,
      })
    }
    onImpression?.()
  }, [bizLogging.bizDeliveryLoggingMeta?.impression, onImpression, eventLogFetcher])

  useEffect(() => {
    const targetElement = targetRef.current

    if (disableClickLogging || !targetElement) {
      return
    }

    targetElement.addEventListener('click', handleClick)

    return () => {
      if (disableClickLogging || !targetElement) {
        return
      }

      targetElement.removeEventListener('click', handleClick)
    }
  }, [handleClick, disableClickLogging, targetRef])

  useImpression(
    {
      ref: targetRef,
      scrollContainerRef,
      ...impressOption,
    },
    handleImpression,
    []
  )

  return {
    logBizLoggingOnClick: handleClick,
  }
}
