import { FC } from 'react'
import { graphql, useFragment } from 'react-relay'

import { CustomSection_componentType$key } from '@src/__relay__/CustomSection_componentType.graphql'
import { CustomSection_requestInfo$key } from '@src/__relay__/CustomSection_requestInfo.graphql'
import { SectionInfo } from '@src/types'

import { OnItemClick, OnListSwipe } from './Items/types'
import LazyCustomListSection from './LazyCustomListSection'

interface CustomSectionProps {
  sectionInfo: SectionInfo
  onItemClick: OnItemClick
  onListSwipe: OnListSwipe
  requestInfoRef: CustomSection_requestInfo$key
  customTypeRef: CustomSection_componentType$key
  onSectionClose: () => void
}

const CustomSection: FC<CustomSectionProps> = ({
  onItemClick,
  onListSwipe,
  onSectionClose,
  sectionInfo,
  requestInfoRef,
  customTypeRef,
}) => {
  const requestInfo = useFragment(
    graphql`
      fragment CustomSection_requestInfo on RequestInfo {
        businessAccountCreatedAfter
        businessAccountCreatedInDays
        businessAccountIncludeCategoryIds
        businessAccountExcludeCategoryIds
        businessAccountIncludeThemeIds
        businessAccountRequiredDescription
        businessPostFilter {
          createdAfter
          createdInDays
          hasCoupon
          keywordInContent
          keywordInPost
          keywordInTitle
          minimumImageCount
          onePostPerBusinessAccount
          sortOption
        }
        businessAccountFilter {
          sortOption
        }
        range
      }
    `,
    requestInfoRef
  )

  const componentTypeInfo = useFragment(
    graphql`
      fragment CustomSection_componentType on RequestInfo {
        contentType
        businessPostFilter {
          componentType
        }
        businessAccountFilter {
          componentType
        }
      }
    `,
    customTypeRef
  )

  const isBizPost = componentTypeInfo.contentType === 'BUSINESS_POST'
  const isBizAccount = componentTypeInfo.contentType === 'BUSINESS_ACCOUNT'

  const componentType = isBizAccount
    ? componentTypeInfo?.businessAccountFilter?.componentType
    : componentTypeInfo?.businessPostFilter?.componentType

  // TODO. componentType에 list, swipe가 들어갔을 때 결정
  // const componentViewType = componentType?.toLowerCase().includes('list') ? 'list' : 'swipe'
  const componentViewType = componentType === 'BUSINESS_POST_COMPONENT_A' ? 'list' : 'swipe'

  if (!componentType) {
    return null
  }

  if (componentViewType === 'list') {
    return (
      <LazyCustomListSection
        sectionInfo={sectionInfo}
        requestInfo={requestInfo}
        componentType={componentType}
        isBizPost={isBizPost}
        isBizAccount={isBizAccount}
        onItemClick={onItemClick}
        onListSwipe={onListSwipe}
        onSectionClose={onSectionClose}
      />
    )
  }

  // TODO. Swipe Section 나왔을 때 작업
  // return <CustomSwipeSection />
  return null
}

export default CustomSection
