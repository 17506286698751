import { SizeIconProps } from '@daangn/karrot-clothes/lib/types/svg'
import React, { memo } from 'react'

import { Svg } from '@src/components/_lib/styles'

const CouponFilled: React.FC<SizeIconProps> = ({ size = 18, color = '#fff' }) => {
  return (
    <Svg width={size} height={size} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.5 2.5H11V5H12.2V2.5H16.5C16.7761 2.5 17 2.72386 17 3V6.79501C17 7.02557 16.8423 7.22624 16.6183 7.2808C16.0361 7.42262 15.5 8.08672 15.5 9C15.5 9.91328 16.0361 10.5774 16.6183 10.7192C16.8423 10.7738 17 10.9744 17 11.205V15C17 15.2761 16.7761 15.5 16.5 15.5H12.2V13H11V15.5H1.5C1.22386 15.5 1 15.2761 1 15V11.205C1 10.9744 1.15766 10.7738 1.38167 10.7192C1.96389 10.5774 2.5 9.91328 2.5 9C2.5 8.08672 1.96389 7.42262 1.38167 7.2808C1.15766 7.22624 1 7.02557 1 6.79501V3C1 2.72386 1.22386 2.5 1.5 2.5ZM12.2 7.5H11V10.5H12.2V7.5Z"
        fill={color}
      />
    </Svg>
  )
}

export default memo(CouponFilled)
