import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { useState, type FC } from 'react'
import { graphql, useLazyLoadQuery } from 'react-relay'
import { z } from 'zod'

import { type VerticalContentsCloseOnboardingSectionQuery } from '@src/__relay__/VerticalContentsCloseOnboardingSectionQuery.graphql'
import CoveredLazyLoadImage from '@src/components/_lib/CoveredLazyLoadImage'
import Impression from '@src/components/_lib/Impression'
import { FlexAlignCenter, FlexCenter } from '@src/components/_lib/styles'
import { IS_PRODUCTION } from '@src/constants'
import { useLogger } from '@src/hooks/useLogger'
import { goHomeTab } from '@src/lib/scheme-util'
import { nearbyStorage } from '@src/lib/storage/storage'
import withErrorBoundary from '@src/lib/with-fallback/withErrorBoundary'
import withSuspense from '@src/lib/with-fallback/withSuspense'

import IcChevronRightOutlineS from '../../../../assets/images/icons/ic_chevron_right_outline_s.svg'

const Vertical_Service = z.array(z.enum(['중고차', '알바', '부동산']))
type VERTICAL_SERVICE = z.infer<typeof Vertical_Service>[number]

const STOARGE_KEY = 'isViewContentsCloseOnboardingSection'

const GO_HOME_BUTTON = '홈으로 바로가기'

const MAPPER: Record<
  VERTICAL_SERVICE,
  {
    title: string
    desc: string
    image: string
  }
> = {
  알바: {
    title: '알바 공고를 찾으셨나요?',
    desc: '알바는 이제 홈에서 찾을 수 있어요.',
    image:
      'https://assetstorage.krrt.io/1087934554248340709/fd5854be-d690-4b0a-833e-cf53e11b7ac4/width=933,height=453.png',
  },
  중고차: {
    title: '중고차 매물을 찾으셨나요?',
    desc: '중고차는 이제 홈에서 찾을 수 있어요.',
    image:
      'https://assetstorage.krrt.io/1087934554248340709/5279b45e-23ce-4483-8bd0-4c6121a97214/width=933,height=453.png',
  },
  부동산: {
    title: '부동산 매물을 찾으셨나요?',
    desc: '부동산은 이제 홈에서 찾을 수 있어요.',
    image:
      'https://assetstorage.krrt.io/1087934554248340709/3587912c-ed01-4431-8b57-4ba760c5d167/width=933,height=453.png',
  },
}

const VerticalContentsCloseOnboardingSection: FC = () => {
  // lazy load query
  const { viewer } = useLazyLoadQuery<VerticalContentsCloseOnboardingSectionQuery>(
    graphql`
      query VerticalContentsCloseOnboardingSectionQuery {
        viewer {
          verticalSectionRemoveOnboardingServices
        }
      }
    `,
    {}
  )

  const { nearbyLog } = useLogger()

  const isViewContentsCloseOnboardingSection = Vertical_Service.parse(
    JSON.parse(nearbyStorage.getItem(STOARGE_KEY) || '[]')
  )

  const targetVertical = IS_PRODUCTION
    ? viewer?.verticalSectionRemoveOnboardingServices?.find(
        (item) => !isViewContentsCloseOnboardingSection.includes(item as VERTICAL_SERVICE)
      )
    : getRandomItem(['부동산', '중고차', '알바'])

  const [target] = useState(targetVertical ? MAPPER[targetVertical as VERTICAL_SERVICE] : null)

  if (!viewer?.verticalSectionRemoveOnboardingServices) {
    return null
  }

  if (!target || !targetVertical) {
    return null
  }

  const handleGoHomeClick = () => {
    nearbyLog({
      params: {
        name: 'click_verticalContentsCloseOnboarding',
      },
    })

    goHomeTab()
  }

  const handleImpression = () => {
    nearbyLog({
      params: {
        name: 'show_verticalContentsCloseOnboarding',
      },
    })

    if (IS_PRODUCTION) {
      nearbyStorage.setItem(STOARGE_KEY, JSON.stringify([...isViewContentsCloseOnboardingSection, targetVertical]))
    }
  }

  return (
    <Impression onImpression={handleImpression}>
      <S_VerticalContentsCloseOnboardingSection>
        <Group>
          <TextArea>
            <Title>{target.title}</Title>
            <SubTextArea>{target.desc}</SubTextArea>
          </TextArea>
          <ImageArea>
            <S_CoveredLazyLoadImage src={target.image} alt="부동산,중고차,알바 홈 숏컷 온보딩" visibleByDefault />
          </ImageArea>
          <ButtonArea>
            <Button onClick={handleGoHomeClick}>
              {GO_HOME_BUTTON}
              <IconContainer>
                <IcChevronRightOutlineS />
              </IconContainer>
            </Button>
          </ButtonArea>
        </Group>
      </S_VerticalContentsCloseOnboardingSection>
    </Impression>
  )
}

export default withErrorBoundary(
  withSuspense(VerticalContentsCloseOnboardingSection, () => null),
  () => null
)

const S_VerticalContentsCloseOnboardingSection = styled.div`
  background-color: ${vars.$semantic.color.paperDefault};
  padding: 1.25rem 1rem;
`

const Group = styled.div`
  background-color: ${vars.$semantic.color.paperContents};
  border-radius: 0.5rem;
`

const TextArea = styled.div`
  padding: 1.5rem 1rem 1.12rem;

  & > *:not(:last-child) {
    margin-bottom: 0.38rem;
  }
`
const Title = styled.h2`
  ${FlexAlignCenter};
  flex-direction: column;
  margin: 0;
  ${vars.$semantic.typography.subtitle1Bold};
  color: ${vars.$scale.color.gray900};
`
const SubTextArea = styled.p`
  ${FlexCenter};
  ${vars.$semantic.typography.label3Regular};
  margin: 0;
  color: ${vars.$scale.color.gray700};
`
const ImageArea = styled.div`
  margin-bottom: 1.125rem;
  padding: 0 1rem;
  width: calc(100% - (1rem * 2));
  height: 40vw;
  max-height: 20rem;
`
const S_CoveredLazyLoadImage = styled(CoveredLazyLoadImage)`
  & > img {
    height: auto;
    max-width: 40rem;
  }
`
const ButtonArea = styled.div`
  padding: 0rem 1.25rem 1.25rem 1.25rem;
`
const Button = styled.div`
  ${FlexCenter};
  ${vars.$semantic.typography.label3Regular};
  padding: 0.094rem 0;
`
const IconContainer = styled.div`
  ${FlexCenter};
  width: 1rem;
  height: 1rem;
`

function getRandomItem(arr: Array<any>) {
  const index = Math.floor(Math.random() * arr.length)
  return arr[index]
}
