import { Avatar as SeedAvatar, type SeedAvatarProps } from '@daangn/sprout-components-avatar'
import styled from '@emotion/styled'
import { type FC } from 'react'

import { BIZ_PROFILE_THUMBNAIL_PLACEHOLDER } from '@src/constants/bizProfile'
import { USER_PROFILE_PLACEHOLDER } from '@src/constants/user'

import { Flex } from './styles'

type AvatarFallbackProps = React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>

type AvatarProps = Omit<SeedAvatarProps, 'fallback'> & {
  type?: 'User' | 'Biz'
  fallback?: React.ReactNode
}

export const Avatar: FC<AvatarProps> = (props) => {
  const fallback = (
    <SeedFallback>
      <SeedAvatar
        {...props}
        src={props.type === 'User' ? USER_PROFILE_PLACEHOLDER : BIZ_PROFILE_THUMBNAIL_PLACEHOLDER}
        fallback=""
      />
    </SeedFallback>
  )

  return <SeedAvatar {...props} fallback={props.fallback ?? fallback} />
}

export const AvatarFallback: FC<AvatarFallbackProps> = (props) => {
  return <S_AvatarFallback {...props} />
}

const S_AvatarFallback = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: var(--seed-avatar-border-radius);
  display: block;
`

const SeedFallback = styled.div`
  ${Flex}
`
