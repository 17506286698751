import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { type FCWithChildren } from 'react'

import { getEllipsisStyle } from '@src/components/_lib/styles'

type Size = 'small' | 'large'

type PinLabelProps = {
  size: Size
  className?: string
}

export const PinLabel: FCWithChildren<PinLabelProps> = ({ size, children, className }) => {
  return (
    <S_PinLabel size={size} className={className}>
      {children}
    </S_PinLabel>
  )
}

const S_PinLabel = styled.span<{ size: Size }>`
  max-width: 4rem;
  ${getEllipsisStyle(2)};
  color: ${vars.$static.color.staticBlack};
  text-align: center;

  text-shadow: white 2px 0px 0px, white 1.75517px 0.958851px 0px, white 1.0806px 1.68294px 0px,
    white 0.141474px 1.99499px 0px, white -0.832294px 1.81859px 0px, white -1.60229px 1.19694px 0px,
    white -1.97998px 0.28224px 0px, white -1.87291px -0.701566px 0px, white -1.30729px -1.5136px 0px,
    white -0.421592px -1.95506px 0px, white 0.567324px -1.91785px 0px, white 1.41734px -1.41108px 0px,
    white 1.92034px -0.558831px 0px, 2px 3px 1px rgba(0, 0, 0, 0.15);

  ${({ size }) => {
    switch (size) {
      case 'small':
        return css`
          ${vars.$semantic.typography.label6Bold}
        `
      case 'large':
        return css`
          ${vars.$semantic.typography.label4Bold}
        `
    }
  }}
`
