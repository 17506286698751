declare global {
  interface Window {
    gtag: any
    dataLayer?: any[]
  }
}

interface ParamProps {
  [key: string]: string | number | boolean | null | undefined
}

export enum GA_EVENTS_NAME {
  /** 런치패드 아이콘 클릭 이벤트 */
  CLICK_LAUNCH = 'click_launch',
  /** 컨텐츠 섹션 클릭 이벤트 */
  CLICK_CONTENTS_SECTION = 'click_contents_section',
  /** 컨텐츠 섹션 노출 이벤트 */
  SHOW_CONTENTS_SECTION = 'show_contents_section',
  /** 목적페이지 섹션 클릭 이벤트 */
  CLICK_PURPOSE_SECTION = 'click_purpose_section',
  /** 목적페이지 섹션 노출 이벤트 */
  SHOW_PURPOSE_SECTION = 'show_purpose_section',
}

export const GA_EVENTS_NAME_LIST = Object.values(GA_EVENTS_NAME)

const userInfoMap: Record<string, any> = {}

export const gaEvent = (eventName: string, paramObj: ParamProps) => {
  if (typeof window.gtag === 'function') {
    window.gtag('event', eventName, {
      ...userInfoMap,
      ...paramObj,
    })
  }
}

export const gaException = (paramObj: ParamProps) => {
  if (typeof window.gtag === 'function') {
    window.gtag('event', 'exception', {
      ...userInfoMap,
      ...paramObj,
    })
  }
}

export const pushGaDataLayer = (paramObj: ParamProps) => {
  if (typeof window.gtag === 'function') {
    window.dataLayer?.push(paramObj)
  }
}

export const sendPageView = ({ title, params }: { title: string; params?: Record<string, any> }) => {
  if (typeof window.gtag === 'function') {
    window.gtag('event', 'page_view', {
      ...params,
      page_title: title,
      page: window.location.pathname,
      hash: window.location.hash,
      page_path: window.location.href,
    })
  }
}

export const setGAUserConfig = (params: {
  userId: string
  country: string
  userAgent: string
  region?: string
  deviceIdentity: string
}) => {
  userInfoMap['user_id'] = params.userId
  userInfoMap['country'] = params.country
  userInfoMap['user_agent'] = params.userAgent
  userInfoMap['device_identity'] = params.deviceIdentity
  userInfoMap['region'] = params.region

  pushGaDataLayer(params)

  window.gtag('set', 'user_properties', {
    crm_id: params.userId,
  })
}
