/**
 * @generated SignedSource<<f7a9b91a58a3e60225559f83fdc3f8e4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HomeBizThemes_targetUris$data = {
  readonly ALL: string;
  readonly BEAUTY: string;
  readonly CLOSING_SALE: string;
  readonly EDUCATION: string;
  readonly FOLLOWING_AND_RECOMMENDATION: string;
  readonly FOOD: string;
  readonly HEALTH: string;
  readonly LIFE: string;
  readonly " $fragmentType": "HomeBizThemes_targetUris";
};
export type HomeBizThemes_targetUris$key = {
  readonly " $data"?: HomeBizThemes_targetUris$data;
  readonly " $fragmentSpreads": FragmentRefs<"HomeBizThemes_targetUris">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HomeBizThemes_targetUris",
  "selections": [
    {
      "alias": "FOLLOWING_AND_RECOMMENDATION",
      "args": [
        {
          "kind": "Literal",
          "name": "type",
          "value": "FOLLOWING_AND_RECOMMENDATION"
        }
      ],
      "kind": "ScalarField",
      "name": "bizPostsTargetUri",
      "storageKey": "bizPostsTargetUri(type:\"FOLLOWING_AND_RECOMMENDATION\")"
    },
    {
      "alias": "FOOD",
      "args": [
        {
          "kind": "Literal",
          "name": "type",
          "value": "FOOD"
        }
      ],
      "kind": "ScalarField",
      "name": "bizPostsTargetUri",
      "storageKey": "bizPostsTargetUri(type:\"FOOD\")"
    },
    {
      "alias": "LIFE",
      "args": [
        {
          "kind": "Literal",
          "name": "type",
          "value": "LIFE"
        }
      ],
      "kind": "ScalarField",
      "name": "bizPostsTargetUri",
      "storageKey": "bizPostsTargetUri(type:\"LIFE\")"
    },
    {
      "alias": "HEALTH",
      "args": [
        {
          "kind": "Literal",
          "name": "type",
          "value": "HEALTH"
        }
      ],
      "kind": "ScalarField",
      "name": "bizPostsTargetUri",
      "storageKey": "bizPostsTargetUri(type:\"HEALTH\")"
    },
    {
      "alias": "BEAUTY",
      "args": [
        {
          "kind": "Literal",
          "name": "type",
          "value": "BEAUTY"
        }
      ],
      "kind": "ScalarField",
      "name": "bizPostsTargetUri",
      "storageKey": "bizPostsTargetUri(type:\"BEAUTY\")"
    },
    {
      "alias": "EDUCATION",
      "args": [
        {
          "kind": "Literal",
          "name": "type",
          "value": "EDUCATION"
        }
      ],
      "kind": "ScalarField",
      "name": "bizPostsTargetUri",
      "storageKey": "bizPostsTargetUri(type:\"EDUCATION\")"
    },
    {
      "alias": "ALL",
      "args": [
        {
          "kind": "Literal",
          "name": "type",
          "value": "ALL"
        }
      ],
      "kind": "ScalarField",
      "name": "bizPostsTargetUri",
      "storageKey": "bizPostsTargetUri(type:\"ALL\")"
    },
    {
      "alias": "CLOSING_SALE",
      "args": [
        {
          "kind": "Literal",
          "name": "type",
          "value": "CLOSING_SALE"
        }
      ],
      "kind": "ScalarField",
      "name": "bizPostsTargetUri",
      "storageKey": "bizPostsTargetUri(type:\"CLOSING_SALE\")"
    }
  ],
  "type": "SectionBizTheme",
  "abstractKey": null
};

(node as any).hash = "01b0be6441e28298936654064851e777";

export default node;
