import { Math } from 'globalthis/implementation'

import { DISTANCE_UNIT_LIMIT } from '../../constants'

export const distanceToUnion = (
  {
    distance,
    name,
  }: {
    distance: number
    name?: string
  },
  { useOverMark = false, distanceLimit = DISTANCE_UNIT_LIMIT }: { useOverMark: boolean; distanceLimit?: number } = {
    useOverMark: false,
    distanceLimit: DISTANCE_UNIT_LIMIT,
  }
) => {
  if (!useOverMark && distance >= distanceLimit && name) {
    return { _TAG: 'name', name }
  }

  const { _distance, isOverMark } =
    useOverMark && distanceLimit < distance
      ? { _distance: distanceLimit, isOverMark: true }
      : { _distance: distance, isOverMark: false }

  const km = Math.ceil(_distance / 100) / 10

  return _distance < 1000
    ? { _TAG: 'm' as const, m: Math.ceil(_distance), isOverMark }
    : {
        _TAG: 'km' as const,
        km: useOverMark && km > Math.ceil(distanceLimit / 1000) ? Math.floor(km) : km,
        isOverMark,
      }
}
