import { PropOf } from '@cometjs/react-utils'
import styled from '@emotion/styled'
import React from 'react'
import { graphql, useFragment } from 'react-relay'

import WriteLaunch from './WriteLaunch'
import { WriteLaunches_query$key } from '../../../../__relay__/WriteLaunches_query.graphql'

interface WriteLaunchesProps {
  query: WriteLaunches_query$key
  onLaunchClick: PropOf<typeof WriteLaunch, 'onClick'>
}
const WriteLaunches: React.FC<WriteLaunchesProps> = (props) => {
  const query = useFragment(
    graphql`
      fragment WriteLaunches_query on Query @argumentDefinitions(regionId: { type: "Int!" }) {
        regionByRegionId(regionId: $regionId) {
          writeLaunches {
            id
            ...WriteLaunch_launch
          }
        }
      }
    `,
    props.query
  )

  return (
    <Items>
      {query.regionByRegionId.writeLaunches.map((launch) => (
        <WriteLaunch key={launch.id} launch={launch} onClick={props.onLaunchClick} />
      ))}
    </Items>
  )
}

const Items = styled.div``

export default WriteLaunches
