import { graphql, useLazyLoadQuery } from 'react-relay'

import { type HomeActionCentricUIsQuery } from '@src/__relay__/HomeActionCentricUIsQuery.graphql'
import { useHomeFAB } from '@src/hooks/useHomeFAB'
import { useViewer } from '@src/hooks/useViewer'

import HomeNavbar from './HomeNavbar'
import HomeWriteFloatingActionButton from './Modals/HomeWriteFloatingActionButton'

const HomeActionCentricUIs: React.FC = () => {
  const viewer = useViewer()

  const query = useLazyLoadQuery<HomeActionCentricUIsQuery>(
    graphql`
      query HomeActionCentricUIsQuery($regionId: Int!) {
        ...HomeNavbar_query
        ...HomeWriteFloatingActionButton_query @arguments(regionId: $regionId)
      }
    `,
    {
      regionId: viewer.region.id || 0,
    }
  )

  const { handleFABModalOpenChange, openFABModal } = useHomeFAB()

  return (
    <>
      <HomeNavbar query={query} />
      <HomeWriteFloatingActionButton
        query={query}
        onModalOpenChange={handleFABModalOpenChange}
        openModal={openFABModal}
      />
    </>
  )
}

export default HomeActionCentricUIs
