import { atom } from 'recoil'

import recoilKeyGenerator from '@src/lib/recoil-util'

const generateDevtoolsStateKey = recoilKeyGenerator('devtools')

export const mapOptionDebugViewAtom = atom<boolean>({
  key: generateDevtoolsStateKey('mapOptionView'),
  default: false,
})
