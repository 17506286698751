/**
 * @generated SignedSource<<a3c843afee21eb290a7f3be9c3a91f43>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HomePopularServiceField_SectionPopularServiceField$data = {
  readonly loggingMeta: {
    readonly click: string;
    readonly show: string;
    readonly swipe: string;
  };
  readonly serviceFields: ReadonlyArray<{
    readonly id: string;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"HomePopularServiceFieldBasicList_SectionPopularServiceField" | "HomeSectionBase_sectionBase">;
  readonly " $fragmentType": "HomePopularServiceField_SectionPopularServiceField";
};
export type HomePopularServiceField_SectionPopularServiceField$key = {
  readonly " $data"?: HomePopularServiceField_SectionPopularServiceField$data;
  readonly " $fragmentSpreads": FragmentRefs<"HomePopularServiceField_SectionPopularServiceField">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HomePopularServiceField_SectionPopularServiceField",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "EventMeta",
      "kind": "LinkedField",
      "name": "loggingMeta",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "swipe",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "click",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "show",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ExpertServiceField",
      "kind": "LinkedField",
      "name": "serviceFields",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HomeSectionBase_sectionBase"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HomePopularServiceFieldBasicList_SectionPopularServiceField"
    }
  ],
  "type": "SectionPopularServiceField",
  "abstractKey": null
};

(node as any).hash = "ebd511e8a9eba22e6db9d8470e4058ed";

export default node;
