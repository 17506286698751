import { Tooltip2 } from '@blueprintjs/popover2'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { type RefObject, type FC } from 'react'
import { CSSTransition } from 'react-transition-group'

import NodeRef from '@src/components/_lib/NodeRef'

import { nearbySDK } from '../../../sdk'
import { SPOTLIGHT_ANIMATION_DURATION, useSpotlight } from '../hooks/useSpotlight'

interface SpotlightProps {
  scrollElementRef?: RefObject<HTMLDivElement>
}

const Spotlight: FC<SpotlightProps> = ({ scrollElementRef }) => {
  const { spotlightBox, spotlightTooltipBox } = useSpotlight({
    nearbySDK,
    scrollElementRef,
  })

  return (
    <>
      {spotlightBox.dimmed && (
        <NodeRef>
          {(ref) => (
            // @ts-ignore
            <CSSTransition nodeRef={ref} in={spotlightBox.opened} timeout={SPOTLIGHT_ANIMATION_DURATION} unmountOnExit>
              <SpotlightBox ref={ref} boundingClientRects={spotlightBox} />
            </CSSTransition>
          )}
        </NodeRef>
      )}
      <SpotlightTooltipBox boundingClientRects={spotlightTooltipBox}>
        {/* @ts-ignore */}
        <Tooltip2
          isOpen={spotlightTooltipBox.opened}
          usePortal
          placement={spotlightTooltipBox.placement}
          content={<SpotlightTooltipContent>{spotlightTooltipBox.content}</SpotlightTooltipContent>}>
          <SpotlightTooltipBoxRect />
        </Tooltip2>
      </SpotlightTooltipBox>
    </>
  )
}

export default Spotlight

interface SpotlightBoxProps {
  boundingClientRects: {
    top: number
    left: number
    width: number
    height: number
  }
}
const SpotlightBox = styled.div<SpotlightBoxProps>`
  position: fixed;
  border-radius: 0.5rem;
  z-index: 9999;
  box-shadow: 0 0 0 100rem ${vars.$scale.color.grayAlpha500};
  opacity: 0;
  transform: scale(2);
  transition: ${SPOTLIGHT_ANIMATION_DURATION}ms;
  top: ${(props) => props.boundingClientRects.top}px;
  left: ${(props) => props.boundingClientRects.left}px;
  width: ${(props) => props.boundingClientRects.width}px;
  height: ${(props) => props.boundingClientRects.height}px;

  &.enter-active,
  &.enter-done {
    opacity: 1;
    transform: scale(1);
  }
  &.exit-active,
  &.exit-done {
    opacity: 0;
    transform: scale(2);
  }
`

interface SpotlightTooltipBoxProps {
  boundingClientRects: {
    opened: boolean
    top: number
    left: number
    width: number
    height: number
  }
}
const SpotlightTooltipBox = styled.div<SpotlightTooltipBoxProps>`
  width: 100%;
  height: 100%;
  position: fixed;
  display: none;
  top: ${(props) => props.boundingClientRects.top}px;
  left: ${(props) => props.boundingClientRects.left}px;
  width: ${(props) => props.boundingClientRects.width}px;
  height: ${(props) => props.boundingClientRects.height}px;

  ${(props) =>
    props.boundingClientRects.opened &&
    css`
      display: block;
    `}

  & > span.bp3-popover2-target {
    width: 100%;
    height: 100%;
    position: relative;
  }
`

const SpotlightTooltipBoxRect = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

const SpotlightTooltipContent = styled.div`
  white-space: pre-wrap;
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1.45;
`
