import styled from '@emotion/styled'
import { FC, ReactNode, useRef, useState } from 'react'

import { useSwipeSection } from '../../../../hooks/sections/useSwipeSection'
import { PropOf } from '../../../../lib/T/utilTypes'
import { CarouselIndicator, ScrollSnapCarousel } from '../../../_lib/ScrollSnapCarousel'

interface CarouselContentProps {
  className?: string
  listItems: ReactNode[]
  onListSwipe: (offset: number) => void
  sectionOrder: number
}

const CarouselContent: FC<CarouselContentProps> = ({ className, listItems, onListSwipe, sectionOrder }) => {
  const carouselContentRef = useRef<HTMLDivElement>(null)
  const [carouselOffset, setCarouselOffset] = useState(0)
  const maximumPage = listItems.length

  const handleCarousleOffsetChange: PropOf<typeof ScrollSnapCarousel, 'onOffsetChange'> = (offset) => {
    if (offset === carouselOffset) {
      return
    }

    setCarouselOffset(offset)
  }

  const handleItemListImpression: PropOf<typeof ScrollSnapCarousel, 'onImpressionItem'> = (offset) => {
    if (offset === 0) {
      return
    }

    onListSwipe?.(offset)
  }

  useSwipeSection({
    swipeContainerRef: carouselContentRef,
    sectionOrder,
  })

  return (
    <CarouselContentContainer ref={carouselContentRef} className={className}>
      <ScrollSnapCarousel
        items={listItems}
        onOffsetChange={handleCarousleOffsetChange}
        onImpressionItem={handleItemListImpression}
      />
      <CarouselIndicator activeOffset={carouselOffset} maxOffset={maximumPage} />
    </CarouselContentContainer>
  )
}

export default CarouselContent

const CarouselContentContainer = styled.div``
