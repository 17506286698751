import { useCallback, useMemo } from 'react'
import { useRecoilValue } from 'recoil'

import { appAtom } from '@src/features/_global/state/app'
import { satisfyVersion } from '@src/lib/version-check'
import { localBusinessBridge } from '@src/sdk'

const IOS_VERSION = '>=23.24.0'
const ANDROID_VERSION = '>=23.24.0'

export function useExtendFAB() {
  const { os, version } = useRecoilValue(appAtom)

  const isEnableExtendFAB = useMemo(
    () =>
      satisfyVersion({
        os,
        version,
        androidVersionCheck: ANDROID_VERSION,
        iosVersionCheck: IOS_VERSION,
      }),
    [os, version]
  )

  const changeFABExtends = useCallback(
    (isExtends: boolean) => {
      if (!isEnableExtendFAB) {
        return
      }

      if (isExtends) {
        localBusinessBridge.extendFab({})
        return
      }

      localBusinessBridge.shortenFab({})
    },
    [isEnableExtendFAB]
  )

  return {
    changeFABExtends,
  }
}
