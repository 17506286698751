import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import React, { useRef, useState } from 'react'
import { graphql, useFragment } from 'react-relay'
import CSSTransition from 'react-transition-group/CSSTransition'
import { useRecoilValue } from 'recoil'

import { appAtom } from '@src/features/_global/state/app'
import { regionAtom } from '@src/features/user/state/region'

import HomeNavbarRegionModal from './Modals/HomeNavbarRegionModal'
import { type HomeNavbar_query$key } from '../../__relay__/HomeNavbar_query.graphql'
import IcAppBarTopBell from '../../assets/images/icons/ic_app_bar_top_bell.svg'
import IcAppBarTopSearch from '../../assets/images/icons/ic_app_bar_top_search.svg'
import IcExpandMore from '../../assets/images/icons/ic_expand_more.svg'
import { NEARBY_SEARCH_SCHEME, SEARCH_APP } from '../../constants'
import { useLogger } from '../../hooks/useLogger'
import { useOpenTarget } from '../../lib/target'
import { satisfyVersion } from '../../lib/version-check'
import { karrotBridge } from '../../sdk'
import { FlexCenter } from '../_lib/styles'
import { useAlertModal } from '../_modals/useAlertModal'

interface HomeNavbarProps {
  query: HomeNavbar_query$key
}

const HomeNavbar: React.FC<HomeNavbarProps> = ({ ...props }) => {
  const { log } = useLogger()
  const { openTarget } = useOpenTarget()
  const { openAlertModal } = useAlertModal()
  const { os, version } = useRecoilValue(appAtom)
  const { name: regionName } = useRecoilValue(regionAtom)

  const searchIconRef = useRef<HTMLDivElement>(null)

  const query = useFragment(
    graphql`
      fragment HomeNavbar_query on Query {
        viewer {
          region {
            id
          }
          ...HomeNavbarRegionModal_viewer
        }
      }
    `,
    props.query
  )

  const onSearchClick = () => {
    log({
      name: 'nearby_event',
      params: {
        name: 'click_navbar_search',
      },
    })

    // 이관된 내근처 검색 웹뷰로 이동
    const satisfied = satisfyVersion({
      os: os,
      version: version,
      androidVersionCheck: '>=6.5.0',
      iosVersionCheck: '>=6.8.4',
    })

    if (satisfied) {
      return (window.location.href = NEARBY_SEARCH_SCHEME)
    }

    openTarget({
      app: SEARCH_APP,
      path: '/nearby-search',
    })
  }
  const onNotiClick = () => {
    log({
      name: 'nearby_event',
      params: {
        name: 'click_navbar_noti',
      },
    })
    openTarget({
      schemePath: 'notifications',
    })
  }

  const [expanded, setExpanded] = useState(false)
  const regionModalRef = useRef<HTMLDivElement>(null)

  const handleRegionChangeModalOpen = () => {
    if (!query.viewer?.region) {
      return openAlertModal({
        message: '동네인증이 되어있지않아 동네변경이 불가능해요.',
      })
    }

    log({
      name: 'nearby_event',
      params: {
        name: 'click_navbar_region',
      },
    })

    setExpanded((expanded) => !expanded)
  }

  return (
    <Container>
      <Flex>
        <LeftSide>
          <Region onClick={handleRegionChangeModalOpen}>
            <RegionName>{regionName}</RegionName>
            <Expand expanded={expanded}>
              <IcExpandMore />
            </Expand>
          </Region>

          {query.viewer && (
            // @ts-ignore
            <CSSTransition nodeRef={regionModalRef} in={expanded} timeout={200} mountOnEnter unmountOnExit>
              <HomeNavbarRegionModal
                nodeRef={regionModalRef}
                viewer={query.viewer}
                onRegionChange={({ regionId }) => {
                  karrotBridge.changeRegion({
                    region: {
                      regionId: Number(regionId),
                    },
                  })
                }}
                onChangeRegionClick={() => {
                  openTarget({
                    schemePath: 'settings/change_region',
                  })
                }}
                onOutsideClick={() => {
                  setExpanded(false)
                }}
              />
            </CSSTransition>
          )}
        </LeftSide>
        <Space />
        <RightSide>
          <Icons>
            <Icon ref={searchIconRef} onClick={onSearchClick}>
              <IcAppBarTopSearch />
            </Icon>
            <Icon onClick={onNotiClick}>
              <IcAppBarTopBell />
            </Icon>
          </Icons>
        </RightSide>
      </Flex>
    </Container>
  )
}

const Container = styled.div`
  background-color: ${vars.$semantic.color.paperDefault};

  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: content-box;
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);

  ${(props) =>
    props.theme.environment === 'android' &&
    css`
      height: 3.5rem;
    `}
  ${(props) =>
    props.theme.environment === 'cupertino' &&
    css`
      height: 2.75rem;
    `}

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    height: 0.5px;
    width: 100%;
    background-color: ${vars.$semantic.color.divider3};
  }
`

const Flex = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`

const Side = styled.div`
  color: ${vars.$scale.color.gray900};
  display: flex;
  align-items: center;
  height: 100%;
`

const LeftSide = styled(Side)`
  padding-left: 1rem;
`

const RightSide = styled(Side)`
  padding-right: 1rem;
`

const Space = styled.div`
  flex: 1;
`

const Region = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  ${(props) =>
    props.theme.environment === 'android' &&
    css`
      transform: translate(4px, 2px);
    `}

  ${(props) =>
    props.theme.environment === 'cupertino' &&
    css`
      transform: translateY(-1px);
    `}
`

const Expand = styled.div<{ expanded: boolean }>`
  ${FlexCenter};
  margin-left: 0.25rem;
  width: 1rem;
  height: 1rem;
  transition: transform 300ms;

  svg {
    path {
      fill: ${vars.$scale.color.gray900};
    }
  }

  ${(props) =>
    props.expanded &&
    css`
      letter-spacing: 0;
      transform: rotate(180deg);
    `}
`

const RegionName = styled.div`
  font-weight: bold;
  line-height: 1.5rem;
  color: ${vars.$scale.color.gray900};
  font-size: 1.25rem;

  ${(props) =>
    props.theme.environment === 'android' &&
    css`
      font-size: 1.188rem;
    `}
`

const Icons = styled.div`
  display: flex;
  margin-right: -0.5rem;
  margin-bottom: 0.0625rem;

  ${(props) =>
    props.theme.environment === 'cupertino' &&
    css`
      margin-right: -0.25rem;
    `}

  ${(props) =>
    props.theme.environment === 'android' &&
    css`
      margin-right: -0.9325rem;
    `}
`

const Icon = styled.div<{ fade?: boolean }>`
  color: ${vars.$scale.color.gray900};
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  padding: 0.375rem;
  margin: 0 0.125rem;
  display: flex;
  border-radius: 50%;

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }

  ${(props) =>
    props.theme.environment === 'cupertino' &&
    css`
      margin: 0 0.0625rem;
      transition: 300ms;

      &:active {
        opacity: 0.2;
        transition: 0s;
      }
    `}

  ${(props) =>
    props.theme.environment === 'android' &&
    css`
      margin: 0 0.375rem;
      transition: 300ms;

      &:active {
        background-color: ${vars.$scale.color.grayAlpha200};
        transition: 0s;
      }
    `}

  ${(props) =>
    props.fade &&
    css`
      opacity: 0;
      transform: translateY(1rem);
      transition: 150ms;

      &.enter-active,
      &.enter-done {
        opacity: 1;
        transform: translateY(0);
      }
      &.exit-active,
      &.exit-done {
        opacity: 0;
        transform: translateY(1rem);
      }
    `}
`

export default HomeNavbar
