/**
 * @generated SignedSource<<7cad2fbf891af00a5e0dcdc0aec29a27>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type PoiTabCategoryEnum = "BEAUTY" | "CAFE" | "EXERCISE" | "FOOD" | "HOSPITAL" | "PET";
import { FragmentRefs } from "relay-runtime";
export type HomeMaps_sectionMap$data = {
  readonly baseInfo: {
    readonly title: string;
  } | null;
  readonly loggingMeta: {
    readonly click: string;
    readonly show: string;
    readonly swipe: string;
  };
  readonly region: {
    readonly name3: string;
  };
  readonly tabs: ReadonlyArray<{
    readonly __typename: string;
    readonly originalTabType?: PoiTabCategoryEnum;
    readonly targetUri?: string;
    readonly title?: string;
    readonly type?: PoiTabCategoryEnum;
    readonly " $fragmentSpreads": FragmentRefs<"HomeMapsTabList_tab">;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"HomeSectionBase_sectionBase">;
  readonly " $fragmentType": "HomeMaps_sectionMap";
};
export type HomeMaps_sectionMap$key = {
  readonly " $data"?: HomeMaps_sectionMap$data;
  readonly " $fragmentSpreads": FragmentRefs<"HomeMaps_sectionMap">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "targetUri",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HomeMaps_sectionMap",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Region",
      "kind": "LinkedField",
      "name": "region",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name3",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "tabs",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "type",
              "storageKey": null
            },
            (v1/*: any*/)
          ],
          "type": "PoiTab",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "originalTabType",
              "storageKey": null
            },
            (v1/*: any*/)
          ],
          "type": "LazyPoiTab",
          "abstractKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "HomeMapsTabList_tab"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EventMeta",
      "kind": "LinkedField",
      "name": "loggingMeta",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "swipe",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "click",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "show",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BaseInfo",
      "kind": "LinkedField",
      "name": "baseInfo",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HomeSectionBase_sectionBase"
    }
  ],
  "type": "SectionMap",
  "abstractKey": null
};
})();

(node as any).hash = "b72c8aa83a44b115cf2c17b6ed0c6144";

export default node;
