import { usePullToRefreshScrollElement } from '@daangn/karrot-clothes/lib/pullToRefresh'
import { FC, useCallback, useRef } from 'react'
import { graphql, useFragment } from 'react-relay'

import { PropOf } from '@src/lib/T/utilTypes'

import HomeBizLocalFoodsItem from './HomeBizLocalFoodsItem'
import { HomeBizLocalFoods_sectionBizProduct$key } from '../../../../__relay__/HomeBizLocalFoods_sectionBizProduct.graphql'
import { useSwipeSection } from '../../../../hooks/sections/useSwipeSection'
import { useLogger } from '../../../../hooks/useLogger'
import { useOpenTarget } from '../../../../lib/target'
import type { SectionInfo } from '../../../../types'
import { SwipeEntry } from '../../../_lib/SwipeSection/SwipeEntry'
import HomeSectionBase from '../Common/HomeSectionBase'

interface HomeBizLocalFoodsProps {
  sectionLocalFoods: HomeBizLocalFoods_sectionBizProduct$key
  sectionInfo: SectionInfo
}

const HomeBizLocalFoods: FC<HomeBizLocalFoodsProps> = ({ sectionInfo, ...props }) => {
  const scrollContainerRef = usePullToRefreshScrollElement()
  const itemScrollContainerRef = useRef<HTMLDivElement>(null)

  const { nearbyLog, contentsSectionLog, loggingMetaLog } = useLogger()
  const { openTarget } = useOpenTarget()

  const sectionBizProduct = useFragment(
    graphql`
      fragment HomeBizLocalFoods_sectionBizProduct on SectionLocalFood {
        bizProducts {
          id
          ...HomeBizLocalFoodsItem_bizProduct
        }
        targetUri
        wishListTargetUri
        loggingMeta {
          swipe
          click
          show
        }
        ...HomeSectionBase_sectionBase
      }
    `,
    props.sectionLocalFoods
  )

  const handleImpression = () => {
    contentsSectionLog('show', {
      contentsType: 'local_food',
      sectionOrder: sectionInfo.order,
    })

    if (sectionInfo.order === 1) {
      loggingMetaLog(sectionBizProduct.loggingMeta.show)
    }
  }

  const handleMoreClick: NonNullable<PropOf<typeof HomeSectionBase, 'onMoreClick'>> = useCallback(
    ({ targetUri }) => {
      contentsSectionLog('click', {
        contentsType: 'local_food',
        buttonType: 'more',
        params: {
          sectionOrder: sectionInfo.order,
        },
      })

      loggingMetaLog(sectionBizProduct.loggingMeta.click)

      if (targetUri) {
        openTarget({
          targetUri,
        })
      }
    },
    [loggingMetaLog, contentsSectionLog, sectionInfo.order, openTarget, sectionBizProduct.loggingMeta.click]
  )

  useSwipeSection({
    swipeContainerRef: itemScrollContainerRef,
    sectionOrder: sectionInfo.order,
  })

  const handleSwipeItem = useCallback(
    (itemOrder: number) => {
      if (itemOrder === 0) {
        return
      }

      nearbyLog({
        params: {
          name: 'swipe_contents_section',
          contentsType: 'local_food',
          sectionOrder: sectionInfo.order,
          itemOrder,
        },
      })

      loggingMetaLog(sectionBizProduct.loggingMeta.swipe)
    },
    [loggingMetaLog, sectionBizProduct.loggingMeta.swipe, nearbyLog, sectionInfo.order]
  )

  if (sectionBizProduct.bizProducts.length < 3) {
    return null
  }

  return (
    <HomeSectionBase
      sectionBase={sectionBizProduct}
      ImpressionProps={{
        scrollContainerRef: scrollContainerRef,
        onImpression: handleImpression,
        threshold: 0.9,
      }}
      ServiceTitleProps={{
        serviceType: 'LOCAL_FOOD',
      }}
      SectionTitleProps={{}}
      onMoreClick={handleMoreClick}>
      <SwipeEntry ref={itemScrollContainerRef}>
        {sectionBizProduct.bizProducts.map((bizProduct, index) => (
          <HomeBizLocalFoodsItem
            key={bizProduct.id}
            itemOrder={index}
            sectionOrder={sectionInfo.order}
            bizProduct={bizProduct}
            sectionLoggingMeta={sectionBizProduct.loggingMeta}
            itemScrollContainerRef={itemScrollContainerRef}
            onImpression={handleSwipeItem}
          />
        ))}
      </SwipeEntry>
    </HomeSectionBase>
  )
}

export default HomeBizLocalFoods
