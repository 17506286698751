import { usePullToRefreshScrollElement } from '@daangn/karrot-clothes/lib/pullToRefresh'
import { Tabs, TabList, Tab, TabPanelGroup, TabPanel } from '@daangn/sprout-components-tabs'
import React, { useCallback, useMemo, useReducer, useRef, useState } from 'react'
import { graphql, useFragment, useRelayEnvironment } from 'react-relay'

import { type HomeMaps_sectionMap$key } from '@src/__relay__/HomeMaps_sectionMap.graphql'
import { useImpression } from '@src/components/_lib/Impression'
import { useBridgeStreamListener } from '@src/hooks/useBridgeStreamListener'
import { useLogger } from '@src/hooks/useLogger'
import { type PropOf } from '@src/lib/T/utilTypes'
import { useOpenTarget } from '@src/lib/target'
import { type SectionInfo } from '@src/types'
import { type PoiTabCategoryEnum } from '@src/types/schemaEnums'

import HomeMapsLazyTabList from './HomeMapsLazyTabList'
import HomeMapsTabList, { type ActiveTabCategory } from './HomeMapsTabList'
import TabButton from './TabButton'
import HomeSectionBase from '../Common/HomeSectionBase'

const tabKeyToId: {
  [key in PoiTabCategoryEnum]: string
} = {
  FOOD: 'HomeMaps_tab_FOOD' as const,
  BEAUTY: 'HomeMaps_tab_BEAUTY' as const,
  CAFE: 'HomeMaps_tab_CAFE' as const,
  EXERCISE: 'HomeMaps_tab_EXERCISE' as const,
  HOSPITAL: 'HomeMaps_tab_HOSPITAL' as const,
  PET: 'HomeMaps_tab_PET' as const,
}

interface HomeMapsProps {
  sectionMap: HomeMaps_sectionMap$key
  sectionInfo: SectionInfo
}
const HomeMaps: React.FC<HomeMapsProps> = ({ sectionInfo, ...props }) => {
  const sectionMap = useFragment(
    graphql`
      fragment HomeMaps_sectionMap on SectionMap {
        region {
          name3
        }
        tabs {
          __typename
          ... on PoiTab {
            __typename
            title
            type
            targetUri
          }

          ... on LazyPoiTab {
            __typename
            title
            originalTabType
            targetUri
          }

          ...HomeMapsTabList_tab
        }
        loggingMeta {
          swipe
          click
          show
        }
        baseInfo {
          title
        }
        ...HomeSectionBase_sectionBase
      }
    `,
    props.sectionMap
  )
  const environment = useRelayEnvironment()
  const [isViewMap, viewMap] = useReducer(() => true, false)
  const [isMapError, setMapError] = useReducer(() => true, false)
  const tabPannelGroupRef = useRef<HTMLDivElement>(null)
  const scrollContainerRef = usePullToRefreshScrollElement()
  const { nearbyLog, contentsSectionLog, loggingMetaLog } = useLogger()
  const { openTarget } = useOpenTarget()
  const [isSwipeable, setIsSwipeable] = useState(false)

  const firstTabKey =
    sectionMap.tabs[0].__typename === 'LazyPoiTab'
      ? (sectionMap.tabs[0].originalTabType as PoiTabCategoryEnum)
      : (sectionMap.tabs[0].type as PoiTabCategoryEnum)

  const [activeTabCategory, setActiveTabCategory] = useState<ActiveTabCategory>({
    activeTabKey: firstTabKey,
    activeTabLabel: sectionMap.tabs[0].title || '알 수 없음',
    activeTabOrder: 0,
  })

  const targetUris: {
    [key in PoiTabCategoryEnum]?: string
  } = useMemo(() => {
    return sectionMap.tabs.reduce((acc, tab) => {
      return {
        ...acc,
        ...(tab.__typename === 'LazyPoiTab' ? { [tab.originalTabType as PoiTabCategoryEnum]: tab.targetUri } : null),
        ...(tab.__typename === 'PoiTab' ? { [tab.type as PoiTabCategoryEnum]: tab.targetUri } : null),
      }
    }, {})
  }, [sectionMap.tabs])

  const { activeTabKey, activeTabLabel } = activeTabCategory

  const handleMapClick = useCallback(() => {
    contentsSectionLog('click', {
      contentsType: 'map',
      buttonType: 'more',
      params: {
        categoryType: activeTabKey,
        categoryName: activeTabLabel,
        itemType: 'map',
        sectionOrder: sectionInfo.order,
      },
    })

    const targetUri = targetUris[activeTabKey]

    if (targetUri) {
      openTarget({
        targetUri,
      })
    }
  }, [activeTabKey, activeTabLabel, contentsSectionLog, openTarget, sectionInfo.order, targetUris])

  const handleTabSwipe: PropOf<typeof TabButton, 'onImpressionExceptOnload'> = useCallback(
    (itemOrder: number) => {
      nearbyLog({
        params: {
          name: 'swipe_tagging_map_category',
          categoryType: activeTabKey,
          categoryName: activeTabLabel,
          tabOrder: itemOrder,
          sectionOrder: sectionInfo.order,
        },
      })
    },
    [activeTabKey, activeTabLabel, nearbyLog, sectionInfo.order]
  )

  const handleReconciliationTab = useCallback(() => {
    const tabPannelGroupElement = tabPannelGroupRef.current
    const prevTranslateXMatch = tabPannelGroupElement?.style.transform.match(/-?\d+(\.\d+)?/)?.[0]

    if (tabPannelGroupElement && prevTranslateXMatch) {
      const translateX = Math.round(parseFloat(prevTranslateXMatch) / 100) * 100

      tabPannelGroupElement.style.transform = `translateX(${translateX}%)`
    }
  }, [])

  const handleListSwipe = useCallback((isListEnd: boolean) => {
    setIsSwipeable(isListEnd)
  }, [])

  const tabContents = useMemo(
    () =>
      sectionMap.tabs.map((tab, index) => {
        const tabType =
          tab.__typename === 'LazyPoiTab'
            ? (tab.originalTabType as PoiTabCategoryEnum)
            : (tab.type as PoiTabCategoryEnum)
        const tabLabel = tab.title || '알 수 없음'

        return {
          key: tabType,
          tabButton: (
            <TabButton
              id={tabKeyToId[tabType]}
              tabKey={tabType}
              tabLabel={tabLabel}
              activeTabKey={activeTabCategory.activeTabKey}
              itemOrder={index}
              onImpressionExceptOnload={handleTabSwipe}
            />
          ),
          tabLabel,
          tabContent: (() => {
            switch (tab.__typename) {
              case 'LazyPoiTab': {
                const originalTabType = tab.originalTabType as PoiTabCategoryEnum

                return (
                  <HomeMapsLazyTabList
                    originalTabType={originalTabType}
                    tabListProps={{
                      activeTabCategory: activeTabCategory,
                      isViewMap,
                      sectionLoggingMeta: sectionMap.loggingMeta,
                      sectionOrder: sectionInfo.order,
                      onMapClick: handleMapClick,
                      isMapError,
                      onMapError: setMapError,
                      onListSwipe: handleListSwipe,
                    }}
                  />
                )
              }
              default:
                return (
                  <HomeMapsTabList
                    tab={tab}
                    isViewMap={isViewMap}
                    sectionLoggingMeta={sectionMap.loggingMeta}
                    sectionOrder={sectionInfo.order}
                    activeTabCategory={activeTabCategory}
                    onMapClick={handleMapClick}
                    isMapError={isMapError}
                    onMapError={setMapError}
                    onReconciliationTab={handleReconciliationTab}
                    onListSwipe={handleListSwipe}
                  />
                )
            }
          })(),
        }
      }, {}),
    [
      sectionMap.tabs,
      sectionMap.loggingMeta,
      activeTabCategory,
      handleTabSwipe,
      isViewMap,
      sectionInfo.order,
      handleMapClick,
      isMapError,
      handleReconciliationTab,
      handleListSwipe,
    ]
  )

  useBridgeStreamListener('place.poi.bookmark', ({ poiId, isBookmarked }) => {
    environment.commitUpdate((store) => {
      const poi = store.get(`Poi#${poiId}`)

      if (poi) {
        poi.setValue(isBookmarked, 'viewerIsWatched')
      }
    })
  })

  const handleImpression = useCallback(() => {
    contentsSectionLog('show', {
      contentsType: 'map',
      sectionOrder: sectionInfo.order,
    })

    if (sectionInfo.order === 1) {
      loggingMetaLog(sectionMap.loggingMeta.show)
    }
  }, [contentsSectionLog, sectionInfo.order, loggingMetaLog, sectionMap.loggingMeta.show])

  const handleTabChange: PropOf<typeof Tabs, 'onChange'> = useCallback(
    (tabKey: string | null) => {
      const itemOrder = tabContents.findIndex((tab) => tab.key === tabKey)
      const label = tabContents[itemOrder].tabLabel

      nearbyLog({
        params: {
          name: 'click_tagging_map_category',
          categoryType: tabKey,
          categoryName: label,
          itemOrder,
          sectionOrder: sectionInfo.order,
        },
      })

      loggingMetaLog(sectionMap.loggingMeta.swipe)

      setActiveTabCategory({
        activeTabKey: tabKey as PoiTabCategoryEnum,
        activeTabLabel: label,
        activeTabOrder: itemOrder,
      })
    },
    [tabContents, nearbyLog, sectionInfo.order, loggingMetaLog, sectionMap.loggingMeta.swipe]
  )

  const handleMoreClick = useCallback(() => {
    contentsSectionLog('click', {
      contentsType: 'map',
      buttonType: 'more',
      params: {
        name: 'click_tagging_map_more',
        categoryType: activeTabKey,
        categoryName: activeTabLabel,
        sectionOrder: sectionInfo.order,
      },
    })

    const targetUri = targetUris[activeTabKey]

    if (targetUri) {
      openTarget({
        targetUri,
      })
    }
  }, [activeTabKey, activeTabLabel, contentsSectionLog, openTarget, sectionInfo.order, targetUris])

  const handleSectionImpression = () => {
    viewMap()
  }

  useImpression(
    {
      scrollContainerRef,
      ref: tabPannelGroupRef,
      threshold: 0.1,
      rootMargin: '0px 0px 80px', // section animation 고려
    },
    handleSectionImpression,
    []
  )

  if (sectionMap.tabs.length === 0) {
    return null
  }

  return (
    <HomeSectionBase
      sectionBase={sectionMap}
      ImpressionProps={{
        scrollContainerRef: scrollContainerRef,
        onImpression: handleImpression,
        threshold: 0.7,
      }}
      ServiceTitleProps={{
        serviceType: 'MAP',
      }}
      SectionTitleProps={{
        title: sectionMap.baseInfo?.title?.replace('{region.name3}', sectionMap.region.name3),
        addPaddingBottom: '4px',
      }}
      onMoreClick={handleMoreClick}>
      <Tabs
        layout="hug"
        value={activeTabKey}
        onChange={handleTabChange}
        isSwipeable={isSwipeable}
        lazyMode="keepMounted">
        <TabList
          UNSAFE_style={{
            padding: '0 1rem',
          }}>
          {tabContents.map((tabContent, idx) => (
            <Tab
              key={tabContent.key}
              value={tabContent.key}
              UNSAFE_style={{
                padding: 0,
                marginRight: idx === tabContents.length - 1 ? 0 : '1.5rem',
              }}>
              {tabContent.tabButton}
            </Tab>
          ))}
        </TabList>
        <TabPanelGroup ref={tabPannelGroupRef}>
          {tabContents.map((tabContent) => (
            <TabPanel key={tabContent.key} value={tabContent.key}>
              {tabContent.tabContent ?? null}
            </TabPanel>
          ))}
        </TabPanelGroup>
      </Tabs>
    </HomeSectionBase>
  )
}

export default HomeMaps
