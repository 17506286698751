import { KarrotButton } from '@daangn/karrot-clothes'
import styled from '@emotion/styled'
import { type FC } from 'react'
import { useRecoilState } from 'recoil'

import { experimentsAtom } from '@src/features/_global/state/experiments'

import { ExperimentSegmentMap, type ExperimentSegmentType } from '../../../lib/experiment/ExperimentType'
import { removeExperimentsTargets, setExperimentTargets } from '../../../lib/experiment/storage'
import BasicModalView, { DevModalInputWrapper, DevModalLabel, DevModalSelect } from '../lib/BasicModalView'

const ExperimentGroupConfigModalView: FC = () => {
  const [experiments, setExperiments] = useRecoilState(experimentsAtom)

  const setExperimentsClick = () => {
    if (!experiments) return

    const experimentTargets = Object.values(experiments).reduce(
      (acc, ex) => ({
        ...acc,
        ...(ex ? { [ex.key]: ex.targetSegment } : null),
      }),
      {}
    )

    setExperimentTargets(experimentTargets)

    window.location.reload()
  }

  const initExperimentsClick = () => {
    removeExperimentsTargets()

    window.location.reload()
  }

  return (
    <BasicModalView title="실험 그룹 설정" description="현재 실험중인 실험그룹을 설정해요">
      {experiments &&
        Object.values(experiments).map((experiment) => {
          if (!experiment) return null

          const { key, name, targetSegment, segments } = experiment

          return (
            <DevModalInputWrapper key={key}>
              <DevModalLabel>{name}</DevModalLabel>
              <DevModalSelect
                value={targetSegment}
                onChange={(e) => {
                  setExperiments({
                    ...experiments,
                    [key]: {
                      ...experiment,
                      targetSegment: e.target.value,
                    },
                  })
                }}>
                {segments.map((segment) => (
                  <option key={segment.name} value={segment.value}>
                    {segment.name || ExperimentSegmentMap[segment.value as ExperimentSegmentType]}
                  </option>
                ))}
              </DevModalSelect>
            </DevModalInputWrapper>
          )
        })}
      <DevModalInputWrapper />
      <Buttons>
        <KarrotButton size="small" onClick={setExperimentsClick}>
          설정
        </KarrotButton>
        <KarrotButton size="small" variant="secondary" onClick={initExperimentsClick}>
          초기화
        </KarrotButton>
      </Buttons>
    </BasicModalView>
  )
}

export default ExperimentGroupConfigModalView

const Buttons = styled.div`
  & > button {
    margin-right: 1rem;
  }
`
