/**
 * @generated SignedSource<<ceb8f743997c32eaf6aa66597e72e874>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ExpertRecommendItem_BizAccount$data = {
  readonly distance: {
    readonly distance: number;
  } | null;
  readonly id: string;
  readonly images: ReadonlyArray<{
    readonly thumbnail: string;
  }>;
  readonly name: string;
  readonly reviewCount: number;
  readonly starRating: number | null;
  readonly " $fragmentType": "ExpertRecommendItem_BizAccount";
};
export type ExpertRecommendItem_BizAccount$key = {
  readonly " $data"?: ExpertRecommendItem_BizAccount$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExpertRecommendItem_BizAccount">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExpertRecommendItem_BizAccount",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Image",
      "kind": "LinkedField",
      "name": "images",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "thumbnail",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "starRating",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "reviewCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DistanceInfo",
      "kind": "LinkedField",
      "name": "distance",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "distance",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "BizAccount",
  "abstractKey": null
};

(node as any).hash = "7b544860eef84b9f26258027293ef357";

export default node;
