import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { throttle } from 'lodash'
import { type FC, useMemo, useState } from 'react'
import { useRecoilValue } from 'recoil'

import { appAtom } from '@src/features/_global/state/app'

import { NAVBAR_DIVIDER_VISIBLE_PERCENT } from '../../constants'
import { useScroll } from '../../hooks/useScroll'
import { satisfyVersion } from '../../lib/version-check'

interface HomeIOSNavDividerProps {
  mainElement: HTMLDivElement | null
}

const HomeIOSNavDivider: FC<HomeIOSNavDividerProps> = ({ mainElement }) => {
  const { os, version } = useRecoilValue(appAtom)

  const shouldViewDivider = satisfyVersion({
    os,
    version,
    iosVersionCheck: '>=23.2.0',
  })

  const [dividerOpacity, setDividerOpacity] = useState(0)

  const onScroll = useMemo(() => {
    return throttle((scrollContainer: HTMLElement) => {
      const { scrollTop, scrollHeight } = scrollContainer

      const _dividerOpacity = scrollTop / (scrollHeight * NAVBAR_DIVIDER_VISIBLE_PERCENT)
      const isOverOpacity = _dividerOpacity > 1

      if (dividerOpacity === 1 && isOverOpacity) {
        return
      }

      setDividerOpacity(isOverOpacity ? 1 : _dividerOpacity)
    }, 60)
  }, [dividerOpacity])

  useScroll({
    onScroll,
    targetElement: mainElement?.parentNode as HTMLElement,
    disable: !shouldViewDivider,
  })

  if (!shouldViewDivider) {
    return null
  }

  return <S_HomeIOSNavDivider dividerOpacity={dividerOpacity} />
}

export default HomeIOSNavDivider

const S_HomeIOSNavDivider = styled.div<{
  dividerOpacity: number
}>`
  background-color: ${vars.$semantic.color.paperDefault};
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: content-box;
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);

  &::after {
    content: '';
    height: 1px;
    width: 100%;
    background-color: ${vars.$semantic.color.divider3};
    opacity: ${({ dividerOpacity }) => dividerOpacity};
  }
`
