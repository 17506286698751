/**
 * @generated SignedSource<<72acb8c999bb47c37846fe1f503462e0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HomeNavbar_query$data = {
  readonly viewer: {
    readonly region: {
      readonly id: string;
    } | null;
    readonly " $fragmentSpreads": FragmentRefs<"HomeNavbarRegionModal_viewer">;
  } | null;
  readonly " $fragmentType": "HomeNavbar_query";
};
export type HomeNavbar_query$key = {
  readonly " $data"?: HomeNavbar_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"HomeNavbar_query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HomeNavbar_query",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "KarrotUser",
      "kind": "LinkedField",
      "name": "viewer",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Region",
          "kind": "LinkedField",
          "name": "region",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "HomeNavbarRegionModal_viewer"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "04e958358275ef5351376dd598d165d2";

export default node;
