import { type BridgeAppInfo, type BridgeRegionInfo, type BridgeUserInfo, getInfo } from './bridgeInfo'

export type BridgeInfo = {
  app: BridgeAppInfo
  region: BridgeRegionInfo
  user: BridgeUserInfo | null
}

function makeBridgeInfoResource() {
  type State =
    | {
        _t: 'pending'
      }
    | {
        _t: 'rejected'
        result: Error
      }
    | {
        _t: 'resolved'
        result: BridgeInfo
      }

  let state: State = {
    _t: 'pending',
  }

  const promise = (async () => {
    try {
      const { app, region, user } = await getInfo()

      if (!region.id) {
        return void (state = {
          _t: 'rejected',
          result: new Error('bridge.info.region() not found'),
        })
      }

      state = {
        _t: 'resolved',
        result: { app, region, user },
      }
    } catch (error) {
      state = {
        _t: 'rejected',
        result: error as Error,
      }
    }
  })()

  return {
    read() {
      switch (state._t) {
        case 'pending':
          throw promise
        case 'rejected':
          throw state.result
        case 'resolved':
          return state.result
      }
    },
  }
}

export const bridgeInfoResource = makeBridgeInfoResource()
