import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import React from 'react'

import IcChevronRightOutlineS from '../../../assets/images/icons/ic_chevron_right_outline_s.svg'
import { noneActiveEffect } from '../../_lib/styles'

interface SectionBottomButtonProps {
  content: React.ReactNode
  noRightArrow?: boolean
  onClick?: () => void
}
const SectionBottomButton: React.FC<SectionBottomButtonProps> = ({ content, noRightArrow = false, onClick }) => {
  return (
    <Button onClick={onClick}>
      <Content>
        {content}
        {!noRightArrow && (
          <IconContainer>
            <IcChevronRightOutlineS />
          </IconContainer>
        )}
      </Content>
    </Button>
  )
}

const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  appearance: none;
  border: 0;
  background: ${vars.$semantic.color.paperDefault};
  color: ${vars.$scale.color.gray700};
  padding: 0.75rem 0;
  box-shadow: 0 -1px 0 0 ${vars.$semantic.color.divider1};
  border-bottom: 1px solid var(--seed-semantic-color-divider-1);

  text-decoration: none;
  ${noneActiveEffect};
`

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.125rem 0;
  ${vars.$semantic.typography.label3Bold}
`

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1rem;
  height: 1rem;
  margin-left: 0.25rem;
`

export default SectionBottomButton
