import styled from '@emotion/styled'
import { type FC } from 'react'
import { graphql, useLazyLoadQuery } from 'react-relay'

import { type HomeFeedShortcutOnboardingQuery } from '@src/__relay__/HomeFeedShortcutOnboardingQuery.graphql'

import { useHomeFeedShortcutOnboarding } from '../hooks/useHomeFeedShortcutOnboarding'

const HomeFeedShortcutOnboarding: FC = () => {
  const { viewer } = useLazyLoadQuery<HomeFeedShortcutOnboardingQuery>(
    graphql`
      query HomeFeedShortcutOnboardingQuery {
        viewer {
          isVerticalServiceOnboardingUser
        }
      }
    `,
    {}
  )

  useHomeFeedShortcutOnboarding({
    isVerticalServiceOnboardingUser: viewer?.isVerticalServiceOnboardingUser,
  })

  return <S_HomeFeedShortcutOnboarding />
}

export default HomeFeedShortcutOnboarding

const S_HomeFeedShortcutOnboarding = styled.div``
