/**
 * @generated SignedSource<<5a84fc10bd029c137440673f677f00bd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type NearbyBadgeEnum = "COUPON_EXISTS" | "DELIVERY" | "FAST_RESPONSE" | "FREE_DELIVERY" | "HAS_TRIAL" | "MOST_REVIEW" | "MOST_VIEW" | "SAME_DAY_DELIVERY" | "WALK_10_MIN";
import { FragmentRefs } from "relay-runtime";
export type Badges_badges$data = {
  readonly badges: ReadonlyArray<NearbyBadgeEnum>;
  readonly " $fragmentType": "Badges_badges";
};
export type Badges_badges$key = {
  readonly " $data"?: Badges_badges$data;
  readonly " $fragmentSpreads": FragmentRefs<"Badges_badges">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Badges_badges",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "badges",
      "storageKey": null
    }
  ],
  "type": "Badge",
  "abstractKey": "__isBadge"
};

(node as any).hash = "ee0389dbcd82e548f752bd8aebcb39ce";

export default node;
