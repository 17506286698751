import { atom } from 'recoil'

import { type BridgeRegionInfo } from '@src/bridgeInfo'

import { generateUserStateKey } from './key'

/**
 * 현재 지역 정보
 */
export const regionAtom = atom<BridgeRegionInfo>({
  key: generateUserStateKey('region'),
  default: {
    name: '',
  } as any,
})
