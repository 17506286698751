import { Portal } from '@daangn/karrot-clothes'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import React from 'react'
import { graphql, useFragment } from 'react-relay'

import { type HomeNavbarRegionModal_viewer$key } from '../../../__relay__/HomeNavbarRegionModal_viewer.graphql'
import { useLogger } from '../../../hooks/useLogger'

interface HomeNavbarRegionModalProps {
  nodeRef: React.Ref<HTMLDivElement>
  viewer: HomeNavbarRegionModal_viewer$key
  onRegionChange: (info: { regionId: string }) => void
  onChangeRegionClick: () => void
  onOutsideClick: () => void
}
const HomeNavbarRegionModal: React.FC<HomeNavbarRegionModalProps> = (props) => {
  const { log } = useLogger()
  const viewer = useFragment(
    graphql`
      fragment HomeNavbarRegionModal_viewer on KarrotUser {
        region {
          id
          externalId
          name3
          name4
        }
        regions {
          id
          externalId
          name3
          name4
        }
      }
    `,
    props.viewer
  )

  const handleRegionClick = (data: { active: boolean; regionId: string }) => {
    if (!data.active) {
      log({
        name: 'nearby_event',
        params: {
          name: 'click_region_change',
          region_id: data.regionId,
        },
      })

      props.onRegionChange({ regionId: data.regionId })
    }
  }

  const handleRegionSettingClick = () => {
    log({
      name: 'nearby_event',
      params: {
        name: 'click_region_settings',
      },
    })

    props.onChangeRegionClick()
  }

  return (
    // @ts-ignore
    <Portal>
      <Container ref={props.nodeRef}>
        <Dim onClick={props.onOutsideClick}>
          <Modal>
            {viewer.regions.map((region) => {
              const active = region.id === viewer.region?.id

              return (
                <Item
                  key={region.id}
                  active={active}
                  onClick={() =>
                    handleRegionClick({
                      active,
                      regionId: region.externalId,
                    })
                  }>
                  <ItemText>{region.name4 || region.name3}</ItemText>
                </Item>
              )
            })}
            <Item onClick={handleRegionSettingClick}>
              <ItemText>내 동네 설정</ItemText>
            </Item>
          </Modal>
        </Dim>
      </Container>
    </Portal>
  )
}

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9;
`

const Dim = styled.div`
  position: fixed;
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
  width: 100vw;
  height: 100vh;
  background-color: ${vars.$semantic.color.overlayLow};
  opacity: 0;
  transition: opacity 300ms;
  ${Container}.enter-active & {
    opacity: 1;
  }
  ${Container}.enter-done & {
    opacity: 1;
  }
`

const Modal = styled.div`
  position: absolute;
  margin-top: 3rem;
  left: 1rem;
  padding: 0.5rem 0;
  background-color: ${vars.$semantic.color.paperDefault};
  min-width: 12rem;
  border-radius: 0.375rem;
  transform: scale(0.2);
  transform-origin: 1.5rem 0rem;
  opacity: 0;
  transition: transform 300ms, opacity 150ms;
  box-shadow: 0rem 0.25rem 0.375rem rgba(0, 0, 0, 0.15), 0rem 0rem 0.188rem rgba(0, 0, 0, 0.08);

  ${Container}.enter-active & {
    transform: scale(1);

    ${(props) =>
      props.theme.environment === 'cupertino' &&
      css`
        transform: scale(1.2);
      `}

    opacity: 1;
  }
  ${Container}.enter-done & {
    transform: scale(1);
    opacity: 1;
  }
`

const Item = styled.div<{ active?: boolean }>`
  padding: 0.813rem 1rem;
  font-size: 1rem;
  line-height: 1.375rem;
  color: ${vars.$scale.color.gray600};
  position: relative;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  ${(props) =>
    props.active &&
    css`
      color: ${vars.$scale.color.gray900};
      font-weight: bold;
    `}

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${vars.$scale.color.gray100};
    transform: scaleX(0);
    opacity: 0;
    transition: 70ms ease;
    z-index: 0;
  }

  &:active {
    &::after {
      transform: scaleX(1);
      opacity: 1;
      transition: transform 0s;
    }
  }
`

const ItemText = styled.div`
  position: relative;
  z-index: 1;
`

export default HomeNavbarRegionModal
