import { type FC } from 'react'

import { BEAUTY_ICON, CAFE_ICON, EXERCISE_ICON, FOOD_ICON, HOSPITAL_ICON, PET_ICON } from './categoryIcons'
import { type PoiTabCategoryEnum } from '../../../../types/schemaEnums'

interface MapsCategoryIconsProps {
  category: PoiTabCategoryEnum
}

const MapsCategoryIcons: FC<MapsCategoryIconsProps> = ({ category }) => {
  return <img src={ICON_MAP[category]} width={ICON_SIZE} height={ICON_SIZE} />
}

export default MapsCategoryIcons

const ICON_SIZE = 18

const ICON_MAP: Record<PoiTabCategoryEnum, string> = {
  FOOD: FOOD_ICON,
  PET: PET_ICON,
  EXERCISE: EXERCISE_ICON,
  HOSPITAL: HOSPITAL_ICON,
  CAFE: CAFE_ICON,
  BEAUTY: BEAUTY_ICON,
}
