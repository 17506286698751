import { SizeIconProps } from '@daangn/karrot-clothes/lib/types/svg'
import styled from '@emotion/styled'
import React from 'react'

const Write: React.FC<SizeIconProps> = ({ size = 16, color = 'currentColor' }) => {
  return (
    <Svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.2897 1.62288C10.498 1.4146 10.8356 1.4146 11.0439 1.62288L14.3773 4.95622C14.5855 5.1645 14.5855 5.50219 14.3773 5.71047L11.7109 8.37678L11.7106 8.37713L11.7102 8.37749L5.71059 14.3771C5.61057 14.4772 5.47491 14.5333 5.33346 14.5333H2.00013C1.70558 14.5333 1.4668 14.2946 1.4668 14V10.6667C1.4668 10.5252 1.52299 10.3896 1.62301 10.2896L10.2897 1.62288ZM13.2459 5.33334L11.3335 7.24576L9.71059 5.62288C9.50231 5.4146 9.16462 5.4146 8.95634 5.62288C8.74806 5.83116 8.74806 6.16885 8.95634 6.37713L10.5792 8.00001L5.11255 13.4667H2.53346V10.8876L10.6668 2.75426L13.2459 5.33334ZM9.33346 13.4667C9.03891 13.4667 8.80013 13.7055 8.80013 14C8.80013 14.2946 9.03891 14.5333 9.33346 14.5333H14.0001C14.2947 14.5333 14.5335 14.2946 14.5335 14C14.5335 13.7055 14.2947 13.4667 14.0001 13.4667H9.33346Z"
        fill={color}
      />
    </Svg>
  )
}

export default React.memo(Write)

const Svg = styled.svg`
  display: block;
`
