/**
 * @generated SignedSource<<b976fdbb78bd12649e18e8c52aa1e175>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type VisitCountEnum = "VISIT_1" | "VISIT_10_TO_29" | "VISIT_2_TO_4" | "VISIT_30_OR_MORE" | "VISIT_5_TO_9";
import { FragmentRefs } from "relay-runtime";
export type HomeNativeReviewItemUser_userProfileNativeReview$data = {
  readonly hoianUser: {
    readonly id: string;
    readonly image: {
      readonly thumbnail: string;
    };
    readonly nickname: string;
  };
  readonly startYearOfResidence: number;
  readonly visitCount: VisitCountEnum;
  readonly " $fragmentType": "HomeNativeReviewItemUser_userProfileNativeReview";
};
export type HomeNativeReviewItemUser_userProfileNativeReview$key = {
  readonly " $data"?: HomeNativeReviewItemUser_userProfileNativeReview$data;
  readonly " $fragmentSpreads": FragmentRefs<"HomeNativeReviewItemUser_userProfileNativeReview">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HomeNativeReviewItemUser_userProfileNativeReview",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "HoianUser",
      "kind": "LinkedField",
      "name": "hoianUser",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Image",
          "kind": "LinkedField",
          "name": "image",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "thumbnail",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "nickname",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startYearOfResidence",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "visitCount",
      "storageKey": null
    }
  ],
  "type": "NativeReview",
  "abstractKey": null
};

(node as any).hash = "01b126b7bb476bed8d97f80c22bedc4e";

export default node;
