import React, { createContext, useCallback, useContext } from 'react'

import { useFlow } from '@src/stackflow/stackflow'

import type { StackflowModalParams } from './Modal'

type Renderer = () => JSX.Element | null
type ModalRenderContextType = {
  render: Renderer | null
}

const ModalRenderContext = createContext<ModalRenderContextType>({
  render: () => null,
})

const ModalRenderDispatchContext = createContext<React.Dispatch<React.SetStateAction<ModalRenderContextType>>>(
  null as any
)

// eslint-disable-next-line @typescript-eslint/ban-types
export const ModalRenderContextProvider: React.FCWithChildren<{}> = ({ children }) => {
  const [ModalContext, setModalContext] = React.useState<ModalRenderContextType>({
    render: null,
  })

  return (
    <ModalRenderContext.Provider value={ModalContext}>
      <ModalRenderDispatchContext.Provider value={setModalContext}>{children}</ModalRenderDispatchContext.Provider>
    </ModalRenderContext.Provider>
  )
}

export const useModalRender = () => {
  return useContext(ModalRenderContext)
}

export const useModalRenderDispatch = () => {
  return useContext(ModalRenderDispatchContext)
}

export interface OpenModalProps {
  render: Renderer
  style?: StackflowModalParams
}

export const useModal = () => {
  const { push, pop } = useFlow()
  const setModalRender = useModalRenderDispatch()

  const openModal = useCallback(
    ({ render, style }: OpenModalProps) => {
      setModalRender({ render })

      push('Modal', {
        ...style,
      })
    },
    [push, setModalRender]
  )

  return {
    openModal,
    closeModal: pop,
  }
}
