import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { FC, useRef } from 'react'
import { graphql, useFragment } from 'react-relay'

import { HomeBizLocalFoodsItem_bizProduct$key } from '../../../../__relay__/HomeBizLocalFoodsItem_bizProduct.graphql'
import { useLogger } from '../../../../hooks/useLogger'
import { useOpenTarget } from '../../../../lib/target'
import { numberWithComma } from '../../../../lib/unit'
import BizProductItem from '../../../_lib/BizProducts/BizProduct'
import BizProfile from '../../../_lib/BizProducts/BizProfile'
import CoveredLazyLoadImage from '../../../_lib/CoveredLazyLoadImage'
import { useImpression } from '../../../_lib/Impression'
import { getEllipsisStyle } from '../../../_lib/styles'
import { Badges } from '../../lib/Badge'

interface HomeBizLocalFoodsItemProps {
  bizProduct: HomeBizLocalFoodsItem_bizProduct$key
  sectionOrder: number
  sectionLoggingMeta: {
    swipe: string
    click: string
  }
  itemScrollContainerRef: React.RefObject<HTMLDivElement>
  itemOrder: number
  onImpression: (itemOrder: number) => void
}

const HomeBizLocalFoodsItem: FC<HomeBizLocalFoodsItemProps> = (props) => {
  const { openTarget } = useOpenTarget()
  const { loggingMetaLog, contentsSectionLog } = useLogger()
  const itemRef = useRef<HTMLDivElement>(null)

  const bizProduct = useFragment(
    graphql`
      fragment HomeBizLocalFoodsItem_bizProduct on BizProduct {
        id
        externalId
        title
        discountPercentage
        salePrice
        targetUri
        bookmarkCount
        distance {
          distance
          name
        }
        image {
          thumbnail
        }
        bizAccount {
          name
        }
        viewerIsBookmarked
        badges
        ...BizProductItem_common
        ...Badges_badges
      }
    `,
    props.bizProduct
  )

  const handleProductItemClick = () => {
    contentsSectionLog('click', {
      contentsType: 'local_food',
      buttonType: 'list',
      params: {
        name: 'click_recommend_product',
        sectionOrder: props.sectionOrder,
        ...(bizProduct.badges.length > 0 ? { badges: bizProduct.badges.join(',') } : null),
      },
    })

    loggingMetaLog([props.sectionLoggingMeta.click])

    openTarget({
      targetUri: bizProduct.targetUri,
    })
  }

  // swipe log
  useImpression(
    {
      ref: itemRef,
      scrollContainerRef: props.itemScrollContainerRef,
      disableImpressionOnload: true,
    },
    () => {
      props.onImpression(props.itemOrder)
    },
    [props.itemOrder, props.onImpression]
  )

  return (
    <BizProductItem bizProduct={bizProduct} itemRef={itemRef} onClick={handleProductItemClick}>
      <CoveredLazyLoadImageStyle effect="opacity" height={140} src={bizProduct.image.thumbnail} visibleByDefault />
      <ProductContent>
        <BizProfiles>
          <BizProfile bizAccountName={bizProduct.bizAccount?.name} distance={bizProduct.distance} />
          <Title>{bizProduct.title}</Title>
          <PriceContent>
            {bizProduct.discountPercentage > 0 && <DiscountPercent>{bizProduct.discountPercentage}%</DiscountPercent>}
            <DiscountPrice>{`${numberWithComma(bizProduct.salePrice)}원`}</DiscountPrice>
          </PriceContent>
        </BizProfiles>
        <Badges
          badgesRef={bizProduct}
          badgeProps={{
            size: 'small',
          }}
        />
      </ProductContent>
    </BizProductItem>
  )
}

export default HomeBizLocalFoodsItem

const ProductContent = styled.div`
  margin-top: 0.75rem;

  & > *:not(:last-child) {
    margin-bottom: 0.5rem;
  }
`

const CoveredLazyLoadImageStyle = styled(CoveredLazyLoadImage)`
  width: 8.75rem;
  height: 8.75rem;
`

const Title = styled.div`
  width: 100%;
  ${vars.$semantic.typography.label3Regular};
  color: ${vars.$scale.color.gray900};

  ${getEllipsisStyle(2)};
`

const BizProfiles = styled.div``

const PriceContent = styled.div`
  display: flex;
  margin-top: 0.125rem;
  ${vars.$semantic.typography.label3Bold};
`

const DiscountPercent = styled.div`
  margin-right: 0.25rem;
  color: ${vars.$scale.color.carrot500};
`

const DiscountPrice = styled.div`
  ${getEllipsisStyle(1)};
`
