import { type PropOf } from '@cometjs/react-utils'
import styled from '@emotion/styled'
import { graphql, useFragment } from 'react-relay'

import { type HomeBizTheme_bizPostTheme$key } from '@src/__relay__/HomeBizTheme_bizPostTheme.graphql'
import ErrorFallback from '@src/components/_lib/ErrorFallback'
import { type BizPostThemeType } from '@src/types/schemaEnums'

import HomeBizThemePost from './HomeBizThemePost'

interface HomeBizThemeProps {
  activeTabKey: BizPostThemeType
  currentTabKey: BizPostThemeType
  bizPostTheme: HomeBizTheme_bizPostTheme$key
  onBizPostClick?: PropOf<typeof HomeBizThemePost, 'onClick'>
  onAllBizPostsClick?: () => void
}
const HomeBizTheme: React.FC<HomeBizThemeProps> = ({ activeTabKey, currentTabKey, ...props }) => {
  const bizPostTheme = useFragment(
    graphql`
      fragment HomeBizTheme_bizPostTheme on BizPostTheme {
        externalId
        bizPosts {
          __typename

          ... on BizPost {
            id
            ...HomeBizThemePost_bizPost
          }
        }
      }
    `,
    props.bizPostTheme
  )

  return (
    <BizPosts>
      {bizPostTheme.bizPosts.length === 0 && (
        <ErrorFallback
          retry={props.onAllBizPostsClick}
          overrides={{
            text: '아직 등록된 업체가 없어요.\n다른 업체 소식을 둘러보세요.',
            retryButtonText: '업체 소식 전체 보기',
          }}
          ignoreSentryForwarding
        />
      )}
      {bizPostTheme.bizPosts.map((bizPost) => {
        return bizPost.__typename === 'BizPost' ? (
          <HomeBizThemePost key={bizPost.id} bizPost={bizPost} onClick={props.onBizPostClick} />
        ) : null
      })}
    </BizPosts>
  )
}

const BizPosts = styled.div`
  padding: 0 1rem;
  height: 100%;
  position: relative;
  min-height: 24.223rem;
`

export default HomeBizTheme
