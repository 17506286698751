import { IS_PRODUCTION } from '@src/constants'
import { type CategoryFilterSortEnum } from '@src/types/schemaEnums'

export const SORT_FILTER: CategoryFilterSortEnum[] = [
  'BA_POI_CREATED_AT_DESC',
  'BA_POI_DISTANCE_ASC',
  'BA_POI_SCORE_DESC',
]

// 카테고리
export type ManagedCategoryEnum =
  | 'Food' // 음식점
  | 'Cafe' // 카페
  | 'Beauty' // 뷰티
  | 'Hair' // 미용
  | 'Exercise' // 운동
  | 'Yongdal' // 이사 용달
  | 'YongdalOnly' // 용달
  | 'Moving' // 이사
  | 'Repair' // 수리
  | 'Tutoring' // 육아
  | 'Academy' // 교육
  | 'Default'

// themeId: 비즈플랫폼에 존재하는 카테고리 id (아래 어드민에서 조희 가능)
// production: https://ba-admin.kr.karrotmarket.com/themes/105
// alpha: https://ba-admin.alpha.kr.karrotmarket.com/themes/106
export const CATEGORY_HOME_MAP: Record<string, ManagedCategoryEnum> = IS_PRODUCTION
  ? {
      '106': 'Food', // 음식점
      '118': 'Cafe', // 카페
      '123': 'Beauty', // 뷰티
      '209': 'Hair', // 미용
      '179': 'Exercise', // 운동
      '175': 'Repair', // 수리
      '189': 'Tutoring', // 육아
      '166': 'Academy', // 교육
      '156': 'Yongdal', // 용달 + 이사
      '157': 'YongdalOnly', // 용달
      '158': 'Moving', // 이사
    }
  : {
      '107': 'Food', // 음식점
      '108': 'Cafe', // 카페
      '126': 'Hair', // 미용
      '109': 'Beauty', // 뷰티
      '172': 'Exercise', // 운동
      '168': 'Repair', // 수리
      '185': 'Tutoring', // 육아
      '150': 'Yongdal', // 용달
      '151': 'YongdalOnly', // 용달
      '152': 'Moving', // 용달
    }

export const FEATURED_IMAGE_CATEGORIES: ManagedCategoryEnum[] = ['Exercise']
