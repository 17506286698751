import { usePullToRefreshScrollElement } from '@daangn/karrot-clothes/lib/pullToRefresh'
import styled from '@emotion/styled'
import { FCWithChildren } from 'react'
import { graphql, useFragment } from 'react-relay'

import { BizProductItem_common$key } from '../../../__relay__/BizProductItem_common.graphql'
import { useBizLoggingMeta } from '../../../hooks/useBizLoggingMeta'
import { SwipeItem } from '../SwipeSection/SwipeItem'

interface BizProductItemProps {
  bizProduct: BizProductItem_common$key
  onClick?: () => void
  onImpression?: () => void
  itemRef: React.RefObject<HTMLElement>
  className?: string
  [key: string]: any
}

const BizProductItem: FCWithChildren<BizProductItemProps> = ({
  bizProduct,
  onClick,
  onImpression,
  itemRef,
  children,
  className,
}) => {
  const scrollContainerRef = usePullToRefreshScrollElement()

  const BizProductInfo = useFragment(
    graphql`
      fragment BizProductItem_common on BizProduct {
        ...useBizLoggingMeta_bizLoggingMeta
      }
    `,
    bizProduct
  )

  useBizLoggingMeta({
    targetRef: itemRef,
    scrollContainerRef,
    bizLoggingMetaRef: BizProductInfo,
    onClick,
    onImpression,
  })

  return (
    <BizProductItemStyle ref={itemRef} className={className}>
      {children}
    </BizProductItemStyle>
  )
}

export default BizProductItem

export const BizProductItemStyle = styled(SwipeItem)`
  display: inline-block;
  width: 8.75rem;
  height: fit-content;
  border-radius: 0.375rem;
  vertical-align: top;
  -webkit-tap-highlight-color: transparent;
  white-space: normal;
`
