import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'

import BottomSheetComponent from './BottomSheetComponent'
import { useHistoryState } from './useHistoryState'

export const BottomSheetProviderContext = createContext<{
  isOpen: boolean
  openBottomSheet: (params: BottomSheetParams) => UnmountFunction
  setIgnoreOnPop: (ignoreOnPop: boolean) => void
}>({} as any)

export const useBottomSheetOpen = ({ ignoreOnPop = false } = {}) => {
  const { isOpen, openBottomSheet, setIgnoreOnPop } = useContext(BottomSheetProviderContext)

  useEffect(() => {
    setIgnoreOnPop(ignoreOnPop)
  }, [ignoreOnPop, setIgnoreOnPop])

  return useMemo(() => ({ isOpen, openBottomSheet }), [isOpen, openBottomSheet])
}

const INITIAL_STATE = { sheets: [] }

export type UnmountFunction = () => Promise<void>

export interface Sheet {
  name: string
  onClick: (close: UnmountFunction) => void
  warning?: boolean
}

export interface BottomSheetParams {
  sheets: Sheet[]
  onClose?: () => void
}

const BottomSheetProvider: React.FCWithChildren = ({ children }) => {
  const [isOpen, handleOpen, handleClose, handleIgnoreOnPop] = useHistoryState()

  const [bottomSheetState, setBottomSheetState] = useState<BottomSheetParams>(INITIAL_STATE)

  const { sheets, onClose } = bottomSheetState!

  const sheetsWithUnmount = useMemo(() => {
    return sheets.map((sheet) => ({
      ...sheet,
      onClick: () => {
        sheet.onClick(handleClose)
      },
    }))
  }, [sheets, handleClose])

  const openBottomSheet = useCallback(
    (params: BottomSheetParams) => {
      handleOpen()
      setBottomSheetState(params)

      return handleClose
    },
    [handleOpen, handleClose]
  )

  const value = useMemo(
    () => ({
      isOpen,
      openBottomSheet,
      setIgnoreOnPop: handleIgnoreOnPop,
    }),
    [isOpen, openBottomSheet, handleIgnoreOnPop]
  )

  const handleClickOverlay = useCallback(() => {
    handleClose()
    onClose?.()
  }, [handleClose, onClose])

  return (
    <BottomSheetProviderContext.Provider value={value}>
      {children}
      <BottomSheetComponent isOpen={isOpen} sheets={sheetsWithUnmount} onClickOverlay={handleClickOverlay} />
    </BottomSheetProviderContext.Provider>
  )
}

export default BottomSheetProvider
