/**
 * @generated SignedSource<<a3202e57af106976064097b6ccec6092>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HomeRecommendPosts_sectionRecommendPost$data = {
  readonly __typename: "SectionRecommendPost";
  readonly baseInfo: {
    readonly viewMoreInfo: {
      readonly label: string;
      readonly targetUri: string;
    } | null;
  } | null;
  readonly bizPosts: ReadonlyArray<{
    readonly externalId: string;
    readonly " $fragmentSpreads": FragmentRefs<"HomeBizThemePost_bizPost">;
  }>;
  readonly loggingMeta: {
    readonly click: string;
    readonly show: string;
    readonly swipe: string;
  };
  readonly " $fragmentSpreads": FragmentRefs<"HomeSectionBase_sectionBase">;
  readonly " $fragmentType": "HomeRecommendPosts_sectionRecommendPost";
};
export type HomeRecommendPosts_sectionRecommendPost$key = {
  readonly " $data"?: HomeRecommendPosts_sectionRecommendPost$data;
  readonly " $fragmentSpreads": FragmentRefs<"HomeRecommendPosts_sectionRecommendPost">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HomeRecommendPosts_sectionRecommendPost",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "BizPost",
      "kind": "LinkedField",
      "name": "bizPosts",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "externalId",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "HomeBizThemePost_bizPost"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EventMeta",
      "kind": "LinkedField",
      "name": "loggingMeta",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "swipe",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "click",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "show",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BaseInfo",
      "kind": "LinkedField",
      "name": "baseInfo",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ViewMoreInfo",
          "kind": "LinkedField",
          "name": "viewMoreInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "label",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "targetUri",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HomeSectionBase_sectionBase"
    }
  ],
  "type": "SectionRecommendPost",
  "abstractKey": null
};

(node as any).hash = "06430a8540408705191e5bcdbacfbf48";

export default node;
