import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { type FC, useRef } from 'react'
import { graphql, useFragment } from 'react-relay'
import { match } from 'ts-pattern'

import {
  type ClassBizPostItem_bizPost$data,
  type ClassBizPostItem_bizPost$key,
} from '../../../../__relay__/ClassBizPostItem_bizPost.graphql'
import { distanceToUnion } from '../../../../lib/union'
import { getEllipsisString } from '../../../../lib/unit'
import BizPostItem from '../../../_lib/BizPosts/BizPostItem'
import CoveredLazyLoadImage from '../../../_lib/CoveredLazyLoadImage'
import { getEllipsisStyle } from '../../../_lib/styles'
import { Badges } from '../../lib/Badge'

interface ClassBizPostItemProps {
  classBizPost: ClassBizPostItem_bizPost$key
  onClick: (bizPost: ClassBizPostItem_bizPost$data) => void
}

const ClassBizPostItem: FC<ClassBizPostItemProps> = (props) => {
  const bizPost = useFragment(
    graphql`
      fragment ClassBizPostItem_bizPost on BizPost {
        title
        content
        image {
          thumbnail
        }
        bizAccount {
          name
        }
        distance {
          distance
          name
        }
        createdAt
        targetUri
        badges
        ...BizPostItem_common
        ...Badges_badges
      }
    `,
    props.classBizPost
  )

  const handlebizPostClick = () => {
    props.onClick?.(bizPost)
  }

  const itemRef = useRef<HTMLElement>(null)

  return (
    <BizPostItem itemRef={itemRef} bizPost={bizPost} onClick={handlebizPostClick}>
      {bizPost.image?.thumbnail && (
        <Left>
          <S_CoveredLazyLoadImage effect="opacity" src={bizPost.image.thumbnail} height={88} visibleByDefault />
        </Left>
      )}
      <Right>
        <BizInfo>
          {bizPost.bizAccount?.name && (
            <BizProfileName>{getEllipsisString(bizPost.bizAccount.name, 10)}</BizProfileName>
          )}
          {bizPost.distance &&
            match(distanceToUnion(bizPost.distance))
              .with({ _TAG: 'name' }, ({ name }) => <DistanceName>{name}</DistanceName>)
              .with({ _TAG: 'm' }, ({ m }) => <Distance>{`${m}m`}</Distance>)
              .with({ _TAG: 'km' }, ({ km }) => <Distance>{`${km}km`}</Distance>)
              .otherwise(() => null)}
        </BizInfo>
        <Title>{bizPost.title}</Title>
        <Content>{bizPost.content}</Content>
        <Badges badgesRef={bizPost} />
      </Right>
    </BizPostItem>
  )
}

export default ClassBizPostItem

const Right = styled.div`
  flex: 1;
  width: calc(100% - 6.5rem);
`

const Left = styled.div``

const S_CoveredLazyLoadImage = styled(CoveredLazyLoadImage)`
  height: 5.5rem;
  width: 5.5rem;
  border-radius: 0.375rem;
  overflow: hidden;
  margin-right: 1rem;
`

const BizInfo = styled.div`
  display: flex;
  margin-bottom: 0.125rem;
  font-size: 13px;
  line-height: 20px;
  color: ${vars.$scale.color.gray900};
`

const BizProfileName = styled.div`
  ${getEllipsisStyle(1)};
`

const DistanceName = styled.div`
  flex-shrink: 0;
  font-size: 0.813rem;
  color: ${vars.$scale.color.gray600};

  &::before {
    content: '·';
    color: ${vars.$scale.color.gray600};
    margin: 0 0.25rem;
  }
`

const Distance = styled.div`
  color: ${vars.$scale.color.gray600};

  &::before {
    content: '·';
    color: ${vars.$scale.color.gray600};
    margin: 0 0.25rem;
  }
`

const Title = styled.h3`
  margin: 0px 0px 0.125rem;
  color: ${vars.$scale.color.gray900};
  font-size: 1rem;
  line-height: 1.375rem;
  font-weight: 700;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

const Content = styled.div`
  display: flex;
  margin-bottom: 0.5rem;
  font-size: 14px;
  line-height: 19px;
  color: ${vars.$scale.color.gray600};

  display: -webkit-box;
  -webkit-line-clamp: 2;
  visibility: visible;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
`
