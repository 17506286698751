import { BoxButton } from '@daangn/sprout-components-button'
import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { useMemo } from 'react'

import { useFlow } from '@src/stackflow/stackflow'

import { type StackflowModalParams } from './Modal'
import { useModal } from './useModal'
import { noneActiveEffect } from '../_lib/styles'

/**
 * ButtonDirection에 따라 CANCEL 표현이 다름
 */
export type AlertModalButton = {
  variant: 'CTA' | 'CANCEL'
  text: string
  onClick: (closeModal: () => void) => void
}

type ButtonDirection = 'vertical' | 'horizontal'

interface AlertInfo {
  message: string
  buttons?: AlertModalButton[]
  buttonDirection?: ButtonDirection
}

export const useAlertModal = () => {
  const { pop } = useFlow()
  const defaultButtons: AlertModalButton[] = useMemo(
    () => [
      {
        variant: 'CTA',
        text: '확인',
        onClick: () => {
          pop()
        },
      },
    ],
    [pop]
  )

  const renderAlertView = useMemo(
    () =>
      ({ message, buttons, buttonDirection }: AlertInfo) => {
        const _buttons = buttons ?? defaultButtons

        return (
          <AlertModal>
            <Message>{message}</Message>
            <ActionWrapper direction={buttonDirection}>
              {_buttons.map(({ variant, text, onClick }, idx) => {
                const handleClick = () => {
                  onClick(pop)
                }

                switch (variant) {
                  case 'CTA':
                    return buttonDirection === 'vertical' ? (
                      <BoxButton key={idx} variant="primary" size="medium" onClick={handleClick}>
                        {text}
                      </BoxButton>
                    ) : (
                      <ButtonWrapper key={idx}>
                        <BoxButton variant="primary" size="medium" onClick={handleClick}>
                          {text}
                        </BoxButton>
                      </ButtonWrapper>
                    )
                  case 'CANCEL':
                    return buttonDirection === 'vertical' ? (
                      <VerticalCloseButton key={idx} onClick={handleClick}>
                        {text}
                      </VerticalCloseButton>
                    ) : (
                      <ButtonWrapper key={idx}>
                        <HorizontalCloseButton onClick={handleClick}>{text}</HorizontalCloseButton>
                      </ButtonWrapper>
                    )
                }
              })}
            </ActionWrapper>
          </AlertModal>
        )
      },
    [defaultButtons, pop]
  )

  const { openModal } = useModal()

  const openAlertModal = (alertInfo: AlertInfo, style?: StackflowModalParams) => {
    openModal({
      render: () => renderAlertView(alertInfo),
      style,
    })
  }

  const openLoginAlertModal = () => {
    openAlertModal({
      message: '해당 기능을 사용하시려면 로그인이 필요해요.',
    })
  }

  return { openAlertModal, openLoginAlertModal }
}

export default useAlertModal

const AlertModal = styled.div`
  padding: 1.25rem;
`
const Message = styled.div`
  margin-bottom: 1rem;
`
const ActionWrapper = styled.div<{ direction?: 'vertical' | 'horizontal' }>`
  display: flex;
  flex-direction: ${({ direction }) => {
    return direction === 'vertical' ? 'column' : 'row'
  }};
  gap: ${({ direction }) => {
    return direction === 'vertical' ? '0.625rem' : '0.5rem'
  }};
`
const ButtonWrapper = styled.div`
  flex: 1;
`
const VerticalCloseButton = styled.button`
  ${vars.$semantic.typography.label3Bold};
  color: ${vars.$scale.color.gray700};
  height: 1.5rem;
  ${noneActiveEffect};
`
const HorizontalCloseButton = styled.button`
  width: 100%;
  height: 100%;
  ${vars.$semantic.typography.label3Bold};
  color: ${vars.$scale.color.gray900};
  border: 1px solid ${vars.$scale.color.gray300};
  border-radius: 0.313rem;
`
