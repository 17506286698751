import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import axios from 'axios'
import React, { useCallback, useRef } from 'react'
import { graphql, useFragment } from 'react-relay'

import {
  type ShortFormPost_shortForm$data,
  type ShortFormPost_shortForm$key,
} from '@src/__relay__/ShortFormPost_shortForm.graphql'
import { useImpression } from '@src/components/_lib/Impression'
import { useEventLogFetcher } from '@src/hooks/useEventLogFetcher'
import { secondToDuration } from '@src/lib/unit'

interface ShortFormPostProps {
  shortForm: ShortFormPost_shortForm$key
  itemOrder: number
  itemScrollContainerRef: React.RefObject<HTMLElement>
  onImpression: (shortForm: ShortFormPost_shortForm$data, itemOrder: number) => void
  onClick: (shortForm: ShortFormPost_shortForm$data, itemOrder: number) => void
}

const ShortFormPost = (props: ShortFormPostProps) => {
  const shortForm = useFragment(
    graphql`
      fragment ShortFormPost_shortForm on ShortForm {
        id
        externalId
        description
        duration
        impressionCallbackUrl
        itemDescription
        itemTitle
        targetUri
        thumbnailImageUrl
      }
    `,
    props.shortForm
  )

  const itemRef = useRef<HTMLDivElement>(null)

  const eventLogFetcher = useEventLogFetcher()

  // swipe log
  useImpression(
    {
      ref: itemRef,
      scrollContainerRef: props.itemScrollContainerRef,
      disableImpressionOnload: true,
    },
    () => {
      props.onImpression(shortForm, props.itemOrder)

      if (shortForm.impressionCallbackUrl) {
        eventLogFetcher(shortForm.impressionCallbackUrl, 'get')
      }
    },
    [props.itemOrder, props.onImpression]
  )

  const handleClick = useCallback(() => {
    window.location.href = shortForm.targetUri ?? ''
  }, [shortForm.targetUri])

  return (
    <Card onClick={handleClick} ref={itemRef}>
      <Image src={shortForm.thumbnailImageUrl} alt="숏폼 썸네일" />
      <Overlay>
        <DurationBg>
          <Duration>{secondToDuration(Math.floor(shortForm.duration))}</Duration>
        </DurationBg>
      </Overlay>
      <DescriptionBackground>
        <Description>{shortForm.description}</Description>
      </DescriptionBackground>
    </Card>
  )
}

const Card = styled.div({
  position: 'relative',
  height: 'calc(47.25vw + 48px)',
  width: '31.5vw',
  overflow: 'hidden',
})

const Image = styled.img({
  zIndex: 0,
  width: '100%',
  height: 'calc(100% - 48px)',
  objectFit: 'cover',
  borderRadius: 6,
})

const Overlay = styled.div({
  position: 'absolute',
  zIndex: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  bottom: 48,
  right: 0,
})

const DurationBg = styled.div({
  zIndex: 1,
  margin: '0.5rem',
  display: 'flex',
})

const Duration = styled.div([
  vars.$semantic.typography.label6Regular,
  {
    display: 'inline-block',
    padding: '0.125rem 0.25rem',
    borderRadius: '0.375rem',
    background: 'rgba(0, 0, 0, 0.502)',
    color: vars.$static.color.staticWhite,
  },
])

const DescriptionBackground = styled.div({
  zIndex: 1,
})

const Description = styled.div([
  vars.$semantic.typography.bodyL2Regular,
  {
    color: vars.$scale.color.gray900,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical',
    whiteSpace: 'normal',
  },
])

export default ShortFormPost
