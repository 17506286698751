/**
 * @generated SignedSource<<63b707ccdc570e9a89e3d187b51a957c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HomeSectionBase_sectionBase$data = {
  readonly " $fragmentSpreads": FragmentRefs<"useSectionBase_sectionBase">;
  readonly " $fragmentType": "HomeSectionBase_sectionBase";
};
export type HomeSectionBase_sectionBase$key = {
  readonly " $data"?: HomeSectionBase_sectionBase$data;
  readonly " $fragmentSpreads": FragmentRefs<"HomeSectionBase_sectionBase">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HomeSectionBase_sectionBase",
  "selections": [
    {
      "kind": "InlineDataFragmentSpread",
      "name": "useSectionBase_sectionBase",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "BaseInfo",
          "kind": "LinkedField",
          "name": "baseInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "title",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "subTitle",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ViewMoreInfo",
              "kind": "LinkedField",
              "name": "viewMoreInfo",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "type",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "label",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "targetUri",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isNew",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "SectionBaseInfo",
  "abstractKey": "__isSectionBaseInfo"
};

(node as any).hash = "493a881d9c1e5c2b35e06f53294b9a2f";

export default node;
