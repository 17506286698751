import { type PropOf } from '@cometjs/react-utils'
import { usePullToRefreshScrollElement } from '@daangn/karrot-clothes/lib/pullToRefresh'
import { chunk } from 'lodash'
import { type FC, useCallback, useMemo } from 'react'
import { graphql, useFragment } from 'react-relay'

import JobPostItem from './NearbyJobPostItem'
import { type HomeNearbyJobs_sectionNearbyJobs$key } from '../../../../__relay__/HomeNearbyJobs_sectionNearbyJobs.graphql'
import { useLogger } from '../../../../hooks/useLogger'
import { useOpenTarget } from '../../../../lib/target'
import type { SectionInfo } from '../../../../types'
import { Divider } from '../../../_lib/ListSection/Divider'
import { ListEntry } from '../../../_lib/ListSection/ListEntry'
import CarouselContent from '../Common/CarouselContent'
import HomeSectionBase from '../Common/HomeSectionBase'
interface HomeNearbyJobsProps {
  sectionNearbyJobs: HomeNearbyJobs_sectionNearbyJobs$key
  sectionInfo: SectionInfo
}

const VIEW_ITEM_COUNT = 3

const HomeNearbyJobs: FC<HomeNearbyJobsProps> = ({ sectionInfo, ...props }) => {
  const sectionNearbyJobs = useFragment(
    graphql`
      fragment HomeNearbyJobs_sectionNearbyJobs on SectionNearbyJobs {
        __typename
        jobPosts {
          id
          ...NearbyJobPostItem_jobPost
        }
        loggingMeta {
          swipe
          click
          show
        }
        ...HomeSectionBase_sectionBase
      }
    `,
    props.sectionNearbyJobs
  )

  const scrollContainerRef = usePullToRefreshScrollElement()
  const { nearbyLog, contentsSectionLog, loggingMetaLog } = useLogger()
  const { openTarget } = useOpenTarget()

  const handleImpression = () => {
    contentsSectionLog('show', {
      contentsType: 'jobs',
      sectionOrder: sectionInfo.order,
    })

    if (sectionInfo.order === 1) {
      loggingMetaLog(sectionNearbyJobs.loggingMeta.show)
    }
  }

  const handleMoreClick: NonNullable<PropOf<typeof HomeSectionBase, 'onMoreClick'>> = ({ targetUri }) => {
    contentsSectionLog('click', {
      contentsType: 'jobs',
      buttonType: 'more',
      params: {
        name: 'click_jobs_post_home',
        sectionOrder: sectionInfo.order,
      },
    })

    loggingMetaLog(sectionNearbyJobs.loggingMeta.click)

    if (targetUri) {
      openTarget({
        targetUri,
      })
    }
  }

  const handleJobPostItemClick: PropOf<typeof JobPostItem, 'onClick'> = useCallback(
    ({ targetUri, badges, externalId }) => {
      contentsSectionLog('click', {
        contentsType: 'jobs',
        buttonType: 'list',
        params: {
          name: 'click_jobs_post',
          sectionOrder: sectionInfo.order,
          item_id: externalId,
          ...(badges.length > 0 ? { badges: badges.join(',') } : null),
        },
      })

      loggingMetaLog(sectionNearbyJobs.loggingMeta.click)

      openTarget({
        targetUri,
      })
    },
    [contentsSectionLog, loggingMetaLog, openTarget, sectionInfo.order, sectionNearbyJobs.loggingMeta.click]
  )

  const handleCarouselListSwipe: PropOf<typeof CarouselContent, 'onListSwipe'> = useCallback(
    (offset) => {
      nearbyLog({
        params: {
          name: 'swipe_contents_section',
          contentsType: 'jobs',
          page: offset,
          sectionOrder: sectionInfo.order,
        },
      })

      loggingMetaLog(sectionNearbyJobs.loggingMeta.swipe)
    },
    [loggingMetaLog, nearbyLog, sectionInfo.order, sectionNearbyJobs.loggingMeta.swipe]
  )

  const jobPostItems = useMemo(
    () =>
      chunk(sectionNearbyJobs.jobPosts, VIEW_ITEM_COUNT).map((jobPosts, index) => (
        <ListEntry key={index}>
          {jobPosts.map((jobPost, index) => (
            <div key={jobPost.id}>
              <JobPostItem jobPost={jobPost} onClick={handleJobPostItemClick} />
              {index !== jobPosts.length - 1 && <Divider />}
            </div>
          ))}
        </ListEntry>
      )),
    [handleJobPostItemClick, sectionNearbyJobs.jobPosts]
  )

  if (sectionNearbyJobs.jobPosts.length === 0) {
    return null
  }

  return (
    <HomeSectionBase
      sectionBase={sectionNearbyJobs}
      ImpressionProps={{
        scrollContainerRef,
        onImpression: handleImpression,
        threshold: 0.8,
      }}
      onMoreClick={handleMoreClick}>
      <CarouselContent
        listItems={jobPostItems}
        onListSwipe={handleCarouselListSwipe}
        sectionOrder={sectionInfo.order}
      />
    </HomeSectionBase>
  )
}

export default HomeNearbyJobs
