/**
 * @generated SignedSource<<559d61e689238e3c7bb95f7009774076>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type PurposeSubThemeEnum = "FOOD" | "HEALTH" | "HOME_REPAIR" | "INTERIOR" | "PILATES" | "YOGA";
export type PurposeThemeEnum = "ETC" | "EXERCISE" | "REMODELING";
import { FragmentRefs } from "relay-runtime";
export type HomePurpose_section$data = {
  readonly __typename: "SectionPurpose";
  readonly loggingMeta: {
    readonly click: string;
    readonly show: string;
    readonly swipe: string;
  };
  readonly purposeThemeInfo: ReadonlyArray<{
    readonly subTheme: ReadonlyArray<PurposeSubThemeEnum>;
    readonly theme: PurposeThemeEnum;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"HomeSectionBase_sectionBase">;
  readonly " $fragmentType": "HomePurpose_section";
};
export type HomePurpose_section$key = {
  readonly " $data"?: HomePurpose_section$data;
  readonly " $fragmentSpreads": FragmentRefs<"HomePurpose_section">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HomePurpose_section",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PurposeThemeInfo",
      "kind": "LinkedField",
      "name": "purposeThemeInfo",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "theme",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "subTheme",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EventMeta",
      "kind": "LinkedField",
      "name": "loggingMeta",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "swipe",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "click",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "show",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HomeSectionBase_sectionBase"
    }
  ],
  "type": "SectionPurpose",
  "abstractKey": null
};

(node as any).hash = "9bcb7565d376929ecdb72fdb121e18cb";

export default node;
