import { atom } from 'recoil'

import { generateMapStateKey } from './key'

const DEFAULT_TOLERANCE = 0.00004 // 000035

/**
 * Atom에 대한 설명
 */
export const toleranceAtom = atom({
  key: generateMapStateKey('tolerance'),
  default: DEFAULT_TOLERANCE,
})
