import { usePullToRefreshScrollElement } from '@daangn/karrot-clothes/lib/pullToRefresh'
import { css as makeClassName } from '@emotion/css'
import styled from '@emotion/styled'
import { Tabs } from '@karrotframe/tabs'
import { vars } from '@seed-design/design-token'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { graphql, useFragment } from 'react-relay'
import { useRecoilValue } from 'recoil'
import { __, match } from 'ts-pattern'
import * as z from 'zod'

import { type HomeBizThemes_sectionBizTheme$key } from '@src/__relay__/HomeBizThemes_sectionBizTheme.graphql'
import { type HomeBizThemes_targetUris$key } from '@src/__relay__/HomeBizThemes_targetUris.graphql'
import { FlexCenter } from '@src/components/_lib/styles'
import { spotlightTargetInfoAtom } from '@src/features/spotlight/state/spotlightTargetInfo'
import { useSwipeSection } from '@src/hooks/sections/useSwipeSection'
import { useLogger } from '@src/hooks/useLogger'
import { invert } from '@src/lib/F'
import { type PropOf } from '@src/lib/T/utilTypes'
import { useOpenTarget, type Target } from '@src/lib/target'
import { type SectionInfo } from '@src/types'
import { type BizPostThemeType } from '@src/types/schemaEnums'

import HomeBizTheme from './HomeBizTheme'
import HomeLazyBizTheme from './HomeLazyBizTheme'
import { useSectionTitleBizNudging } from '../../lib/SectionTitle/SectionTitle'
import HomeSectionBase from '../Common/HomeSectionBase'

// 우리동네 업체 소식 컨텐츠 섹션에 단골/추천, 최근 본 소식 탭을 제거해요.
type ThemeType = Exclude<BizPostThemeType, 'FOLLOWING_AND_RECOMMENDATION' | 'RECENTLY'>

const tabKeyToId: Record<ThemeType, string> = {
  FOOD: 'HomeBizThemes_tab_FOOD' as const,
  LIFE: 'HomeBizThemes_tab_LIFE' as const,
  HEALTH: 'HomeBizThemes_tab_HEALTH' as const,
  BEAUTY: 'HomeBizThemes_tab_BEAUTY' as const,
  EDUCATION: 'HomeBizThemes_tab_EDUCATION' as const,
  ALL: 'HomeBizThemes_tab_ALL' as const,
  CLOSING_SALE: 'HomeBizThemes_tab_CLOSING_SALE' as const,
}

const tabIdToKey = invert(tabKeyToId)

const SchemaTabId = z.enum([
  tabKeyToId['FOOD'],
  tabKeyToId['LIFE'],
  tabKeyToId['HEALTH'],
  tabKeyToId['BEAUTY'],
  tabKeyToId['EDUCATION'],
  tabKeyToId['ALL'],
])

const BizPostTheme = {
  FOOD: {
    tabId: tabKeyToId.FOOD,
    tabLabel: '먹거리',
  } as const,
  LIFE: {
    tabId: tabKeyToId.LIFE,
    tabLabel: '생활',
  } as const,
  HEALTH: {
    tabId: tabKeyToId.HEALTH,
    tabLabel: '건강',
  } as const,
  BEAUTY: {
    tabId: tabKeyToId.BEAUTY,
    tabLabel: '미용',
  } as const,
  EDUCATION: {
    tabId: tabKeyToId.EDUCATION,
    tabLabel: '교육',
  } as const,
  ALL: {
    tabId: tabKeyToId.ALL,
    tabLabel: '전체',
  } as const,
  CLOSING_SALE: {
    tabId: tabKeyToId.CLOSING_SALE,
    tabLabel: '마감 할인',
  } as const,
}

interface HomeBizThemesProps {
  sectionBizTheme: HomeBizThemes_sectionBizTheme$key
  sectionInfo: SectionInfo
}
const HomeBizThemes: React.FC<HomeBizThemesProps> = ({ sectionInfo, ...props }) => {
  const scrollContainerRef = usePullToRefreshScrollElement()
  const tabsContainerRef = useRef<HTMLDivElement>(null)
  const { nearbyLog, contentsSectionLog, loggingMetaLog } = useLogger()
  const { openTarget } = useOpenTarget()
  const { tooltipTargetId } = useRecoilValue(spotlightTargetInfoAtom)

  const sectionBizTheme = useFragment<HomeBizThemes_sectionBizTheme$key>(
    graphql`
      fragment HomeBizThemes_sectionBizTheme on SectionBizTheme {
        firstBizPostTheme {
          ...HomeBizTheme_bizPostTheme
        }
        bizThemeOrder
        ...HomeSectionBase_sectionBase
        writeBizTargetUri
        loggingMeta {
          swipe
          click
          show
        }
        ...HomeBizThemes_targetUris
      }
    `,
    props.sectionBizTheme
  )

  const targetUris = useFragment<HomeBizThemes_targetUris$key>(
    graphql`
      fragment HomeBizThemes_targetUris on SectionBizTheme {
        FOLLOWING_AND_RECOMMENDATION: bizPostsTargetUri(type: FOLLOWING_AND_RECOMMENDATION)
        FOOD: bizPostsTargetUri(type: FOOD)
        LIFE: bizPostsTargetUri(type: LIFE)
        HEALTH: bizPostsTargetUri(type: HEALTH)
        BEAUTY: bizPostsTargetUri(type: BEAUTY)
        EDUCATION: bizPostsTargetUri(type: EDUCATION)
        ALL: bizPostsTargetUri(type: ALL)
        CLOSING_SALE: bizPostsTargetUri(type: CLOSING_SALE)
      }
    `,
    sectionBizTheme
  )

  const [activeTabKey, setActiveTabKey] = useState(sectionBizTheme.bizThemeOrder[0] ?? 'BEAUTY')

  useEffect(() => {
    const activeTabId = SchemaTabId.safeParse(tooltipTargetId)

    if (activeTabId.success) {
      setActiveTabKey(tabIdToKey[activeTabId.data])
    }
  }, [tooltipTargetId])

  const handleTabChange: PropOf<typeof Tabs, 'onTabChange'> = useCallback(
    (key, { swiped }) => {
      const nextActiveTabKey = key as ThemeType

      nearbyLog({
        params: {
          name: 'click_theme',
          theme: nextActiveTabKey,
          is_swiped: String(swiped),
          sectionOrder: sectionInfo.order,
        },
      })

      if (swiped) {
        nearbyLog({
          params: {
            name: 'swipe_contents_section',
            contentsType: 'post',
            sectionOrder: sectionInfo.order,
          },
        })
      }

      loggingMetaLog(sectionBizTheme.loggingMeta.swipe)

      setActiveTabKey(nextActiveTabKey)
    },
    [nearbyLog, sectionInfo.order, sectionBizTheme, loggingMetaLog]
  )

  const handleBizPostClick = useCallback(
    (
      target: Target,
      info: Parameters<NonNullable<PropOf<typeof HomeBizTheme, 'onBizPostClick'>>>[1],
      themeId: string
    ) => {
      const { bizPost } = info

      contentsSectionLog('click', {
        contentsType: 'post',
        buttonType: 'list',
        params: {
          name: 'click_theme_feed_item',
          theme_id: themeId,
          biz_post_id: bizPost.externalId,
          sectionOrder: sectionInfo.order,
        },
      })

      loggingMetaLog([sectionBizTheme.loggingMeta.click])

      openTarget({
        ...target,
        additionalQueryParams: {
          entry: match(themeId)
            .with('FOLLOWING_AND_RECOMMENDATION', () => 'nearby_biz_feed_following')
            .with('ALL', () => 'nearby_biz_feed_all')
            .with(__, (themeId) => `nearby_biz_feed_theme_${themeId}`)
            .exhaustive(),
        },
      })
    },
    [contentsSectionLog, openTarget, sectionInfo.order, loggingMetaLog, sectionBizTheme.loggingMeta.click]
  )

  const handleMoreClick = () => {
    contentsSectionLog('click', {
      contentsType: 'post',
      buttonType: 'more',
      params: {
        name: 'click_theme_feed_more',
        theme: activeTabKey,
        sectionOrder: sectionInfo.order,
      },
    })

    loggingMetaLog(sectionBizTheme.loggingMeta.click)

    openTarget({
      targetUri: targetUris[activeTabKey as ThemeType],
    })
  }

  const handleAllBizPostsClick = useCallback(() => {
    openTarget({
      targetUri: targetUris.ALL,
    })
  }, [openTarget, targetUris])

  const handleImpression = () => {
    contentsSectionLog('show', {
      contentsType: 'post',
      sectionOrder: sectionInfo.order,
    })

    if (sectionInfo.order === 1) {
      loggingMetaLog(sectionBizTheme.loggingMeta.show)
    }
  }

  const tabs = useMemo(
    () =>
      sectionBizTheme.bizThemeOrder
        .filter((t) => t.indexOf('_') !== 0)
        .map((bizPostThemeType, tabIndex) => {
          const { tabId, tabLabel } = BizPostTheme[bizPostThemeType as ThemeType]
          const _handleBizPostClick: PropOf<typeof HomeBizTheme, 'onBizPostClick'> = (target, info) => {
            handleBizPostClick(target, info, bizPostThemeType)
          }

          return {
            key: bizPostThemeType,
            render:
              tabIndex === 0
                ? () => {
                    return (
                      <HomeBizTheme
                        activeTabKey={activeTabKey}
                        currentTabKey={bizPostThemeType}
                        bizPostTheme={sectionBizTheme.firstBizPostTheme!}
                        onBizPostClick={_handleBizPostClick}
                        onAllBizPostsClick={handleAllBizPostsClick}
                      />
                    )
                  }
                : () => (
                    <HomeLazyBizTheme
                      bizPostThemeType={bizPostThemeType}
                      HomeBizThemeProps={{
                        activeTabKey: activeTabKey,
                        currentTabKey: bizPostThemeType,
                        onBizPostClick: _handleBizPostClick,
                        onAllBizPostsClick: handleAllBizPostsClick,
                      }}
                    />
                  ),
            buttonLabel: <TabButtonContainer id={tabId}>{tabLabel}</TabButtonContainer>,
            buttonAriaLabel: tabLabel,
          }
        })
        .filter((tab): tab is NonNullable<typeof tab> => {
          return tab !== null
        }),
    [activeTabKey, sectionBizTheme, handleAllBizPostsClick, handleBizPostClick]
  )

  // 업체소식 작성 넛지
  // todo. 서버에서 writeBizTargetUri를 nullable로 해주면 그거 반영하기
  const { sectionWriteButton, sectionWriteTooltip } = useSectionTitleBizNudging({
    rightButtonContent: '소식 작성',
    rightButtonTargetUri: sectionBizTheme.writeBizTargetUri,
    sectionType: 'post',
  })

  useSwipeSection({
    swipeContainerRef: tabsContainerRef,
    sectionOrder: sectionInfo.order,
  })

  return (
    <HomeSectionBase
      sectionBase={sectionBizTheme}
      ImpressionProps={{
        scrollContainerRef: scrollContainerRef,
        onImpression: handleImpression,
        threshold: 0.8,
      }}
      ServiceTitleProps={{
        serviceType: 'BIZ_STORE',
      }}
      SectionTitleProps={{
        rightButton: sectionWriteButton,
        rightButtonTooltip: sectionWriteTooltip,
      }}
      SectionBottomButtonProps={{
        content: match(activeTabKey)
          .with(__, () => '더보기')
          .exhaustive(),
        onClick: handleMoreClick,
      }}>
      <TabsContainer ref={tabsContainerRef}>
        <CategoryTabs
          className={makeClassName`
            --kf_tabs_tabBar-backgroundColor: ${vars.$semantic.color.paperDefault};
            --kf_tabs_tabMain-backgroundColor: ${vars.$semantic.color.paperDefault};
            --kf_tabs_tabBar-baseFontColor: ${vars.$scale.color.gray600};
            --kf_tabs_tabBar-activeFontColor: ${vars.$scale.color.gray900};
            --kf_tabs_tabBar-indicator-color: ${vars.$scale.color.gray900};
            --kf_tabs_tabBar-borderColor: ${vars.$scale.color.gray300};
            --kf_tabs_tabBar-item-vPadding: 0;
            --kf_tabs_tabBar-item-gap: 1rem;
          `}
          tabs={tabs}
          activeTabKey={activeTabKey}
          onTabChange={handleTabChange}
          useInlineButtons
        />
      </TabsContainer>
    </HomeSectionBase>
  )
}

const CategoryTabs = styled(Tabs)`
  & > div:first-of-type {
    display: flex;

    & > a[role='tab'] {
      ${FlexCenter}
    }
  }
`

const TabsContainer = styled.div`
  padding-bottom: 1px;
  min-height: 427px;
`

const TabButtonContainer = styled.div`
  display: flex;
  padding: 0.59375rem 0;
`

export default HomeBizThemes
