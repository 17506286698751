/**
 * @generated SignedSource<<bf20f561c4b054310ece72377285c225>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BusinessPostComponentA_bizPost$data = {
  readonly businessAccount: {
    readonly categoryName: string | null;
    readonly image: {
      readonly url: string;
    };
    readonly name: string;
    readonly viewerAttribute: {
      readonly distance: number | null;
      readonly hasViewerFollowed: boolean;
    };
  };
  readonly createdAt: any;
  readonly externalId: string;
  readonly images: ReadonlyArray<{
    readonly url: string;
  }>;
  readonly targetUri: string;
  readonly title: string;
  readonly " $fragmentType": "BusinessPostComponentA_bizPost";
};
export type BusinessPostComponentA_bizPost$key = {
  readonly " $data"?: BusinessPostComponentA_bizPost$data;
  readonly " $fragmentSpreads": FragmentRefs<"BusinessPostComponentA_bizPost">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "url",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BusinessPostComponentA_bizPost",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "externalId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "referrer",
          "value": ""
        }
      ],
      "kind": "ScalarField",
      "name": "targetUri",
      "storageKey": "targetUri(referrer:\"\")"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "FeedImage",
      "kind": "LinkedField",
      "name": "images",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "FeedBusinessAccount",
      "kind": "LinkedField",
      "name": "businessAccount",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "categoryName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "FeedBusinessAccountViewerAttribute",
          "kind": "LinkedField",
          "name": "viewerAttribute",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "distance",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasViewerFollowed",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "FeedImage",
          "kind": "LinkedField",
          "name": "image",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "FeedBusinessPost",
  "abstractKey": null
};
})();

(node as any).hash = "15b658e6d5946e07ddbb10e979976f81";

export default node;
