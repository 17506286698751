/**
 * @generated SignedSource<<e742408d0ef2d54345e5597a3dba56dd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HomeBizLocalFoods_sectionBizProduct$data = {
  readonly bizProducts: ReadonlyArray<{
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"HomeBizLocalFoodsItem_bizProduct">;
  }>;
  readonly loggingMeta: {
    readonly click: string;
    readonly show: string;
    readonly swipe: string;
  };
  readonly targetUri: string;
  readonly wishListTargetUri: string;
  readonly " $fragmentSpreads": FragmentRefs<"HomeSectionBase_sectionBase">;
  readonly " $fragmentType": "HomeBizLocalFoods_sectionBizProduct";
};
export type HomeBizLocalFoods_sectionBizProduct$key = {
  readonly " $data"?: HomeBizLocalFoods_sectionBizProduct$data;
  readonly " $fragmentSpreads": FragmentRefs<"HomeBizLocalFoods_sectionBizProduct">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HomeBizLocalFoods_sectionBizProduct",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "BizProduct",
      "kind": "LinkedField",
      "name": "bizProducts",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "HomeBizLocalFoodsItem_bizProduct"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "targetUri",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "wishListTargetUri",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EventMeta",
      "kind": "LinkedField",
      "name": "loggingMeta",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "swipe",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "click",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "show",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HomeSectionBase_sectionBase"
    }
  ],
  "type": "SectionLocalFood",
  "abstractKey": null
};

(node as any).hash = "3b4e08889d74b55b65f921d7ef3e834b";

export default node;
