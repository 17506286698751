/**
 * @generated SignedSource<<36f1a45f4e3fc976df0be19889d79573>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HomePopularServiceFieldBasicListItem_ExpertServiceField$data = {
  readonly externalId: string;
  readonly imageUrl: string | null;
  readonly lastWeekVisitCount: number | null;
  readonly name: string;
  readonly " $fragmentType": "HomePopularServiceFieldBasicListItem_ExpertServiceField";
};
export type HomePopularServiceFieldBasicListItem_ExpertServiceField$key = {
  readonly " $data"?: HomePopularServiceFieldBasicListItem_ExpertServiceField$data;
  readonly " $fragmentSpreads": FragmentRefs<"HomePopularServiceFieldBasicListItem_ExpertServiceField">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HomePopularServiceFieldBasicListItem_ExpertServiceField",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "externalId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "imageUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastWeekVisitCount",
      "storageKey": null
    }
  ],
  "type": "ExpertServiceField",
  "abstractKey": null
};

(node as any).hash = "382be8e7288b63c4680d6a6dafe71997";

export default node;
