import { atom, selector } from 'recoil'

import { generateBizAccountKey } from './key'

/**
 * 가지고 있는 비즈계정
 */
export const owningBizAccountsAtom = atom<
  | {
      readonly externalId: string
      readonly categoryId: number | null
      readonly individual: boolean
    }[]
>({
  key: generateBizAccountKey('owningBizAccount'),
  default: [],
})

/**
 * 비즈 유저 여부 (가지고 있는 비즈계정이 있는지 여부)
 */
export const isBizUserSelector = selector({
  key: generateBizAccountKey('isBizUser'),
  get: ({ get }) => get(owningBizAccountsAtom).length > 0,
})
