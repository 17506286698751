import { SizeIconProps } from '@daangn/karrot-clothes/lib/types/svg'
import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import React from 'react'

const IcLoadMore: React.FC<SizeIconProps> = ({ size = 26 }) => {
  return (
    <Svg width={size} height={size} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="26" height="26" rx="6" fill={vars.$scale.color.gray400} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.62911 10.2542C7.85691 10.0264 8.22626 10.0264 8.45406 10.2542L12.9999 14.8L17.5458 10.2542C17.7736 10.0264 18.1429 10.0264 18.3707 10.2542C18.5985 10.482 18.5985 10.8513 18.3707 11.0791L13.4124 16.0375C13.1846 16.2653 12.8152 16.2653 12.5874 16.0375L7.62911 11.0791C7.4013 10.8513 7.4013 10.482 7.62911 10.2542Z"
        fill={vars.$scale.color.gray700}
      />
    </Svg>
  )
}

export default React.memo(IcLoadMore)

const Svg = styled.svg`
  display: block;
`
