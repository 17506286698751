/**
 * @generated SignedSource<<90eef9c3fe439f32d52aacd07ed66893>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BizProductItem_common$data = {
  readonly " $fragmentSpreads": FragmentRefs<"useBizLoggingMeta_bizLoggingMeta">;
  readonly " $fragmentType": "BizProductItem_common";
};
export type BizProductItem_common$key = {
  readonly " $data"?: BizProductItem_common$data;
  readonly " $fragmentSpreads": FragmentRefs<"BizProductItem_common">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BizProductItem_common",
  "selections": [
    {
      "kind": "InlineDataFragmentSpread",
      "name": "useBizLoggingMeta_bizLoggingMeta",
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "BizDeliveryLoggingMeta",
              "kind": "LinkedField",
              "name": "bizDeliveryLoggingMeta",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "impression",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "click",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "BizLoggingMeta",
          "abstractKey": "__isBizLoggingMeta"
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "BizProduct",
  "abstractKey": null
};

(node as any).hash = "533aa8c8df967640f2ea0ff5a0ea92ae";

export default node;
