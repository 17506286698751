import { vars } from '@seed-design/design-token'
import React from 'react'

const BadgeNewIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="16" height="16" rx="8" fill={vars.$scale.color.carrot500} />
    <path d="M5 11V5.42857L11 11V5" stroke="white" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

export default BadgeNewIcon
