import { atom } from 'recoil'

import { devStorage } from '@src/lib/storage/storage'
import syncWithStorage from '@src/store/effects/syncWithStorage'

const syncWithDevStorage = syncWithStorage(devStorage)

export const bigFontState = atom({
  key: 'BigFontState',
  default: false,
  effects: [syncWithDevStorage({ key: 'bigFont' })],
})

export const DEFAULT_CPL = 16

export const bigFontCplState = atom({
  key: 'BigFontCplState',
  default: DEFAULT_CPL,
  effects: [
    syncWithDevStorage({
      key: 'bigFontCpl',
      decoder: (value) => (value ? parseInt(value, 10) : DEFAULT_CPL),
    }),
  ],
})
