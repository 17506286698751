import 'core-js/stable'
import 'regenerator-runtime/runtime'
import '@blueprintjs/popover2/lib/css/blueprint-popover2.css'
import '@blueprintjs/core/src/components/toast/_toast.scss'
import '@karrotframe/tabs/index.css'
import '@daangn/karrot-clothes/lib/index.css'
import 'react-lazy-load-image-component/src/effects/opacity.css'
import './styles/seedDesignStyle'
import './shim'
import './styles/global.scss'
import 'simple-reveal/index.css'
import '@stackflow/plugin-basic-ui/index.css'
import '@daangn/karrot-clothes/lib/pullToRefresh.css'
import '@daangn/sprout-components-avatar/index.css'
import '@daangn/sprout-components-button/index.css'
import '@daangn/sprout-components-callout/index.css'
import '@daangn/sprout-components-tabs/index.css'
import '@daangn/sprout-components-snackbar/index.css'
import '@daangn/sprout-components-slider/index.css'
import '@daangn/sprout-components-checkbox/index.css'
import '@daangn/sprout-components-help-bubble/index.css'
import '@daangn/sprout-components-chips/index.css'

import React, { StrictMode, Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import { ErrorBoundary } from 'react-error-boundary'
import { RecoilRoot } from 'recoil'

import Main from './App'
import ErrorFallback from './components/_lib/ErrorFallback'
import LoadingFallback from './components/_lib/LoadingFallback'
import { DATADOG_APPLICATION_ID, DATADOG_CLIENT_TOKEN, SENTRY_DSN, STAGE, VERSION } from './constants'
import { InitdatadogRum } from './lib/analytics/datadogRum'
import { initSentry } from './lib/analytics/sentry'
import { MyRelayEnvironmentProvider } from './relay'
import { MyThemeProvider } from './styles/MyThemeProvider'

replaceHashToBrowserRouter()

if (STAGE !== 'development') {
  //sentry
  initSentry({
    environment: STAGE,
    release: VERSION,
    dsn: SENTRY_DSN,
    tracesSampleRate: 0,
    sampleRate: 0.2,
  })

  // datadog
  InitdatadogRum({
    applicationId: DATADOG_APPLICATION_ID,
    clientToken: DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: 'nearby-webview',
    env: STAGE,
    version: VERSION,
    sessionSampleRate: 0.2, // 0.2% of sessions will be recorded
    sessionReplaySampleRate: 0,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask',
  })
}

createRoot(document.getElementById('root')!).render(
  // <StrictMode>
  <RecoilRoot>
    <ErrorBoundary
      FallbackComponent={({ error }) => (
        <ErrorFallback
          retry={() => {
            window.location.reload()
          }}
          error={error}
        />
      )}>
      <MyThemeProvider>
        <MyRelayEnvironmentProvider>
          <Suspense fallback={<LoadingFallback />}>
            <Main />
          </Suspense>
        </MyRelayEnvironmentProvider>
      </MyThemeProvider>
    </ErrorBoundary>
  </RecoilRoot>
  // </StrictMode>
)

function replaceHashToBrowserRouter() {
  const currentUrl = new URL(window.location.href)

  if (currentUrl.hash) {
    const hashPath = currentUrl.hash.substring(1)
    const newUrl = new URL(currentUrl.origin + hashPath)
    window.location.replace(newUrl.toString())
  }
}
