/**
 * @generated SignedSource<<eb5af0193a123532a382ccf8a1d577c0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useBizLoggingMeta_bizLoggingMeta$data = {
  readonly bizDeliveryLoggingMeta: {
    readonly click: string;
    readonly impression: string;
  } | null;
  readonly " $fragmentType": "useBizLoggingMeta_bizLoggingMeta";
};
export type useBizLoggingMeta_bizLoggingMeta$key = {
  readonly " $data"?: useBizLoggingMeta_bizLoggingMeta$data;
  readonly " $fragmentSpreads": FragmentRefs<"useBizLoggingMeta_bizLoggingMeta">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "useBizLoggingMeta_bizLoggingMeta"
};

(node as any).hash = "c299cb31109f7e889397128590445aaa";

export default node;
