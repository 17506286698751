/**
 * @generated SignedSource<<5ffdbb071c53732807204428251ad4a8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HomeNativeReview_sectionNativeReview$data = {
  readonly baseInfo: {
    readonly title: string;
    readonly viewMoreInfo: {
      readonly targetUri: string;
    } | null;
  } | null;
  readonly loggingMeta: {
    readonly click: string;
    readonly show: string;
    readonly swipe: string;
  };
  readonly nativeReviews: ReadonlyArray<{
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"HomeNativeReviewItem_reviewNativeReview">;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"HomeSectionBase_sectionBase">;
  readonly " $fragmentType": "HomeNativeReview_sectionNativeReview";
};
export type HomeNativeReview_sectionNativeReview$key = {
  readonly " $data"?: HomeNativeReview_sectionNativeReview$data;
  readonly " $fragmentSpreads": FragmentRefs<"HomeNativeReview_sectionNativeReview">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HomeNativeReview_sectionNativeReview",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HomeSectionBase_sectionBase"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BaseInfo",
      "kind": "LinkedField",
      "name": "baseInfo",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ViewMoreInfo",
          "kind": "LinkedField",
          "name": "viewMoreInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "targetUri",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EventMeta",
      "kind": "LinkedField",
      "name": "loggingMeta",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "show",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "click",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "swipe",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "NativeReview",
      "kind": "LinkedField",
      "name": "nativeReviews",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "HomeNativeReviewItem_reviewNativeReview"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "SectionNativeReview",
  "abstractKey": null
};

(node as any).hash = "8f0e12287a640924e06ae0912f57e7f0";

export default node;
