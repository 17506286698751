import styled from '@emotion/styled'
import { createContext, FCWithChildren, RefObject, useContext } from 'react'

const ScrollContainerContext = createContext<RefObject<HTMLDivElement>>(null as any)

const ScrollContainer: FCWithChildren<{
  scrollContainerRef: RefObject<HTMLDivElement>
}> = ({ scrollContainerRef, children }) => {
  return (
    <Container>
      <Stretched ref={scrollContainerRef}>
        <ScrollContainerContext.Provider value={scrollContainerRef}>{children}</ScrollContainerContext.Provider>
      </Stretched>
    </Container>
  )
}

export default ScrollContainer

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`

const Stretched = styled.div`
  flex: 1;
  overflow-y: scroll;
  position: relative;
`

export function useScrollContainerRef() {
  return useContext(ScrollContainerContext)
}
