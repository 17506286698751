import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { useEffect, useMemo, useRef, type FC } from 'react'

import IcClock from '@src/assets/images/icons/IcClock'
import IcCouponFilled from '@src/assets/images/icons/IcCouponFilled'
import IcGroupPurchase from '@src/assets/images/icons/IcGroupPurchase'
import IcInterestList from '@src/assets/images/icons/IcInterestList'
import { Flex, FlexCenter } from '@src/components/_lib/styles'

import { COUPON_MAP_Z_INDEX } from '../../../styles'
import { type IconPinMarker } from '../../types'

type IconPinProps = Omit<IconPinMarker, 'position' | 'type'>

const IconPin: FC<IconPinProps> = ({ iconType, backgroundColor, isSelected, onClick }) => {
  const IconByType = useMemo(() => {
    switch (iconType) {
      case 'COUPON':
        return <IcCouponFilled size={12} />
      case 'CLOSING_SALE':
        return <IcClock size={12} />
      case 'GROUP_PURCHASE':
        return <IcGroupPurchase size={12} />
      case 'INTEREST':
        return <IcInterestList size={12} color={vars.$static.color.staticWhite} />
    }
  }, [iconType])

  const ref = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (ref.current?.parentElement) {
      ref.current.parentElement.style.zIndex = isSelected ? COUPON_MAP_Z_INDEX.SELECTED_PIN : '0'
    }
  }, [isSelected])

  return (
    <S_IconPin ref={ref} onClick={onClick} data-type="marker-icon">
      <S_Pin backgroundColor={backgroundColor} />
      <IconWrapper>{IconByType}</IconWrapper>
    </S_IconPin>
  )
}

export default IconPin

const S_IconPin = styled.div`
  ${FlexCenter};
  flex-direction: column;
`

const S_Pin = styled.div<{ backgroundColor?: string | null }>`
  width: 1.375rem;
  height: 1.375rem;
  border: 0.1rem solid ${vars.$static.color.staticWhite};
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  background-color: ${({ backgroundColor }) => backgroundColor ?? vars.$scale.color.carrot500};
  border-radius: 50%;
  ${Flex};
`

const IconWrapper = styled.div`
  position: absolute;
  top: 0.3438rem;
`
