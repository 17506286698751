import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import React, { useMemo } from 'react'
import { useFragment, graphql } from 'react-relay'

import { type HomePopularServiceFieldBasicList_SectionPopularServiceField$key } from '@src/__relay__/HomePopularServiceFieldBasicList_SectionPopularServiceField.graphql'

import HomePopularServiceFieldBasicListItem from './HomePopularServiceFieldBasicListItem'

interface Props {
  sectionPopularServiceFieldRef: HomePopularServiceFieldBasicList_SectionPopularServiceField$key
  onClickItem: (serviceField: string) => void
}

const HomePopularServiceFieldBasicList = (props: Props) => {
  const { serviceFields } = useFragment(
    graphql`
      fragment HomePopularServiceFieldBasicList_SectionPopularServiceField on SectionPopularServiceField {
        serviceFields {
          id
          ...HomePopularServiceFieldBasicListItem_ExpertServiceField
        }
      }
    `,
    props.sectionPopularServiceFieldRef
  )
  const [topRankServiceFields, etcRankServiceFields] = useMemo(() => {
    const half = Math.ceil(serviceFields.length / 2)

    return serviceFields.reduce<
      [
        {
          rank: number
          node: (typeof serviceFields)[number]
        }[],
        {
          rank: number
          node: (typeof serviceFields)[number]
        }[]
      ]
    >(
      (acc, cur, index) => {
        const rank = index + 1
        acc[rank > half ? 1 : 0].push({ node: cur, rank: rank })

        return acc
      },
      [[], []]
    )
  }, [serviceFields])

  return (
    <Base>
      <ListWrapper>
        <List>
          {topRankServiceFields.map(({ node, rank }) => (
            <Item key={node.id}>
              <HomePopularServiceFieldBasicListItem
                rank={rank}
                expertServiceFieldRef={node}
                onClickItem={props.onClickItem}
              />
            </Item>
          ))}
        </List>
        <List>
          {etcRankServiceFields.map(({ node, rank }) => (
            <Item key={node.id}>
              <HomePopularServiceFieldBasicListItem
                rank={rank}
                expertServiceFieldRef={node}
                onClickItem={props.onClickItem}
              />
            </Item>
          ))}
        </List>
      </ListWrapper>
    </Base>
  )
}

const Base = styled.div`
  padding: 0.5rem 0 1.25rem;
`
const ListWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  overflow-x: scroll;
  overflow-y: hidden;
  scroll-snap-align: center;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    display: none;
  }
`
const List = styled.div`
  width: 20.25rem;
  min-width: 20.25rem;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: start;
  overflow: hidden;
`
const Item = styled.div`
  & + &:before {
    content: '';
    display: block;
    margin: 0 1rem;
    height: 0.0625rem;
    background-color: ${vars.$semantic.color.divider1};
  }
`

export default HomePopularServiceFieldBasicList
