import { debounce } from 'lodash'
import { createContext, type FCWithChildren, useCallback, useContext, useMemo, useRef } from 'react'
import snakecaseKeys from 'snakecase-keys'

import { useLocalBusinessLogEvent } from '@src/stackflow/customPlugins/localBusinessAnalytics'

import { useBridgeAnalytics } from './useBridgeAnalytics'
import { useEventLogFetcher } from './useEventLogFetcher'
import { type GA_EVENTS_NAME, GA_EVENTS_NAME_LIST, gaEvent } from '../lib/analytics/gtag'
import * as F from '../lib/F'

type EventPayload = string | number | null | undefined | boolean | object

type EventParams = {
  name?: string
  [key: string]: EventPayload
}

export type LogSectionType =
  | 'fitness'
  | 'map'
  | 'post'
  | 'review'
  | 'coupon'
  | 'mini'
  | 'jobs'
  | 'recommend_commerce'
  | 'keyword'
  | 'nearby_post'
  | 'class'
  | 'local_food'
  | 'purpose'
  | 'hotKeyword'
  | 'jobs_targeting_banner'
  | 'recently_bizaccount'
  | 'my_daangn_story'
  | 'car_hot_articles'
  | 'hot_stores'
  | 'car_saved_cost'
  | 'realty_new_listing'
  | 'jobs_famous_post'
  | 'nearby_fitness'
  | 'recommended_post'
  | 'native_reviews'
  | 'public_biz_post'
  | 'short_form'
  | 'expert_recommend'
  | 'expert_auto_request'
  | 'popular_service_field'

export function useLogger() {
  const analytics = useBridgeAnalytics()
  const dataLayer = useLogDataLayer()
  const eventLogFetcher = useEventLogFetcher()

  const log = useCallback(
    ({ name, params }: { name: 'nearby_event' | 'mini_event'; params?: EventParams }) => {
      const _params = snakecaseKeys(
        F.compactMap({
          ...dataLayer,
          ...params,
          //todo. 실험 로그 필요시에 추가
          // ...(experimentType && { experimentType }),
          // ...(experimentId && { experimentId }),
        }),
        { deep: true }
      )

      analytics.log({
        name,
        params: _params,
      })

      if (params?.name && GA_EVENTS_NAME_LIST.includes(params.name as GA_EVENTS_NAME)) {
        const { name, ...rest } = _params
        gaEvent(name, rest)
      }
    },
    [analytics, dataLayer]
  )

  const _localBizLog = useLocalBusinessLogEvent()
  const localBizLog = useMemo(() => {
    return _localBizLog
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const nearbyLog = useCallback(
    ({ params }: { params?: EventParams }) => {
      log({
        name: 'nearby_event',
        params,
      })
    },
    [log]
  )

  interface ContentsSectionClickLog {
    contentsType: LogSectionType
    buttonType: 'list' | 'more' | 'main' | 'info' | 'watch'
    params?: EventParams
  }

  interface ContentsSectionShowLog {
    contentsType: LogSectionType
    sectionOrder?: number
    params?: EventParams
  }

  interface ContentsSectionSwipeLog {
    contentsType: LogSectionType
    itemOrder?: number
    page?: number
    params?: EventParams
  }

  type ContentsSectionLogType = 'click' | 'show' | 'swipe'

  type SectionLogData<T extends ContentsSectionLogType> = T extends 'click'
    ? ContentsSectionClickLog
    : T extends 'show'
    ? ContentsSectionShowLog
    : ContentsSectionSwipeLog

  const contentsSectionLog = useCallback<
    <T extends ContentsSectionLogType>(logType: T, logData: SectionLogData<T>) => void
  >(
    (logType, logData) => {
      if (logData.params && logData.params.name) {
        nearbyLog({
          params: logData.params,
        })
      }

      const params = logData.params

      delete logData.params

      nearbyLog({
        params: {
          ...params,
          ...logData,
          name: `${logType}_contents_section`,
        },
      })
    },
    [nearbyLog]
  )

  // dobunce가 적용된 로깅 post
  const loggingMetaLog = useMemo(
    () =>
      debounce((logingUrls: string | Array<string | undefined> | undefined) => {
        if (Array.isArray(logingUrls)) {
          return logingUrls.forEach((url) => {
            if (url) {
              eventLogFetcher(url)
            }
          })
        }

        if (logingUrls) {
          eventLogFetcher(logingUrls)
        }
      }, 500),
    [eventLogFetcher]
  )

  // debounce 적용 없는 로깅 post
  const loggingMetaLogDirectly = useCallback(
    (logingUrls: string | Array<string | undefined> | undefined) => {
      if (Array.isArray(logingUrls)) {
        return logingUrls.forEach((url) => {
          if (url) {
            eventLogFetcher(url)
          }
        })
      }

      if (logingUrls) {
        eventLogFetcher(logingUrls)
      }
    },
    [eventLogFetcher]
  )

  return useMemo(
    () => ({
      log,
      nearbyLog,
      localBizLog,
      contentsSectionLog,
      loggingMetaLog,
      loggingMetaLogDirectly,
    }),
    [log, nearbyLog, contentsSectionLog, loggingMetaLog, loggingMetaLogDirectly, localBizLog]
  )
}

type DataLayer = Record<string, EventPayload>

type LogDataLayerActionContextType = {
  addData: (key: string, value: EventPayload) => void
  setDatas: (dataLayer: DataLayer) => void
}

const LogDataLayerContext = createContext<DataLayer>({})

const LogDataLayerActionContext = createContext<LogDataLayerActionContextType>({
  addData: null as any,
  setDatas: null as any,
})

export const LogDataLayerProvider: FCWithChildren<{ initialDataLayer: DataLayer }> = ({
  children,
  initialDataLayer,
}) => {
  const dataLayerRef = useRef<DataLayer>(initialDataLayer)

  const handleAddData: LogDataLayerActionContextType['addData'] = useCallback((key, value) => {
    dataLayerRef.current[key] = value
  }, [])

  const handleSetDatas: LogDataLayerActionContextType['setDatas'] = useCallback((dataLayer) => {
    dataLayerRef.current = dataLayer
  }, [])

  const value = useMemo(
    () => ({
      addData: handleAddData,
      setDatas: handleSetDatas,
    }),
    [handleAddData, handleSetDatas]
  )

  return (
    <LogDataLayerContext.Provider value={dataLayerRef.current}>
      <LogDataLayerActionContext.Provider value={value}>{children}</LogDataLayerActionContext.Provider>
    </LogDataLayerContext.Provider>
  )
}

export const useLogDataLayerAction = () => {
  return useContext(LogDataLayerActionContext)
}

const useLogDataLayer = () => {
  return useContext(LogDataLayerContext)
}
