import { HeartButton } from '@daangn/karrot-clothes'
import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { type FC, type MouseEvent, useMemo } from 'react'
import { graphql, useFragment } from 'react-relay'

import { type BannerPoi_poi$key, type BannerPoi_poi$data } from '@src/__relay__/BannerPoi_poi.graphql'
import CoveredLazyLoadImage from '@src/components/_lib/CoveredLazyLoadImage'
import TagGroup from '@src/components/_lib/TagGroup'
import { POI_THUMBNAIL_PLACEHOLDER } from '@src/constants/poi'
import { usePoiWatch } from '@src/features/poi/hooks/usePoiWatch'
import { type PropOf } from '@src/lib/T/utilTypes'
import { formatDistance } from '@src/lib/unit'

import { activeEffect, FlexAlignCenter, NonWebkitTabHighlight } from '../../../components/_lib/styles'

interface BannerPoiProps {
  poiRef: BannerPoi_poi$key
  replaceImageUrl?: string
  onClick?: (poi: BannerPoi_poi$data) => void
  onWatchClick?: (poi: BannerPoi_poi$data, isWatched: boolean) => void
}

const BannerPoi: FC<BannerPoiProps> = ({ poiRef, replaceImageUrl, onClick, onWatchClick }) => {
  const poi = useFragment(
    graphql`
      fragment BannerPoi_poi on Poi {
        id
        externalId
        name
        isNew
        distance {
          distance
          name
        }
        category {
          name
        }
        images {
          url
        }
        targetUri
        bizAccount {
          image {
            url
          }
        }
        ...usePoiWatch_poi
      }
    `,
    poiRef
  )

  const { viewerIsWatched, watchPoi } = usePoiWatch({ poiRef: poi })

  const handleClick = (e?: MouseEvent<HTMLDivElement>) => {
    e?.stopPropagation()

    onClick?.(poi)
  }

  const handleWatchClick = (e?: MouseEvent<HTMLButtonElement>) => {
    e?.stopPropagation()

    watchPoi()

    onWatchClick?.(poi, viewerIsWatched)
  }

  const poiTags: PropOf<typeof TagGroup, 'tags'> = useMemo(
    () => [
      {
        text: poi.category?.name,
      },
      {
        text: poi.distance ? formatDistance(poi.distance) : null,
      },
    ],
    [poi.category, poi.distance]
  )

  const poiImageUrl =
    poi.bizAccount?.image?.url ??
    (poi.images.length > 0 ? poi.images[0].url : replaceImageUrl) ??
    POI_THUMBNAIL_PLACEHOLDER

  return (
    <S_BannerPoi onClick={handleClick}>
      <BannerPoiContent>
        <Left>{poiImageUrl && <S_CoveredLazyLoadImage src={poiImageUrl} alt={poi.name} visibleByDefault />}</Left>
        <Right>
          <PoiInfo>
            <PoiName>{poi.name}</PoiName>
            <TagGroup size="medium" tags={poiTags} />
          </PoiInfo>
        </Right>
      </BannerPoiContent>
      <HeartButtonWrapper>
        <HeartButton onClick={handleWatchClick} filled={viewerIsWatched} />
      </HeartButtonWrapper>
    </S_BannerPoi>
  )
}

export default BannerPoi

const S_BannerPoi = styled.div`
  position: relative;
`

const BannerPoiContent = styled.div`
  display: flex;
  border: 1px solid ${vars.$scale.color.gray200};
  border-radius: 0.5rem;
  color: ${vars.$scale.color.gray900};
  ${activeEffect()};
`

const Left = styled.div`
  width: 3.875rem;
  height: 3.875rem;
`

const Right = styled.div`
  ${FlexAlignCenter};
  flex: 1;
  position: relative;
  padding: 0.719rem 0.875rem;
  height: 3.875rem;
  box-sizing: border-box;
`

const PoiInfo = styled.div``

const PoiName = styled.div`
  ${vars.$semantic.typography.bodyM2Regular};
`

const S_CoveredLazyLoadImage = styled(CoveredLazyLoadImage)`
  border-radius: 0.5rem 0 0 0.5rem;
`

const HeartButtonWrapper = styled.div`
  ${FlexAlignCenter};
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  ${NonWebkitTabHighlight};
`
