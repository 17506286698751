import styled from '@emotion/styled'
import { type FC } from 'react'
import { graphql, useFragment } from 'react-relay'

import SectionBottomButton from './SectionBottomButton'
import SectionContainer, { S_SectionContainer } from './SectionContainer'
import SectionTitle from './SectionTitle/SectionTitle'
import { type SectionLoading_sectionBase$key } from '../../../__relay__/SectionLoading_sectionBase.graphql'
import { useSectionBase } from '../../../hooks/sections/useSectionBase'
import { type PropsOf } from '../../../lib/T/utilTypes'
import Spinner from '../../_lib/Spinner'

interface SectionLoadingProps {
  sectionBase: SectionLoading_sectionBase$key
  SectionTitleProps: Partial<PropsOf<typeof SectionTitle>>
  SectionBottomButtonProps?: PropsOf<typeof SectionBottomButton>
  spinnerSize?: number
  height?: string
}

const SectionLoading: FC<SectionLoadingProps> = ({
  SectionTitleProps,
  SectionBottomButtonProps,
  spinnerSize = 24,
  height,
  ...props
}) => {
  const sectionBase = useFragment(
    graphql`
      fragment SectionLoading_sectionBase on SectionBaseInfo {
        ...useSectionBase_sectionBase
      }
    `,
    props.sectionBase
  )

  const { SectionTitlePropsWithBaseInfo, SectionBottomButtonPropsWithBaseInfo } = useSectionBase({
    sectionBase,
    SectionTitleProps,
    SectionBottomButtonProps,
  })

  if (!height) {
    return null
  }

  return (
    <SectionContainer>
      <SectionTitle {...SectionTitlePropsWithBaseInfo} />
      <FallbackContainer height={height}>
        <Spinner size={spinnerSize} />
      </FallbackContainer>
      {SectionBottomButtonPropsWithBaseInfo && <SectionBottomButton {...SectionBottomButtonPropsWithBaseInfo} />}
    </SectionContainer>
  )
}

export default SectionLoading

const FallbackContainer = styled(S_SectionContainer)<{ height: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({ height }) => height};
  padding-top: 0.75rem;
  margin-bottom: 0.5rem;
`
