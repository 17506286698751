import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { type FC, type PropsWithChildren } from 'react'

type BasicModalViewProps = PropsWithChildren<{
  title: string
  description?: string
}>

const BasicModalView: FC<BasicModalViewProps> = ({ title, description, children }) => {
  return (
    <Container>
      <Title>{title}</Title>
      {description && <Description>{description}</Description>}
      {children}
    </Container>
  )
}

export default BasicModalView

const Container = styled.div`
  width: 80%;
  padding: 1rem;
  background-color: ${vars.$semantic.color.paperDefault};
  border-radius: 0.25rem;
`

const Title = styled.div`
  font-size: 1.125rem;
  color: ${vars.$semantic.color.primary};
  font-weight: bold;
  margin-bottom: 0.5rem;
`

const Description = styled.div`
  font-size: 0.75rem;
  color: ${vars.$scale.color.gray600};
  font-weight: bold;
  margin-bottom: 0.75rem;
`

export const DevModalSubTitle = styled.div`
  color: ${vars.$scale.color.gray900};
  font-weight: bold;
  flex-wrap: wrap;
`

export const DevModalLabel = styled.span`
  margin-right: 0.5rem;
  color: ${vars.$scale.color.gray900};
`

export const DevModalTextArea = styled.textarea`
  width: 100%;
`

export const DevModalInputWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }
`

export const DevModalSubInputWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const DevModalInput = styled.input``

export const DevModalSelect = styled.select`
  width: 100%;
  padding: 0.25rem;
  box-sizing: border-box;
`
