import { KarrotButton } from '@daangn/karrot-clothes'
import { Nearby } from '@daangn/nearby-sdk'
import { type FC, useMemo, useState } from 'react'

import { karrotBridge } from '../../../sdk'
import BasicModalView, {
  DevModalInputWrapper,
  DevModalLabel,
  DevModalSelect,
  DevModalTextArea,
} from '../lib/BasicModalView'

interface SpotlightDevModalProps {
  closeAll: () => void
}

const INITIAL_TOOLTIP_TARGET_ID = '미적용'

const SpotlightDevModal: FC<SpotlightDevModalProps> = ({ closeAll }) => {
  const idList = useMemo(
    () =>
      Array.from(document.getElementById('root')?.querySelectorAll(`[id]`) ?? [])
        .map((element) => element.id)
        .filter((id) => !id.includes('ic_')),
    []
  )
  const targetIdList = useMemo(() => idList.filter((id) => !id.includes('_tab')), [idList])

  const [targetId, setTargetId] = useState(idList[0])
  const [tooltipContent, setTooltipContent] = useState('')
  const [tooltipTargetId, setTooltipTargetId] = useState(INITIAL_TOOLTIP_TARGET_ID)

  const toolTipTargetIdList = useMemo(
    () => idList.filter((id) => id.startsWith(`${targetId}_tab_`)),
    [idList, targetId]
  )

  const handleButtonClick = () => {
    const nearby = new Nearby()

    nearby
      .openSpotlight({
        targetId,
        tooltipContent,
        ...(tooltipTargetId !== INITIAL_TOOLTIP_TARGET_ID && {
          tooltipTargetId,
        }),
      })
      .then(() => {
        //window.location.href = `${APP_SCHEME}tab/nearby'`
        closeAll()
      })
  }

  return (
    <BasicModalView title="NearbySDK Spotlight Test">
      <DevModalInputWrapper>
        <DevModalLabel>targetId: </DevModalLabel>
        <DevModalSelect value={targetId} onChange={(e) => setTargetId(e.target.value)}>
          {targetIdList.map((id) => (
            <option key={id}>{id}</option>
          ))}
        </DevModalSelect>
      </DevModalInputWrapper>
      {toolTipTargetIdList && toolTipTargetIdList.length > 0 && (
        <DevModalInputWrapper>
          <DevModalLabel>tooltipContent: </DevModalLabel>
          <DevModalSelect value={tooltipTargetId} onChange={(e) => setTooltipTargetId(e.target.value)}>
            <option>미적용</option>
            {toolTipTargetIdList.map((id) => (
              <option key={id}>{id}</option>
            ))}
          </DevModalSelect>
        </DevModalInputWrapper>
      )}
      <DevModalInputWrapper>
        <DevModalLabel>tooltipContent: </DevModalLabel>
        <DevModalTextArea value={tooltipContent} onChange={(e) => setTooltipContent(e.target.value)} />
      </DevModalInputWrapper>
      <DevModalInputWrapper />
      <KarrotButton size="small" onClick={handleButtonClick}>
        실행
      </KarrotButton>
    </BasicModalView>
  )
}

export default SpotlightDevModal
