import { BoxButton } from '@daangn/sprout-components-button'
import { Checkbox } from '@daangn/sprout-components-checkbox'
import { Slider } from '@daangn/sprout-components-slider'
import { type FC } from 'react'
import { useRecoilState } from 'recoil'

import BasicModalView, { DevModalInputWrapper } from '../lib/BasicModalView'
import { bigFontCplState, bigFontState, DEFAULT_CPL } from '../state/bigFont'

interface BigFontSimultationModalViewProps {
  closeAll: () => void
}

const BigFontSimultationModalView: FC<BigFontSimultationModalViewProps> = ({ closeAll }) => {
  const [bigFont, setBigFont] = useRecoilState(bigFontState)
  // 빅 폰트 시뮬레이션 시, 한 줄에 들어가는 글자 수
  const [CPL, setCPL] = useRecoilState(bigFontCplState)

  return (
    <BasicModalView title="빅폰트 시뮬레이션">
      <DevModalInputWrapper>
        <Checkbox isSelected={bigFont} onChange={setBigFont} shape="square" size="medium">
          CPL: {bigFont ? CPL : Math.round(window.innerWidth / 16)}
        </Checkbox>
        {bigFont && (
          <Slider
            // markers={[
            //   {
            //     align: 'start',
            //     label: minZoom.toString(),
            //     value: minZoom,
            //   },
            //   {
            //     align: 'center',
            //     label: midZoom.toString(),
            //     value: midZoom,
            //   },
            //   {
            //     align: 'end',
            //     label: maxZoom.toString(),
            //     value: maxZoom,
            //   },
            // ]}
            minValue={DEFAULT_CPL}
            maxValue={22}
            value={CPL}
            onChange={(value: number | number[]) => {
              if (Array.isArray(value)) {
                setCPL(value[0])
                return
              }
              setCPL(value)
            }}
          />
        )}
        <BoxButton size="xsmall" onClick={closeAll}>
          닫기
        </BoxButton>
      </DevModalInputWrapper>
    </BasicModalView>
  )
}

export default BigFontSimultationModalView
