import { atom } from 'recoil'

import { type CouponMapContents_couponMap$data } from '@src/__relay__/CouponMapContents_couponMap.graphql'

import { generateMapStateKey } from './key'

export interface ActiveBizAccountState {
  bizId: string
  couponId: string
  couponPosition: {
    lat: number
    lng: number
  }
  couponRef: CouponMapContents_couponMap$data['coupons'][number]
}

export type ActiveTabCategory = {
  activeTabKey: string
  activeTabId: string
  activeTabLabel: string
}

/**
 * 현재 보고있는 지도의 중심좌표
 */
export const activeBizAccountAtom = atom<ActiveBizAccountState | null>({
  key: generateMapStateKey('activeBizAccount'),
  default: null,
})
/**
 * 쿠폰지도 탭
 */
export const couponMapActiveTabCategoryAtom = atom<ActiveTabCategory>({
  key: generateMapStateKey('activeTabCategory'),
  default: {
    activeTabId: '',
    activeTabKey: '',
    activeTabLabel: '',
  },
})
