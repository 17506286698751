import { KarrotButton } from '@daangn/karrot-clothes'
import styled from '@emotion/styled'
import { type FC, useState } from 'react'

import { useRouter } from '../../../hooks/useRouter'
import { type NearbyActivityNames } from '../../../stackflow/stackflow'
import BasicModalView, { DevModalInput } from '../lib/BasicModalView'

interface PageMoveModalViewProps {
  closeAll?: () => void
}

type SubPage = 'MAIN' | 'PURPOSE' | 'CouponDetail'

const PageMoveModalView: FC<PageMoveModalViewProps> = ({ closeAll }) => {
  const { push } = useRouter()
  const [couponId, setCouponId] = useState<string>('')
  const [subView, setSubView] = useState<SubPage>('MAIN')

  const movePage = (activity: NearbyActivityNames, path: string, params: any = {}) => {
    push(activity, path, params)
    closeAll?.()
  }

  return (
    <BasicModalView title="페이지 이동" description="페이지를 이동해요">
      <CenterAlign>
        {subView === 'MAIN' && (
          <>
            <KarrotButton size="small" onClick={() => setSubView('CouponDetail')}>
              쿠폰상세
            </KarrotButton>
            <KarrotButton size="small" onClick={() => movePage('CouponMap', 'coupon_map')}>
              쿠폰지도
            </KarrotButton>
            <KarrotButton
              size="small"
              onClick={() => movePage('HomeFeedShortcutBottomSheet', 'home_feed_shortcut_bottom_sheet')}>
              온보딩 바텀시트
            </KarrotButton>
            <KarrotButton size="small" onClick={() => setSubView('PURPOSE')}>
              고비용 고목적성
            </KarrotButton>
            <KarrotButton size="small" onClick={() => movePage('RecentlyView', 'recently_view')}>
              최근 본 소식
            </KarrotButton>
            <KarrotButton size="small" onClick={() => movePage('Write', 'write')}>
              동글카 (동네글쓰기카테고리)
            </KarrotButton>
          </>
        )}

        {subView === 'PURPOSE' && (
          <>
            <KarrotButton size="small" onClick={() => movePage('Purpose', 'purpose', { theme: 'exercise' })}>
              운동
            </KarrotButton>
            <KarrotButton size="small" onClick={() => movePage('Purpose', 'purpose', { theme: 'remodeling' })}>
              집수리/인테리어
            </KarrotButton>
          </>
        )}

        {subView === 'CouponDetail' && (
          <>
            쿠폰id를 입력하세요
            <DevModalInput
              value={couponId}
              onChange={(e) => {
                setCouponId(e.currentTarget.value)
              }}
            />
            <KarrotButton size="small" onClick={() => movePage('CouponDetail', `coupons/${couponId}`, { couponId })}>
              이동
            </KarrotButton>
          </>
        )}
      </CenterAlign>
    </BasicModalView>
  )
}

export default PageMoveModalView

const CenterAlign = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  button {
    margin-top: 1rem;
  }
`
