import { parse } from '@daangn/webview-target-uri'
import { pipe } from 'lodash/fp.js'
import { useCallback, useMemo } from 'react'

import { STAGE } from '../../constants'
import { useBridgeRouter } from '../../hooks/useBridgeRouter'
import { useViewer } from '../../hooks/useViewer'

export type UpdateReferrer = (currentReferrer: string) => string
export type UpdateReferrerContext = (currentReferrerContext: string) => string

export type Target = {
  additionalQueryParams?: { [key: string]: string }
  updateReferrer?: UpdateReferrer
  updateReferrerContext?: UpdateReferrerContext
} & OriginalTarget

export type OriginalTarget =
  | {
      targetUri: string
    }
  | {
      schemePath: string
    }
  | {
      app: string
      path: string
      present?: 'top'
      navbar?: boolean
      scrollable?: boolean
    }
  | {
      remote: string
      present?: 'top'
      navbar?: boolean
      scrollable?: boolean
    }

export function useOpenTarget() {
  const router = useBridgeRouter()
  const { region } = useViewer()

  const openTarget = useCallback(
    (target: Target): void => {
      if ('targetUri' in target) {
        try {
          const t = parse(target.targetUri)

          if ('app' in t) {
            return openTarget({
              app: t.app,
              path: t.path,
              additionalQueryParams: target.additionalQueryParams,
              updateReferrer: target.updateReferrer,
              updateReferrerContext: target.updateReferrerContext,
              navbar: t.navbar,
              scrollable: t.scrollable,
            })
          } else {
            return openTarget({
              remote: t.remote,
              additionalQueryParams: target.additionalQueryParams,
              updateReferrer: target.updateReferrer,
              updateReferrerContext: target.updateReferrerContext,
              navbar: t.navbar,
              scrollable: t.scrollable,
            })
          }
        } catch (error) {
          return openTarget({
            schemePath: target.targetUri.split('://')[1],
          })
        }
      }

      // eslint-disable-next-line prefer-const
      let { origin, pathname, hash, searchParams } = new URL(
        'schemePath' in target ? target.schemePath : 'remote' in target ? target.remote : target.path,
        /* dummy */ 'file://'
      )

      const isHashRouter = pathname === '/' && searchParams.toString() === '' && hash.split('#')[1]?.indexOf('/') === 0

      if (isHashRouter) {
        const hashUrl = new URL(hash.split('#')[1], /* dummy */ 'file://')
        pathname = hashUrl.pathname
        searchParams = hashUrl.searchParams
      }

      // @TODO 임시
      const render = pipe(templating('{regionId}', String(region.id)))
      pathname = render(pathname)

      for (const key in target.additionalQueryParams) {
        searchParams.set(key, target.additionalQueryParams[key])
      }

      // referrer 수정적용
      if (target.updateReferrer && searchParams.has('referrer')) {
        searchParams.set('referrer', target.updateReferrer(searchParams.get('referrer')!))
      }
      // referrer_context 수정적용
      if (target.updateReferrerContext && searchParams.has('referrer_context')) {
        searchParams.set('referrer_context', target.updateReferrerContext(searchParams.get('referrer_context')!))
      }

      const nextPath = searchParams.toString().length > 0 ? `${pathname}?${searchParams.toString()}` : pathname

      if ('schemePath' in target) {
        switch (STAGE) {
          case 'alpha':
          case 'development':
            return void (window.location.href = `karrot.alpha://${nextPath}`)
          case 'production':
            return void (window.location.href = `karrot://${nextPath}`)
        }
      }
      if ('app' in target) {
        router.push({
          router: {
            app: target.app,
            path: nextPath,
            navbar: target.navbar ?? false,
            scrollable: target.scrollable ?? false,
            present: target.present === 'top',
          },
        })
      }
      if ('remote' in target) {
        router.push({
          router: {
            remote: isHashRouter ? `${origin}/#${nextPath}` : origin + nextPath,
            navbar: target.navbar ?? false,
            scrollable: target.scrollable ?? false,
            present: target.present === 'top',
          },
        })
      }
    },
    [router, region.id]
  )

  return useMemo(
    () => ({
      openTarget,
    }),
    [openTarget]
  )
}

const templating = (from: string, to: string) => (str: string) => {
  return str.split(encodeURIComponent(from)).join(to)
}
