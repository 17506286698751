import { createRoutingManager, createAnalyticsManager, createImpressionManager } from '@daangn/business-review-sdk'
import { installKarrotBridgeDriver, makeKarrotBridge } from '@daangn/karrotbridge'
import { installLocalBusinessDriver, makeLocalBusinessBridge } from '@daangn/local-business-bridge'
import { Nearby, NearbyStream } from '@daangn/nearby-sdk'

import { IS_LOCAL, IS_PRODUCTION } from './constants'
// karrotbridge
const { driver } = installKarrotBridgeDriver()
export const karrotBridge = makeKarrotBridge({ driver })

// local-business-bridge
const { driver: localBusinessDriver } = installLocalBusinessDriver()
export const localBusinessBridge = makeLocalBusinessBridge({ driver: localBusinessDriver })

export const nearbySDK = new Nearby()
export const nearbyStream = new NearbyStream(karrotBridge)

export const businessReviewRoutingManager = createRoutingManager({
  stage: IS_PRODUCTION ? 'production' : 'development',
})
export const businessReviewAnalyticsManager = createAnalyticsManager({
  stage: IS_PRODUCTION ? 'production' : 'development',
  bridge: karrotBridge,
})
export const businessReviewImpressionManager = createImpressionManager({
  stage: IS_PRODUCTION ? 'production' : 'development',
  bridge: karrotBridge,
  options: IS_LOCAL
    ? {
        fallbackUserAuthToken: import.meta.env.VITE_DEFAULT_AUTH_TOKEN,
      }
    : undefined,
})
