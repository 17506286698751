import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { type ReactNode } from 'react'

export const checkNumericRange = (
  value: number,
  {
    min,
    max,
  }: {
    min: number
    max: number
  }
) => {
  if (!(value >= min && value <= max)) {
    throw new RangeError(`The argument must be between ${min} and ${max}.`)
  }
}

export type RatingTextProps = {
  percent: number
  count: number
  size: 'large' | 'small'
  icon: {
    render: () => ReactNode
  }
}

export const RatingText = ({ percent, size, count, icon }: RatingTextProps) => {
  try {
    checkNumericRange(percent, { min: 0, max: 100 })

    const rating = ((percent / 100) * count).toFixed(1)

    return (
      <S_RatingText>
        {icon.render()}
        <S_Text size={size}>{rating}</S_Text>
      </S_RatingText>
    )
  } catch (e) {
    console.error(e)

    return null
  }
}

const S_RatingText = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '0.125rem',
})

const S_Text = styled.span(({ size }: { size?: string }) => [
  size === 'large' ? vars.$semantic.typography.title2Bold : vars.$semantic.typography.caption1Bold,
])
