import { HeartButton } from '@daangn/karrot-clothes'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { type HTMLDivElement } from 'globalthis/implementation'
import { type MouseEvent } from 'react'
import { useFragment } from 'react-relay'
import { graphql } from 'relay-runtime'

import { type HomeNativeReviewItemPoi_poiNativeReview$key } from '@src/__relay__/HomeNativeReviewItemPoi_poiNativeReview.graphql'
import defaultPoiImage from '@src/assets/images/poi_img_map.png'
import { POI_REMOTE_APP } from '@src/constants'
import { usePoiWatch } from '@src/features/poi/hooks/usePoiWatch'
import { useLogger } from '@src/hooks/useLogger'
import { useOpenTarget } from '@src/lib/target'

interface HomeNativeReviewItemPoiProps {
  poiNativeReview: HomeNativeReviewItemPoi_poiNativeReview$key
  reviewId: string
}
const HomeNativeReviewItemPoi: React.FC<HomeNativeReviewItemPoiProps> = (props) => {
  const poi = useFragment<HomeNativeReviewItemPoi_poiNativeReview$key>(
    graphql`
      fragment HomeNativeReviewItemPoi_poiNativeReview on Poi {
        name
        id
        externalId
        images {
          url
          isDefaultImage
          thumbnail
        }
        category {
          name
        }
        region {
          name3
        }
        ...usePoiWatch_poi
      }
    `,
    props.poiNativeReview
  )
  const { id, externalId, name, category, images, region } = poi
  const defaultImage = images?.find((image) => image.isDefaultImage) ?? images?.[0]

  const { contentsSectionLog, localBizLog } = useLogger()
  const { openTarget } = useOpenTarget()
  const { viewerIsWatched, watchPoi } = usePoiWatch({
    poiRef: poi,
  })

  const handleReviewPoiClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()

    contentsSectionLog('click', {
      contentsType: 'native_reviews',
      buttonType: 'list',
      params: {
        click_target: 'poi',
        poi_id: externalId,
      },
    })

    localBizLog({
      name: 'click_nearby_tobaki_section_poi',
      params: {
        poi_id: externalId.toString(),
        review_id: props.reviewId,
      },
    })

    openTarget({ remote: `${POI_REMOTE_APP}#/home/${externalId}` })
  }

  const handleWatchButtonClick = (e?: MouseEvent<HTMLButtonElement>) => {
    e?.stopPropagation()
    const isWatched = !viewerIsWatched

    localBizLog({
      name: 'click_favorite_button',
      params: {
        poi_id: externalId,
        isWatched,
      },
    })

    localBizLog({
      name: 'click_nearby_tobaki_section_poi_like',
      params: {
        review_id: props.reviewId,
        poi_id: externalId,
        is_watched: isWatched,
      },
    })

    watchPoi()
  }

  return (
    <Card onClick={handleReviewPoiClick}>
      <Image>
        <img className="thumbnail-image" alt="thumbnail-image" src={defaultImage?.thumbnail ?? defaultPoiImage} />
      </Image>
      <Content>
        <Text>
          <Title className="title">{name}</Title>
          {(category || region?.name3) && (
            <Information>
              {category?.name}
              {category && region?.name3 && <span className="bull">&bull;</span>}
              {!!region?.name3 && region?.name3}
            </Information>
          )}
        </Text>
        <HeartButton onClick={handleWatchButtonClick} filled={viewerIsWatched} />
      </Content>
    </Card>
  )
}

const Card = styled.div`
  position: relative;
  display: flex;
  align-items: left;
  overflow: hidden;
  border-radius: 6px;
  border: 1px solid ${vars.$semantic.color.divider2};
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.04);
`

const Image = styled.div`
  height: 62px;
  flex-basis: 62px;

  .thumbnail-image {
    object-fit: cover;
    width: 62px;
    height: 62px;
    min-width: 62px;
    min-height: 62px;
  }
`

const Content = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  width: calc(100% - 62px);
`

const Text = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  width: calc(100% - 54px);
  padding: 10px 0 10px 14px;

  .newBadge {
    margin: 0 0 0 2px;
  }
`

interface Props {
  line?: number
}

export const EllipsisText = styled.div<Props>`
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  ${({ line }) => line && line > 1 && multilineEllipsis(line)};
`

const multilineEllipsis = (line: number) => css`
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: ${line};
  -webkit-box-orient: vertical;
  white-space: normal;
`

const Title = styled(EllipsisText)`
  ${vars.$semantic.typography.bodyM2Regular}
  margin: 0 4px 2px 0;
  width: 100%;
  height: 20px;
  svg {
    transform: translateY(4px);
  }
`

const Information = styled(EllipsisText)`
  ${vars.$semantic.typography.caption1Regular}
  min-width: 30px;
  height: 20px;
  color: ${vars.$scale.color.gray600};

  .bull {
    margin: 0 2px 2px;
    color: ${vars.$scale.color.gray600};
    font-weight: 100;
  }
`

export default HomeNativeReviewItemPoi
