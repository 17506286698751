import { type Experiment } from './coreTypes'

/** 프론트 코드에서 관리하는 실험키 */
export type FrontDrivenExperimentKeyEnum = 'None' // 'poi_experiment1' //| 'poi_experiment2'

type GetTargetSegment = ({ userId }: { userId: number }) => string

/** 프론트 코어에서 관리하는 실험 정보
 * 사용시 store.state.experiments.[key] 로 접근 가능
 * @property name 실험 이름
 * @property segments 대조군, 실험군 정보
 * @property targetSegment 디폴트 실험 값
 * @property getTargetSegment 실험 값 가져오는 함수
 */
export type FrontDrivenExperimentScheme = Experiment & {
  getTargetSegment: GetTargetSegment
}

export type FrontDrivenExperiments = {
  [key in FrontDrivenExperimentKeyEnum]: FrontDrivenExperimentScheme
}

export const progressExperiments: FrontDrivenExperiments = {
  None: {
    name: 'none',
    segments: [],
    targetSegment: '',
    getTargetSegment: () => '',
  },
  // poi_experiment1: {
  //   name: 'poi_experiment1',
  //   segments: [
  //     {
  //       name: 'UI 형태 1',
  //       value: '0',
  //     },
  //     {
  //       name: 'UI 형태 2',
  //       value: '1',
  //     },
  //   ],
  //   targetSegment: '0',
  //   getTargetSegment: getTargetValueFromUserId(2),
  // },
}

/**
 * 유저 아이디를 이용해 실험 값을 가져오는 함수를 반환하는 함수
 * @param segmentNum 실험 값의 범위
 */
function getTargetValueFromUserId(segmentNum: number): GetTargetSegment {
  return ({ userId }) => (userId % segmentNum).toString()
}

/**
 * 실험 플랫폼으로 부터 실험 값을 가져오는 함수
 */
function getTargetValueFromExperimentPlatform(): GetTargetSegment {
  return ({ userId }) => ''
}
