/**
 * @generated SignedSource<<8d403fab5066547e19e0ed63f9443e6e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type RealtySalesTypeEnum = "APART" | "ETC" | "OFFICETEL" | "OPEN_ONE_ROOM" | "SPLIT_ONE_ROOM" | "STORE" | "TWO_ROOM" | "UNSPECIFIED";
export type RealtyTradeTypeEnum = "BORROW" | "BUY" | "MONTHLY" | "SHORT" | "UNSPECIFIED" | "YEARLY";
import { FragmentRefs } from "relay-runtime";
export type RecentlyRealtyArticle_realtyArticle$data = {
  readonly externalId: string;
  readonly id: string;
  readonly image: {
    readonly thumbnail: string;
  };
  readonly realtyType: RealtySalesTypeEnum;
  readonly reatyArticlePrice: string;
  readonly reatyArticleTitle: string | null;
  readonly region: {
    readonly name3: string;
  };
  readonly targetUri: string;
  readonly tradeType: RealtyTradeTypeEnum;
  readonly videoDuration: number | null;
  readonly videoThumbnailUrl: string | null;
  readonly " $fragmentType": "RecentlyRealtyArticle_realtyArticle";
};
export type RecentlyRealtyArticle_realtyArticle$key = {
  readonly " $data"?: RecentlyRealtyArticle_realtyArticle$data;
  readonly " $fragmentSpreads": FragmentRefs<"RecentlyRealtyArticle_realtyArticle">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RecentlyRealtyArticle_realtyArticle",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "externalId",
      "storageKey": null
    },
    {
      "alias": "reatyArticleTitle",
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": "reatyArticlePrice",
      "args": null,
      "kind": "ScalarField",
      "name": "price",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Image",
      "kind": "LinkedField",
      "name": "image",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "thumbnail",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "videoThumbnailUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tradeType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Region",
      "kind": "LinkedField",
      "name": "region",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name3",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "realtyType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "videoDuration",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "targetUri",
      "storageKey": null
    }
  ],
  "type": "RealtyArticle",
  "abstractKey": null
};

(node as any).hash = "1be68010294b1cecde6dc8a43e95df8e";

export default node;
