import { type AnalyticsEvent, localBusinessAnalyticsPlugin } from '@daangn/local-business-analytics'

import { type LogEventTypes } from '@src/types/localBusinessEventType'

export const {
  useUnregisterScreenCommonPayload,
  useRegisterScreenCommonPayloadEffect,
  useRegisterScreenCommonPayload,
  useLogEvent: useLocalBusinessLogEvent,
  plugin: localBusinessAnalyticsStackflowPlugin,
} = localBusinessAnalyticsPlugin<
  | LogEventTypes
  | AnalyticsEvent<'show_page', any>
  | AnalyticsEvent<
      'click_biz_profile',
      {
        biz_id: string
        post_id: string | null
        is_manager: boolean
      }
    >
  | AnalyticsEvent<
      'show_review_item',
      {
        item_order: number
      }
    >
  | AnalyticsEvent<
      'click_favorite_button',
      {
        biz_profile_id?: string
        poi_id?: string
      }
    >
  | AnalyticsEvent<'click_share_review', Record<string, never>>
  | AnalyticsEvent<
      'click_review_like_button',
      {
        biz_id: string
        review_id: string
        is_manager: boolean
      }
    >
  | AnalyticsEvent<
      'click_user_profile',
      {
        user_id: string
      }
    >
  | AnalyticsEvent<
      'click_product_item',
      {
        product_id: string
      }
    >
  | AnalyticsEvent<
      'click_create_coupon_onboarding',
      {
        biz_account_id: string
      }
    >
  | AnalyticsEvent<
      'show_create_coupon_onboarding',
      {
        biz_account_id: string
      }
    >
  | AnalyticsEvent<'show_nearby_tobaki_section'>
  | AnalyticsEvent<'click_swipe_nearby_tobaki_section'>
  | AnalyticsEvent<'click_nearby_tobaki_section_more'>
  | AnalyticsEvent<
      'click_nearby_tobaki_section_list',
      {
        review_id: string
      }
    >
  | AnalyticsEvent<
      'click_nearby_tobaki_section_profile',
      {
        review_id: string
        user_id: string
      }
    >
  | AnalyticsEvent<
      'click_nearby_tobaki_section_poi',
      {
        poi_id: string
        review_id: string
      }
    >
  | AnalyticsEvent<
      'click_nearby_tobaki_section_poi_like',
      {
        poi_id: string
        review_id: string
        is_watched: boolean
      }
    >
  | AnalyticsEvent<
      'click_open_banner',
      {
        banner_type: string
      }
    >
  | AnalyticsEvent<
      'show_open_banner',
      {
        banner_type: string
      }
    >
  | AnalyticsEvent<
      'click_open_banner_alert',
      {
        banner_type: string
      }
    >
  | AnalyticsEvent<'click_poi_suggestion_create_button'>
  | AnalyticsEvent<'click_biz_profile_onboarding_button'>
>()
