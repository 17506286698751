import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import React, { useEffect } from 'react'
import { useSetRecoilState } from 'recoil'
import { __, match } from 'ts-pattern'

import { bridgeInfoResource } from './bridgeInfoResource'
import { BottomSheetRenderContextProvider } from './components/_bottomSheets/useBottomSheet'
import LoadingFallback from './components/_lib/LoadingFallback'
import { ModalRenderContextProvider } from './components/_modals/useModal'
import { moveLocalMap } from './components/Home/hooks/useLocalMap'
import { PageTopCheckerProvider } from './components/Home/PageScrollTopChecker'
import { appAtom } from './features/_global/state/app'
import { regionAtom } from './features/user/state/region'
import { userAtom } from './features/user/state/user'
import { ImpressedSectionOrderContextProvider } from './hooks/useImpressedSectionOrder'
import { getQueryFromUrl } from './hooks/useQueryParams'
import { ViewerProvider } from './hooks/useViewer'
import { setClarityUserConfig } from './lib/analytics/clarity'
import { setDatadogRumUserConfig } from './lib/analytics/datadogRum'
import { setGAUserConfig } from './lib/analytics/gtag'
import { setSentryUserConfig } from './lib/analytics/sentry'
import { captureException } from './lib/capture-exception'
import withSuspense from './lib/with-fallback/withSuspense'
import { loadVConsole } from './stackflow/customPlugins/nearbyDevtoolsPlugin'
import { Stack } from './stackflow/stackflow'
import { MyGlobalStyles } from './styles/MyGlobalStyles'

const App: React.FC = () => {
  const bridgeInfo = bridgeInfoResource.read()
  const setApp = useSetRecoilState(appAtom)
  const setUser = useSetRecoilState(userAtom)
  const setRegion = useSetRecoilState(regionAtom)

  useEffect(() => {
    if (!bridgeInfo) return

    const { referrer, isDevMode, isLocalMap } = getQueryFromUrl<{
      referrer: string
      isDevMode: string
      isLocalMap: string
    }>(window.location.href)

    if (isLocalMap) {
      moveLocalMap(false)
      return
    }

    if (isDevMode) {
      loadVConsole().then(() => {
        console.info('### get Bridge', bridgeInfo)
      })
    }

    const { app, region, user } = bridgeInfo

    const [appInfo, osInfo] = app.userAgent.split(' ')
    const [, _version] = appInfo.split('/')
    const [_os] = (osInfo || 'iOS').split('/')

    const userId = user?.id.toString() ?? 'unknown'
    const regionId = region.id.toString()

    const os = match(_os)
      .with('iOS', () => 'IOS' as const)
      .with('Android', () => 'ANDROID' as const)
      .with(__, () => 'UNKNOWN' as const)
      .exhaustive()
    const version = _version.replace('00', '0').replace('01', '1') // 안드로이드 22.38.00, 22.38.01 버전 이슈 대응 (semantics versioning이 아님)

    // recoil setting
    setApp({
      ...app,
      os,
      version,
    })
    setUser(user)
    setRegion(region)

    // Sentry Setting
    setSentryUserConfig({
      userId,
      region: regionId,
      deviceIdentity: app.deviceIdentity,
    })

    // GA User Setting
    setGAUserConfig({
      userId,
      region: regionId,
      userAgent: app.userAgent,
      country: app.country || '',
      deviceIdentity: app.deviceIdentity || '',
    })

    // Clarity Setting
    setClarityUserConfig({
      userId,
      deviceIdentity: app.deviceIdentity,
      region: regionId,
      referrer,
      onError: (err) => {
        err.message = `[clarity 초기화 error]: ${err.message}`
        captureException(err)
      },
    })

    // DataDog Setting
    setDatadogRumUserConfig({
      userId,
      region: regionId,
      referrer,
      userAgent: app.userAgent,
      country: app.country || '',
      deviceIdentity: app.deviceIdentity || '',
    })
  }, [bridgeInfo, setApp, setRegion, setUser])

  return (
    <ViewerProvider
      viewer={{
        region: bridgeInfo.region,
        user: bridgeInfo.user,
      }}>
      <PageTopCheckerProvider>
        <ImpressedSectionOrderContextProvider>
          <BottomSheetRenderContextProvider>
            <ModalRenderContextProvider>
              <Container>
                <Stack />
              </Container>
            </ModalRenderContextProvider>
          </BottomSheetRenderContextProvider>
        </ImpressedSectionOrderContextProvider>
      </PageTopCheckerProvider>
      <MyGlobalStyles />
    </ViewerProvider>
  )
}

const Container = styled.div`
  height: 100%;
  overflow: hidden;
  color: ${vars.$scale.color.gray900};
`

export default withSuspense(App, () => <LoadingFallback />)
