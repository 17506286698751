import { type PropOf } from '@cometjs/react-utils'
import { usePullToRefreshScrollElement } from '@daangn/karrot-clothes/lib/pullToRefresh'
import styled from '@emotion/styled'
import { IconCameraFill } from '@seed-design/icon'
import { useCallback, useRef } from 'react'
import { graphql, useFragment } from 'react-relay'

import { type HomeShortForms_section$key } from '@src/__relay__/HomeShortForms_section.graphql'
import ScrollableX from '@src/components/_lib/Scrollable'
import { useEventLogFetcher } from '@src/hooks/useEventLogFetcher'
import { useLogger } from '@src/hooks/useLogger'
import { useOpenTarget } from '@src/lib/target'
import { type SectionInfo } from '@src/types'

import ShortFormPost from './ShortFormPost'
import HomeSectionBase from '../Common/HomeSectionBase'

interface HomeShortFormsProps {
  sectionShortForm: HomeShortForms_section$key
  sectionInfo: SectionInfo
}

const HomeShortForms = (props: HomeShortFormsProps) => {
  const section = useFragment(
    graphql`
      fragment HomeShortForms_section on SectionShortForm {
        __typename
        baseInfo {
          title
        }
        loggingMeta {
          swipe
          click
          show
        }
        sectionIcon {
          url
        }
        shortForms {
          externalId
          ...ShortFormPost_shortForm
        }
        shortFormUploadTargetUri

        ...HomeSectionBase_sectionBase
      }
    `,
    props.sectionShortForm
  )

  const scrollContainerRef = usePullToRefreshScrollElement()
  const { contentsSectionLog, loggingMetaLog, nearbyLog } = useLogger()
  const { openTarget } = useOpenTarget()
  const itemScrollContainerRef = useRef<HTMLDivElement>(null)
  const eventLogFetcher = useEventLogFetcher()

  const handleSectionImpression = useCallback(() => {
    contentsSectionLog('show', {
      contentsType: 'short_form',
      sectionOrder: props.sectionInfo.order,
    })

    if (props.sectionInfo.order === 0) {
      loggingMetaLog(section.loggingMeta.show)
    }
  }, [contentsSectionLog, loggingMetaLog, props.sectionInfo.order, section.loggingMeta.show])

  const handlePostImpression: NonNullable<PropOf<typeof ShortFormPost, 'onImpression'>> = useCallback(
    ({ impressionCallbackUrl }, itemOrder) => {
      nearbyLog({
        params: {
          name: 'show_short_form_post',
          from: 'contents_section',
          sectionOrder: props.sectionInfo.order,
          itemOrder,
        },
      })

      if (impressionCallbackUrl) {
        eventLogFetcher(impressionCallbackUrl, 'get')
      }
    },
    [eventLogFetcher, nearbyLog, props.sectionInfo.order]
  )

  const handleMoreClick: NonNullable<PropOf<typeof HomeSectionBase, 'onMoreClick'>> = useCallback(
    ({ targetUri }) => {
      contentsSectionLog('click', {
        contentsType: 'short_form',
        buttonType: 'more',
        params: {
          sectionOrder: props.sectionInfo.order,
        },
      })

      loggingMetaLog(section.loggingMeta.click)

      if (targetUri) {
        openTarget({
          targetUri,
        })
      }
    },
    [contentsSectionLog, loggingMetaLog, openTarget, props.sectionInfo.order, section.loggingMeta.click]
  )

  const handlePostClick: NonNullable<PropOf<typeof ShortFormPost, 'onClick'>> = useCallback(
    (itemOrder) => {
      nearbyLog({
        params: {
          name: 'click_short_form_post',
          from: 'contents_section',
          sectionOrder: props.sectionInfo.order,
          itemOrder,
        },
      })
    },
    [nearbyLog, props.sectionInfo.order]
  )

  return (
    <HomeSectionBase
      sectionBase={section}
      ImpressionProps={{
        scrollContainerRef,
        onImpression: handleSectionImpression,
        threshold: 0.9,
      }}
      SectionTitleProps={{
        rightButton: {
          content: (
            <AddShortVideo>
              <IconCameraFill width={16} height={16} /> 스토리 올리기
            </AddShortVideo>
          ),
          onClick: () => {
            openTarget({
              targetUri: section.shortFormUploadTargetUri,
            })
          },
        },
      }}
      onMoreClick={handleMoreClick}>
      <Scrollable ref={itemScrollContainerRef}>
        {section.shortForms.map((shortForm, index) => (
          <ShortFormPost
            key={shortForm.externalId}
            shortForm={shortForm}
            itemOrder={index}
            itemScrollContainerRef={itemScrollContainerRef}
            onImpression={handlePostImpression}
            onClick={handlePostClick}
          />
        ))}
      </Scrollable>
    </HomeSectionBase>
  )
}

export default HomeShortForms

const Scrollable = styled(ScrollableX)`
  padding: 0.75rem 1rem 1rem;
`

const AddShortVideo = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
`

const CustomTitle = styled.div`
  display: flex;
  align-items: center;
  img {
    margin-right: 0.25rem;
    width: 1.5rem;
    height: 1.5rem;
  }
`
