import { withScope } from '@sentry/react'
import { type GraphQLError } from 'graphql'
import { type RequestParameters, type Variables } from 'relay-runtime'

import { LocalGatewayFetcher } from '@src/api/fetcher'
import { captureException } from '@src/lib/capture-exception'

async function fetchGraphQL(operation: RequestParameters, variables: Variables) {
  const response = await LocalGatewayFetcher.post('/graphql', {
    query: operation.text,
    variables,
  })

  response.data?.errors?.forEach((error: GraphQLError) => {
    withScope((scope) => {
      scope.setTag('kind', operation.operationKind)
      scope.setTag('operationName', operation.name)
      scope.setExtra('query', operation.text)
      scope.setExtra('variables', variables)
      scope.setExtra('errorResponse', error)

      const exception = new Error(error?.message || '')
      exception.name = `Relay Error - [${operation.operationKind}][${operation.name}]`

      captureException(exception)
    })
  })

  return response.data
}

export default fetchGraphQL
