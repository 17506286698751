/**
 * @generated SignedSource<<75496dbdfea888ff0c63e7ad0e86c859>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type NearbyBadgeEnum = "COUPON_EXISTS" | "DELIVERY" | "FAST_RESPONSE" | "FREE_DELIVERY" | "HAS_TRIAL" | "MOST_REVIEW" | "MOST_VIEW" | "SAME_DAY_DELIVERY" | "WALK_10_MIN";
import { FragmentRefs } from "relay-runtime";
export type HotBizAccountItem_bizAccount$data = {
  readonly badges: ReadonlyArray<NearbyBadgeEnum>;
  readonly categoryName: string | null;
  readonly description: string | null;
  readonly distance: {
    readonly distance: number;
    readonly name: string;
  } | null;
  readonly followerCount: number;
  readonly image: {
    readonly url: string;
  };
  readonly name: string;
  readonly reviewCount: number;
  readonly targetUri: string;
  readonly " $fragmentType": "HotBizAccountItem_bizAccount";
};
export type HotBizAccountItem_bizAccount$key = {
  readonly " $data"?: HotBizAccountItem_bizAccount$data;
  readonly " $fragmentSpreads": FragmentRefs<"HotBizAccountItem_bizAccount">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HotBizAccountItem_bizAccount",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Image",
      "kind": "LinkedField",
      "name": "image",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DistanceInfo",
      "kind": "LinkedField",
      "name": "distance",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "distance",
          "storageKey": null
        },
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "categoryName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "reviewCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "followerCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "targetUri",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "badges",
      "storageKey": null
    }
  ],
  "type": "BizAccount",
  "abstractKey": null
};
})();

(node as any).hash = "5ef5b7f5856991341c6edbff73b76fd7";

export default node;
