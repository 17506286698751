/**
 * @generated SignedSource<<0b0aaa73c141a80097d5128b0c834859>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HomeCustomSection_sectionCustom$data = {
  readonly baseInfo: {
    readonly extra: string | null;
    readonly requestInfo: {
      readonly " $fragmentSpreads": FragmentRefs<"CustomSection_componentType" | "CustomSection_requestInfo">;
    } | null;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"HomeSectionBase_sectionBase">;
  readonly " $fragmentType": "HomeCustomSection_sectionCustom";
};
export type HomeCustomSection_sectionCustom$key = {
  readonly " $data"?: HomeCustomSection_sectionCustom$data;
  readonly " $fragmentSpreads": FragmentRefs<"HomeCustomSection_sectionCustom">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HomeCustomSection_sectionCustom",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "BaseInfo",
      "kind": "LinkedField",
      "name": "baseInfo",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "extra",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "RequestInfo",
          "kind": "LinkedField",
          "name": "requestInfo",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "CustomSection_requestInfo"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "CustomSection_componentType"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HomeSectionBase_sectionBase"
    }
  ],
  "type": "SectionCustom",
  "abstractKey": null
};

(node as any).hash = "7588fa7973768526dd499bd8bb5d1a91";

export default node;
