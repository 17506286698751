import { type HomeRequestReview_sectionRequestReview$data } from '@src/__relay__/HomeRequestReview_sectionRequestReview.graphql'

type BizReviewTarget = HomeRequestReview_sectionRequestReview$data['bizReviewTargets'][number]
export type NormalizedBizReviewTarget = {
  __typename: BizReviewTarget['bizStore']['__typename']
  id: string
  tier: number
  name: string
  image: {
    url: string
  }
  region: {
    name: string
  } | null
  category: {
    id: number
    name: string
  } | null
  targetUri: string
}
export function normalizeBizReviewTarget(bizReviewTarget: BizReviewTarget): NormalizedBizReviewTarget | null {
  if (!(bizReviewTarget.__typename === 'BizReviewTarget')) {
    return null
  }

  const { bizStore, tier } = bizReviewTarget
  const { __typename } = bizStore

  if (__typename === 'BizAccount') {
    const { targetUri, categoryId, categoryName, externalId, image, name, regionBizAccount } = bizStore

    return {
      __typename,
      id: externalId,
      tier,
      name,
      image: {
        url: image?.url ?? '',
      },
      region: regionBizAccount ? { name: regionBizAccount.name3 } : null,
      category: categoryId && categoryName ? { id: categoryId, name: categoryName } : null,
      targetUri,
    }
  }

  if (__typename === 'Poi') {
    const { name, category, externalId, images, regionPoi, targetUri } = bizStore

    return {
      __typename,
      id: externalId,
      tier,
      name,
      image: {
        url: images?.[0]?.url ?? '',
      },
      region: regionPoi ? { name: regionPoi.name3 } : null,
      category: category?.externalId && category.name ? { id: category.externalId, name: category.name } : null,
      targetUri,
    }
  }

  return null
}
