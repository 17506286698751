import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { FC } from 'react'
import { graphql, useFragment } from 'react-relay'

import { BusinessPostComponentA_bizPost$key } from '@src/__relay__/BusinessPostComponentA_bizPost.graphql'
import CoveredLazyLoadImage from '@src/components/_lib/CoveredLazyLoadImage'
import { Flex, getEllipsisStyle } from '@src/components/_lib/styles'
import TagGroup from '@src/components/_lib/TagGroup'
import { getDateDiffFromNow } from '@src/lib/date/date'
import { getEllipsisString } from '@src/lib/unit'

import type { OnItemClick } from './types'

interface BusinessPostComponentAProps {
  bizPostRef: BusinessPostComponentA_bizPost$key
  onClick: OnItemClick
}

// TODO. local-explorer의 BizPostFeedItem1과 같음. 공용 라이브러리화 되면 대체해야함.
const BusinessPostComponentA: FC<BusinessPostComponentAProps> = ({ bizPostRef, onClick }) => {
  const bizPost = useFragment(
    graphql`
      fragment BusinessPostComponentA_bizPost on FeedBusinessPost {
        externalId
        title
        createdAt
        targetUri(referrer: "")
        images {
          url
        }
        businessAccount {
          name
          categoryName
          viewerAttribute {
            distance
            hasViewerFollowed
          }
          image {
            url
          }
        }
      }
    `,
    bizPostRef
  )

  const handleItemClick = () => {
    onClick({
      externalId: bizPost.externalId,
      targetUri: bizPost.targetUri,
    })
  }

  const imageUrl = (bizPost.images[0]?.url ?? bizPost.businessAccount.image?.url) as string

  if (!imageUrl) {
    return null
  }

  const isWalk10Min = (bizPost.businessAccount.viewerAttribute?.distance ?? 0) < 700

  const tags = [
    { text: `${getDateDiffFromNow(new Date(bizPost.createdAt as string))} 전` },
    {
      text: bizPost.businessAccount.viewerAttribute?.hasViewerFollowed ? '단골소식' : null,
    },
    {
      text: isWalk10Min ? '걸어서 10분' : null,
    },
  ]

  return (
    <BizPostComponentA onClick={handleItemClick}>
      <Left>
        <ImageContainer>
          <CoveredLazyLoadImage effect="opacity" src={imageUrl} alt={bizPost.title} visibleByDefault />
        </ImageContainer>
      </Left>
      <Right>
        <ContentTop>
          <Name>{getEllipsisString(bizPost.businessAccount.name, 10)}</Name>
          <CategoryName>{bizPost.businessAccount.categoryName}</CategoryName>
        </ContentTop>
        <ContentMid>
          <Title>{bizPost.title}</Title>
        </ContentMid>
        <TagGroup tags={tags} size="small" />
      </Right>
    </BizPostComponentA>
  )
}

export default BusinessPostComponentA

const BizPostComponentA = styled.div`
  ${Flex};
  padding: 1rem 0;
  margin: 0 1rem;
  color: ${vars.$scale.color.gray900};

  &:not(:last-of-type) {
    box-shadow: 0 1px 0 0 ${vars.$semantic.color.divider1};
  }
`

const Left = styled.div``

const Right = styled.div`
  margin-left: 1rem;
`

const ImageContainer = styled.div`
  width: 5.75rem;
  height: 5.75rem;
`

const ContentTop = styled.div`
  ${vars.$semantic.typography.caption1Regular};
  color: ${vars.$scale.color.gray600};
  margin-bottom: 0.25rem;
`

const ContentMid = styled.div`
  margin-bottom: 0.125rem;
`

const Title = styled.div`
  ${getEllipsisStyle(2)};
  ${vars.$semantic.typography.subtitle1Regular};
  visibility: visible;
`

const Name = styled.span`
  ${vars.$semantic.typography.caption1Bold};
  color: ${vars.$scale.color.gray900};
  margin-right: 0.25rem;
`

const CategoryName = styled.span``
