import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { FC } from 'react'

import IcExercise from './icons/ic_exercise.svg'
import IcRemodeling from './icons/ic_remodeling.svg'
import { PurposeThemeEnum } from '../../../../types/schemaEnums'
import { getThemeTitleFromSubthemes, PurposeSubTheme } from '../../../Purpose/constant'

type NearbyPurposeTheme = Exclude<PurposeThemeEnum, 'ETC'>
const THEME_INFO: Record<
  NearbyPurposeTheme,
  {
    Icon: string
    backColor: string
    description: string
  }
> = {
  EXERCISE: {
    Icon: IcExercise,
    backColor: vars.$scale.color.green50,
    description: '동네에서 꾸준하게',
  },
  REMODELING: {
    Icon: IcRemodeling,
    backColor: vars.$scale.color.yellow50,
    description: '어렵지 않아요',
  },
}

interface HomePurposeThemeItemProps {
  theme: NearbyPurposeTheme
  subThemes: ReadonlyArray<PurposeSubTheme>
  onClick?: (theme: NearbyPurposeTheme) => void
}

const HomePurposeThemeItem: FC<HomePurposeThemeItemProps> = ({ theme, subThemes, onClick }) => {
  const { Icon, backColor, description } = THEME_INFO[theme]
  const title = getThemeTitleFromSubthemes(subThemes)

  const handleClick = () => {
    onClick?.(theme)
  }

  return (
    <ThemeItem backColor={backColor} onClick={handleClick}>
      <TextWrapper>
        <Description>{description}</Description>
        <Title>{title}</Title>
      </TextWrapper>
      <IconWrapper>
        <Icon />
      </IconWrapper>
    </ThemeItem>
  )
}

export default HomePurposeThemeItem

const ThemeItem = styled.div<{ backColor: string }>`
  position: relative;
  flex: 1;
  margin-right: 8px;
  padding: 12px;
  /* width: 167.5px; */
  min-height: 100px;
  background-color: ${({ backColor }) => backColor};
  border-radius: 0.375rem;
  box-sizing: border-box;

  &:last-of-type {
    margin-right: 0;
  }
`

const IconWrapper = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
`

const TextWrapper = styled.div`
  margin-top: 42px;
`

const Description = styled.div`
  font-weight: 400;
  font-size: 11px;
  line-height: 15px;
`

const Title = styled.div`
  font-size: 14px;
  line-height: 19px;
  font-weight: 700;
`
