import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { chunk } from 'lodash'
import { type FC, Suspense, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { graphql, useFragment } from 'react-relay'

import Placeholder from '@src/components/_lib/Placeholder'
import { useTouchGesture } from '@src/hooks/useTouchGesture'

import HomeMapKarrotMap, { type CoordsWithOrder } from './HomeMapKarrotMap'
import HomeMapsTabListItem from './HomeMapsTabListItem'
import {
  type HomeMapsTabList_tab$key,
  type PoiTabCategoryEnum,
} from '../../../../__relay__/HomeMapsTabList_tab.graphql'
import { MAP_NEARBY_DISTANCE_LIMIT } from '../../../../constants'
import { useSwipeSection } from '../../../../hooks/sections/useSwipeSection'
import { useLogger } from '../../../../hooks/useLogger'
import { CarouselIndicator, ScrollSnapCarousel } from '../../../_lib/ScrollSnapCarousel'

export type ActiveTabCategory = {
  activeTabKey: PoiTabCategoryEnum
  activeTabLabel: string
  activeTabOrder: number
}

interface HomeMapsTabListProps {
  tab: HomeMapsTabList_tab$key
  sectionOrder: number
  activeTabCategory: ActiveTabCategory
  sectionLoggingMeta: {
    swipe: string
    click: string
  }
  isViewMap: boolean
  isMapError: boolean
  isSwipeable?: boolean
  onListSwipe: (isListEnd: boolean) => void
  onMapClick: () => void
  onMapError: () => void
  onReconciliationTab?: (e: TouchEvent) => void
}

const POI_LIST_TAKE_NUM = 8
const POI_LIST_CHUNK_SIZE = 2
const COORDS_CHUNK_SIZE = 4
const mapCoordsChangePoint = POI_LIST_TAKE_NUM / POI_LIST_CHUNK_SIZE / 2

const HomeMapsTabList: FC<HomeMapsTabListProps> = ({
  sectionOrder,
  activeTabCategory,
  sectionLoggingMeta,
  isViewMap,
  isMapError,
  onMapClick,
  onMapError,
  onReconciliationTab,
  onListSwipe,
  ...props
}) => {
  const tab = useFragment(
    graphql`
      fragment HomeMapsTabList_tab on PoiTab {
        ... on PoiTab {
          title
          type
          targetUri
          poiTags {
            poi {
              id
              coords {
                lat
                lng
              }
              name
              distance {
                distance
                name
              }
            }
            ...HomeMapsTabListItem_poiTag
          }
        }
      }
    `,
    props.tab
  )

  const { activeTabKey, activeTabLabel } = activeTabCategory
  const { nearbyLog, loggingMetaLog } = useLogger()

  const [curOffset, setCurOffset] = useState(0)
  const [coordsOffset, setCoordsOffset] = useState(0)
  const containerElementRef = useRef<HTMLDivElement>(null)
  const listElementRef = useRef<HTMLUListElement>(null)

  const [mapOptions, setMapOptions] = useState<{
    coordsWithOrder: CoordsWithOrder
    currentPoisitionView: boolean
  }>({
    coordsWithOrder: null,
    currentPoisitionView: false,
  })

  const handleItemClick = useCallback(() => {
    loggingMetaLog(sectionLoggingMeta.click)
  }, [loggingMetaLog, sectionLoggingMeta.click])

  // poi 좌표정보 가공
  const chunkedCoords = useMemo(
    () =>
      chunk(
        tab.poiTags.map((poiTag, index) => ({
          coords: poiTag.poi.coords,
          itemOrder: index,
          label: poiTag.poi.name,
          distance: poiTag.poi.distance?.distance,
        })),
        COORDS_CHUNK_SIZE
      ).filter((poiList) => poiList.length > 0),
    [tab.poiTags]
  )

  // poiList Element로 poiList 가공
  const chunkedPoiLists = useMemo(
    () =>
      chunk(tab.poiTags.slice(0, POI_LIST_TAKE_NUM), POI_LIST_CHUNK_SIZE)
        .filter((poiList) => poiList.length > 0)
        .map((poiList, offset) => (
          <PoiList key={offset}>
            {poiList.map((poiTag, index) => (
              <HomeMapsTabListItem
                key={poiTag.poi.id}
                poiTag={poiTag}
                itemOrder={offset * POI_LIST_CHUNK_SIZE + index}
                onItemClick={handleItemClick}
                activeTabCategory={activeTabCategory}
                sectionOrder={sectionOrder}
                isViewAlphabet={false}
              />
            ))}
          </PoiList>
        )),
    [tab.poiTags, sectionOrder, activeTabCategory, handleItemClick]
  )

  const maxOffset = chunkedPoiLists.length

  useEffect(() => {
    if (!chunkedCoords[coordsOffset]) {
      return
    }

    // 현재 지도에 보여지는 모든 좌표가 MAP_NEARBY_DISTANCE_LIMIT 이하이면 유저의 현재 위치를 보여준다.
    const viewCurrentPositionView = chunkedCoords[coordsOffset].every(
      (poi) => poi.distance && poi.distance < MAP_NEARBY_DISTANCE_LIMIT
    )

    setMapOptions({
      coordsWithOrder: chunkedCoords[coordsOffset],
      currentPoisitionView: viewCurrentPositionView,
    })
  }, [coordsOffset, chunkedCoords])

  const handleSnapCarouselOffsetChange = (offset: number) => {
    if (offset === curOffset) {
      return
    }

    setCurOffset(offset)

    const _coordsOffset = Math.floor(offset / mapCoordsChangePoint)

    if (coordsOffset !== _coordsOffset) {
      setCoordsOffset(_coordsOffset)
    }
  }

  const handlePoiListImpression = (offset: number) => {
    if (offset === 0) {
      return
    }

    nearbyLog({
      params: {
        name: 'swipe_contents_section',
        contentsType: 'map',
        page: offset,
        categoryType: activeTabKey,
        categoryName: activeTabLabel,
        sectionOrder: sectionOrder,
      },
    })

    loggingMetaLog(sectionLoggingMeta.swipe)
  }

  useSwipeSection({
    swipeContainerRef: listElementRef,
    sectionOrder,
  })

  useTouchGesture({
    targetElementRef: containerElementRef,
    // onGestureStart: (e) => {
    //   if (!isSwipable) {
    //     e.stopPropagation()
    //   }
    // },
    onGestureEnd: (e) => {
      if (onReconciliationTab) {
        setTimeout(() => {
          onReconciliationTab(e)
        }, 300)
      }
    },
    onSwipe: ({ moveType, direction }, e) => {
      if (curOffset === maxOffset - 1 && moveType === 'Horizontal' && direction === 'Left') {
        onListSwipe(true)
        return
      }

      if (curOffset === 0 && moveType === 'Horizontal' && direction === 'Right') {
        onListSwipe(true)
        return
      }

      e.stopPropagation()
      onListSwipe(false)
    },
  })

  const renderMap = useMemo(() => {
    return (
      <Suspense fallback={<Placeholder height="140px" width="auto" />}>
        {!isMapError && (
          <MapContainer>
            {isViewMap && (
              <HomeMapKarrotMap
                selectedCategory={activeTabKey}
                coordsWithOrder={mapOptions.coordsWithOrder}
                viewCurrentPosition={mapOptions.currentPoisitionView}
                onClick={onMapClick}
                onError={onMapError}
              />
            )}
          </MapContainer>
        )}
      </Suspense>
    )
  }, [
    activeTabKey,
    isMapError,
    isViewMap,
    mapOptions.coordsWithOrder,
    mapOptions.currentPoisitionView,
    onMapClick,
    onMapError,
  ])

  return (
    <ListContainer ref={containerElementRef}>
      {renderMap}
      <ScrollSnapCarousel
        elementRef={listElementRef}
        items={chunkedPoiLists}
        onOffsetChange={handleSnapCarouselOffsetChange}
        onImpressionItem={handlePoiListImpression}
      />
      <IndicatorContainer>
        <CarouselIndicator activeOffset={curOffset} maxOffset={maxOffset} />
      </IndicatorContainer>
    </ListContainer>
  )
}

export default HomeMapsTabList

const ListContainer = styled.div``

const PoiList = styled.div`
  padding: 0;
`

const IndicatorContainer = styled.div``

const MapContainer = styled.div`
  margin: 1rem 1rem 0;
  height: 140px;
  border-radius: 0.25rem;
  background-color: ${vars.$scale.color.gray100};
`
