import { invert as _invert, omitBy, take, size, dropRight } from 'lodash'

import { type ItemOfList } from '../T/utilTypes'

/**
 * Creates an object with all null values removed.
 * @param obj The object to compact
 */
export function compactMap<T extends object>(
  obj: T
): {
  [key in keyof T]: NonNullable<T[key]>
} {
  return omitBy(obj, (value) => value === null || value === undefined) as any
}

export type AllValues<T extends Record<PropertyKey, PropertyKey>> = {
  [P in keyof T]: { key: P; value: T[P] }
}[keyof T]

export type InvertResult<T extends Record<PropertyKey, PropertyKey>> = {
  [P in AllValues<T>['value']]: Extract<AllValues<T>, { value: P }>['key']
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function invert<T extends {}>(obj: T): InvertResult<T> {
  return _invert(obj) as InvertResult<T>
}

type GrouppedList<L extends Array<any> | ReadonlyArray<any>, GT extends string> = {
  groupType: GT
  list: ItemOfList<L>[]
  originStartIndex: number
}

/**
 * 리스트의 그루핑 및 일렬 파티셔닝된 요소 리스트 생성.
 * `defineGroupType`로 그루핑한다.
 *
 * @param {L} list 적용할 리스트
 * @param defineGroupType 순회하면서 실행되는 함수. 그룹화를 결정한다.
 * @returns 일렬로 그룹화된 배열
 * @example
 * var users = [
 * { 'user': 'doe', 'age': 50 },
 * { 'user': 'fred', 'age': 80 },
 * { 'user': 'locke', 'age': 29 },
 * { 'user': 'pebbles', 'age': 10 }
 * { 'user': 'john', 'age': 44 },
 * ];
 *
 * const groupPartitionUsers = sequenceGroupPartition<
 *   typeof users,
 *   'Child' | 'Parrent' | 'GrandParrent'
 * >(users, (user) => {
 *   if (user.age > 70) return 'GrandParrent';
 *   if (user.age > 40) return 'Parrent';
 *   return 'Child';
 * // =>
 * //[
 * //  {
 * //    groupType: 'Parrent',
 * //    list: [{ 'user': 'doe', 'age': 50 }],
 * //    originStartIndex: 0
 * //  },
 * //  {
 * //    groupType: 'GrandParrent',
 * //    list: [{ 'user': 'fred', 'age': 80 }],
 * //    originStartIndex: 1
 * //  },
 * //  {
 * //    groupType: 'Child',
 * //    list: [{ 'user': 'locke', 'age': 29 }, { 'user': 'pebbles', 'age': 10 }],
 * //    originStartIndex: 2
 * //  },
 * //  {
 * //    groupType: 'Parrent',
 * //    list: [{ 'user': 'john', 'age': 44 }],
 * //    originStartIndex: 4
 * //  },
 * //]
 */
export function sequenceGroupPartition<L extends Array<any> | ReadonlyArray<any>, GT extends string>(
  list: L,
  defineGroupType: (item: ItemOfList<L>) => GT
): GrouppedList<L, GT>[] {
  const result = list.reduce((acc, item, index) => {
    const curGroupType = defineGroupType(item)
    const { prevGroupList, groupPartitionList } = acc

    if (!prevGroupList && index === 0) {
      const groupList = {
        groupType: curGroupType,
        list: [item],
        originStartIndex: 0,
      }

      const groupPartitionList = [groupList]

      return {
        prevGroupList: groupList,
        groupPartitionList,
      }
    }

    const { groupType: prevGroupType, list: prevList, originStartIndex: prevOriginStartIndex } = prevGroupList

    if (prevGroupType === curGroupType) {
      prevList.push(item)

      return acc
    }

    const groupList = {
      groupType: curGroupType,
      list: [item],
      originStartIndex: prevOriginStartIndex + prevList.length,
    }

    acc.prevGroupList = groupList
    groupPartitionList.push(groupList)

    return acc
  }, {})

  return result.groupPartitionList
}

/**
 * string의 32bit integer hash값을 가져온다 (Java 방식)
 * @param str string
 * @returns 32bit integer hash
 */
export function getStringHashCode(str: string): string {
  let hash = 0
  if (str.length === 0) return '0'
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i)
    hash = (hash << 5) - hash + char
    hash = hash & hash // Convert to 32bit integer
  }

  return hash.toString()
}

/**
 * 페이지별 최소 아이템 개수를 구한다.
 */
export const minItemsPerPage = <T>(items: T[], takeNum = 12, min = 2, max = 3): T[] => {
  let takenItems = take(items, takeNum)
  const itemsLength = size(takenItems)
  if (itemsLength % max < min && itemsLength % max > 0) {
    takenItems = dropRight(takenItems, itemsLength % max)
  }
  if (itemsLength < max) {
    takenItems = take(takenItems, 0)
  }
  return takenItems
}

/**
 * 객체에서 null값 제거
 */
export const filterNil = <T extends Record<string, any>>(obj: T) => {
  const entries = Object.entries(obj).filter(([, v]) => v != null)

  return Object.fromEntries(entries) as { [key in keyof T]: NonNullable<T[key]> }
}
