/**
 * @generated SignedSource<<eaa2d3452aba86e8a278e3856ba9e728>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HomePopularServiceFieldBasicList_SectionPopularServiceField$data = {
  readonly serviceFields: ReadonlyArray<{
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"HomePopularServiceFieldBasicListItem_ExpertServiceField">;
  }>;
  readonly " $fragmentType": "HomePopularServiceFieldBasicList_SectionPopularServiceField";
};
export type HomePopularServiceFieldBasicList_SectionPopularServiceField$key = {
  readonly " $data"?: HomePopularServiceFieldBasicList_SectionPopularServiceField$data;
  readonly " $fragmentSpreads": FragmentRefs<"HomePopularServiceFieldBasicList_SectionPopularServiceField">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HomePopularServiceFieldBasicList_SectionPopularServiceField",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ExpertServiceField",
      "kind": "LinkedField",
      "name": "serviceFields",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "HomePopularServiceFieldBasicListItem_ExpertServiceField"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "SectionPopularServiceField",
  "abstractKey": null
};

(node as any).hash = "b52430ffc36a7bf1ad09a5b98bbd6beb";

export default node;
