import { usePullToRefreshScrollElement } from '@daangn/karrot-clothes/lib/pullToRefresh'
import styled from '@emotion/styled'
import { FC, useCallback } from 'react'
import { graphql, useFragment } from 'react-relay'

import HomePurposeThemeItem from './HomePurposeThemeItem'
import { HomePurpose_section$key, PurposeThemeEnum } from '../../../../__relay__/HomePurpose_section.graphql'
import { useLogger } from '../../../../hooks/useLogger'
import { useRouter } from '../../../../hooks/useRouter'
import { PropOf } from '../../../../lib/T/utilTypes'
import type { SectionInfo } from '../../../../types'
import { ScrollableX } from '../../../_lib/Scrollable'
import HomeSectionBase from '../Common/HomeSectionBase'

interface HomePurposeProps {
  sectionPurpose: HomePurpose_section$key
  sectionInfo: SectionInfo
}

const HomePurpose: FC<HomePurposeProps> = ({ sectionInfo, ...props }) => {
  const section = useFragment(
    graphql`
      fragment HomePurpose_section on SectionPurpose {
        __typename
        purposeThemeInfo {
          theme
          subTheme
        }
        loggingMeta {
          swipe
          click
          show
        }

        ...HomeSectionBase_sectionBase
      }
    `,
    props.sectionPurpose
  )

  const scrollContainerRef = usePullToRefreshScrollElement()
  const { loggingMetaLog, contentsSectionLog } = useLogger()
  const { push } = useRouter()

  const handleImpression = () => {
    contentsSectionLog('show', {
      contentsType: 'purpose',
      sectionOrder: sectionInfo.order,
    })

    if (sectionInfo.order === 0) {
      loggingMetaLog(section.loggingMeta.show)
    }
  }

  const handleThemeClick: NonNullable<PropOf<typeof HomePurposeThemeItem, 'onClick'>> = useCallback(
    (theme) => {
      const themeLowerCase = theme.toLowerCase() as Lowercase<PurposeThemeEnum>

      contentsSectionLog('click', {
        contentsType: 'purpose',
        buttonType: 'list',
        params: {
          theme: themeLowerCase,
        },
      })

      loggingMetaLog(section.loggingMeta.click)

      push('Purpose', 'purpose', { theme: themeLowerCase })
    },
    [contentsSectionLog, push, loggingMetaLog, section.loggingMeta.click]
  )

  return (
    <HomeSectionBase
      sectionBase={section}
      ImpressionProps={{
        scrollContainerRef,
        onImpression: handleImpression,
        threshold: 1,
      }}
      SectionTitleProps={{}}>
      <HomePurposeContainer>
        {section.purposeThemeInfo.map(({ theme, subTheme }) => {
          if (theme === 'ETC') {
            return null
          }

          return <HomePurposeThemeItem key={theme} theme={theme} subThemes={subTheme} onClick={handleThemeClick} />
        })}
      </HomePurposeContainer>
    </HomeSectionBase>
  )
}

export default HomePurpose

const HomePurposeContainer = styled(ScrollableX)`
  display: flex;
  padding: 8px 1rem 20px;
`
