/**
 * @generated SignedSource<<b1156956b01905fbdc123f18c0212183>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type HomeFeedShortcutOnboardingQuery$variables = {};
export type HomeFeedShortcutOnboardingQuery$data = {
  readonly viewer: {
    readonly isVerticalServiceOnboardingUser: boolean;
  } | null;
};
export type HomeFeedShortcutOnboardingQuery = {
  response: HomeFeedShortcutOnboardingQuery$data;
  variables: HomeFeedShortcutOnboardingQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isVerticalServiceOnboardingUser",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "HomeFeedShortcutOnboardingQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "KarrotUser",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "HomeFeedShortcutOnboardingQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "KarrotUser",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0c40de14e87aba73a862d04bd897da78",
    "id": null,
    "metadata": {},
    "name": "HomeFeedShortcutOnboardingQuery",
    "operationKind": "query",
    "text": "query HomeFeedShortcutOnboardingQuery {\n  viewer {\n    isVerticalServiceOnboardingUser\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "83128db2c0dd0b0326f83a3726428fea";

export default node;
