import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

type State = boolean
type OpenFunction = () => void
type CloseFunction = () => Promise<void>
type IgnoreHandler = (ignore: boolean) => void

type ReturnType = [State, OpenFunction, CloseFunction, IgnoreHandler]

export const useHistoryState = (ignore?: boolean): ReturnType => {
  const ignoreRef = useRef<boolean>(false)
  const closeResolver = useRef<(() => void) | null>(null)
  const [open, setOpen] = useState(false)

  const handleOpen = useCallback(() => setOpen(true), [])
  const handleClose = useCallback(() => setOpen(false), [])

  const handleIgnoreOnPop = useCallback((ignore: boolean) => {
    setTimeout(() => {
      ignoreRef.current = ignore
    })
  }, [])

  useEffect(() => {
    ignoreRef.current = Boolean(ignore)
  }, [ignore])

  useEffect(() => {
    if (open) {
      window.history.pushState({}, '')

      const onPop = () => {
        if (!ignoreRef.current) {
          handleClose()
        }
      }

      window.addEventListener('popstate', onPop)
      return () => window.removeEventListener('popstate', onPop)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  useEffect(() => {
    if (!open) {
      closeResolver.current?.()
    }
  }, [open])

  const handleCloseModal: CloseFunction = useCallback(() => {
    if (open) {
      window.history.back()
      return new Promise<void>((resolve) => {
        closeResolver.current = resolve
      })
    }
    return Promise.resolve()
  }, [open])

  return useMemo(
    () => [open, handleOpen, handleCloseModal, handleIgnoreOnPop],
    [handleCloseModal, handleOpen, open, handleIgnoreOnPop]
  )
}
