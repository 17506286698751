import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { type FC, useRef } from 'react'
import { graphql, useFragment } from 'react-relay'
import { __, match } from 'ts-pattern'

import {
  type NearbyJobPostItem_jobPost$data,
  type NearbyJobPostItem_jobPost$key,
} from '../../../../__relay__/NearbyJobPostItem_jobPost.graphql'
import { SALARY_TYPE_MAP } from '../../../../constants/jobs'
import { useLogger } from '../../../../hooks/useLogger'
import { distanceToUnion } from '../../../../lib/union'
import { getEllipsisString, maskPrice } from '../../../../lib/unit'
import CoveredLazyLoadImage from '../../../_lib/CoveredLazyLoadImage'
import { useImpression } from '../../../_lib/Impression'
import { ListItem } from '../../../_lib/ListSection/ListItem'
import { DotSeparator, Flex, getEllipsisStyle } from '../../../_lib/styles'
import { Badges } from '../../lib/Badge'

interface NearbyJobPostItemProps {
  jobPost: NearbyJobPostItem_jobPost$key
  onClick: (jobPost: NearbyJobPostItem_jobPost$data) => void
}

const NearbyJobPostItem: FC<NearbyJobPostItemProps> = (props) => {
  const jobPost = useFragment(
    graphql`
      fragment NearbyJobPostItem_jobPost on JobPost {
        externalId
        title
        companyName
        salaryType
        salary
        image {
          thumbnail
        }
        distance {
          distance
          name
        }
        targetUri
        badges
        ...Badges_badges
      }
    `,
    props.jobPost
  )

  const itemRef = useRef<HTMLDivElement>(null)
  const { nearbyLog } = useLogger()

  const handleJobPostClick = () => {
    props.onClick?.(jobPost)
  }

  useImpression(
    {
      ref: itemRef,
    },
    () => {
      nearbyLog({
        params: {
          name: 'show_contents_section_item',
          contentsType: 'jobs',
          ...(jobPost.badges.length > 0 ? { badges: jobPost.badges.join(',') } : null),
        },
      })
    },
    []
  )

  return (
    <Container ref={itemRef} onClick={handleJobPostClick}>
      <JobsInfo>
        <Left>
          <EmployerInfos>
            {jobPost.companyName && <EmployerInfoName>{getEllipsisString(jobPost.companyName, 10)}</EmployerInfoName>}
            {jobPost.distance && (
              <>
                <EmployerInfo>{getEllipsisString(jobPost.distance.name, 6)}</EmployerInfo>
                {match(distanceToUnion(jobPost.distance))
                  .with({ _TAG: 'm' }, ({ m }) => <EmployerInfo>{`${m}m`}</EmployerInfo>)
                  .with({ _TAG: 'km' }, ({ km }) => <EmployerInfo>{`${km}km`}</EmployerInfo>)
                  .with(__, () => null)
                  .exhaustive()}
              </>
            )}
          </EmployerInfos>
          <Title>{jobPost.title}</Title>
          <Salarys>
            <SalaryType>{SALARY_TYPE_MAP[jobPost.salaryType]}</SalaryType>
            <Salary>{`${maskPrice(jobPost.salary, {
              applyPrice: 1000000,
            })}`}</Salary>
          </Salarys>
        </Left>
        {jobPost.image?.thumbnail && (
          <Right>
            <S_CoveredLazyLoadImage src={jobPost.image.thumbnail} alt={jobPost.companyName ?? jobPost.title} />
          </Right>
        )}
      </JobsInfo>

      <Badges badgesRef={jobPost} />
    </Container>
  )
}

export default NearbyJobPostItem

const Container = styled(ListItem)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: ${vars.$scale.color.gray900};

  & > *:not(:last-child) {
    margin-bottom: 0.5rem;
  }
`

const JobsInfo = styled.div`
  display: flex;

  & > *:not(:last-child) {
    margin-right: 1rem;
  }
`

const Left = styled.div`
  flex: 1;
  width: calc(100% - 5.5rem);

  & > *:not(:last-child) {
    margin-bottom: 0.25rem;
  }
`

const Right = styled.div``

const S_CoveredLazyLoadImage = styled(CoveredLazyLoadImage)`
  width: 4.5rem;
  height: 4.5rem;
`

const EmployerInfos = styled.div`
  ${Flex};
  width: 100%;
  color: ${vars.$scale.color.gray600};
`

const EmployerInfo = styled.div`
  ${getEllipsisStyle(1)};
  ${vars.$semantic.typography.caption1Regular};
  ${DotSeparator};
`

const EmployerInfoName = styled(EmployerInfo)`
  ${vars.$semantic.typography.caption1Bold};
`

const Title = styled.h3`
  margin: 0;
  ${vars.$semantic.typography.subtitle1Regular};
  ${getEllipsisStyle(1)};
`

const Salarys = styled.div`
  display: flex;
  width: 100%;
  ${vars.$semantic.typography.subtitle1Bold};

  & > *:not(:last-child) {
    margin-right: 0.25rem;
  }
`

const SalaryType = styled.div`
  flex-shrink: 0;
`

const Salary = styled.div`
  ${getEllipsisStyle(1)};
`
