import { TextButton } from '@daangn/sprout-components-button'
import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { IconMarketRegular, IconLocationRegular } from '@seed-design/icon'
import { useActivity } from '@stackflow/react'
import React, { useCallback, useMemo } from 'react'

import { BIZ_PLATFORM_APP } from '@src/constants'
import { useOpenTarget } from '@src/lib/target'
import {
  useLocalBusinessLogEvent,
  useRegisterScreenCommonPayloadEffect,
} from '@src/stackflow/customPlugins/localBusinessAnalytics'

const HomeCreatePoiOrBizProfile = () => {
  const { openTarget } = useOpenTarget()
  const activity = useActivity()

  const queryParams = useMemo(() => {
    const urlSearchParams = new URLSearchParams()

    urlSearchParams.set('referrer', 'nearby')

    const params = urlSearchParams.toString()

    return params ? `?${params}` : ''
  }, [])

  const logEvent = useLocalBusinessLogEvent()
  useRegisterScreenCommonPayloadEffect(
    {
      where: `nearby_${activity.name}`,
    },
    []
  )

  const handleOnClickRegisterBiz = useCallback(() => {
    logEvent({
      name: 'click_poi_suggestion_create_button',
      params: {} as never,
    })

    openTarget({
      remote: `${BIZ_PLATFORM_APP}/onboarding${queryParams}`,
      present: 'top',
    })
  }, [logEvent, openTarget, queryParams])
  const handleOnClickSuggestionNewPlace = useCallback(() => {
    logEvent({
      name: 'click_biz_profile_onboarding_button',
      params: {} as never,
    })

    openTarget({
      remote: `${BIZ_PLATFORM_APP}/poi/suggestion/create${queryParams}`,
      present: 'top',
    })
  }, [logEvent, openTarget, queryParams])

  return (
    <S_Base>
      <S_ButtonWrapper>
        <S_IconWrapper>
          <IconMarketRegular width="100%" height="100%" color={vars.$scale.color.gray900} />
        </S_IconWrapper>
        <TextButton variant="secondary" size="small" onClick={handleOnClickRegisterBiz}>
          내 업체 등록
        </TextButton>
      </S_ButtonWrapper>

      <S_Divider />

      <S_ButtonWrapper>
        <S_IconWrapper>
          <IconLocationRegular width="100%" height="100%" color={vars.$scale.color.gray900} />
        </S_IconWrapper>

        <TextButton variant="secondary" size="small" onClick={handleOnClickSuggestionNewPlace}>
          동네 업체 제안
        </TextButton>
      </S_ButtonWrapper>
    </S_Base>
  )
}

export default HomeCreatePoiOrBizProfile

const S_Base = styled.div`
  margin-top: 0.5rem;
  height: 4.1875rem;

  display: flex;
  align-items: center;

  background-color: ${vars.$semantic.color.paperDefault};
`

const S_ButtonWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`

const S_Divider = styled.div`
  width: 1px;
  height: 0.75rem;

  background-color: ${vars.$scale.color.gray300};
`
const S_IconWrapper = styled.div`
  display: flex;
  width: 0.875rem;
  height: 0.875rem;

  margin-right: 0.25rem;
`
