import { usePullToRefreshScrollElement } from '@daangn/karrot-clothes/lib/pullToRefresh'
import styled from '@emotion/styled'
import React, { useCallback, useRef } from 'react'
import { graphql, useFragment } from 'react-relay'

import { type AutoRequestServiceFieldCuration_SectionAutoRequestServiceFieldCuration$key } from '@src/__relay__/AutoRequestServiceFieldCuration_SectionAutoRequestServiceFieldCuration.graphql'
import ScrollableX from '@src/components/_lib/Scrollable'
import { type SectionInfo } from '@src/types'

import ServiceFieldItem from './ServiceFieldItem'
import { useLogger } from '../../../../hooks/useLogger'
import { type PropOf } from '../../../../lib/T/utilTypes'
import { useOpenTarget } from '../../../../lib/target'
import HomeSectionBase from '../Common/HomeSectionBase'

interface AutoRequestServiceFieldCurationProps {
  sectionAutoRequestServiceFieldCuration: AutoRequestServiceFieldCuration_SectionAutoRequestServiceFieldCuration$key
  sectionInfo: SectionInfo
}

const AutoRequestServiceFieldCuration = ({ sectionInfo, ...props }: AutoRequestServiceFieldCurationProps) => {
  const sectionAutoRequestServiceFieldCuration = useFragment(
    graphql`
      fragment AutoRequestServiceFieldCuration_SectionAutoRequestServiceFieldCuration on SectionAutoRequestServiceFieldCuration {
        __typename
        baseInfo {
          title
        }
        loggingMeta {
          swipe
          click
          show
        }

        serviceFields {
          id
          ...ServiceFieldItem_ExpertServiceField
        }

        ...HomeSectionBase_sectionBase
      }
    `,
    props.sectionAutoRequestServiceFieldCuration
  )
  const scrollContainerRef = usePullToRefreshScrollElement()
  const { contentsSectionLog, nearbyLog, loggingMetaLog } = useLogger()
  const { openTarget } = useOpenTarget()

  const handleImpression = () => {
    nearbyLog({
      params: {
        name: 'show_contents_section',
        contents_type: 'expert_auto_request',
        sectionOrder: sectionInfo.order,
      },
    })

    if (sectionInfo.order === 1) {
      loggingMetaLog(sectionAutoRequestServiceFieldCuration.loggingMeta.show)
    }
  }

  const handleMoreClick: NonNullable<PropOf<typeof HomeSectionBase, 'onMoreClick'>> = useCallback(
    ({ targetUri }) => {
      contentsSectionLog('click', {
        contentsType: 'expert_auto_request',
        buttonType: 'more',
        params: {
          sectionOrder: sectionInfo.order,
        },
      })

      loggingMetaLog(sectionAutoRequestServiceFieldCuration.loggingMeta.click)

      if (targetUri) {
        openTarget({
          targetUri,
        })
      }
    },
    [
      contentsSectionLog,
      loggingMetaLog,
      openTarget,
      sectionInfo.order,
      sectionAutoRequestServiceFieldCuration.loggingMeta.click,
    ]
  )

  const handleServiceFieldItemClick = useCallback(
    (targetUri: string, serviceFieldId: string) => {
      contentsSectionLog('click', {
        contentsType: 'expert_auto_request',
        buttonType: 'list',
        params: {
          sectionOrder: sectionInfo.order,
          serviceFieldId,
        },
      })

      loggingMetaLog(sectionAutoRequestServiceFieldCuration.loggingMeta.click)

      openTarget({
        targetUri,
      })
    },
    [
      contentsSectionLog,
      sectionInfo.order,
      loggingMetaLog,
      sectionAutoRequestServiceFieldCuration.loggingMeta.click,
      openTarget,
    ]
  )

  const itemScrollContainerRef = useRef<HTMLDivElement>(null)

  const handleSwipeItem = useCallback(
    (itemOrder: number) => {
      if (itemOrder === 0) {
        return
      }

      nearbyLog({
        params: {
          name: 'swipe_contents_section',
          contentsType: 'expert_auto_request',
          sectionOrder: sectionInfo.order,
          itemOrder,
        },
      })

      loggingMetaLog(sectionAutoRequestServiceFieldCuration.loggingMeta.swipe)
    },
    [nearbyLog, sectionInfo.order, loggingMetaLog, sectionAutoRequestServiceFieldCuration.loggingMeta.swipe]
  )

  return (
    <HomeSectionBase
      sectionBase={sectionAutoRequestServiceFieldCuration}
      ImpressionProps={{
        scrollContainerRef,
        onImpression: handleImpression,
        threshold: 0.9,
      }}
      ServiceTitleProps={{ serviceType: 'EXPERT_AUTO_REQUEST' }}
      onMoreClick={handleMoreClick}>
      <Scrollable ref={itemScrollContainerRef}>
        {sectionAutoRequestServiceFieldCuration.serviceFields.map((serviceField, index) => (
          <ServiceFieldItem
            key={serviceField.id}
            serviceField={serviceField}
            itemOrder={index}
            onClick={handleServiceFieldItemClick}
            itemScrollContainerRef={itemScrollContainerRef}
            onImpression={handleSwipeItem}
          />
        ))}
      </Scrollable>
    </HomeSectionBase>
  )
}

const Scrollable = styled(ScrollableX)`
  display: block;
  padding: 0.375rem 1rem 1rem;

  & > *:not(:last-child) {
    margin-right: 0.5rem;
  }
`

export default AutoRequestServiceFieldCuration
