import { useCallback, useEffect } from 'react'

export function usePageVisibleChange(onVisibilityChange?: (isVisible: boolean) => void) {
  const handleVisibilityChange = useCallback(() => {
    onVisibilityChange?.(document.visibilityState === 'visible')
  }, [onVisibilityChange])

  useEffect(() => {
    if (!onVisibilityChange) {
      return
    }

    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [handleVisibilityChange, onVisibilityChange])
}
