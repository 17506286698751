import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { IconReviewStarFill } from '@seed-design/icon'

import { RatingText, type RatingTextProps } from './RatingText'

const TOTAL_STAR_COUNT = 5

type StarRatingDisplayBase = {
  rating: number
}
export type StarRatingDisplayProps = { type: 'with-text' } & Pick<RatingTextProps, 'size'> & StarRatingDisplayBase
export const StarRatingDisplay = (props: StarRatingDisplayProps) => {
  const count = TOTAL_STAR_COUNT
  const percent = (props.rating / TOTAL_STAR_COUNT) * 100
  return (
    <>
      {(() => {
        switch (props.type) {
          case 'with-text': {
            return (
              <RatingText
                count={count}
                percent={percent}
                size={props.size}
                icon={{
                  render: () => <S_IconReviewStarFill size={props.size} color={vars.$semantic.color.primary} />,
                }}
              />
            )
          }
          default:
            return null
        }
      })()}
    </>
  )
}

const S_IconReviewStarFill = styled(IconReviewStarFill)(({ size }: { size?: string }) => [
  size === 'large' ? { width: '1.375rem', height: '1.375rem' } : { width: '0.9375rem', height: '0.9375rem' },
])
