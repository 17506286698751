/**
 * @generated SignedSource<<29c31b07c18149a4566c5d7333aeb095>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HomeReviews_sectionBestBizAccount$data = {
  readonly bestBizAccountReviews: ReadonlyArray<{
    readonly bizAccount: {
      readonly images: ReadonlyArray<{
        readonly url: string;
      }>;
    };
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"HomeReview_bizAccountReview">;
  }>;
  readonly loggingMeta: {
    readonly click: string;
    readonly show: string;
    readonly swipe: string;
  };
  readonly " $fragmentSpreads": FragmentRefs<"HomeSectionBase_sectionBase">;
  readonly " $fragmentType": "HomeReviews_sectionBestBizAccount";
};
export type HomeReviews_sectionBestBizAccount$key = {
  readonly " $data"?: HomeReviews_sectionBestBizAccount$data;
  readonly " $fragmentSpreads": FragmentRefs<"HomeReviews_sectionBestBizAccount">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HomeReviews_sectionBestBizAccount",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "BizAccountReview",
      "kind": "LinkedField",
      "name": "bestBizAccountReviews",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "BizAccount",
          "kind": "LinkedField",
          "name": "bizAccount",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Image",
              "kind": "LinkedField",
              "name": "images",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "url",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "HomeReview_bizAccountReview"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EventMeta",
      "kind": "LinkedField",
      "name": "loggingMeta",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "swipe",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "click",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "show",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HomeSectionBase_sectionBase"
    }
  ],
  "type": "SectionBestBizAccount",
  "abstractKey": null
};

(node as any).hash = "08d254cd7ac408b1ed469922e0d2b673";

export default node;
