import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { noneActiveEffect } from '../styles'

export const ListItemCSSStyle = css`
  padding: 1rem 0;
  ${noneActiveEffect};
`

export const ListItem = styled.article`
  ${ListItemCSSStyle}
`
