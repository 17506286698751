import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import React from 'react'
import { graphql, useFragment } from 'react-relay'

import { WriteLaunchIcon_icon$key } from '../../../../__relay__/WriteLaunchIcon_icon.graphql'

const WriteLaunchIcon: React.FC<{
  launch: WriteLaunchIcon_icon$key
}> = (props) => {
  const launch = useFragment(
    graphql`
      fragment WriteLaunchIcon_icon on WriteLaunchpadIcon {
        iconUrl
        nearbyIconUrl
      }
    `,
    props.launch
  )

  if (launch.iconUrl) {
    return <UrlIcon url={launch.iconUrl} />
  }
  if (launch.nearbyIconUrl) {
    return <UrlIcon url={launch.nearbyIconUrl} />
  }

  return <EmptyIcon />
}

const UrlIcon = styled.div<{ url: string }>`
  width: 1.5rem;
  height: 1.5rem;
  background-image: url('${(props) => props.url}');
  background-size: cover;
  background-position: 50% 50%;
`

const EmptyIcon = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 1rem;
  background-color: ${vars.$scale.color.gray100};
`

export default WriteLaunchIcon
