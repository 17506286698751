/**
 * @generated SignedSource<<60cfa52ebe819c38049239aee2c54a09>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type BizPostClosingTimeSaleStatusEnum = "CLOSED" | "ON_SALE";
export type NearbyBadgeEnum = "COUPON_EXISTS" | "DELIVERY" | "FAST_RESPONSE" | "FREE_DELIVERY" | "HAS_TRIAL" | "MOST_REVIEW" | "MOST_VIEW" | "SAME_DAY_DELIVERY" | "WALK_10_MIN";
import { FragmentRefs } from "relay-runtime";
export type HomeBizThemePost_bizPost$data = {
  readonly bizAccount: {
    readonly badges: ReadonlyArray<NearbyBadgeEnum>;
    readonly categoryName: string | null;
    readonly name: string;
  } | null;
  readonly bookmarkCount: number;
  readonly commentCount: number;
  readonly content: string;
  readonly couponBox: {
    readonly id: string;
  } | null;
  readonly createdAt: string;
  readonly distance: {
    readonly distance: number;
    readonly name: string;
  } | null;
  readonly extensions: {
    readonly closingTimeSale: {
      readonly closeTime: string;
      readonly status: BizPostClosingTimeSaleStatusEnum | null;
    } | null;
  };
  readonly externalId: string;
  readonly image: {
    readonly thumbnail: string;
  };
  readonly meta: {
    readonly entry: string | null;
    readonly type: string | null;
  };
  readonly region: {
    readonly externalId: string;
    readonly name3: string;
  };
  readonly targetUri: string;
  readonly title: string;
  readonly " $fragmentSpreads": FragmentRefs<"useBizLoggingMeta_bizLoggingMeta">;
  readonly " $fragmentType": "HomeBizThemePost_bizPost";
};
export type HomeBizThemePost_bizPost$key = {
  readonly " $data"?: HomeBizThemePost_bizPost$data;
  readonly " $fragmentSpreads": FragmentRefs<"HomeBizThemePost_bizPost">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "externalId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HomeBizThemePost_bizPost",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "content",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DistanceInfo",
      "kind": "LinkedField",
      "name": "distance",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "distance",
          "storageKey": null
        },
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Image",
      "kind": "LinkedField",
      "name": "image",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "thumbnail",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Region",
      "kind": "LinkedField",
      "name": "region",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name3",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BizAccount",
      "kind": "LinkedField",
      "name": "bizAccount",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "categoryName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "badges",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CouponBox",
      "kind": "LinkedField",
      "name": "couponBox",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "targetUri",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BizPostExtension",
      "kind": "LinkedField",
      "name": "extensions",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "BizPostClosingTimeSaleExtension",
          "kind": "LinkedField",
          "name": "closingTimeSale",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "status",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "closeTime",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bookmarkCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "commentCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BizPostMetadata",
      "kind": "LinkedField",
      "name": "meta",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "entry",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "useBizLoggingMeta_bizLoggingMeta",
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "BizDeliveryLoggingMeta",
              "kind": "LinkedField",
              "name": "bizDeliveryLoggingMeta",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "impression",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "click",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "BizLoggingMeta",
          "abstractKey": "__isBizLoggingMeta"
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "BizPost",
  "abstractKey": null
};
})();

(node as any).hash = "3c8451af316141c46c417e57278ddaf5";

export default node;
