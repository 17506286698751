import { type Position } from '@src/currentPositionResource'
import { getDistanceBetweenCoordinates } from '@src/lib/calc/calculate'

export const initialMapCoordinates = (
  userPosition: Position | null,
  userRegionCenterCoords: Position | null | undefined,
  fallbackCoordinates: Position = { lat: 37.566168, lng: 126.978052 }
) => {
  const currentCoords = userPosition?.lat

  if (!userRegionCenterCoords) {
    return fallbackCoordinates
  }

  if (!currentCoords) {
    return userRegionCenterCoords
  }

  const distanceBetweenCurrentAndRegionCenter = getDistanceBetweenCoordinates(
    userPosition.lat,
    userPosition.lng,
    userRegionCenterCoords.lat,
    userRegionCenterCoords.lng
  )
  if (distanceBetweenCurrentAndRegionCenter.meterDistance >= 3000) {
    return userRegionCenterCoords
  } else {
    return userPosition
  }
}
