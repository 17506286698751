/**
 * @generated SignedSource<<d593bc4a79eeaf2e6f72ed60cd07ace8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HomeNearbyExerciseBizAccountRefetchQuery$variables = {
  id: string;
};
export type HomeNearbyExerciseBizAccountRefetchQuery$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"HomeNearbyExerciseBizAccount_sectionNearbyExerciseBizAccount">;
  } | null;
};
export type HomeNearbyExerciseBizAccountRefetchQuery = {
  response: HomeNearbyExerciseBizAccountRefetchQuery$data;
  variables: HomeNearbyExerciseBizAccountRefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isNew",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "targetUri",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "HomeNearbyExerciseBizAccountRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "HomeNearbyExerciseBizAccount_sectionNearbyExerciseBizAccount"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "HomeNearbyExerciseBizAccountRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "EventMeta",
                "kind": "LinkedField",
                "name": "loggingMeta",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "swipe",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "click",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "show",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "BizAccount",
                "kind": "LinkedField",
                "name": "bizAccounts",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "categoryName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "externalId",
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "description",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "badges",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasCoupon",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "onSale",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "reservable",
                    "storageKey": null
                  },
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "starRating",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DistanceInfo",
                    "kind": "LinkedField",
                    "name": "distance",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "distance",
                        "storageKey": null
                      },
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "followerCount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "reviewCount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Image",
                    "kind": "LinkedField",
                    "name": "image",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "thumbnail",
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Image",
                    "kind": "LinkedField",
                    "name": "images",
                    "plural": true,
                    "selections": [
                      (v5/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "viewerIsWatched",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "BaseInfo",
                "kind": "LinkedField",
                "name": "baseInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ViewMoreInfo",
                    "kind": "LinkedField",
                    "name": "viewMoreInfo",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "label",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BaseInfo",
                    "kind": "LinkedField",
                    "name": "baseInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "title",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "subTitle",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ViewMoreInfo",
                        "kind": "LinkedField",
                        "name": "viewMoreInfo",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "type",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "type": "SectionBaseInfo",
                "abstractKey": "__isSectionBaseInfo"
              }
            ],
            "type": "SectionNearbyExerciseBizAccount",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8adf657f5e0f1bd5e0063d2d7ed56895",
    "id": null,
    "metadata": {},
    "name": "HomeNearbyExerciseBizAccountRefetchQuery",
    "operationKind": "query",
    "text": "query HomeNearbyExerciseBizAccountRefetchQuery(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...HomeNearbyExerciseBizAccount_sectionNearbyExerciseBizAccount\n    id\n  }\n}\n\nfragment BizAccountListItem_BizAccount on BizAccount {\n  id\n  externalId\n  name\n  description\n  categoryName\n  badges\n  hasCoupon\n  onSale\n  reservable\n  isNew\n  starRating\n  distance {\n    distance\n    name\n  }\n  followerCount\n  reviewCount\n  image {\n    url\n    thumbnail\n    id\n  }\n  images {\n    url\n    id\n  }\n  targetUri\n  viewerIsWatched\n}\n\nfragment HomeNearbyExerciseBizAccount_sectionNearbyExerciseBizAccount on SectionNearbyExerciseBizAccount {\n  __typename\n  loggingMeta {\n    swipe\n    click\n    show\n  }\n  bizAccounts {\n    id\n    categoryName\n    ...BizAccountListItem_BizAccount\n  }\n  baseInfo {\n    viewMoreInfo {\n      targetUri\n      label\n    }\n  }\n  ...HomeSectionBase_sectionBase\n  id\n}\n\nfragment HomeSectionBase_sectionBase on SectionBaseInfo {\n  __isSectionBaseInfo: __typename\n  ...useSectionBase_sectionBase\n}\n\nfragment useSectionBase_sectionBase on SectionBaseInfo {\n  __isSectionBaseInfo: __typename\n  baseInfo {\n    title\n    subTitle\n    viewMoreInfo {\n      type\n      label\n      targetUri\n    }\n    isNew\n  }\n}\n"
  }
};
})();

(node as any).hash = "b357dddea2882e70c2313ea6299bf128";

export default node;
