import { usePullToRefreshScrollElement } from '@daangn/karrot-clothes/lib/pullToRefresh'
import styled from '@emotion/styled'
import React, { useCallback, useRef } from 'react'
import { graphql, useFragment } from 'react-relay'

import { type ExpertRecommend_SectionExpertRecommend$key } from '@src/__relay__/ExpertRecommend_SectionExpertRecommend.graphql'
import ScrollableX from '@src/components/_lib/Scrollable'
import { useLogger } from '@src/hooks/useLogger'
import { type PropOf } from '@src/lib/T/utilTypes'
import { useOpenTarget } from '@src/lib/target'
import { type SectionInfo } from '@src/types'

import ExpertRecommendItem from './ExpertRecommendItem'
import type CarouselContent from '../Common/CarouselContent'
import HomeSectionBase from '../Common/HomeSectionBase'

interface ExpertRecommendProps {
  expertRecommendRef: ExpertRecommend_SectionExpertRecommend$key
  sectionInfo: SectionInfo
}

const MIN_VIEW_ITEM_COUNT = 5
const VIEW_ITEM_COUNT = 8

const ExpertRecommend = ({ expertRecommendRef, sectionInfo }: ExpertRecommendProps) => {
  const expertRecommend = useFragment(
    graphql`
      fragment ExpertRecommend_SectionExpertRecommend on SectionExpertRecommend {
        __typename
        baseInfo {
          title
        }
        loggingMeta {
          swipe
          click
          show
        }

        bizAccounts {
          id
          ...ExpertRecommendItem_BizAccount
        }

        id

        ...HomeSectionBase_sectionBase
      }
    `,
    expertRecommendRef
  )

  const scrollContainerRef = usePullToRefreshScrollElement()
  const { contentsSectionLog, nearbyLog, loggingMetaLog } = useLogger()
  const { openTarget } = useOpenTarget()
  const handleImpression = () => {
    nearbyLog({
      params: {
        name: 'show_contents_section',
        contents_type: 'expert_recommend',
        sectionOrder: sectionInfo.order,
      },
    })

    if (sectionInfo.order === 1) {
      loggingMetaLog(expertRecommend.loggingMeta.show)
    }
  }
  const handleMoreClick: NonNullable<PropOf<typeof HomeSectionBase, 'onMoreClick'>> = useCallback(
    ({ targetUri }) => {
      contentsSectionLog('click', {
        contentsType: 'expert_recommend',
        buttonType: 'more',
        params: {
          sectionOrder: sectionInfo.order,
        },
      })

      loggingMetaLog(expertRecommend.loggingMeta.click)

      if (targetUri) {
        openTarget({
          targetUri,
        })
      }
    },
    [contentsSectionLog, sectionInfo.order, loggingMetaLog, expertRecommend.loggingMeta.click, openTarget]
  )

  const handleSwipeItem: PropOf<typeof CarouselContent, 'onListSwipe'> = useCallback(
    (offset) => {
      contentsSectionLog('swipe', {
        contentsType: 'expert_recommend',
        page: offset + 1,
      })

      loggingMetaLog(expertRecommend.loggingMeta.swipe)
    },
    [contentsSectionLog, expertRecommend.loggingMeta.swipe, loggingMetaLog]
  )

  const handleClickBizProfile = useCallback(
    (baId: string) => {
      contentsSectionLog('click', {
        contentsType: 'expert_recommend',
        buttonType: 'list',
        params: {
          sectionOrder: sectionInfo.order,
          baId,
        },
      })

      loggingMetaLog(expertRecommend.loggingMeta.click)
    },
    [contentsSectionLog, sectionInfo.order, loggingMetaLog, expertRecommend.loggingMeta.click]
  )

  const itemScrollContainerRef = useRef<HTMLDivElement>(null)

  if (expertRecommend.bizAccounts.length < MIN_VIEW_ITEM_COUNT) {
    return null
  }

  return (
    <HomeSectionBase
      sectionBase={expertRecommend}
      ImpressionProps={{
        scrollContainerRef,
        onImpression: handleImpression,
        threshold: 0.9,
      }}
      ServiceTitleProps={{ serviceType: 'EXPERT_RECOMMEND' }}
      onMoreClick={handleMoreClick}>
      <Scrollable ref={itemScrollContainerRef}>
        {expertRecommend.bizAccounts.slice(0, VIEW_ITEM_COUNT).map((bizAccount, index) => {
          return (
            <ExpertRecommendItem
              key={bizAccount.id}
              bizAccountRef={bizAccount}
              onClick={handleClickBizProfile}
              onImpression={handleSwipeItem}
              itemOrder={index}
              itemScrollContainerRef={itemScrollContainerRef}
            />
          )
        })}
      </Scrollable>
    </HomeSectionBase>
  )
}

const Scrollable = styled(ScrollableX)`
  display: flex;
  padding: 0.375rem 0 1rem;

  &::before {
    content: '';
    min-width: 1rem;
    height: 0.0625rem;
  }

  &::after {
    content: '';
    min-width: 1rem;
    height: 0.0625rem;
  }
`

export default ExpertRecommend
