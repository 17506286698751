import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import React, { useRef, type FC } from 'react'
import { graphql, useFragment } from 'react-relay'
import { getExpertQuestScheme } from 'src/lib/scheme-util'

import { type ServiceFieldItem_ExpertServiceField$key } from '@src/__relay__/ServiceFieldItem_ExpertServiceField.graphql'
import { useImpression } from '@src/components/_lib/Impression'
import { STORE_DEFAULT_IMAGE_URL } from '@src/constants'
import { numberWithComma } from '@src/lib/unit'
import { getEllipsisStyle } from '@src/components/_lib/styles'

interface ServiceFieldItemProps {
  serviceField: ServiceFieldItem_ExpertServiceField$key
  onClick: (targetUri: string, serviceFieldId: string) => void
  itemOrder: number
  itemScrollContainerRef: React.RefObject<HTMLDivElement>
  onImpression: (itemOrder: number) => void
}

const ServiceFieldItem: FC<ServiceFieldItemProps> = ({ onClick, ...props }) => {
  const serviceFieldItem = useFragment(
    graphql`
      fragment ServiceFieldItem_ExpertServiceField on ExpertServiceField {
        externalId
        id
        imageUrl
        name
        totalUsedCount
      }
    `,
    props.serviceField
  )

  const handlebizPostClick = () => {
    const expertQuestScheme = getExpertQuestScheme(serviceFieldItem.externalId)
    onClick(expertQuestScheme(`nearby.home`), serviceFieldItem.externalId)
  }
  const itemRef = useRef<HTMLDivElement>(null)

  // swipe log
  useImpression(
    {
      ref: itemRef,
      scrollContainerRef: props.itemScrollContainerRef,
      disableImpressionOnload: true,
      threshold: 0.7,
    },
    () => {
      props.onImpression(props.itemOrder)
    },
    [props.itemOrder, props.onImpression]
  )

  return (
    <Item ref={itemRef}>
      <ServiceFieldButton onClick={handlebizPostClick}>
        <ServiceFieldImageWrapper>
          <ServiceFieldImage
            loading="lazy"
            src={serviceFieldItem.imageUrl || STORE_DEFAULT_IMAGE_URL}
            alt="전문 영역 사진"
          />
        </ServiceFieldImageWrapper>
        <ServiceFieldName>{serviceFieldItem.name}</ServiceFieldName>
        <ServiceFieldUsedCount>{numberWithComma(serviceFieldItem.totalUsedCount)}명 이용</ServiceFieldUsedCount>
      </ServiceFieldButton>
    </Item>
  )
}

const Item = styled.div`
  display: 'flex';
  flex: 1;
  max-width: 31.5%;
`

const ServiceFieldButton = styled.button`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
  text-align: left;
  width: 100%;
`
const ServiceFieldImageWrapper = styled.div`
  width: 100%;
  height: 100%;
`
const ServiceFieldImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 0.375rem;
`
const ServiceFieldName = styled.p`
  margin: 0;
  display: 'block';
  margin-top: 0.5rem;
  white-space: 'nowrap';
  ${getEllipsisStyle(1)};
`

const ServiceFieldUsedCount = styled.p`
  margin: 0;
  margin-top: 0.125rem;
  font-size: 0.875rem;
  line-height: 150%;
  color: ${vars.$scale.color.gray600};
`

export default ServiceFieldItem
