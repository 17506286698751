import styled from '@emotion/styled'
import React, { useCallback, useEffect, useState } from 'react'
import { graphql, useFragment } from 'react-relay'
import { useRecoilValue } from 'recoil'

import { type HomePurposeLaunchpad_SectionPurposeLaunchpad$key } from '@src/__relay__/HomePurposeLaunchpad_SectionPurposeLaunchpad.graphql'
import Accordion from '@src/components/_lib/Accordion'
import { spotlightTargetInfoAtom } from '@src/features/spotlight/state/spotlightTargetInfo'
import HomeFeedShortcutOnboarding from '@src/features/vertical/components/HomeFeedShortcutOnboarding'
import { useLogger } from '@src/hooks/useLogger'
import { type PropOf } from '@src/lib/T/utilTypes'
import { useOpenTarget } from '@src/lib/target'

import HomePurposeLaunchCategory from './HomePurposeLaunchCategory'
import PurposeCategoryFoldButton from './PurposeCategoryFoldButton'
import PurposeCategoryMoreView from './PurposeCategoryMoreView'
import SectionContainer from '../../lib/SectionContainer'
import EventBanner from '../Temporary/EventBanner'

interface HomePurposeLaunchpadProps {
  sectionLaunchpad: HomePurposeLaunchpad_SectionPurposeLaunchpad$key
}

const VIEW_LAUNCHPAD_ROWS = 3
const VIEW_LAUNCHPAD_COLUMNS = 4
const VIEW_LAUCNHPAD_ITEM_COUNT = VIEW_LAUNCHPAD_ROWS * VIEW_LAUNCHPAD_COLUMNS - 1

const HomePurposeLaunchpad: React.FC<HomePurposeLaunchpadProps> = ({ ...props }) => {
  const { openTarget } = useOpenTarget()
  const { nearbyLog } = useLogger()

  const spotlightTargetInfo = useRecoilValue(spotlightTargetInfoAtom)

  const [expanded, setExpanded] = useState(false)

  const { purposeCategories } = useFragment(
    graphql`
      fragment HomePurposeLaunchpad_SectionPurposeLaunchpad on SectionPurposeLaunchpad {
        purposeCategories {
          externalId
          id
          name
          ...HomePurposeLaunchCategory_purposeCategory
        }
      }
    `,
    props.sectionLaunchpad
  )

  const handlePurposeLaunchCategoryClick: PropOf<typeof HomePurposeLaunchCategory, 'onClick'> = useCallback(
    (purposeCategory, launchItemId, categoryOrder, updateReferrer) => {
      const isSpotlightOnboarding = spotlightTargetInfo.targetId === launchItemId

      nearbyLog({
        params: {
          name: 'click_directory_section',
          directory_id: purposeCategory.externalId,
          directory_name: purposeCategory.name,
          order: categoryOrder,
          expanded,
          ...(isSpotlightOnboarding ? { isSpotlightOnboarding: 'true' } : null),
        },
      })

      if (purposeCategory.targetUri) {
        const targetUri = purposeCategory.targetUri

        // 겨간지 spotlight onboarding referrer 대응
        isSpotlightOnboarding
          ? openTarget({
              targetUri,
              updateReferrer,
            })
          : openTarget({ targetUri })
      }
    },
    [spotlightTargetInfo.targetId, nearbyLog, expanded, openTarget]
  )

  const handleCategoryMoreViewClick = useCallback(() => {
    nearbyLog({
      params: {
        name: 'click_directory_section',
        directory_id: 'more',
        order: purposeCategories.length + 1,
      },
    })
  }, [nearbyLog, purposeCategories.length])

  const categoryList = purposeCategories.slice(0, VIEW_LAUCNHPAD_ITEM_COUNT)
  const [replacedCategory, ...expandedCategoryList] = purposeCategories.slice(VIEW_LAUCNHPAD_ITEM_COUNT)

  return (
    <>
      <HomeFeedShortcutOnboarding />
      <LaunchpadContainer extraPadding={expanded}>
        <Accordion
          isOpen={expanded}
          content={
            <LaunchpadGrid>
              {categoryList.map((purposeCategory, index) => (
                <HomePurposeLaunchCategory
                  key={purposeCategory.id}
                  purposeCategory={purposeCategory}
                  itemOrder={index + 1}
                  onClick={handlePurposeLaunchCategoryClick}
                />
              ))}
              {expanded ? (
                <HomePurposeLaunchCategory
                  key={replacedCategory.id}
                  purposeCategory={replacedCategory}
                  itemOrder={12}
                  onClick={handlePurposeLaunchCategoryClick}
                />
              ) : (
                <>
                  {expandedCategoryList.length > 0 && (
                    <PurposeCategoryMoreView
                      expanded={expanded}
                      setExpanded={setExpanded}
                      onClick={handleCategoryMoreViewClick}
                    />
                  )}
                </>
              )}
            </LaunchpadGrid>
          }
          expandedContent={
            <>
              <LaunchpadGrid>
                {expandedCategoryList.map((purposeCategory, index) => (
                  <HomePurposeLaunchCategory
                    key={purposeCategory.id}
                    purposeCategory={purposeCategory}
                    itemOrder={index + 1}
                    onClick={handlePurposeLaunchCategoryClick}
                  />
                ))}
              </LaunchpadGrid>
              <PurposeCategoryFoldButton setExpanded={setExpanded} />
            </>
          }
        />
        <EventBanner padding={expanded ? '0px 16px 32px' : '24px 16px 16px'} />
      </LaunchpadContainer>
    </>
  )
}

export const LaunchpadContainer = styled(SectionContainer)<{ extraPadding?: boolean }>`
  transition: padding 0.25s ease-in-out;
  padding: ${({ extraPadding }) => (extraPadding ? '0.25rem 0 0' : '0.25rem 0 1rem')};
`

export const LaunchpadGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(${VIEW_LAUNCHPAD_COLUMNS}, 1fr);
  grid-gap: 0;
  grid-row-gap: 0;
  grid-column-gap: 0.313rem;
  padding: 0 1rem;
`

export default HomePurposeLaunchpad
