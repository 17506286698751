import { useEffect } from 'react'
import { useRecoilValue } from 'recoil'

import { bigFontCplState, bigFontState } from '../state/bigFont'

const useSimulateBigFont = () => {
  const bigFont = useRecoilValue(bigFontState)
  const CPL = useRecoilValue(bigFontCplState)

  useEffect(() => {
    if (bigFont) {
      const nextFontSize = Math.round(window.innerWidth / CPL) + 'px'

      document.documentElement.style.fontSize = nextFontSize
    } else {
      const DEFAULT_FONT_SIZE = '16px'
      document.documentElement.style.fontSize = DEFAULT_FONT_SIZE
    }
  }, [bigFont, CPL])

  return null
}

export default useSimulateBigFont
