import React, { useRef } from 'react'

interface NodeRefProps {
  children: (ref: React.RefObject<HTMLDivElement>) => React.ReactNode
}
const NodeRef: React.FC<NodeRefProps> = (props) => {
  const ref = useRef<HTMLDivElement>(null)

  return <>{props.children(ref)}</>
}

export default NodeRef
