import styled from '@emotion/styled'
import React, { type FC, Suspense } from 'react'
import { graphql, useFragment } from 'react-relay'

import withSuspense from '@src/lib/with-fallback/withSuspense'

import HomeOptimizedLazySection from './HomeOptimizedLazySection'
import SectionLoading from './lib/SectionLoading'
import VerticalContentsCloseOnboardingSection from './Sections/Onboarding/VerticalContentsCloseOnboardingSection'
import { type HomeSections_sections$key } from '../../__relay__/HomeSections_sections.graphql'
import type { Position } from '../../currentPositionResource'
import Spinner from '../_lib/Spinner'
import HomeSection, { SECTION_INFO } from '../Home/HomeSection'
interface HomeSectionsProps {
  fetchKey: number
  currentPosition: Position | null
  query: HomeSections_sections$key
}
const HomeSections: FC<HomeSectionsProps> = ({ fetchKey, currentPosition, query }) => {
  const homeSectionsQuery = useFragment(
    graphql`
      fragment HomeSections_sections on Query
      @argumentDefinitions(regionId: { type: "Int!" }, currentCoords: { type: "CoordsInput" }) {
        sectionsByRegionId(regionId: $regionId, currentCoords: $currentCoords) {
          __typename
          ...HomeSection_section

          ... on LazySection {
            __typename
            originalSectionType
            baseInfo {
              sectionType
            }
            ...HomeSectionBase_sectionBase
            ...SectionLoading_sectionBase
          }
        }

        ...HomeSection_query
      }
    `,
    query
  )

  const sectionWithOrderList = (() => {
    return homeSectionsQuery.sectionsByRegionId.map((section, sectionOrder) => {
      return {
        section,
        sectionOrder: sectionOrder - 1,
      }
    })
  })()

  return (
    <Sections>
      {sectionWithOrderList.map(({ section, sectionOrder }) => {
        const sectionId =
          section.baseInfo?.sectionType ?? (section.__typename.includes('Launchpad') ? 'LAUNCHPAD' : section.__typename)

        const sectionInfo = {
          id: sectionId,
          order: sectionOrder,
        }
        const Component = () => {
          switch (section.__typename) {
            case 'LazySection': {
              if (!section.originalSectionType) {
                return null
              }

              const sectionLoadingInfo = SECTION_INFO[section.originalSectionType]

              const SectionLoadingComponent = (
                <SectionLoading
                  sectionBase={section}
                  SectionTitleProps={{
                    ...(sectionLoadingInfo?.loadingTitle ? { title: sectionLoadingInfo?.loadingTitle } : null),
                  }}
                  height={sectionLoadingInfo?.loadingHeight}
                />
              )

              return (
                <Suspense fallback={SectionLoadingComponent}>
                  <S_SectionWrapper>
                    <HomeOptimizedLazySection
                      sectionOrder={sectionOrder}
                      loadingComponent={SectionLoadingComponent}
                      homeLazySectionProps={{
                        fetchKey: fetchKey,
                        currentPosition: currentPosition,
                        originalSectionType: section.originalSectionType,
                        homeSectionProps: {
                          query: homeSectionsQuery,
                          sectionInfo,
                        },
                      }}
                    />
                  </S_SectionWrapper>
                </Suspense>
              )
            }
            default:
              return (
                <S_SectionWrapper>
                  <HomeSection section={section} sectionInfo={sectionInfo} query={homeSectionsQuery} />
                </S_SectionWrapper>
              )
          }
        }

        return <Component key={section.originalSectionType ?? section.__typename} />
      })}

      <VerticalContentsCloseOnboardingSection />
    </Sections>
  )
}

export default React.memo(
  withSuspense(HomeSections, () => {
    return (
      <LoadingSections>
        <Spinner size={24} />
      </LoadingSections>
    )
  })
)

const Sections = styled.div``

const LoadingSections = styled.div({
  padding: '1.5rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

const S_SectionWrapper = styled.section`
  & > div:first-of-type {
    margin-top: 0.5rem;
  }
  & > div:not(:last-of-type) {
    margin-bottom: 0.5rem;
  }
`
