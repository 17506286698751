/**
 * @generated SignedSource<<ab6c8212b63994af3b62ce3a99f698c6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type BizPostThemeType = "ALL" | "BEAUTY" | "CLOSING_SALE" | "EDUCATION" | "FOLLOWING_AND_RECOMMENDATION" | "FOOD" | "HEALTH" | "LIFE" | "RECENTLY";
import { FragmentRefs } from "relay-runtime";
export type HomeBizThemes_sectionBizTheme$data = {
  readonly bizThemeOrder: ReadonlyArray<BizPostThemeType>;
  readonly firstBizPostTheme: {
    readonly " $fragmentSpreads": FragmentRefs<"HomeBizTheme_bizPostTheme">;
  } | null;
  readonly loggingMeta: {
    readonly click: string;
    readonly show: string;
    readonly swipe: string;
  };
  readonly writeBizTargetUri: string;
  readonly " $fragmentSpreads": FragmentRefs<"HomeBizThemes_targetUris" | "HomeSectionBase_sectionBase">;
  readonly " $fragmentType": "HomeBizThemes_sectionBizTheme";
};
export type HomeBizThemes_sectionBizTheme$key = {
  readonly " $data"?: HomeBizThemes_sectionBizTheme$data;
  readonly " $fragmentSpreads": FragmentRefs<"HomeBizThemes_sectionBizTheme">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HomeBizThemes_sectionBizTheme",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "BizPostTheme",
      "kind": "LinkedField",
      "name": "firstBizPostTheme",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "HomeBizTheme_bizPostTheme"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bizThemeOrder",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HomeSectionBase_sectionBase"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "writeBizTargetUri",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EventMeta",
      "kind": "LinkedField",
      "name": "loggingMeta",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "swipe",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "click",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "show",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HomeBizThemes_targetUris"
    }
  ],
  "type": "SectionBizTheme",
  "abstractKey": null
};

(node as any).hash = "359a35c645eb22bcadb3f5b9d5041457";

export default node;
