import styled from '@emotion/styled'
import { type FC } from 'react'
import { graphql, useLazyLoadQuery } from 'react-relay'

import { useCurrentPosition } from '@src/hooks/useCurrentPosition'

import HomeMapsTabList from './HomeMapsTabList'
import {
  type HomeMapsLazyTabListQuery,
  type PoiTabCategoryEnum,
} from '../../../../__relay__/HomeMapsLazyTabListQuery.graphql'
import { useViewer } from '../../../../hooks/useViewer'
import { type PropsOf } from '../../../../lib/T/utilTypes'
import withSuspense from '../../../../lib/with-fallback/withSuspense'
import Spinner from '../../../_lib/Spinner'

interface HomeMapsLazyTabListProps {
  originalTabType: PoiTabCategoryEnum
  tabListProps: Omit<PropsOf<typeof HomeMapsTabList>, 'tab'>
}

const HomeMapsLazyTabList: FC<HomeMapsLazyTabListProps> = (props) => {
  const viewer = useViewer()
  const { currentPosition } = useCurrentPosition()

  const lazyTabQuery = useLazyLoadQuery<HomeMapsLazyTabListQuery>(
    graphql`
      query HomeMapsLazyTabListQuery(
        $regionId: Int!
        $originalTabType: PoiTabCategoryEnum!
        $currentCoords: CoordsInput
      ) {
        section(input: { sectionType: MAP, regionId: $regionId, currentCoords: $currentCoords }) {
          __typename
          ... on SectionMap {
            tab(type: $originalTabType) {
              ...HomeMapsTabList_tab
            }
          }
        }
      }
    `,
    {
      regionId: viewer.region.id || 0,
      originalTabType: props.originalTabType,
      currentCoords: currentPosition
        ? {
            lat: currentPosition.lat,
            lng: currentPosition.lng,
          }
        : null,
    }
  )

  if (lazyTabQuery.section?.__typename !== 'SectionMap') {
    throw new Error('HomeMap LazyTab Component expected section query to be of type SectionMap')
  }

  return <HomeMapsTabList {...props.tabListProps} tab={lazyTabQuery.section.tab} />
}

const Fallback = () => (
  <FallbackContainer>
    <Spinner size={24} />
  </FallbackContainer>
)

const FallbackContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 27rem;
`

export default withSuspense(HomeMapsLazyTabList, Fallback)
