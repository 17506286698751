import { atom } from 'recoil'

import { generateMapStateKey } from './key'

export const DEFAULT_ZOOM = 15

/**
 * Atom에 대한 설명
 */
export const zoomLevelAtom = atom<number>({
  key: generateMapStateKey('zoomLevel'),
  default: DEFAULT_ZOOM,
})

/**
 * Atom에 대한 설명
 */
export const maxZoomAtom = atom<number>({
  key: generateMapStateKey('maxZoom'),
  default: 24,
})

export const minZoomAtom = atom<number>({
  key: generateMapStateKey('monZoom'),
  default: 0,
})
