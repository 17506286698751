import axios from 'axios'
import { useCallback } from 'react'
import { useRecoilValue } from 'recoil'

import { appAtom } from '@src/features/_global/state/app'

export function useEventLogFetcher() {
  const { userAgent } = useRecoilValue(appAtom)

  const eventLogFetcher = useCallback(
    (logApiUrl: string, method: 'post' | 'get' = 'post') => {
      switch (method) {
        case 'get':
          return axios.get(logApiUrl, {
            headers: {
              'X-User-Agent': userAgent,
            },
          })
        case 'post':
        default:
          return axios.post(
            logApiUrl,
            {},
            {
              headers: {
                'X-User-Agent': userAgent,
              },
            }
          )
      }
    },
    [userAgent]
  )

  return eventLogFetcher
}
