import styled from '@emotion/styled'
import React from 'react'

const CouponDownload: React.FC<{
  size?: string | number
  color?: string
}> = ({ size = '14px', color = 'currentColor' }) => {
  return (
    <Svg width={size} height={size} viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3999 1.7002C13.8619 1.7002 14.2365 2.05837 14.2365 2.5002V16.5688L20.1284 10.9345C20.4551 10.6221 20.9848 10.6221 21.3116 10.9345C21.6383 11.2469 21.6383 11.7535 21.3116 12.0659L13.9914 19.0659C13.6647 19.3783 13.135 19.3783 12.8083 19.0659L5.48819 12.0659C5.16149 11.7535 5.16149 11.2469 5.48819 10.9345C5.8149 10.6221 6.3446 10.6221 6.6713 10.9345L12.5633 16.5688V2.5002C12.5633 2.05837 12.9378 1.7002 13.3999 1.7002ZM5.24316 21.5002C5.24316 21.0584 5.61772 20.7002 6.07975 20.7002H20.72C21.182 20.7002 21.5566 21.0584 21.5566 21.5002C21.5566 21.942 21.182 22.3002 20.72 22.3002H6.07975C5.61772 22.3002 5.24316 21.942 5.24316 21.5002Z"
        fill={color}
      />
    </Svg>
  )
}

export default React.memo(CouponDownload)

const Svg = styled.svg`
  display: block;
`
