import { usePullToRefreshScrollElement } from '@daangn/karrot-clothes/lib/pullToRefresh'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { useEffect, useRef } from 'react'
import { graphql, useFragment } from 'react-relay'

import {
  type HomeBizThemePost_bizPost$key,
  type HomeBizThemePost_bizPost$data,
} from '@src/__relay__/HomeBizThemePost_bizPost.graphql'
import PlaceholderBizPost from '@src/assets/images/PlaceholderBizPost'
import CoveredLazyLoadImage from '@src/components/_lib/CoveredLazyLoadImage'
import { noneActiveEffect, getEllipsisStyle } from '@src/components/_lib/styles'
import { useBizLoggingMeta } from '@src/hooks/useBizLoggingMeta'
import { getDateDiffFromNow } from '@src/lib/date/date'
import { type Target } from '@src/lib/target'

interface HomeBizThemePostProps {
  bizPost: HomeBizThemePost_bizPost$key
  onClick?: (target: Target, info: { bizPost: HomeBizThemePost_bizPost$data }) => void
  isDisabledCategoryName?: boolean
  isCaptionCreatedAtOnly?: boolean
}
const HomeBizThemePost: React.FC<HomeBizThemePostProps> = ({
  isDisabledCategoryName = false,
  isCaptionCreatedAtOnly = false,
  ...props
}) => {
  const itemRef = useRef<HTMLDivElement>(null)
  const scrollContainerRef = usePullToRefreshScrollElement()
  const badgeTimeSaleRef = useRef<HTMLDivElement>(null)

  const bizPost = useFragment(
    graphql`
      fragment HomeBizThemePost_bizPost on BizPost {
        externalId
        title
        content
        distance {
          distance
          name
        }
        image {
          thumbnail
        }
        region {
          externalId
          name3
        }
        bizAccount {
          name
          categoryName
          badges
        }
        couponBox {
          id
        }
        createdAt
        targetUri
        extensions {
          closingTimeSale {
            status
            closeTime
          }
        }
        bookmarkCount
        commentCount
        meta {
          type
          entry
        }
        ...useBizLoggingMeta_bizLoggingMeta
      }
    `,
    props.bizPost
  )
  const isNearbyBizPost = bizPost.bizAccount?.badges.includes('WALK_10_MIN')
  const isFollowingPost = bizPost.meta.type === 'FOLLOW_POST'

  useEffect(() => {
    const $badgeTimeSale = badgeTimeSaleRef.current

    if (!$badgeTimeSale) {
      return
    }
    if (!bizPost.extensions.closingTimeSale) {
      return
    }
    if (bizPost.extensions.closingTimeSale.status === 'CLOSED') {
      return
    }

    const closeTime = new Date(bizPost.extensions.closingTimeSale.closeTime).getTime()

    const { dispose } = loopRAF(() => {
      const now = new Date().getTime()
      const distanceRaw = closeTime - now

      if (distanceRaw <= 0) {
        $badgeTimeSale.innerText = '마감됨'
        return
      }

      const distance = new Date(distanceRaw)

      const { h, m, s } = getAtomicTimeFromDate(distance)

      const { h: prevH, m: prevM, s: prevS } = parseTimer($badgeTimeSale.innerText)

      if (prevH !== h || prevM !== m || prevS !== s) {
        $badgeTimeSale.innerText = stringifyTimer(distance) + ' 남음'
      }
    })

    return () => {
      dispose()
    }
  }, [badgeTimeSaleRef, bizPost])

  useBizLoggingMeta({
    targetRef: itemRef,
    scrollContainerRef,
    bizLoggingMetaRef: bizPost,
    onClick: () => {
      props.onClick?.(
        {
          targetUri: bizPost.targetUri,
        },
        {
          bizPost,
        }
      )
    },
  })

  return (
    <Container ref={itemRef}>
      <Button>
        <S_CoveredLazyLoadImage effect="opacity" src={bizPost.image?.thumbnail} height={88} visibleByDefault>
          {!bizPost.image?.thumbnail && <PlaceholderBizPost />}
          {bizPost.couponBox && <BadgeCoupon>쿠폰</BadgeCoupon>}
          {bizPost.extensions.closingTimeSale && (
            <BadgeTimeSale ref={badgeTimeSaleRef}>
              {bizPost.extensions.closingTimeSale.status === 'CLOSED' && '마감됨'}
            </BadgeTimeSale>
          )}
        </S_CoveredLazyLoadImage>
        <Main>
          <Top>
            <TopLabel variant="bold">{bizPost.bizAccount?.name}</TopLabel>
            {!isDisabledCategoryName && <TopLabel variant="gray600">{bizPost.bizAccount?.categoryName}</TopLabel>}
          </Top>
          <Title>{bizPost.title}</Title>
          <Captions>
            <Caption>{getDateDiffFromNow(new Date(bizPost.createdAt))}전</Caption>
            {!isCaptionCreatedAtOnly && (
              <>
                {isFollowingPost && <Caption>단골소식</Caption>}
                {isNearbyBizPost && <Caption>걸어서 10분</Caption>}
              </>
            )}
          </Captions>
        </Main>
      </Button>
    </Container>
  )
}

const Container = styled.div`
  padding: 1rem 0;
  box-shadow: 0 1px 0 0 ${vars.$semantic.color.divider1};
  &:last-of-type {
    box-shadow: none;
  }
`

const Button = styled.div`
  display: flex;
  ${noneActiveEffect};
`

const S_CoveredLazyLoadImage = styled(CoveredLazyLoadImage)`
  background-color: ${vars.$semantic.color.paperContents};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  height: 5.75rem;
  width: 5.75rem;
  border-radius: 0.375rem;
  overflow: hidden;
  margin-right: 1rem;

  transform: translate3d(0, 0, 0);
  mask-image: -webkit-radial-gradient(white, black);

  span {
    display: inline-flex !important;
  }

  img,
  svg {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const BadgeCoupon = styled.div`
  position: absolute;
  left: 0.425rem;
  top: 0.4235rem;
  font-size: 0.688rem;
  color: ${vars.$semantic.color.onPrimary};
  font-weight: 700;
  background-color: ${vars.$scale.color.carrot500};
  padding: 0.125rem 0.375rem;
  vertical-align: top;
  border-radius: 0.1875rem;
`

const BadgeTimeSale = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  font-size: 0.75rem;
  color: ${vars.$static.color.staticWhite};
  width: 100%;
  text-align: center;
  padding: 0.1875rem 0;
  background-color: ${vars.$static.color.staticBlackAlpha500};
`

const Main = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
`

const Top = styled.div`
  display: flex;
  margin-bottom: 0.1875rem;
  width: 100%;
`

const TopLabel = styled.div<{ variant?: 'bold' | 'gray600' }>`
  flex-shrink: 0;
  font-size: 0.8125rem;
  margin-right: 0.25rem;
  ${vars.$semantic.typography.caption1Regular}

  ${(props) =>
    props.variant === 'bold' &&
    css`
      ${vars.$semantic.typography.caption1Bold}
    `}

  ${(props) =>
    props.variant === 'gray600' &&
    css`
      color: ${vars.$scale.color.gray600};
    `}

  &:last-of-type {
    ${getEllipsisStyle(1)};
  }
`

const Title = styled.div`
  font-size: 1rem;
  font-weight: normal;
  line-height: 135%;
  color: ${vars.$scale.color.gray900};
  margin-bottom: 0.25rem;
  width: 100%;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  visibility: visible;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
`

const Captions = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.25rem;
`

const Caption = styled.div`
  color: ${vars.$scale.color.gray600};
  font-size: 0.8125rem;
  white-space: nowrap;

  &::before {
    content: '·';
    color: ${vars.$scale.color.gray600};
    margin: 0 0.25rem;
  }

  &:first-of-type {
    &::before {
      display: none;
    }
  }
`

export default HomeBizThemePost

const loopRAF = (callback: () => void) => {
  let finished = false

  const animate = () => {
    requestAnimationFrame(() => {
      callback()

      if (!finished) {
        animate()
      }
    })
  }

  animate()

  return {
    dispose() {
      finished = true
    },
  }
}

function parseTimer(str: string) {
  const [h, m, s] = str.split(':').map(Number)

  return {
    h,
    m,
    s,
  }
}

function stringifyTimer(distance: Date) {
  const { h, m, s } = getAtomicTimeFromDate(distance)

  return [h, fillZero(2, String(m)), fillZero(2, String(s))].join(':')
}

function getAtomicTimeFromDate(date: Date) {
  const h = date.getUTCHours()
  const m = date.getUTCMinutes()
  const s = date.getUTCSeconds()

  return {
    h,
    m,
    s,
  }
}

function fillZero(width: number, str: string) {
  return str.length >= width ? str : new Array(width - str.length + 1).join('0') + str //남는 길이만큼 0으로 채움
}
