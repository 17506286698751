/**
 * @generated SignedSource<<b84e74d438035196b29b6f8cc46e2a2a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WriteLaunch_launch$data = {
  readonly app: string | null;
  readonly externalId: string;
  readonly externalLaunchServiceId: string;
  readonly isNew: boolean;
  readonly name: string;
  readonly path: string | null;
  readonly targetUri: string | null;
  readonly title: string | null;
  readonly writeRequireBizAccount: boolean;
  readonly " $fragmentSpreads": FragmentRefs<"WriteLaunchIcon_icon">;
  readonly " $fragmentType": "WriteLaunch_launch";
};
export type WriteLaunch_launch$key = {
  readonly " $data"?: WriteLaunch_launch$data;
  readonly " $fragmentSpreads": FragmentRefs<"WriteLaunch_launch">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WriteLaunch_launch",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "externalId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "externalLaunchServiceId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isNew",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "app",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "targetUri",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "writeRequireBizAccount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "path",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "WriteLaunchIcon_icon"
    }
  ],
  "type": "WriteLaunchpadIcon",
  "abstractKey": null
};

(node as any).hash = "b4da53a45972bb90e4941e452d828d59";

export default node;
