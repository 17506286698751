/**
 * @generated SignedSource<<cd5ade780c7be894fd050077979e9a49>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CouponEventTypeEnum = "CUSTOM_AREA_ONLY" | "FOLLOWER_ONLY" | "NORMAL" | "TARGET_ONLY" | "UNKNOWN_EVENT";
export type CouponTypeEnum = "OFFLINE" | "ONLINE" | "THIRD_BARCODE" | "UNKNOWN_TYPE";
export type CouponUserStatusEnum = "CAN_CLAIM" | "CAN_USE" | "CLAIMED_BUT_EXPIRED" | "INACTIVE_COUPON" | "IS_EXPIRED" | "IS_USED" | "NO_QUANTITY" | "UNKNOWN_USER_STATUS";
import { FragmentRefs } from "relay-runtime";
export type HomeCoupon_couponBox$data = {
  readonly bizAccount: {
    readonly categoryName: string | null;
    readonly externalId: string;
    readonly id: string;
    readonly images: ReadonlyArray<{
      readonly url: string;
    }>;
    readonly name: string;
  };
  readonly bizDeliveryLoggingMeta: {
    readonly click: string;
    readonly impression: string;
  } | null;
  readonly couponEventType: CouponEventTypeEnum;
  readonly couponType: CouponTypeEnum;
  readonly coverImage: {
    readonly url: string;
  } | null;
  readonly expiredAt: string | null;
  readonly externalId: string;
  readonly id: string;
  readonly image: {
    readonly thumbnail: string;
    readonly url: string;
  };
  readonly isBrand: boolean;
  readonly name: string;
  readonly remainCount: number | null;
  readonly targetUri: string;
  readonly totalClaimedUserCount: number;
  readonly userStatus: CouponUserStatusEnum | null;
  readonly viewerHasClaimed: boolean;
  readonly " $fragmentType": "HomeCoupon_couponBox";
};
export type HomeCoupon_couponBox$key = {
  readonly " $data"?: HomeCoupon_couponBox$data;
  readonly " $fragmentSpreads": FragmentRefs<"HomeCoupon_couponBox">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "externalId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v3 = [
  (v2/*: any*/)
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HomeCoupon_couponBox",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isBrand",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Image",
      "kind": "LinkedField",
      "name": "image",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "thumbnail",
          "storageKey": null
        },
        (v2/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Image",
      "kind": "LinkedField",
      "name": "coverImage",
      "plural": false,
      "selections": (v3/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "remainCount",
      "storageKey": null
    },
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "BizAccount",
      "kind": "LinkedField",
      "name": "bizAccount",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "categoryName",
          "storageKey": null
        },
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Image",
          "kind": "LinkedField",
          "name": "images",
          "plural": true,
          "selections": (v3/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "expiredAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "couponType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "couponEventType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "targetUri",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "viewerHasClaimed",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalClaimedUserCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BizDeliveryLoggingMeta",
      "kind": "LinkedField",
      "name": "bizDeliveryLoggingMeta",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "click",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "impression",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CouponBox",
  "abstractKey": null
};
})();

(node as any).hash = "ca389cccb70ab07dc41a200d2115ea45";

export default node;
