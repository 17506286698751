import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import React from 'react'

interface HomeLaunchpadAppIconProps {
  src: string | null
  alt?: string
}

const PurposeCategoryLaunchIcon: React.FC<HomeLaunchpadAppIconProps> = ({ src, alt }) => {
  if (src) {
    return <Icon src={src} alt={alt} />
  }

  return <EmptyIcon />
}

const Icon = styled.img`
  width: 100%;
  height: 100%;
`

const EmptyIcon = styled.div`
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 1.125rem;
  background-color: ${vars.$scale.color.gray100};
`

export default PurposeCategoryLaunchIcon
