/**
 * @generated SignedSource<<31d9e4c7b546a36ca5811d553d8a058b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PoiTabCategoryEnum = "BEAUTY" | "CAFE" | "EXERCISE" | "FOOD" | "HOSPITAL" | "PET";
export type CoordsInput = {
  lat: number;
  lng: number;
};
export type HomeMapsLazyTabListQuery$variables = {
  currentCoords?: CoordsInput | null;
  originalTabType: PoiTabCategoryEnum;
  regionId: number;
};
export type HomeMapsLazyTabListQuery$data = {
  readonly section: {
    readonly __typename: "SectionMap";
    readonly tab: {
      readonly " $fragmentSpreads": FragmentRefs<"HomeMapsTabList_tab">;
    };
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null;
};
export type HomeMapsLazyTabListQuery = {
  response: HomeMapsLazyTabListQuery$data;
  variables: HomeMapsLazyTabListQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "currentCoords"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "originalTabType"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "regionId"
},
v3 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "currentCoords",
        "variableName": "currentCoords"
      },
      {
        "kind": "Variable",
        "name": "regionId",
        "variableName": "regionId"
      },
      {
        "kind": "Literal",
        "name": "sectionType",
        "value": "MAP"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v5 = [
  {
    "kind": "Variable",
    "name": "type",
    "variableName": "originalTabType"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "targetUri",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "externalId",
  "storageKey": null
},
v10 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "url",
    "storageKey": null
  },
  (v7/*: any*/)
],
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "viewerIsWatched",
  "storageKey": null
},
v12 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "thumbnail",
    "storageKey": null
  },
  (v7/*: any*/)
],
v13 = [
  (v7/*: any*/)
],
v14 = {
  "kind": "InlineFragment",
  "selections": (v13/*: any*/),
  "type": "Node",
  "abstractKey": "__isNode"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "HomeMapsLazyTabListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "section",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v5/*: any*/),
                "concreteType": "PoiTab",
                "kind": "LinkedField",
                "name": "tab",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "HomeMapsTabList_tab"
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "SectionMap",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "HomeMapsLazyTabListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "section",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v5/*: any*/),
                "concreteType": "PoiTab",
                "kind": "LinkedField",
                "name": "tab",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "type",
                    "storageKey": null
                  },
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PoiTag",
                    "kind": "LinkedField",
                    "name": "poiTags",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Poi",
                        "kind": "LinkedField",
                        "name": "poi",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Coords",
                            "kind": "LinkedField",
                            "name": "coords",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "lat",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "lng",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v8/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "DistanceInfo",
                            "kind": "LinkedField",
                            "name": "distance",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "distance",
                                "storageKey": null
                              },
                              (v8/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v9/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isNew",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "PoiCategory",
                            "kind": "LinkedField",
                            "name": "category",
                            "plural": false,
                            "selections": [
                              (v8/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Image",
                            "kind": "LinkedField",
                            "name": "images",
                            "plural": true,
                            "selections": (v10/*: any*/),
                            "storageKey": null
                          },
                          (v6/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "BizAccount",
                            "kind": "LinkedField",
                            "name": "bizAccount",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Image",
                                "kind": "LinkedField",
                                "name": "image",
                                "plural": false,
                                "selections": (v10/*: any*/),
                                "storageKey": null
                              },
                              (v7/*: any*/),
                              (v11/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v11/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "taggedBy",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v9/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "content",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "HoianUser",
                                "kind": "LinkedField",
                                "name": "author",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "nickname",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Image",
                                    "kind": "LinkedField",
                                    "name": "image",
                                    "plural": false,
                                    "selections": (v12/*: any*/),
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Region",
                                    "kind": "LinkedField",
                                    "name": "region",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "name3",
                                        "storageKey": null
                                      },
                                      (v7/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "checkinCount",
                                    "storageKey": null
                                  },
                                  (v7/*: any*/)
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Image",
                                "kind": "LinkedField",
                                "name": "firstImage",
                                "plural": false,
                                "selections": (v12/*: any*/),
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Image",
                                "kind": "LinkedField",
                                "name": "images",
                                "plural": true,
                                "selections": (v13/*: any*/),
                                "storageKey": null
                              }
                            ],
                            "type": "StoryArticle",
                            "abstractKey": null
                          },
                          (v14/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "SectionMap",
            "abstractKey": null
          },
          (v14/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0d00a1bd5fbe9342521bbdd5dcf64f9e",
    "id": null,
    "metadata": {},
    "name": "HomeMapsLazyTabListQuery",
    "operationKind": "query",
    "text": "query HomeMapsLazyTabListQuery(\n  $regionId: Int!\n  $originalTabType: PoiTabCategoryEnum!\n  $currentCoords: CoordsInput\n) {\n  section(input: {sectionType: MAP, regionId: $regionId, currentCoords: $currentCoords}) {\n    __typename\n    ... on SectionMap {\n      tab(type: $originalTabType) {\n        ...HomeMapsTabList_tab\n      }\n    }\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n}\n\nfragment BannerPoi_poi on Poi {\n  id\n  externalId\n  name\n  isNew\n  distance {\n    distance\n    name\n  }\n  category {\n    name\n  }\n  images {\n    url\n    id\n  }\n  targetUri\n  bizAccount {\n    image {\n      url\n      id\n    }\n    id\n  }\n  ...usePoiWatch_poi\n}\n\nfragment HomeMapsTabListItem_poiTag on PoiTag {\n  poi {\n    ...BannerPoi_poi\n    id\n  }\n  taggedBy {\n    __typename\n    ... on StoryArticle {\n      __typename\n      externalId\n      content\n      author {\n        nickname\n        image {\n          thumbnail\n          id\n        }\n        region {\n          name3\n          id\n        }\n        checkinCount\n        id\n      }\n      firstImage {\n        thumbnail\n        id\n      }\n      images {\n        id\n      }\n    }\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n  targetUri\n}\n\nfragment HomeMapsTabList_tab on PoiTab {\n  title\n  type\n  targetUri\n  poiTags {\n    poi {\n      id\n      coords {\n        lat\n        lng\n      }\n      name\n      distance {\n        distance\n        name\n      }\n    }\n    ...HomeMapsTabListItem_poiTag\n  }\n}\n\nfragment usePoiWatch_poi on Poi {\n  id\n  viewerIsWatched\n  bizAccount {\n    id\n    viewerIsWatched\n  }\n}\n"
  }
};
})();

(node as any).hash = "912469c3cde299d102c4898fbe185260";

export default node;
