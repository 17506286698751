/**
 * @generated SignedSource<<f8e2a902ffd8f9e30522af0ec16dc248>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type SectionTypeEnum = "AUTO_REQUEST_SERVICE_FIELD_CURATION" | "BANNER" | "BEST_BIZ_ACCOUNT" | "BIZ_FITNESS_PRODUCT" | "BIZ_RECOMMEND_PRODUCT" | "BIZ_THEME" | "CAR_SAVED_COST" | "CLASS_BIZ_POST" | "COMMUNITY_POST" | "COUPON" | "CUSTOM" | "EXPERT_RECOMMEND" | "HOT_BIZ_ACCOUNT" | "HOT_CAR_ARTICLE" | "HOT_JOBS" | "HOT_KEYWORD" | "LOCAL_FOOD" | "MAP" | "MINI" | "MY_DAANGN_STORY" | "NATIVE_REVIEW" | "NEARBY_BIZ_POST" | "NEARBY_EXERCISE_BIZ_ACCOUNT" | "NEARBY_JOBS" | "POPULAR_SERVICE_FIELD" | "PUBLIC_BIZ_POST" | "PURPOSE" | "RECENTLY_BIZ_ACCOUNT" | "RECENTLY_REALTY_ARTICLE" | "RECOMMEND_POST" | "REQUEST_REVIEW" | "SHORT_FORM";
import { FragmentRefs } from "relay-runtime";
export type HomeSections_sections$data = {
  readonly sectionsByRegionId: ReadonlyArray<{
    readonly __typename: string;
    readonly baseInfo?: {
      readonly sectionType: SectionTypeEnum;
    } | null;
    readonly originalSectionType?: SectionTypeEnum;
    readonly " $fragmentSpreads": FragmentRefs<"HomeSectionBase_sectionBase" | "HomeSection_section" | "SectionLoading_sectionBase">;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"HomeSection_query">;
  readonly " $fragmentType": "HomeSections_sections";
};
export type HomeSections_sections$key = {
  readonly " $data"?: HomeSections_sections$data;
  readonly " $fragmentSpreads": FragmentRefs<"HomeSections_sections">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "currentCoords"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "regionId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "HomeSections_sections",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "currentCoords",
          "variableName": "currentCoords"
        },
        {
          "kind": "Variable",
          "name": "regionId",
          "variableName": "regionId"
        }
      ],
      "concreteType": null,
      "kind": "LinkedField",
      "name": "sectionsByRegionId",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "HomeSection_section"
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "originalSectionType",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "BaseInfo",
              "kind": "LinkedField",
              "name": "baseInfo",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "sectionType",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "HomeSectionBase_sectionBase"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SectionLoading_sectionBase"
            }
          ],
          "type": "LazySection",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HomeSection_query"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "8dd075f9f20bb0676c81ff4839a1252a";

export default node;
