import { TouchEvent } from 'globalthis/implementation'
import { isEqual } from 'lodash'
import { useCallback, useEffect, useState } from 'react'

type MoveType = 'None' | 'Horizontal' | 'Vertical'
type Direction = 'None' | 'Right' | 'Left' | 'Up' | 'Down'

type Gesture = {
  moveType: MoveType
  direction: Direction
}

interface useTouchGestureProps {
  targetElementRef: React.RefObject<HTMLElement>
  onSwipe?: (gesture: Gesture, event: TouchEvent) => void
  onGestureStart?: (event: TouchEvent) => void
  onGestureEnd?: (event: TouchEvent) => void
  disable?: boolean
}

const initialGesture: Gesture = {
  moveType: 'None',
  direction: 'None',
}

const htTouchInfo = {
  //touchstart 시점의 좌표와 시간을 저장하기
  nStartX: -1,
  nStartY: -1,
  nStartTime: 0,
}

export function useTouchGesture({
  targetElementRef,
  onSwipe,
  onGestureStart,
  onGestureEnd,
  disable,
}: useTouchGestureProps) {
  const [isTouched, setIsTouched] = useState(false)
  const [isTouchMove, setIsTouchMove] = useState(false)
  const [gesture, setGesture] = useState<Gesture>(initialGesture)
  const handleTouchStart = useCallback(
    (e: TouchEvent) => {
      onGestureStart?.(e)

      initTouchInfo()

      setIsTouched(true)
      setGesture(initialGesture)

      htTouchInfo.nStartX = e.changedTouches[0].pageX
      htTouchInfo.nStartY = e.changedTouches[0].pageY
      htTouchInfo.nStartTime = e.timeStamp
    },
    [onGestureStart]
  )

  const handleTouchMove = useCallback(
    (e: TouchEvent) => {
      const nX = e.changedTouches[0].pageX
      const nY = e.changedTouches[0].pageY

      //현재 touchmMove에서 사용자 터치에 대한 움직임을 판단한다.
      const nextGesture = getMoveType(htTouchInfo.nStartX, htTouchInfo.nStartY, nX, nY)

      onSwipe?.(nextGesture, e)

      if (isEqual(nextGesture.moveType, gesture.moveType)) {
        return
      }

      setGesture(nextGesture)
      setIsTouchMove(true)
      setIsTouched(false)
    },
    [gesture.moveType, onSwipe]
  )

  const handleTouchEnd = useCallback(
    (e: TouchEvent) => {
      setIsTouched(false)
      setIsTouchMove(false)

      if (gesture.moveType === 'None') {
        const nX = e.changedTouches[0].pageX
        const nY = e.changedTouches[0].pageY

        setGesture(getMoveType(htTouchInfo.nStartX, htTouchInfo.nStartY, nX, nY))
      }

      initTouchInfo() //터치 정보를 초기화한다.

      onGestureEnd?.(e)
    },
    [gesture.moveType, onGestureEnd]
  )

  useEffect(() => {
    const targetElement = targetElementRef.current
    if (!targetElement || disable) {
      return
    }

    if (targetElement && !disable) {
      targetElement.addEventListener('touchstart', handleTouchStart)
      targetElement.addEventListener('touchmove', handleTouchMove)
      targetElement.addEventListener('touchend', handleTouchEnd)
      targetElement.addEventListener('touchcancel', handleTouchEnd)
    }

    return () => {
      if (targetElement) {
        targetElement.removeEventListener('touchstart', handleTouchStart)
        targetElement.removeEventListener('touchmove', handleTouchMove)
        targetElement.removeEventListener('touchend', handleTouchEnd)
        targetElement.removeEventListener('touchcancel', handleTouchEnd)
      }
    }
  }, [disable, handleTouchStart, handleTouchEnd, handleTouchMove, targetElementRef])

  return {
    isTouched,
    isTouchMove,
    gesture,
    moveType: gesture.moveType,
    direction: gesture.direction,
  }
}

function initTouchInfo() {
  //터치 정보들의 값을 초기화하는 함수
  htTouchInfo.nStartX = -1
  htTouchInfo.nStartY = -1
  htTouchInfo.nStartTime = 0
}

//touchstart 좌표값과 비교하여 현재 사용자의 움직임을 판단하는 함수
function getMoveType(
  startX: number,
  startY: number,
  endX: number,
  endY: number
): {
  moveType: MoveType
  direction: Direction
} {
  const nHSlope = parseFloat((window.innerHeight / 2 / window.innerWidth).toFixed(2)) * 1

  let moveType: MoveType = 'None'
  let direction: Direction = 'None'

  const nX = Math.abs(startX - endX)
  const nY = Math.abs(startY - endY)
  const nDis = nX + nY
  //현재 움직인 거리가 기준 거리보다 작을 땐 방향을 판단하지 않는다.
  if (nDis < 25) {
    return {
      moveType,
      direction,
    }
  }

  const nSlope = parseFloat((nY / nX).toFixed(2))

  if (nSlope > nHSlope) {
    moveType = 'Vertical'
    if (endY > startY) {
      direction = 'Down'
    } else {
      direction = 'Up'
    }
  } else {
    moveType = 'Horizontal'
    if (endX > startX) {
      direction = 'Right'
    } else {
      direction = 'Left'
    }
  }

  return {
    moveType,
    direction,
  }
}
