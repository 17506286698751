import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { type FC, useRef } from 'react'
import { graphql, useFragment } from 'react-relay'

import {
  type HotBizAccountItem_bizAccount$data,
  type HotBizAccountItem_bizAccount$key,
} from '../../../../__relay__/HotBizAccountItem_bizAccount.graphql'
import { numberWithComma } from '../../../../lib/unit'
import CoveredLazyLoadImage from '../../../_lib/CoveredLazyLoadImage'
import { ListItem } from '../../../_lib/ListSection/ListItem'
import { Flex, FlexAlignCenter, getEllipsisStyle, ListItemDivider } from '../../../_lib/styles'
import { Badge } from '../../lib/Badge'
import TextDot from '../../lib/TextDot'

interface HotBizAccountItemProps {
  bizAccount: HotBizAccountItem_bizAccount$key
  onClick: (bizAccount: HotBizAccountItem_bizAccount$data) => void
}

const HotBizAccountItem: FC<HotBizAccountItemProps> = (props) => {
  const bizAccount = useFragment(
    graphql`
      fragment HotBizAccountItem_bizAccount on BizAccount {
        name
        description
        image {
          url
        }
        distance {
          distance
          name
        }
        categoryName
        reviewCount
        followerCount
        targetUri
        badges
      }
    `,
    props.bizAccount
  )

  const itemRef = useRef<HTMLDivElement>(null)

  const handleBizStoreClick = () => {
    props.onClick?.(bizAccount)
  }

  return (
    <S_HotBizAccountItem ref={itemRef} onClick={handleBizStoreClick}>
      {bizAccount.image.url && (
        <Left>
          <S_CoveredLazyLoadImage effect="opacity" src={bizAccount.image.url} height={88} visibleByDefault />
        </Left>
      )}
      <Right>
        <BizAccountInfo>
          <BizAccountBio>
            {bizAccount.name && <BizAccountName>{bizAccount.name}</BizAccountName>}
            {bizAccount.badges.length === 1 && (
              <BizAccountSubBio>
                <S_Badge badgeType={bizAccount.badges[0]} />
              </BizAccountSubBio>
            )}
          </BizAccountBio>

          <Description>{bizAccount.description}</Description>
          <ResponseIndicators>
            {bizAccount.categoryName && <Category>{bizAccount.categoryName}</Category>}
            {bizAccount.categoryName && (!!bizAccount.reviewCount || !!bizAccount.followerCount) && <S_TextDot />}
            {!!bizAccount.reviewCount && <Indicator>{`후기 ${numberWithComma(bizAccount.reviewCount)}`}</Indicator>}
            {!!bizAccount.followerCount && <Indicator>{`단골 ${numberWithComma(bizAccount.followerCount)}`}</Indicator>}
          </ResponseIndicators>
        </BizAccountInfo>
      </Right>
    </S_HotBizAccountItem>
  )
}

export default HotBizAccountItem

const S_HotBizAccountItem = styled(ListItem)`
  ${Flex};
  ${ListItemDivider};

  & > *:not(:last-child) {
    margin-right: 1rem;
  }
`

const Right = styled.div`
  flex: 1;
  padding-right: 1.563rem;
  width: calc(100% - 8.563rem);

  & > *:not(:last-child) {
    margin-bottom: 0.5rem;
  }
`

const Left = styled.div``

const S_CoveredLazyLoadImage = styled(CoveredLazyLoadImage)`
  height: 4rem;
  width: 4rem;
  border-radius: 50%;
  overflow: hidden;
`

const BizAccountInfo = styled.div`
  & > *:not(:last-child) {
    margin-bottom: 0.25rem;
  }
`

const BizAccountBio = styled.div`
  ${FlexAlignCenter};
  width: 100%;
  color: ${vars.$scale.color.gray600};
  ${getEllipsisStyle(1)};

  & > *:not(:last-child) {
    margin-right: 0.25rem;
  }
`

const BizAccountSubBio = styled.div`
  ${Flex};
  flex-shrink: 0;
  ${vars.$semantic.typography.caption1Regular};
  color: ${vars.$scale.color.gray600};
`

const BizAccountName = styled.div`
  ${vars.$semantic.typography.subtitle1Bold};
  color: ${vars.$scale.color.gray900};
  ${getEllipsisStyle(1)};
`

const Description = styled.div`
  ${vars.$semantic.typography.bodyL2Regular};
  color: ${vars.$scale.color.gray900};
  ${getEllipsisStyle(2)};
`

const ResponseIndicators = styled.div`
  display: flex;
  font-size: 13px;
  line-height: 20px;
  color: ${vars.$scale.color.gray600};
`

const Category = styled.span`
  ${getEllipsisStyle(1)};
`

const S_TextDot = styled(TextDot)`
  transform: none;
`

const Indicator = styled.span`
  flex-shrink: 0;

  &:not(:last-of-type)::after {
    content: '·';
    margin: 0 0.25rem;
  }
`

const S_Badge = styled(Badge)`
  border-radius: 0.75rem;
  padding: 0.125rem 0.375rem;
  background-color: transparent;
  border: 1px solid ${vars.$semantic.color.success};
  color: ${vars.$semantic.color.success};
  ${vars.$semantic.typography.label5Bold};
`
