import { css } from '@emotion/react'
import { vars } from '@seed-design/design-token'

export type BADGE_TYPE = 'basic' | 'primary' | 'accent' | 'success' | 'error'
export type BADGE_SIZE = 'small' | 'medium' | 'large'
export type BADGE_OPTION = 'default' | 'outlined' | 'isBold'

export const TYPE_OPTION_TOKEN: Record<
  BADGE_TYPE,
  Record<
    BADGE_OPTION,
    {
      backgroundColor: string
      color: string
      border: string
    }
  >
> = {
  basic: {
    default: {
      backgroundColor: vars.$scale.color.gray100,
      color: vars.$scale.color.gray700,
      border: 'none',
    },
    outlined: {
      backgroundColor: 'transparent',
      color: vars.$scale.color.gray900,
      border: `1px solid ${vars.$scale.color.gray900}`,
    },
    isBold: {
      backgroundColor: vars.$scale.color.gray700,
      color: vars.$scale.color.gray00,
      border: 'none',
    },
  },

  accent: {
    default: {
      backgroundColor: vars.$scale.color.blue50,
      color: vars.$scale.color.blue600,
      border: 'none',
    },
    outlined: {
      backgroundColor: 'transparent',
      color: vars.$scale.color.blue700,
      border: `1px solid ${vars.$scale.color.blue700}`,
    },
    isBold: {
      backgroundColor: vars.$semantic.color.accent,
      color: vars.$static.color.staticWhite,
      border: 'none',
    },
  },

  success: {
    default: {
      backgroundColor: vars.$semantic.color.successLow,
      color: vars.$scale.color.green700,
      border: 'none',
    },
    outlined: {
      backgroundColor: 'transparent',
      color: vars.$semantic.color.success,
      border: `1px solid ${vars.$semantic.color.success}`,
    },
    isBold: {
      backgroundColor: vars.$semantic.color.success,
      color: vars.$static.color.staticWhite,
      border: 'none',
    },
  },

  primary: {
    default: {
      backgroundColor: vars.$scale.color.carrotAlpha100,
      color: vars.$semantic.color.primary,
      border: 'none',
    },
    outlined: {
      backgroundColor: 'transparent',
      color: vars.$semantic.color.primary,
      border: `1px solid ${vars.$semantic.color.primary}`,
    },
    isBold: {
      backgroundColor: vars.$semantic.color.primary,
      color: vars.$static.color.staticWhite,
      border: 'none',
    },
  },

  error: {
    default: {
      backgroundColor: vars.$semantic.color.dangerLow,
      color: vars.$semantic.color.danger,
      border: 'none',
    },
    outlined: {
      backgroundColor: 'transparent',
      color: vars.$semantic.color.danger,
      border: `1px solid ${vars.$semantic.color.danger}`,
    },
    isBold: {
      backgroundColor: vars.$semantic.color.danger,
      color: vars.$static.color.staticWhite,
      border: 'none',
    },
  },
}

export const getTypeOptionToken = (type: BADGE_TYPE = 'basic', option: BADGE_OPTION = 'default') => css`
  ${TYPE_OPTION_TOKEN[type][option]};
`

export const getSizeOptionToken = (size: BADGE_SIZE = 'medium', option: BADGE_OPTION = 'default') => {
  const isBold = option !== 'default'

  switch (size) {
    case 'large':
      return css`
        padding: 0.25rem 0.5rem;
        ${vars.$semantic.typography.label4Regular};
        border-radius: 0.25rem;
        font-weight: ${isBold ? 700 : 400};
      `
    case 'medium':
      return css`
        padding: 0.125rem 0.375rem;
        ${vars.$semantic.typography.label5Regular};
        border-radius: 0.125rem;
        font-weight: ${isBold ? 700 : 400};
      `
    case 'small':
      return css`
        padding: 0.125rem 0.25rem;
        ${vars.$semantic.typography.label6Regular};
        border-radius: 0.125rem;
        font-weight: ${isBold ? 700 : 400};
      `
  }
}
