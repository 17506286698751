import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import React, { useRef } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { graphql, useFragment } from 'react-relay'
import { __, match } from 'ts-pattern'

import useAlertModal from '@src/components/_modals/useAlertModal'

import { type HomeReview_bizAccountReview$key } from '../../../../__relay__/HomeReview_bizAccountReview.graphql'
import { BIZ_PLATFORM_APP } from '../../../../constants'
import { useLogger } from '../../../../hooks/useLogger'
import { useViewer } from '../../../../hooks/useViewer'
import { useOpenTarget } from '../../../../lib/target'
import { distanceToUnion } from '../../../../lib/union'
import { useImpression } from '../../../_lib/Impression'
import { getEllipsisStyle } from '../../../_lib/styles'

interface HomeReviewProps {
  bizAccountReview: HomeReview_bizAccountReview$key
  itemOrder: number
  sectionOrder: number
  sectionLoggingMeta: {
    swipe: string
    click: string
  } // todo. 모든 아이템 sectionLoggingMeta 밖으로 꺼내기
  itemScrollContainerRef: React.RefObject<HTMLDivElement>
  onImpression: (itemOrder: number) => void
}
const HomeReview: React.FC<HomeReviewProps> = (props) => {
  const viewer = useViewer()
  const { contentsSectionLog, loggingMetaLog } = useLogger()
  const { openTarget } = useOpenTarget()
  const itemRef = useRef<HTMLDivElement>(null)
  const { openLoginAlertModal } = useAlertModal()

  const bizAccountReview = useFragment(
    graphql`
      fragment HomeReview_bizAccountReview on BizAccountReview {
        externalId
        bizAccount {
          image {
            url
          }
          images {
            url
          }
          name
          region {
            name3
          }
          description
          reviewCount
          followerCount
          externalId
        }
        user {
          nickname
        }
        content
        distance {
          distance
          name
        }
        bizDeliveryLoggingMeta {
          click
          impression
        }
      }
    `,
    props.bizAccountReview
  )

  const renderImages = () => {
    const images =
      bizAccountReview.bizAccount?.images.length === 0
        ? [bizAccountReview.bizAccount.image]
        : bizAccountReview.bizAccount?.images

    if (!images) {
      return null
    }

    return images
      .filter((image) => !!image.url)
      .filter((_, i) => i < 2)
      .map((image) => image.url!)
      .map((imageUrl, i) => (
        <Image key={i}>
          <LazyLoadImage effect="opacity" height={96} src={imageUrl} visibleByDefault />
        </Image>
      ))
  }

  const onClick = () => {
    if (!viewer.user) {
      return openLoginAlertModal()
    }
    if (!bizAccountReview.bizAccount) {
      return
    }

    contentsSectionLog('click', {
      contentsType: 'review',
      buttonType: 'list',
      params: {
        name: 'click_review',
        review_id: bizAccountReview.externalId,
        biz_account_id: bizAccountReview.bizAccount.externalId,
        sectionOrder: props.sectionOrder,
      },
    })

    loggingMetaLog([props.sectionLoggingMeta.click, bizAccountReview.bizDeliveryLoggingMeta?.click])

    openTarget({
      remote: `${BIZ_PLATFORM_APP}/biz_accounts/${bizAccountReview.bizAccount.externalId}/viewer/home`,
      additionalQueryParams: {
        entry: 'nearby_review',
        tab: 'REVIEW',
        highlightReviewId: bizAccountReview.externalId,
      },
    })
  }

  // swipe log
  useImpression(
    {
      ref: itemRef,
      scrollContainerRef: props.itemScrollContainerRef,
      disableImpressionOnload: true,
    },
    () => {
      // loggingMetaLog(bizAccountReview.bizDeliveryLoggingMeta?.impression)

      props.onImpression(props.itemOrder)
    },
    [props.itemOrder, props.onImpression]
  )

  return (
    <React.Fragment>
      <Container onClick={onClick} ref={itemRef}>
        <Images>
          <CoverdOverlay />
          {renderImages()}
        </Images>
        <Padding>
          <Titles>
            <Title>{bizAccountReview.bizAccount?.name}</Title>
          </Titles>
          <Description>{bizAccountReview.bizAccount?.description}</Description>
          <Captions>
            {bizAccountReview.distance &&
              match(distanceToUnion(bizAccountReview.distance))
                .with({ _TAG: 'name' }, ({ name }) => <Caption>{name}</Caption>)
                .with({ _TAG: 'm' }, ({ m }) => <Caption>{`${m}m`}</Caption>)
                .with({ _TAG: 'km' }, ({ km }) => <Caption> {`${km}km`}</Caption>)
                .with(__, () => null)
                .exhaustive()}
            {!!bizAccountReview.bizAccount.reviewCount && (
              <Caption>후기 {bizAccountReview.bizAccount.reviewCount}</Caption>
            )}
            {!!bizAccountReview.bizAccount.followerCount && (
              <Caption>단골 {bizAccountReview.bizAccount.followerCount}</Caption>
            )}
          </Captions>
          <ReviewContainer>
            <ReviewContent>
              <ReviewAuthorName>{bizAccountReview.user?.nickname}</ReviewAuthorName>
              {bizAccountReview.content}
            </ReviewContent>
          </ReviewContainer>
        </Padding>
      </Container>
    </React.Fragment>
  )
}

const Container = styled.div`
  display: inline-block;
  width: 18rem;
  border: 1px solid ${vars.$semantic.color.divider1};
  box-sizing: border-box;
  border-radius: 0.375rem;
  vertical-align: top;
`

const Images = styled.div`
  position: relative;
  display: flex;
  height: 6rem;
  border-radius: 0.375rem 0.375rem 0 0;
  transform: translate3d(0, 0, 0);
  mask-image: -webkit-radial-gradient(white, black);
`

const Image = styled.div`
  background-color: ${vars.$scale.color.gray100};
  margin-right: 0.125rem;
  flex: 1;

  &:last-of-type {
    margin-right: 0;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const CoverdOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
`

const Padding = styled.div`
  padding: 0.75rem;
  width: 100%;
  box-sizing: border-box;
`

const Titles = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.1875rem;
`

const Title = styled.div`
  font-size: 0.875rem;
  font-weight: bold;
  margin-right: 0.25rem;
  color: ${vars.$scale.color.gray900};

  ${getEllipsisStyle(1)};
`

const Description = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.875rem;
  color: ${vars.$scale.color.gray900};
`

const Captions = styled.div`
  display: flex;
  margin-top: 0.25rem;
  line-height: 100%;
  margin-bottom: 0.875rem;
  width: 100%;
`

const Caption = styled.span`
  font-size: 0.75rem;
  line-height: 100%;
  color: ${vars.$scale.color.gray600};

  flex-shrink: 0;

  &:first-of-type {
    ${getEllipsisStyle(1)};

    &::before {
      display: none;
    }
  }

  &::before {
    content: '·';
    color: ${vars.$scale.color.gray900};
    margin: 0 0.25rem;
  }
`

const ReviewContainer = styled.div`
  background-color: ${vars.$semantic.color.paperContents};
  border-radius: 0.5rem;
  padding: 0.75rem;
  margin: 0 -0.125rem -0.125rem;
`

const ReviewAuthorName = styled.span`
  font-weight: bold;
  margin-right: 0.3em;
`

const ReviewContent = styled.span`
  white-space: normal;
  font-size: 0.875rem;
  color: ${vars.$scale.color.gray900};

  display: -webkit-box;
  -webkit-line-clamp: 2;
  visibility: visible;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
`

export default HomeReview
