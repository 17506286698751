import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { IconChevronRightRegular } from '@seed-design/icon'
import React from 'react'
import { graphql, useFragment } from 'react-relay'

import { type HomePopularServiceFieldBasicListItem_ExpertServiceField$key } from '@src/__relay__/HomePopularServiceFieldBasicListItem_ExpertServiceField.graphql'
import CoveredLazyLoadImage from '@src/components/_lib/CoveredLazyLoadImage'
import { getExpertDirectorySchemeFromServiceFieldId } from '@src/lib/scheme-util'
import { numberWithComma } from '@src/lib/unit'

interface Props {
  expertServiceFieldRef: HomePopularServiceFieldBasicListItem_ExpertServiceField$key
  rank: number
  onClickItem: (serviceField: string) => void
}

const HomePopularServiceFieldBasicListItem = (props: Props) => {
  const { externalId, imageUrl, name, lastWeekVisitCount } = useFragment(
    graphql`
      fragment HomePopularServiceFieldBasicListItem_ExpertServiceField on ExpertServiceField {
        externalId
        imageUrl
        name
        lastWeekVisitCount
      }
    `,
    props.expertServiceFieldRef
  )
  const handleClick = () => {
    props.onClickItem(externalId)
    window.location.href = getExpertDirectorySchemeFromServiceFieldId(externalId, 'nearby.home')
  }
  return (
    <Base type="button" onClick={handleClick}>
      <Rank>{props.rank}</Rank>

      <Content>
        <LazyLoadImage insideStrokeDisabled isLazy src={imageUrl ?? ''} alt={`${name} 사진`} />
        <ContentTextWrapper>
          <ServiceName>{name}</ServiceName>
          {Boolean(lastWeekVisitCount) && <ServiceUsedCount>{numberWithComma(lastWeekVisitCount!)}명</ServiceUsedCount>}
        </ContentTextWrapper>
      </Content>

      <ChevronWrapper>
        <IconChevronRightRegular width={16} height={16} color="currentColor" />
      </ChevronWrapper>
    </Base>
  )
}

const Base = styled.button`
  padding: 0.875rem 1rem;
  overflow: hidden;
  background-color: ${vars.$semantic.color.paperDefault};
  display: flex;
  align-items: center;
  justify-content: start;
  width: 100%;
  &:active {
    background-color: ${vars.$semantic.color.grayHover};
  }
`
const Rank = styled.b`
  color: ${vars.$scale.color.gray900};
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 135%; /* 21.6px */
`
const Content = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  flex: 1;
  overflow: hidden;
  margin: 0 1rem;
`
const LazyLoadImage = styled(CoveredLazyLoadImage)`
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 0.375rem;
`
const ContentTextWrapper = styled.div`
  flex: 1;
  margin-left: 0.625rem;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
`
const ServiceName = styled.span`
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
  color: ${vars.$scale.color.gray900};
`
const ServiceUsedCount = styled.span`
  margin-top: 0.125rem;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 19.5px */
  color: ${vars.$scale.color.gray600};
`
const ChevronWrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${vars.$scale.color.gray900};
`

export default HomePopularServiceFieldBasicListItem
