import React, { createContext, Suspense, useContext, useMemo } from 'react'

import { type BridgeUserInfo, type BridgeRegionInfo } from '@src/bridgeInfo'

export interface Viewer {
  user: BridgeUserInfo | null
  region: BridgeRegionInfo
}

const ViewerContext = createContext<Viewer>(null as any)

export const ViewerProvider: React.FCWithChildren<{ viewer: Viewer }> = ({ viewer, children }) => {
  return (
    <Suspense fallback={null}>
      <ViewerContext.Provider
        value={useMemo(
          () => ({
            user: viewer.user,
            region: viewer.region,
          }),
          [viewer.user, viewer.region]
        )}>
        {children}
      </ViewerContext.Provider>
    </Suspense>
  )
}

export function useViewer() {
  return useContext(ViewerContext)
}
