import { SnackbarProvider } from '@daangn/sprout-components-snackbar'
import { type StackflowReactPlugin } from '@stackflow/react'

import BottomSheetModalProvider from './BottomSheetProvider'

export function globalComponentPlugin(): StackflowReactPlugin {
  return () => ({
    key: 'nearbyGlobalComponent',
    wrapStack({ stack }) {
      return (
        <>
          <BottomSheetModalProvider>
            <SnackbarProvider offset="0.75rem">{stack.render()}</SnackbarProvider>
          </BottomSheetModalProvider>
        </>
      )
    },
  })
}
