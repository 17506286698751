/**
 * @generated SignedSource<<aba7c07e4de930858d4c172458e2ed42>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HomeRequestReviewRefetchQuery$variables = {
  id: string;
};
export type HomeRequestReviewRefetchQuery$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"HomeRequestReview_sectionRequestReview">;
  } | null;
};
export type HomeRequestReviewRefetchQuery = {
  response: HomeRequestReviewRefetchQuery$data;
  variables: HomeRequestReviewRefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "externalId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "url",
    "storageKey": null
  },
  (v3/*: any*/)
],
v7 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name3",
    "storageKey": null
  },
  (v3/*: any*/)
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "targetUri",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "HomeRequestReviewRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "HomeRequestReview_sectionRequestReview"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "HomeRequestReviewRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BizReviewTarget",
                "kind": "LinkedField",
                "name": "bizReviewTargets",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "bizStore",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v4/*: any*/),
                          (v5/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Image",
                            "kind": "LinkedField",
                            "name": "image",
                            "plural": false,
                            "selections": (v6/*: any*/),
                            "storageKey": null
                          },
                          {
                            "alias": "regionBizAccount",
                            "args": null,
                            "concreteType": "Region",
                            "kind": "LinkedField",
                            "name": "region",
                            "plural": false,
                            "selections": (v7/*: any*/),
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "categoryId",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "categoryName",
                            "storageKey": null
                          },
                          (v8/*: any*/)
                        ],
                        "type": "BizAccount",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v4/*: any*/),
                          (v5/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Image",
                            "kind": "LinkedField",
                            "name": "images",
                            "plural": true,
                            "selections": (v6/*: any*/),
                            "storageKey": null
                          },
                          {
                            "alias": "regionPoi",
                            "args": null,
                            "concreteType": "Region",
                            "kind": "LinkedField",
                            "name": "region",
                            "plural": false,
                            "selections": (v7/*: any*/),
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "PoiCategory",
                            "kind": "LinkedField",
                            "name": "category",
                            "plural": false,
                            "selections": [
                              (v4/*: any*/),
                              (v5/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v8/*: any*/)
                        ],
                        "type": "Poi",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v3/*: any*/)
                        ],
                        "type": "Node",
                        "abstractKey": "__isNode"
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "tier",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BaseInfo",
                    "kind": "LinkedField",
                    "name": "baseInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "title",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "subTitle",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ViewMoreInfo",
                        "kind": "LinkedField",
                        "name": "viewMoreInfo",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "type",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "label",
                            "storageKey": null
                          },
                          (v8/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isNew",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "type": "SectionBaseInfo",
                "abstractKey": "__isSectionBaseInfo"
              }
            ],
            "type": "SectionRequestReview",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1b6f0e46448d06f2a85adb22142b152f",
    "id": null,
    "metadata": {},
    "name": "HomeRequestReviewRefetchQuery",
    "operationKind": "query",
    "text": "query HomeRequestReviewRefetchQuery(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...HomeRequestReview_sectionRequestReview\n    id\n  }\n}\n\nfragment HomeRequestReview_sectionRequestReview on SectionRequestReview {\n  __typename\n  bizReviewTargets {\n    __typename\n    bizStore {\n      __typename\n      ... on BizAccount {\n        externalId\n        name\n        image {\n          url\n          id\n        }\n        regionBizAccount: region {\n          name3\n          id\n        }\n        categoryId\n        categoryName\n        targetUri\n      }\n      ... on Poi {\n        externalId\n        name\n        images {\n          url\n          id\n        }\n        regionPoi: region {\n          name3\n          id\n        }\n        category {\n          externalId\n          name\n        }\n        targetUri\n      }\n      ... on Node {\n        __isNode: __typename\n        id\n      }\n    }\n    tier\n  }\n  ...HomeSectionBase_sectionBase\n  id\n}\n\nfragment HomeSectionBase_sectionBase on SectionBaseInfo {\n  __isSectionBaseInfo: __typename\n  ...useSectionBase_sectionBase\n}\n\nfragment useSectionBase_sectionBase on SectionBaseInfo {\n  __isSectionBaseInfo: __typename\n  baseInfo {\n    title\n    subTitle\n    viewMoreInfo {\n      type\n      label\n      targetUri\n    }\n    isNew\n  }\n}\n"
  }
};
})();

(node as any).hash = "e410ed6246ded1b8219537a86d40dbef";

export default node;
