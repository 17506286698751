import React, { createContext, useContext, useMemo, useState } from 'react'

const ImpressedSectionOrderDispatchContext = createContext<React.Dispatch<React.SetStateAction<number>>>(null as any)

const ImpressedSectionOrderContext = createContext<{
  currentImpressedSectionOrder: number
  setCurrentImpressedSectionOrder: React.Dispatch<React.SetStateAction<number>>
}>({
  currentImpressedSectionOrder: -1,
  setCurrentImpressedSectionOrder: null as any,
})

// eslint-disable-next-line @typescript-eslint/ban-types
export const ImpressedSectionOrderContextProvider: React.FCWithChildren<{}> = ({ children }) => {
  const [currentImpressedSectionOrder, setCurrentImpressedSectionOrder] = useState(-1)

  const value = useMemo(
    () => ({
      currentImpressedSectionOrder,
      setCurrentImpressedSectionOrder,
    }),
    [currentImpressedSectionOrder]
  )

  return (
    <ImpressedSectionOrderContext.Provider value={value}>
      <ImpressedSectionOrderDispatchContext.Provider value={setCurrentImpressedSectionOrder}>
        {children}
      </ImpressedSectionOrderDispatchContext.Provider>
    </ImpressedSectionOrderContext.Provider>
  )
}

export const useImpressedSectionOrder = () => {
  return useContext(ImpressedSectionOrderContext)
}

export const useImpressedSectionOrderDisatch = () => {
  return useContext(ImpressedSectionOrderDispatchContext)
}
