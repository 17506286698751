import * as Sentry from '@sentry/react'

export function captureException(error?: Error | null) {
  if (error?.message === 'Network Error') {
    return
  }
  if (error?.message === 'Network request failed') {
    return
  }
  if (error) {
    Sentry.captureException(error)
  }
}
