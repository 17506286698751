import { usePullToRefreshScrollElement } from '@daangn/karrot-clothes/lib/pullToRefresh'
import styled from '@emotion/styled'
import { chunk } from 'lodash'
import { type FC, useCallback, useMemo, useRef } from 'react'
import { graphql, useRefetchableFragment } from 'react-relay'

import BizAccountListItem from '@src/components/CategoryHome/CategoryListItem/BizAccountListItem'
import { usePageVisibleChange } from '@src/hooks/usePageVisibleChange'

import { type HomeNearbyExerciseBizAccount_sectionNearbyExerciseBizAccount$key } from '../../../../__relay__/HomeNearbyExerciseBizAccount_sectionNearbyExerciseBizAccount.graphql'
import { useLogger } from '../../../../hooks/useLogger'
import { type PropOf } from '../../../../lib/T/utilTypes'
import { useOpenTarget } from '../../../../lib/target'
import type { SectionInfo } from '../../../../types'
import { Divider } from '../../../_lib/ListSection/Divider'
import { ListEntry } from '../../../_lib/ListSection/ListEntry'
import CarouselContent from '../Common/CarouselContent'
import HomeSectionBase from '../Common/HomeSectionBase'

interface HomeNearbyExerciseBizAccountProps {
  sectionNearbyFitnessBizAccount: HomeNearbyExerciseBizAccount_sectionNearbyExerciseBizAccount$key
  sectionInfo: SectionInfo
}

const VIEW_ITEM_COUNT = 3

const HomeNearbyExerciseBizAccount: FC<HomeNearbyExerciseBizAccountProps> = ({ sectionInfo, ...props }) => {
  const [section, refetch] = useRefetchableFragment(
    graphql`
      fragment HomeNearbyExerciseBizAccount_sectionNearbyExerciseBizAccount on SectionNearbyExerciseBizAccount
      @refetchable(queryName: "HomeNearbyExerciseBizAccountRefetchQuery") {
        __typename
        loggingMeta {
          swipe
          click
          show
        }
        bizAccounts {
          id
          categoryName
          ...BizAccountListItem_BizAccount
        }
        baseInfo {
          viewMoreInfo {
            targetUri
            label
          }
        }
        ...HomeSectionBase_sectionBase
      }
    `,
    props.sectionNearbyFitnessBizAccount
  )

  const handleRefresh = useCallback(() => {
    refetch({}, { fetchPolicy: 'store-and-network' })
  }, [refetch])

  usePageVisibleChange((isVisible) => isVisible && handleRefresh())

  const scrollContainerRef = usePullToRefreshScrollElement()
  const { nearbyLog, contentsSectionLog, loggingMetaLog } = useLogger()
  const { openTarget } = useOpenTarget()
  const pageOffset = useRef<number>(0)

  const handleImpression = () => {
    contentsSectionLog('show', {
      contentsType: 'nearby_fitness',
      sectionOrder: sectionInfo.order,
    })

    if (sectionInfo.order === 0) {
      loggingMetaLog(section.loggingMeta.show)
    }
  }

  const handleMoreClick: NonNullable<PropOf<typeof HomeSectionBase, 'onMoreClick'>> = ({ targetUri }) => {
    contentsSectionLog('click', {
      contentsType: 'nearby_fitness',
      buttonType: 'more',
      params: {
        sectionOrder: sectionInfo.order,
      },
    })

    loggingMetaLog(section.loggingMeta.click)

    if (targetUri) {
      openTarget({
        targetUri,
      })
    }
  }

  const handleCarouselListSwipe: PropOf<typeof CarouselContent, 'onListSwipe'> = useCallback(
    (offset) => {
      nearbyLog({
        params: {
          name: 'swipe_contents_section',
          contentsType: 'nearby_fitness',
          page: offset,
          sectionOrder: sectionInfo.order,
        },
      })

      loggingMetaLog(section.loggingMeta.swipe)

      pageOffset.current = offset
    },
    [loggingMetaLog, nearbyLog, sectionInfo.order, section.loggingMeta.swipe]
  )

  const handleBizAccountItemClick: PropOf<typeof BizAccountListItem, 'onClick'> = useCallback(
    ({ externalId, tags, targetUri }) => {
      contentsSectionLog('click', {
        contentsType: 'nearby_fitness',
        buttonType: 'list',
        params: {
          bizaccount_id: externalId,
          sectionOrder: sectionInfo.order,
          page: pageOffset.current,
          ...(tags && tags.length > 0 ? { badges: tags.join(',') } : null),
        },
      })

      loggingMetaLog(section.loggingMeta.click)

      if (targetUri) {
        openTarget({
          targetUri,
        })
      }
    },
    [contentsSectionLog, loggingMetaLog, openTarget, sectionInfo.order, section.loggingMeta.click]
  )

  const handleWatchBizAccount = useCallback(
    ({ id, badges }: { id: string; badges?: string[] }) => {
      contentsSectionLog('click', {
        contentsType: 'nearby_fitness',
        buttonType: 'watch',
        params: {
          bizaccount_id: id,
          page: pageOffset.current,
          ...(badges && badges.length > 0 ? { badges: badges.join(',') } : null),
        },
      })
    },
    [contentsSectionLog]
  )

  const bizPostItems = useMemo(
    () =>
      chunk(section.bizAccounts, VIEW_ITEM_COUNT).map((bizAccounts, index) => (
        <ListContainer key={index}>
          {bizAccounts.map((bizAccount, index) => (
            <div key={bizAccount.id}>
              <BizAccountListItem
                key={bizAccount.id}
                bizAccountRef={bizAccount}
                onClick={handleBizAccountItemClick}
                onClickWatch={handleWatchBizAccount}
                isFeaturedImage={false}
                itemType="bizprofile"
              />
              {index !== bizAccounts.length - 1 && <Divider margin="0 1rem" />}
            </div>
          ))}
        </ListContainer>
      )),
    [section.bizAccounts, handleBizAccountItemClick, handleWatchBizAccount]
  )

  return (
    <HomeSectionBase
      sectionBase={section}
      ImpressionProps={{
        scrollContainerRef,
        onImpression: handleImpression,
        threshold: 0.9,
      }}
      SectionTitleProps={{
        addPaddingBottom: '6px',
      }}
      onMoreClick={handleMoreClick}>
      <CarouselContent
        listItems={bizPostItems}
        onListSwipe={handleCarouselListSwipe}
        sectionOrder={sectionInfo.order}
      />
    </HomeSectionBase>
  )
}

const ListContainer = styled(ListEntry)`
  padding: 0;
`

export default HomeNearbyExerciseBizAccount
