import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { type FC } from 'react'

import { getEllipsisString } from '@src/lib/unit'

import PurposeCategoryLaunchIcon from './PurposeCategoryLaunchIcon'
import BadgeNewIcon from '../../../../assets/images/icons/BadgeNewIcon'
import { FlexCenter, noneActiveEffect } from '../../../_lib/styles'
import { TextWithDot } from '../../../_lib/styles/util'

interface PurposeLaunchpadItemProps {
  id?: string
  onClick: () => void
  iconUrl: string | null
  IconElement?: React.ReactNode
  name: string
  isNew?: boolean
  className?: string
}

const PurposeLaunchpadItem: FC<PurposeLaunchpadItemProps> = ({
  id,
  onClick,
  iconUrl,
  IconElement,
  name,
  isNew,
  className,
}) => {
  return (
    <S_PurposeCategoryWrapper>
      <S_PurposeCategory id={id} className={className} onClick={onClick}>
        <PurposeCategoryContent>
          {IconElement ? (
            IconElement
          ) : (
            <S_PurposeCategoryIconWrapper>
              {isNew && <BadgeNew />}
              <PurposeCategoryLaunchIcon src={iconUrl} alt={name} />
            </S_PurposeCategoryIconWrapper>
          )}
          <S_Label>{TextWithDot(getEllipsisString(name, 7))}</S_Label>
        </PurposeCategoryContent>
      </S_PurposeCategory>
    </S_PurposeCategoryWrapper>
  )
}

export default PurposeLaunchpadItem

export const S_PurposeCategoryIconWrapper = styled.div`
  position: relative;
  ${FlexCenter};
  width: 1.75rem;
  height: 1.75rem;
  border-radius: 50%;
  transition: background-color 300ms;
  box-sizing: content-box;
`

export const S_Label = styled.div`
  padding: 0.375rem 0 0;
  width: 100%;
  text-align: center;
  word-wrap: break-word;
  word-break: keep-all;
  white-space: normal;
  color: ${vars.$scale.color.gray900};
  ${vars.$semantic.typography.caption2Regular};
`

export const S_PurposeCategoryWrapper = styled.div`
  ${FlexCenter};
  ${noneActiveEffect};
  overflow: hidden;
  padding-top: 1.5rem;
  &:nth-child(-n + 4) {
    padding-top: 0.75rem;
  }
`
export const S_PurposeCategory = styled.div`
  ${FlexCenter};
  ${noneActiveEffect};
  position: relative;
`

const PurposeCategoryContent = styled.div`
  ${FlexCenter};
  flex-direction: column;
  width: 78px;
`

const BadgeNew: React.FC = () => {
  return (
    <BadgeContainer>
      <BadgeNewIcon />
    </BadgeContainer>
  )
}

const BadgeContainer = styled.div`
  top: -0.625rem;
  left: 50%;
  transform: translate(0.875rem);
  position: absolute;
  width: 1rem;
  height: 1rem;
  display: flex;
`
