import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import React, { useRef, useState } from 'react'
import { graphql, useFragment } from 'react-relay'
import { match } from 'ts-pattern'

import IcCheck from '@src/assets/images/icons/IcCheck'
import IcCouponDownload from '@src/assets/images/icons/IcCouponDownload'
import { Avatar } from '@src/components/_lib/Avatar'
import CoveredLazyLoadImage from '@src/components/_lib/CoveredLazyLoadImage'
import useAlertModal from '@src/components/_modals/useAlertModal'
import { BIZ_PROFILE_THUMBNAIL_PLACEHOLDER } from '@src/constants/bizProfile'
import { getDateFormatWithPad } from '@src/lib/date/date'
import { getEllipsisString } from '@src/lib/unit'

import { useCouponMutations } from './useCouponMutations'
import { type HomeCoupon_couponBox$key } from '../../../../__relay__/HomeCoupon_couponBox.graphql'
import { useLogger } from '../../../../hooks/useLogger'
import { asyncMutate } from '../../../../lib/mutation/mutation'
import { useOpenTarget } from '../../../../lib/target'
import { useLocalBusinessLogEvent } from '../../../../stackflow/customPlugins/localBusinessAnalytics'
import type { SectionTypeEnum } from '../../../../types/schemaEnums'
import { useImpression } from '../../../_lib/Impression'
import Spinner from '../../../_lib/Spinner'
import { DotSeparator, FlexAlignCenter, FlexCenter, getEllipsisStyle } from '../../../_lib/styles'

export type { HomeCoupon_couponBox$data } from '../../../../__relay__/HomeCoupon_couponBox.graphql'

interface HomeCouponProps {
  couponBox: HomeCoupon_couponBox$key
  itemOrder: number
  sectionOrder: number
  sectionLoggingMeta: {
    swipe: string
    click: string
  }
  itemScrollContainerRef: React.RefObject<HTMLDivElement>
  onImpression: (itemOrder: number, bizId: number, isClaimed: boolean, couponBoxId: string) => void
  referrer: string
}

const HomeCoupon: React.FC<HomeCouponProps> = ({ referrer, ...props }) => {
  const couponBox = useFragment(
    graphql`
      fragment HomeCoupon_couponBox on CouponBox {
        id
        externalId
        isBrand
        image {
          thumbnail
          url
        }
        coverImage {
          url
        }
        remainCount
        name
        bizAccount {
          id
          externalId
          categoryName
          name
          images {
            url
          }
        }
        expiredAt
        couponType
        couponEventType
        targetUri
        viewerHasClaimed
        userStatus
        totalClaimedUserCount

        bizDeliveryLoggingMeta {
          click
          impression
        }
      }
    `,
    props.couponBox
  )

  const [claiming, setClaiming] = useState(false)
  const itemRef = useRef<HTMLDivElement>(null)
  const { contentsSectionLog, loggingMetaLog } = useLogger()
  const { openTarget } = useOpenTarget()
  const reqLogEvent = useLocalBusinessLogEvent()
  const sectionType: SectionTypeEnum = 'COUPON'

  const { commitClaimCoupon, commitFollowBizAccount } = useCouponMutations()

  const { openAlertModal } = useAlertModal()

  const isClaimedCoupon =
    couponBox.userStatus === 'CAN_USE' ||
    couponBox.userStatus === 'IS_USED' ||
    couponBox.userStatus === 'CLAIMED_BUT_EXPIRED' ||
    couponBox.viewerHasClaimed

  const handleCouponClick = () => {
    contentsSectionLog('click', {
      contentsType: 'coupon',
      buttonType: 'list',
      params: {
        name: 'click_coupon',
        biz_account_id: couponBox.bizAccount.externalId,
        coupon_id: couponBox.externalId,
        sectionOrder: props.sectionOrder,
        is_brand_coupon: String(couponBox.isBrand),
      },
    })

    loggingMetaLog([props.sectionLoggingMeta.click, couponBox.bizDeliveryLoggingMeta?.click])

    openTarget({
      targetUri: couponBox.targetUri,
      additionalQueryParams: {
        referrer,
      },
    })
  }

  const handleCouponDownloadClick: React.MouseEventHandler = async (e) => {
    e.stopPropagation()

    contentsSectionLog('click', {
      contentsType: 'coupon',
      buttonType: 'list',
      params: {
        name: 'click_claim_coupon',
        biz_account_id: couponBox.bizAccount.externalId,
        coupon_id: couponBox.externalId,
        sectionOrder: props.sectionOrder,
      },
    })

    // 지사실 이벤트 로그
    reqLogEvent({
      name: 'click_claim_coupon',
      params: {
        coupon_id: Number(couponBox.externalId),
        biz_id: Number(couponBox.bizAccount.externalId),
        coupon_type: couponBox.couponType,
        coupon_event_type: couponBox.couponEventType,
        distance_tag_visible_flag: null,
        referrer,
      },
    })

    loggingMetaLog([props.sectionLoggingMeta.click, couponBox.bizDeliveryLoggingMeta?.click])

    if (!claiming && !isClaimedCoupon) {
      setClaiming(true)

      try {
        const { claimToCouponBox } = await asyncMutate(commitClaimCoupon, {
          variables: {
            input: {
              couponBoxId: couponBox.id,
            },
          },
        })

        setClaiming(false)

        switch (claimToCouponBox.__typename) {
          case 'ClaimToCouponBoxOutput_Result': {
            return
          }
          case 'PopupError': {
            openAlertModal({
              buttonDirection: 'vertical',
              message: claimToCouponBox.popupError.message,
              buttons: claimToCouponBox.popupError.buttons.map((button) => ({
                text: button.text,
                variant: match(button.variant)
                  .with('PRIMARY', () => 'CTA' as const)
                  .with('SECONDARY', () => 'CANCEL' as const)
                  .exhaustive(),
                onClick: async (closeModal) => {
                  const buttonText = button.text
                  try {
                    if (buttonText.includes('단골') && buttonText.includes('받기')) {
                      const { createFollowBizAccount } = await asyncMutate(commitFollowBizAccount, {
                        variables: {
                          input: {
                            bizAccountNodeId: couponBox.bizAccount.id,
                          },
                        },
                      })

                      if (createFollowBizAccount) {
                        await asyncMutate(commitClaimCoupon, {
                          variables: {
                            input: {
                              couponBoxId: couponBox.id,
                            },
                          },
                        })
                      }
                    }
                  } finally {
                    if (button.redirectTargetUri) {
                      openTarget({
                        targetUri: button.redirectTargetUri,
                        additionalQueryParams: {
                          referrer,
                        },
                      })
                      closeModal()
                    } else {
                      closeModal()
                    }
                  }
                },
              })),
            })
            return
          }
          case 'ToastError': {
            openAlertModal({
              message: claimToCouponBox.toastError.message,
            })
            return
          }
        }
      } catch (err) {
        setClaiming(false)
      }
    }
  }

  // swipe log
  useImpression(
    {
      ref: itemRef,
      scrollContainerRef: props.itemScrollContainerRef,
      disableImpressionOnload: true,
      threshold: 0.7,
    },
    () => {
      props.onImpression(
        props.itemOrder,
        Number(couponBox.bizAccount.externalId),
        couponBox.userStatus === 'CAN_USE',
        couponBox.externalId
      )
    },
    [props.itemOrder, props.onImpression]
  )

  useImpression(
    {
      ref: itemRef,
      scrollContainerRef: props.itemScrollContainerRef,
    },
    () => {
      loggingMetaLog(couponBox.bizDeliveryLoggingMeta?.impression)
    },
    [couponBox.bizDeliveryLoggingMeta?.impression, loggingMetaLog]
  )

  const coverImageUrl =
    (couponBox.isBrand ? couponBox.coverImage?.url : null) ??
    couponBox.bizAccount.images[0].url ??
    BIZ_PROFILE_THUMBNAIL_PLACEHOLDER
  const expiryDate = couponBox.expiredAt ? getDateFormatWithPad(new Date(couponBox.expiredAt)) : null

  return (
    <Container ref={itemRef} id={props.itemOrder === 0 && couponBox.isBrand ? `${sectionType}_Brand` : undefined}>
      <Card onClick={handleCouponClick}>
        <S_CoveredLazyLoadImage src={coverImageUrl} insideStrokeDisabled></S_CoveredLazyLoadImage>
        <CardContent>
          <CardLabels>
            <CardSubtitle>
              <AvatarWrapper>
                <Avatar src={couponBox.image.url} alt={couponBox.bizAccount.name} size="xsmall" type="Biz" />
              </AvatarWrapper>

              {<CardSubtitleName>{getEllipsisString(couponBox.bizAccount.name, 8)}</CardSubtitleName>}
              {couponBox.isBrand ? (
                <BrandMarkerWrapper>
                  <IcBrand />
                </BrandMarkerWrapper>
              ) : (
                <CardSubtitleCategory>{couponBox.bizAccount.categoryName}</CardSubtitleCategory>
              )}
            </CardSubtitle>
            <CardTitle>{couponBox.name}</CardTitle>

            <CouponExtraInfoWrapper>
              {couponBox.remainCount !== null && couponBox.remainCount <= 10 && (
                <ExtraInfo bold>{couponBox.remainCount}장 남음</ExtraInfo>
              )}
              {expiryDate && (
                <ExtraInfo>
                  {expiryDate.year}.{expiryDate.month}.{expiryDate.date}까지
                </ExtraInfo>
              )}
            </CouponExtraInfoWrapper>
          </CardLabels>
          {claiming && (
            <CardDownloadButton viewerHasClaimed>
              <Spinner size={14} />
            </CardDownloadButton>
          )}
          {!claiming && (
            <CardDownloadButton viewerHasClaimed={isClaimedCoupon} onClick={handleCouponDownloadClick}>
              {isClaimedCoupon ? <IcCheck size={15} /> : <IcCouponDownload />}
              {isClaimedCoupon ? '받은 쿠폰' : '쿠폰 받기'}
            </CardDownloadButton>
          )}
        </CardContent>
      </Card>
    </Container>
  )
}

const Container = styled.div`
  display: inline-block;
  vertical-align: top;
  width: 13.125rem;
  background-color: ${vars.$semantic.color.paperDefault};
`

const Card = styled.div`
  position: relative;
  border: 1px solid ${vars.$semantic.color.divider1};
  box-sizing: border-box;
  border-radius: 0.5rem;
  color: ${vars.$scale.color.gray900};
`

const S_CoveredLazyLoadImage = styled(CoveredLazyLoadImage)`
  height: 7.625rem;
  border-radius: 0.5rem 0.5rem 0 0;
  img {
    background-size: cover;
    background-position: 50% 50%;
  }
`

const AvatarWrapper = styled.div`
  ${FlexAlignCenter};
  height: 100%;
  flex-shrink: 0;
`

const CardContent = styled.div`
  padding: 0.875rem 1rem;
  position: relative;
  z-index: 1;

  & > *:not(:last-child) {
    margin-bottom: 0.5rem;
  }
`

const CardLabels = styled.div`
  color: ${vars.$scale.color.gray900};
`

const CardSubtitle = styled.div`
  ${FlexAlignCenter};
  ${vars.$semantic.typography.caption1Regular};
  color: ${vars.$scale.color.gray900};
  width: 100%;
  vertical-align: top;

  & > *:not(:last-child) {
    margin-right: 0.25rem;
  }
`

const CardSubtitleName = styled.span`
  ${vars.$semantic.typography.caption1Bold}
`

const CardSubtitleCategory = styled.span`
  display: inline-block;
  color: ${vars.$scale.color.gray600};
  ${getEllipsisStyle(1)};
`

const BrandMarkerWrapper = styled.span`
  ${FlexCenter};
  width: 1rem;
  height: 1rem;
`

const CardTitle = styled.div`
  ${vars.$semantic.typography.subtitle1Bold};
  width: 100%;
  ${getEllipsisStyle(1)};
  margin-top: 0.625rem;
`

const CardDownloadButton = styled.div<{ viewerHasClaimed: boolean }>`
  ${FlexCenter};
  padding: 0.5313rem 0;
  ${vars.$semantic.typography.label4Bold};
  border-radius: 0.25rem;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  & > svg {
    margin-right: 0.25rem;
    margin-left: -0.25rem;
  }

  ${(props) =>
    !props.viewerHasClaimed &&
    css`
      background-color: ${vars.$semantic.color.primaryLow};
      color: ${vars.$semantic.color.primary};
    `}

  ${(props) =>
    props.viewerHasClaimed &&
    css`
      color: ${vars.$scale.color.gray500};
      background-color: ${vars.$scale.color.gray300};
      box-shadow: 0 0 0 1px ${vars.$scale.color.gray300};
    `}
`

const CouponExtraInfoWrapper = styled.div`
  margin-top: 0.125rem;
  line-height: 1rem;
`

const ExtraInfo = styled.span<{ bold?: boolean }>`
  color: ${vars.$scale.color.gray600};
  ${({ bold }) => (bold ? vars.$semantic.typography.caption2Bold : vars.$semantic.typography.caption2Regular)};
  ${DotSeparator};
`

export default HomeCoupon

const IcBrand = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.77683 2.6325C6.21435 1.85723 7.0456 1.33333 8.00016 1.33333C8.95471 1.33333 9.78594 1.85721 10.2235 2.63238C11.081 2.39364 12.0393 2.61105 12.7141 3.28591C13.3891 3.96091 13.6064 4.9191 13.3677 5.77666C14.1429 6.21418 14.6668 7.04543 14.6668 8C14.6668 8.95454 14.143 9.78577 13.3678 10.2233C13.6065 11.0808 13.3891 12.0391 12.7142 12.714C12.0392 13.389 11.0811 13.6063 10.2235 13.3675C9.78597 14.1428 8.95473 14.6667 8.00016 14.6667C7.04562 14.6667 6.21439 14.1428 5.77686 13.3676C4.91931 13.6064 3.96107 13.3889 3.28621 12.7141C2.61121 12.0391 2.39391 11.0809 2.63267 10.2233C1.8574 9.78581 1.3335 8.95456 1.3335 8C1.3335 7.04545 1.85737 6.21422 2.63255 5.77669C2.39381 4.91915 2.61122 3.9609 3.28608 3.28604C3.96108 2.61104 4.91927 2.39374 5.77683 2.6325ZM10.4305 5.77424C10.1755 5.59034 9.81964 5.648 9.63575 5.90302L7.47894 8.89398L6.32174 7.63615C6.10887 7.40477 5.74873 7.38976 5.51735 7.60263C5.28596 7.81551 5.27096 8.17565 5.48383 8.40703L7.11354 10.1785C7.22935 10.3043 7.39561 10.3715 7.56635 10.3613C7.73709 10.3511 7.89421 10.2647 7.99425 10.126L10.5593 6.56896C10.7432 6.31394 10.6855 5.95813 10.4305 5.77424Z"
      fill="#1AA174"
    />
  </svg>
)
