import { useMemo } from 'react'

import { Badge as BadgeComponent } from '@src/components/_lib/Badge'
interface Props {
  yearsOfResidency?: number
}
export const Badge = ({ yearsOfResidency }: Props) => {
  const tier = useMemo(() => {
    if (!yearsOfResidency) return
    if (yearsOfResidency >= 20) return 'top'
    if (yearsOfResidency >= 10) return 'middle'
    if (yearsOfResidency >= 5) return 'bottom'
    return
  }, [yearsOfResidency])

  if (!yearsOfResidency) return null
  const text = `${yearsOfResidency}년 거주`
  return <TopTierBadge tier={tier} text={text} />
}

const TopTierBadge = ({ tier, text }: { tier?: 'top' | 'middle' | 'bottom'; text: string }) => {
  switch (tier) {
    case 'top':
      return (
        <BadgeComponent shape="pill" type="bold" size="small" variant="primary">
          {text}
        </BadgeComponent>
      )
    case 'middle':
      return (
        <BadgeComponent shape="pill" type="normal" size="small" variant="primary">
          {text}
        </BadgeComponent>
      )
    case 'bottom':
      return (
        <BadgeComponent shape="pill" type="normal" size="small" variant="basic">
          {text}
        </BadgeComponent>
      )
    default:
      return null
  }
}
