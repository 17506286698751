/**
 * @generated SignedSource<<e99539cf52bdb9adbc2feb813f68f6f8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type usePoiWatch_poi$data = {
  readonly bizAccount: {
    readonly id: string;
    readonly viewerIsWatched: boolean;
  } | null;
  readonly id: string;
  readonly viewerIsWatched: boolean;
  readonly " $fragmentType": "usePoiWatch_poi";
};
export type usePoiWatch_poi$key = {
  readonly " $data"?: usePoiWatch_poi$data;
  readonly " $fragmentSpreads": FragmentRefs<"usePoiWatch_poi">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "usePoiWatch_poi"
};

(node as any).hash = "a16d3674dfc238bbaa834e4bb8ac4a03";

export default node;
