import { useActivityParams } from '@stackflow/react'
import { useEffect } from 'react'

import { LOCAL_MAP_URL } from '@src/constants'
import { karrotBridge } from '@src/sdk'

interface useLocalMapProps {
  isEmployee?: boolean
}

export function useLocalMapEffect({ isEmployee }: useLocalMapProps) {
  const { setToDefault } = useActivityParams<{ setToDefault: string }>()

  useEffect(() => {
    // 동네지도에서 내근처로 이동한 순간은 다시 이동 방지
    if (setToDefault) {
      return
    }

    if (isEmployee) {
      moveLocalMap(false)
    }
  }, [isEmployee, setToDefault])

  return {
    moveLocalMap,
  }
}

export function moveLocalMap(isDevMode = false) {
  karrotBridge.replaceRouter({
    router: {
      remote: `${LOCAL_MAP_URL}?isDevMode=${isDevMode}&setToDefault=true`,
      navbar: false,
    },
  })
}
