import React, { Suspense } from 'react'

type ReactComponent = keyof JSX.IntrinsicElements | React.JSXElementConstructor<any>

export default function withSuspense<TComponent extends ReactComponent>(
  Component: TComponent,
  FallbackComponent: ReactComponent
): React.FC<React.ComponentProps<TComponent>> {
  return (props) => {
    return (
      <Suspense fallback={<FallbackComponent {...props} />}>
        <Component {...props} />
      </Suspense>
    )
  }
}
