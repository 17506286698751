import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { graphql, useFragment } from 'react-relay'

import { type HomeNativeReviewItem_reviewNativeReview$key } from '@src/__relay__/HomeNativeReviewItem_reviewNativeReview.graphql'
import CoveredLazyLoadImage from '@src/components/_lib/CoveredLazyLoadImage'
import { POI_APP, POI_REMOTE_APP } from '@src/constants'
import { useLogger } from '@src/hooks/useLogger'
import { useOpenTarget } from '@src/lib/target'

import HomeNativeReviewItemPoi from './HomeNativeReviewItemPoi'
import HomeNativeReviewItemUser from './HomeNativeReviewItemUser'

interface HomeNativeReviewItemProps {
  reviewNativeReview: HomeNativeReviewItem_reviewNativeReview$key
}

const HomeNativeReviewItem: React.FC<HomeNativeReviewItemProps> = (props) => {
  const review = useFragment<HomeNativeReviewItem_reviewNativeReview$key>(
    graphql`
      fragment HomeNativeReviewItem_reviewNativeReview on NativeReview {
        id
        content
        externalId
        images {
          thumbnail
        }
        ...HomeNativeReviewItemUser_userProfileNativeReview
        poi {
          ...HomeNativeReviewItemPoi_poiNativeReview
        }
      }
    `,
    props.reviewNativeReview
  )

  const { content, externalId, images, poi, id, ...userProfile } = review

  const { contentsSectionLog, localBizLog } = useLogger()
  const { openTarget } = useOpenTarget()

  const handleReviewClick = () => {
    contentsSectionLog('click', {
      contentsType: 'native_reviews',
      buttonType: 'list',
      params: {
        click_target: 'review',
        reviewId: id,
      },
    })

    localBizLog({
      name: 'click_nearby_tobaki_section_list',
      params: { review_id: externalId.toString() },
    })

    openTarget({
      remote: `${POI_REMOTE_APP}#/native-review/list?highlight_review_id=${externalId}&referrer=nearby.home.native_review_section`,
    })
  }

  const renderImages = () => {
    if (!images ?? images.length === 0) {
      return null
    }

    return images
      .filter((image) => !!image.thumbnail)
      .filter((_, i) => i < 1)
      .map((image) => image.thumbnail!)
      .map((imageUrl, i) => (
        <Image key={i}>
          <CoveredLazyLoadImage effect="opacity" src={imageUrl} height={70} visibleByDefault />
          {images.length > 1 && <Overlay>{images.length}</Overlay>}
        </Image>
      ))
  }

  return (
    <Card onClick={handleReviewClick}>
      <Content>
        <Left>
          <HomeNativeReviewItemUser reviewId={externalId.toString()} userProfileNativeReview={userProfile} />
          <Review>
            <Text>{content}</Text>
          </Review>
        </Left>
        {renderImages()}
      </Content>
      <HomeNativeReviewItemPoi reviewId={externalId.toString()} poiNativeReview={poi} />
    </Card>
  )
}

const Card = styled.div`
  position: relative;
  margin: 0 1rem;
  padding: 1.25rem 0;
  border-bottom: 1px solid ${vars.$semantic.color.divider1};
  :last-of-type {
    border-bottom: none;
  }
`
const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 0 0 1rem 0;
`

const Left = styled.div``

const Review = styled.div`
  display: flex;
  flex-direction: column;
`

const Text = styled.div`
  ${vars.$semantic.typography.bodyL1Regular}
  display: -webkit-box !important;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
`

const Image = styled.div`
  position: relative;
  width: 70px;
  height: 70px;
  min-width: 70px;
  min-height: 70px;
  border-radius: 6px;
  overflow: hidden;
  margin: 0 0 0 0.75rem;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const Overlay = styled.div`
  ${vars.$semantic.typography.label5Bold}
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  border-bottom-right-radius: 6px;
  background-color: ${vars.$semantic.color.overlayDim};
  color: ${vars.$static.color.staticWhite};
`

export default HomeNativeReviewItem
