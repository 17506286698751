import { Map } from '@daangn/karrotmaps-react'
import React, { useMemo } from 'react'
import { useRecoilValue } from 'recoil'

import { initialMapCoordinates } from '@src/components/_lib/MapUtils/initialMapCoordinates'
import { type Position } from '@src/currentPositionResource'
import Markers from '@src/features/couponMap/components/Markers/Markers'
import { type TMarker } from '@src/features/couponMap/components/Markers/types'
import { currentPositionAtom } from '@src/features/user/state/currentPosition'
import { useViewer } from '@src/hooks/useViewer'
import { type PoiTabCategoryEnum } from '@src/types/schemaEnums'

export type CoordsWithOrder = Array<{
  coords: Position
  label?: string
  itemOrder: number
}> | null

interface HomeMapKarrotMapProps {
  selectedCategory: PoiTabCategoryEnum
  coordsWithOrder: CoordsWithOrder
  viewCurrentPosition?: boolean
  isExtendsCurrentPosition?: boolean
  onClick?: () => void
  onError?: () => void
  enableInteraction?: boolean
}
const HomeMapKarrotMap: React.FC<HomeMapKarrotMapProps> = ({
  coordsWithOrder,
  enableInteraction = false,
  viewCurrentPosition,
  isExtendsCurrentPosition = true,
  onClick,
  onError,
}) => {
  const viewer = useViewer()

  const currentPosition = useRecoilValue(currentPositionAtom)
  const regionCenterCoords = viewer.region.centerCoordinates
  const initialCoords = initialMapCoordinates(currentPosition, {
    lat: regionCenterCoords.latitude,
    lng: regionCenterCoords.longitude,
  })

  const userMarker =
    isExtendsCurrentPosition && viewCurrentPosition && initialCoords
      ? {
          id: 'user',
          type: 'User',
          position: initialCoords,
        }
      : null

  const pinMarkers: Array<TMarker | null> = useMemo(
    () =>
      coordsWithOrder
        ? coordsWithOrder.map((item) =>
            item.coords
              ? {
                  type: 'Pin',
                  isSelected: false,
                  label: item.label,
                  position: item.coords,
                }
              : null
          )
        : [],
    [coordsWithOrder]
  )

  const allMarkers = [...pinMarkers, userMarker].filter(Boolean) as TMarker[]

  const AllMarkers = <Markers markers={allMarkers} isFitBounds />

  return (
    <Map
      onClick={onClick}
      onError={onError}
      options={{
        center: initialCoords,
        zoom: 11,
        interactive: enableInteraction,
      }}
      style={{
        width: '100%',
        height: '100%',
        borderRadius: '0.25rem',
        transform: 'translate3d(0, 0, 0)',
      }}>
      {AllMarkers}
    </Map>
  )
}

export default HomeMapKarrotMap
