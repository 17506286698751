/**
 * @generated SignedSource<<b01f6def6ad9163ea08cc26ab90d9068>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type NearbyBadgeEnum = "COUPON_EXISTS" | "DELIVERY" | "FAST_RESPONSE" | "FREE_DELIVERY" | "HAS_TRIAL" | "MOST_REVIEW" | "MOST_VIEW" | "SAME_DAY_DELIVERY" | "WALK_10_MIN";
import { FragmentRefs } from "relay-runtime";
export type BizAccountListItem_BizAccount$data = {
  readonly badges: ReadonlyArray<NearbyBadgeEnum>;
  readonly categoryName: string | null;
  readonly description: string | null;
  readonly distance: {
    readonly distance: number;
    readonly name: string;
  } | null;
  readonly externalId: string;
  readonly followerCount: number;
  readonly hasCoupon: boolean;
  readonly id: string;
  readonly image: {
    readonly thumbnail: string;
    readonly url: string;
  };
  readonly images: ReadonlyArray<{
    readonly url: string;
  }>;
  readonly isNew: boolean;
  readonly name: string;
  readonly onSale: boolean;
  readonly reservable: boolean;
  readonly reviewCount: number;
  readonly starRating: number | null;
  readonly targetUri: string;
  readonly viewerIsWatched: boolean;
  readonly " $fragmentType": "BizAccountListItem_BizAccount";
};
export type BizAccountListItem_BizAccount$key = {
  readonly " $data"?: BizAccountListItem_BizAccount$data;
  readonly " $fragmentSpreads": FragmentRefs<"BizAccountListItem_BizAccount">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BizAccountListItem_BizAccount",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "externalId",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "categoryName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "badges",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasCoupon",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "onSale",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "reservable",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isNew",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "starRating",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DistanceInfo",
      "kind": "LinkedField",
      "name": "distance",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "distance",
          "storageKey": null
        },
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "followerCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "reviewCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Image",
      "kind": "LinkedField",
      "name": "image",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "thumbnail",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Image",
      "kind": "LinkedField",
      "name": "images",
      "plural": true,
      "selections": [
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "targetUri",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "viewerIsWatched",
      "storageKey": null
    }
  ],
  "type": "BizAccount",
  "abstractKey": null
};
})();

(node as any).hash = "cdfc2861aaba0ac55d7e83948c14f238";

export default node;
