import { type PropOf } from '@cometjs/react-utils'
import { usePullToRefreshScrollElement } from '@daangn/karrot-clothes/lib/pullToRefresh'
import styled from '@emotion/styled'
import { format, startOfWeek } from 'date-fns'
import React, { useCallback, useMemo, useRef } from 'react'
import { graphql, useFragment } from 'react-relay'

import { type HomePopularServiceField_SectionPopularServiceField$key } from '@src/__relay__/HomePopularServiceField_SectionPopularServiceField.graphql'
import { useLogger } from '@src/hooks/useLogger'
import { useOpenTarget } from '@src/lib/target'
import { type SectionInfo } from '@src/types'

import HomePopularServiceFieldBasicList from './HomePopularServiceFieldBasicList/HomePopularServiceFieldBasicList'
import HomeSectionBase from '../Common/HomeSectionBase'

interface Props {
  sectionPopularServiceFieldRef: HomePopularServiceField_SectionPopularServiceField$key
  sectionInfo: SectionInfo
}

const HomePopularServiceField = ({ sectionInfo, sectionPopularServiceFieldRef }: Props) => {
  const sectionPopularServiceField = useFragment(
    graphql`
      fragment HomePopularServiceField_SectionPopularServiceField on SectionPopularServiceField {
        loggingMeta {
          swipe
          click
          show
        }
        serviceFields {
          id
        }

        ...HomeSectionBase_sectionBase
        
        ...HomePopularServiceFieldBasicList_SectionPopularServiceField
        
      }
    `,
    sectionPopularServiceFieldRef
  )
  const scrollContainerRef = usePullToRefreshScrollElement()
  const { contentsSectionLog, nearbyLog, loggingMetaLog } = useLogger()
  const handleImpression = () => {
    nearbyLog({
      params: {
        name: 'show_contents_section',
        contents_type: 'popular_service_field',
        sectionOrder: sectionInfo.order,
      },
    })

    if (sectionInfo.order === 1) {
      loggingMetaLog(sectionPopularServiceField.loggingMeta.show)
    }
  }
  const { openTarget } = useOpenTarget()
  const handleMoreClick: NonNullable<PropOf<typeof HomeSectionBase, 'onMoreClick'>> = useCallback(
    ({ targetUri }) => {
      contentsSectionLog('click', {
        contentsType: 'popular_service_field',
        buttonType: 'more',
        params: {
          sectionOrder: sectionInfo.order,
        },
      })

      loggingMetaLog(sectionPopularServiceField.loggingMeta.click)

      if (targetUri) {
        openTarget({
          targetUri,
        })
      }
    },
    [contentsSectionLog, sectionInfo.order, loggingMetaLog, sectionPopularServiceField.loggingMeta.click, openTarget]
  )
  const subTitle = useMemo(() => {
    const today = new Date()
    const startOfThisWeek = startOfWeek(today, { weekStartsOn: 1 })
    const formattedDate = format(startOfThisWeek, 'MM/dd일 기준 주차별 순위')
    return formattedDate
  }, [])

  const checkerElementRef = useRef<HTMLDivElement>(null)

  const handleClickItem = useCallback(
    (serviceField: string) => {
      contentsSectionLog('click', {
        contentsType: 'popular_service_field',
        buttonType: 'list',
        params: {
          sectionOrder: sectionInfo.order,
          serviceField,
        },
      })

      loggingMetaLog(sectionPopularServiceField.loggingMeta.click)
    },
    [contentsSectionLog, sectionInfo.order, loggingMetaLog, sectionPopularServiceField.loggingMeta.click]
  )

  return (
    <Base ref={checkerElementRef}>
      <HomeSectionBase
        sectionBase={sectionPopularServiceField}
        ImpressionProps={{
        scrollContainerRef,
        onImpression: handleImpression,
        threshold: 0.9,
        }}
        SectionTitleProps={{ subtitle: subTitle }}
        ServiceTitleProps={{ serviceType: 'POPULAR_SERVICE_FIELD' }}
        onMoreClick={handleMoreClick}>
          <HomePopularServiceFieldBasicList sectionPopularServiceFieldRef={sectionPopularServiceField} onClickItem={handleClickItem}/>
      </HomeSectionBase>
    </Base>
  )
}

const Base = styled.div``

export default HomePopularServiceField
