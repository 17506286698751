/**
 * @generated SignedSource<<123bb56939b62e1874da45160f2c1f86>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ManufacturerEnum = "ACURA" | "ASTON_MARTIN" | "AUDI" | "BAIC" | "BENTLEY" | "BENZ" | "BMW" | "CADILLAC" | "CAMMSYS" | "CHEVROLET" | "CHEVROLET_GM_DAEWOO" | "CHRYSLER" | "CITROEN" | "CYAN" | "DAECHANG_MOTORS" | "DAEWOO_BUS" | "DFSK" | "DODGE" | "DS" | "ETC" | "FERRARI" | "FIAT" | "FORD" | "FOTON" | "FOX" | "GENESIS" | "GMC" | "HONDA" | "HUMMER" | "HYUNDAI" | "INFINITI" | "ISUZU" | "JAGUAR" | "JEEP" | "KIA" | "LAMBORGHINI" | "LAND_ROVER" | "LEXUS" | "LINCOLN" | "LOTUS" | "MAIV" | "MASERATI" | "MAYBACH" | "MAZDA" | "MC_LAREN" | "MINI" | "MITSUBISHI" | "NISSAN" | "OULLIM" | "PEUGEOT" | "PORSCHE" | "POWER_PLAZA" | "RENAULT_SAMSUNG" | "ROLLS" | "SAAB" | "SMART" | "SSANGYOUNG" | "SUBARU" | "SUZUKI" | "TESLA" | "TOYOTA" | "VOLKSWAGEN" | "VOLVO" | "ZHIDOU";
import { FragmentRefs } from "relay-runtime";
export type HotCarArticle_carArticle$data = {
  readonly articleManufacturerString: string;
  readonly carName: string;
  readonly externalId: string;
  readonly id: string;
  readonly image: {
    readonly thumbnail: string;
  };
  readonly leaseMonthlyFee: number | null;
  readonly manufacturer: ManufacturerEnum;
  readonly price: number | null;
  readonly rentalMonthlyFee: number | null;
  readonly targetUri: string;
  readonly title: string;
  readonly viewCountInLast3Hours: number;
  readonly " $fragmentType": "HotCarArticle_carArticle";
};
export type HotCarArticle_carArticle$key = {
  readonly " $data"?: HotCarArticle_carArticle$data;
  readonly " $fragmentSpreads": FragmentRefs<"HotCarArticle_carArticle">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HotCarArticle_carArticle",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "externalId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "carName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "price",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "targetUri",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Image",
      "kind": "LinkedField",
      "name": "image",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "thumbnail",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "leaseMonthlyFee",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rentalMonthlyFee",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "manufacturer",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "articleManufacturerString",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "viewCountInLast3Hours",
      "storageKey": null
    }
  ],
  "type": "CarArticle",
  "abstractKey": null
};

(node as any).hash = "88fd2f439fdc88ca5f31304429c2db76";

export default node;
