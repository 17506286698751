import { usePullToRefreshScrollElement } from '@daangn/karrot-clothes/lib/pullToRefresh'
import styled from '@emotion/styled'
import { type FC, useCallback, useRef } from 'react'
import { graphql, useFragment } from 'react-relay'

import RecentlyRealtyArticle, { REALTY_SALES_TYPE_MAP } from './RecentlyRealtyArticle'
import { type HomeRecentlyRealtyArticles_sectionRecentlyArticle$key } from '../../../../__relay__/HomeRecentlyRealtyArticles_sectionRecentlyArticle.graphql'
import { useSwipeSection } from '../../../../hooks/sections/useSwipeSection'
import { useLogger } from '../../../../hooks/useLogger'
import { type PropOf } from '../../../../lib/T/utilTypes'
import { useOpenTarget } from '../../../../lib/target'
import type { SectionInfo } from '../../../../types'
import { ScrollableX } from '../../../_lib/Scrollable'
import HomeSectionBase from '../Common/HomeSectionBase'

interface HomeRecentlyRealtyArticlesProps {
  sectionRealtyArticle: HomeRecentlyRealtyArticles_sectionRecentlyArticle$key
  sectionInfo: SectionInfo
}

const HomeRecentlyRealtyArticles: FC<HomeRecentlyRealtyArticlesProps> = ({ sectionInfo, ...props }) => {
  const section = useFragment(
    graphql`
      fragment HomeRecentlyRealtyArticles_sectionRecentlyArticle on SectionRecentlyRealtyArticle {
        __typename
        loggingMeta {
          swipe
          click
          show
        }
        articles {
          id
          ...RecentlyRealtyArticle_realtyArticle
        }
        ...HomeSectionBase_sectionBase
      }
    `,
    props.sectionRealtyArticle
  )

  const scrollContainerRef = usePullToRefreshScrollElement()
  const { contentsSectionLog, loggingMetaLog, nearbyLog } = useLogger()
  const { openTarget } = useOpenTarget()
  const itemScrollContainerRef = useRef<HTMLDivElement>(null)

  useSwipeSection({
    swipeContainerRef: itemScrollContainerRef,
    sectionOrder: sectionInfo.order,
  })

  const handleImpression = useCallback(() => {
    contentsSectionLog('show', {
      contentsType: 'realty_new_listing',
      sectionOrder: sectionInfo.order,
    })

    if (sectionInfo.order === 0) {
      loggingMetaLog(section.loggingMeta.show)
    }
  }, [contentsSectionLog, loggingMetaLog, sectionInfo.order, section.loggingMeta.show])

  const handleMoreClick: NonNullable<PropOf<typeof HomeSectionBase, 'onMoreClick'>> = useCallback(
    ({ targetUri }) => {
      contentsSectionLog('click', {
        contentsType: 'realty_new_listing',
        buttonType: 'main',
        params: {
          sectionOrder: sectionInfo.order,
        },
      })

      loggingMetaLog(section.loggingMeta.click)

      if (targetUri) {
        openTarget({
          targetUri,
        })
      }
    },
    [contentsSectionLog, loggingMetaLog, openTarget, sectionInfo.order, section.loggingMeta.click]
  )

  const handleArticleClick: PropOf<typeof RecentlyRealtyArticle, 'onClick'> = useCallback(
    (article, itemOrder) => {
      contentsSectionLog('click', {
        contentsType: 'realty_new_listing',
        buttonType: 'list',
        params: {
          item_id: article.externalId,
          item_type: REALTY_SALES_TYPE_MAP[article.realtyType],
          is_video_thumbnail: String(!!article.videoThumbnailUrl),
          item_order: itemOrder,
          sectionOrder: sectionInfo.order,
        },
      })

      loggingMetaLog(section.loggingMeta.click)

      if (article.targetUri) {
        openTarget({
          targetUri: article.targetUri,
        })
      }
    },
    [contentsSectionLog, loggingMetaLog, openTarget, sectionInfo.order, section.loggingMeta.click]
  )

  const handleItemImpression: PropOf<typeof RecentlyRealtyArticle, 'onImpression'> = useCallback(
    (article, itemOrder, impressionType) => {
      switch (impressionType) {
        case 'swipe':
          contentsSectionLog('swipe', {
            contentsType: 'realty_new_listing',
            itemOrder: itemOrder,
            params: {
              sectionOrder: sectionInfo.order,
            },
          })

          loggingMetaLog(section.loggingMeta.swipe)
          break

        case 'show': {
          nearbyLog({
            params: {
              name: 'show_contents_section_item',
              contents_type: 'realty_new_listing',
              item_id: article.externalId,
              item_type: REALTY_SALES_TYPE_MAP[article.realtyType],
              is_video_thumbnail: String(!!article.videoThumbnailUrl),
              item_order: itemOrder,
              section_order: sectionInfo.order,
            },
          })
          break
        }
      }
    },
    [contentsSectionLog, loggingMetaLog, sectionInfo.order, section.loggingMeta.swipe, nearbyLog]
  )

  return (
    <HomeSectionBase
      sectionBase={section}
      ImpressionProps={{
        scrollContainerRef,
        onImpression: handleImpression,
        threshold: 0.9,
      }}
      onMoreClick={handleMoreClick}>
      <Scrollable ref={itemScrollContainerRef}>
        {section.articles.map((article, index) => (
          <RecentlyRealtyArticle
            key={article.id}
            realtyArticle={article}
            itemOrder={index}
            itemScrollContainerRef={itemScrollContainerRef}
            onClick={handleArticleClick}
            onImpression={handleItemImpression}
          />
        ))}
      </Scrollable>
    </HomeSectionBase>
  )
}

export default HomeRecentlyRealtyArticles

const Scrollable = styled(ScrollableX)`
  padding: 0.75rem 1rem 1rem;
`
