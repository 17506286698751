import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import React from 'react'

import IcBizPost from '../../../../assets/images/service_category/ic_nearbyStore.svg'
import { BIZ_PLATFORM_APP } from '../../../../constants'
import { type OriginalTarget } from '../../../../lib/target'

interface WriteBizPostProps {
  onClick: (target: OriginalTarget) => void
}
const WriteBizPost: React.FC<WriteBizPostProps> = (props) => {
  const onClick = () => {
    props.onClick({
      remote: `${BIZ_PLATFORM_APP}/biz_accounts/manager/posts/new`,
      present: 'top',
    })
  }

  return (
    <Button onClick={onClick}>
      <Item>
        <IconContainer>
          <IcBizPost />
        </IconContainer>
        <ItemLabel>{'업체 소식 작성하기'}</ItemLabel>
      </Item>
    </Button>
  )
}

const Item = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
`

const Button = styled.div`
  margin-bottom: 1.25rem;
  -webkit-tap-highlight-color: transparent;

  &:last-of-type {
    margin-bottom: 1.25rem;
  }
`

const IconContainer = styled.div`
  width: 1.5rem;
  height: 1.5rem;

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`

const ItemLabel = styled.div`
  color: ${vars.$scale.color.gray900};
  margin-left: 0.75rem;
  font-size: 1rem;
  line-height: 1.625rem;
`

export default WriteBizPost
